import getConfig from 'next/config';
import { createContext, PropsWithChildren, useContext, useState } from 'react';
const { publicRuntimeConfig } = getConfig();
type AppSettingsContextType = { [key in string]: any };

export const AppSettingsContext = createContext<AppSettingsContextType>({
  ...publicRuntimeConfig?.currentAppConfig
});

export const AppSettingsProvider = ({ children }: PropsWithChildren) => {
  const [isPlayingGame, setIsPlayingGame] = useState<boolean>(false);
  const [triggerCloseGame, setTriggerCloseGame] = useState<boolean>(false);
  return (
    <AppSettingsContext.Provider
      value={{
        ...publicRuntimeConfig?.currentAppConfig,
        isPlayingGame,
        setIsPlayingGame,
        triggerCloseGame,
        setTriggerCloseGame
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  );
};

export const useAppSettings = () => useContext(AppSettingsContext);
