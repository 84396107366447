import { css } from '@emotion/react';

const ScrollWithoutScrollBar = css`
  overflow: scroll;
  overflow-y: hidden;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export default ScrollWithoutScrollBar;
