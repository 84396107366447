import useTranslation from 'next-translate/useTranslation';
import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react';
import {
  getBackgroundUrl,
  ModalHitProps,
  SVGModalHit
} from '@/components/Jackpots';
import { getJackpotColor } from '@/helpers/jackpot';

export const ModalHitDesktop = ({
  onCloseModal,
  isOpen,
  level,
  message,
  amount
}: ModalHitProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      onOverlayClick={onCloseModal}
      size={'3xl'}
    >
      <ModalOverlay />
      <ModalContent
        p={'0!'}
        bg={'black!'}
        maxW={'720px'}
        w={'100%'}
        m={10}
        borderColor={getJackpotColor(level)}
      >
        <ModalBody
          position={'relative'}
          p={'0!'}
          borderRadius={'2rem'}
          overflow={'hidden'}
          display={'grid'}
          height={'100%'}
          gridTemplateColumns={'1fr'}
          gridTemplateRows={'4fr 11fr'}
          sx={{ '> *': { gridColumn: '1 / 2', gridRow: '1 / 3' } }}
        >
          <Box pt={'1.5rem'} pb={'6rem'} px={14} zIndex={1} alignSelf={'start'}>
            <SVGModalHit level={level} amount={amount} />
            <Box
              textAlign={'center'}
              mt={'1.5rem'}
              mx={'auto'}
              maxWidth={'60%'}
              sx={{
                h2: {
                  fontSize: '2rem',
                  fontWeight: '700',
                  lineHeight: 1.1
                },
                p: {
                  fontWeight: '400',
                  m: 0
                }
              }}
              dangerouslySetInnerHTML={{
                __html: message ?? ''
              }}
            />
          </Box>

          {/*TreasureImage*/}
          <Box gridRow={'2 / 3'} alignSelf={'end'}>
            <Box w={'100%'} style={{ aspectRatio: '1.9' }} opacity={0.3}>
              <Image
                alt={'treasure'}
                src={getBackgroundUrl(level, false)}
                objectFit={'cover'}
                objectPosition={'top'}
                width={'100%'}
                height={'100%'}
              />
            </Box>
          </Box>

          {/*CTA*/}

          <Button
            zIndex={1}
            alignSelf={'end'}
            background="buttonPrimary"
            display={'flex'}
            mx={'auto'}
            onClick={onCloseModal}
            mt={'1.5rem'} // 24
            mb={'2.5rem'} // 40
            height={'2.25rem'} // 36
          >
            {t('jackpot:close')}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
