import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  UseModalProps
} from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

type ConfirmModalProps = PropsWithChildren & UseModalProps;

const ConfirmModal = ({ children, ...props }: ConfirmModalProps) => {
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent padding="1rem">
        <ModalCloseButton />
        {children}
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
