const ibanValidator = (value: string) => {
  let rearrange = value.substring(4, value.length) + value.substring(0, 4);
  let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  let alphaMap: any = {};
  let number: number[] = [];

  alphabet.forEach((value: string, index: number) => {
    alphaMap[value] = index + 10;
  });

  rearrange.split('').forEach((value, index) => {
    number[index] = alphaMap[value] || value;
  });

  return modulo(number.join('').toString(), 97) === 1;
};

export default ibanValidator;

const modulo = (aNumStr: string, aDiv: number) => {
  let tmp = '';
  let i, r;
  for (i = 0; i < aNumStr.length; i++) {
    tmp += aNumStr.charAt(i);
    r = +tmp % aDiv;
    tmp = r.toString();
  }
  return +tmp / 1;
};

// export default ibanValidator;
