import { useCatalog } from '@/context/Catalog';
import { GameCatalogItem } from '@/services/getCatalog';
import {
  HStack,
  StackProps,
  Tag,
  useDisclosure,
  Box,
  Collapse,
  Text,
  Tooltip
} from '@chakra-ui/react';

type TagListProps = StackProps & {
  game: GameCatalogItem;
  minNumberOfTags?: number;
};

const TagList = ({ game, minNumberOfTags = 2, ...props }: TagListProps) => {
  const { dataStore } = useCatalog();
  const { isOpen, onToggle } = useDisclosure();
  const listOfTags = dataStore?.providers?.[game.provider as string]
    ? [dataStore?.providers?.[game.provider as string]?.name, ...game.tags]
    : game.tags;
  return (
    <Collapse startingHeight={20} in={isOpen} style={{ marginTop: '1.25rem' }}>
      <HStack marginBottom="0.5rem" {...props}>
        {listOfTags?.slice?.(0, minNumberOfTags).map((tag) => (
          <Tag
            key={tag}
            fontSize="9px"
            fontWeight="bold"
            textTransform="uppercase"
            data-testid={`tag-${tag}`}
            variant="withoutBg"
          >
            <Tooltip label={tag}>
              <Text noOfLines={1}>{tag}</Text>
            </Tooltip>
          </Tag>
        ))}
        {listOfTags?.length >= minNumberOfTags && (
          <Tag
            onClick={onToggle}
            data-testid="more-tags"
            cursor="pointer"
            fontSize="9px"
            fontWeight="bold"
            textTransform="uppercase"
            variant="withoutBg"
          >
            ...
          </Tag>
        )}
      </HStack>
      <Box>
        {listOfTags?.slice?.(minNumberOfTags, listOfTags.length).map((tag) => (
          <Tag
            key={tag}
            fontSize="9px"
            fontWeight="bold"
            textTransform="uppercase"
            marginRight="0.5rem"
            marginBottom="0.5rem"
            variant="withoutBg"
          >
            <Text noOfLines={1}>{tag}</Text>
          </Tag>
        ))}
      </Box>
    </Collapse>
  );
};

export default TagList;
