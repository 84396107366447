import {
  ChakraProvider,
  ChakraProviderProps,
  cookieStorageManagerSSR,
  localStorageManager,
  useColorMode
} from '@chakra-ui/react';
import { GetServerSideProps } from 'next';

import { PropsWithChildren, useEffect } from 'react';

type ChakraWrapperProps = PropsWithChildren &
  ChakraProviderProps & {
    cookies?: string;
    theme: any;
  };

export const ChakraWrapper = ({
  cookies,
  children,
  theme,
  ...props
}: ChakraWrapperProps) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const colorModeManager =
    typeof cookies === 'string'
      ? cookieStorageManagerSSR(cookies)
      : localStorageManager;

  // I don't understand why but chakra init the app with the light mode even if there is not allusion of that in our code.
  // config is set with dark for all theme.
  // So, force colormode at runtime
  useEffect(() => {
    if (theme?.config.initialColorMode) {
      window?.localStorage.setItem(
        'chakra-ui-color-mode',
        theme.config.initialColorMode
      );
      document.cookie = `chakra-ui-color-mode=${theme.config.initialColorMode}; max-age=31536000; path=/`;
    }
    if (typeof document.querySelector('html') !== null) {
      const html = document.querySelector('html');
      html ? (html.style.colorScheme = 'dark') : null;
      const body = document.querySelector('body');
      body?.classList.remove('chakra-ui-light');
      body?.classList.add('chakra-ui-dark');
      html?.setAttribute('data-theme', 'dark');
    }
  }, [theme?.config.initialColorMode]);

  useEffect(() => {
    if (colorMode === 'dark') return;
    toggleColorMode?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorMode]);
  return (
    <ChakraProvider
      theme={theme}
      resetCSS={true}
      colorModeManager={colorModeManager}
      {...props}
    >
      {children}
    </ChakraProvider>
  );
};

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  return {
    props: {
      cookies: req?.headers?.cookie ?? ''
    }
  };
};
