import { HStack, StackProps } from '@chakra-ui/react';
import { SmarticoButton } from '@/components/Smartico/SmarticoButton';
import NotificationOverview from '@/components/Notification/User/NotificationOverview';
import React from 'react';
import { NotificationButton } from '@/components/Button/NotificationButton';
import { WalletButton } from '@/components/Button/WalletButton';

type UserMenuButtonsProps = StackProps & {
  isPlaying: boolean;
  inMenu: boolean;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  handleClose?: () => void;
  showSmarticoButton: boolean;
  onClickNotification?: () => void;
  onClickWallet?: () => void;
};
export const UserMenuButtons = ({
  isPlaying,
  inMenu,
  handleClose,
  isOpen,
  onOpen,
  onClose,
  showSmarticoButton,
  onClickNotification,
  onClickWallet,
  ...props
}: UserMenuButtonsProps) => {
  const buttonStyles = {
    flex: '0 0 36px',
    maxWidth: '36px',
    width: '36px',
    padding: '0',
    borderRadius: '10px',
    height: '100%'
  };
  return (
    <HStack
      position="relative"
      data-testid="catalog-tiny-info"
      justify="center"
      {...props}
      spacing={0}
      gap={4}
    >
      <WalletButton
        onClick={onClickWallet}
        target={isPlaying ? '_blank' : undefined}
        closeMenu={() => {
          if (inMenu && handleClose) handleClose();
        }}
        {...buttonStyles}
        iconProps={{ mb: '1px', ml: '1px', width: '1.5rem', height: '1.5rem' }}
      />

      <NotificationButton
        onClick={onClickNotification}
        variant="outline"
        lineHeight="50px"
        bulletPosition={{ top: '.75rem', right: '-.15rem' }}
        {...buttonStyles}
      />

      {showSmarticoButton && (
        <SmarticoButton lineHeight="1rem" {...buttonStyles} />
      )}

      <NotificationOverview />
    </HStack>
  );
};
