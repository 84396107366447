import { InitialFieldsType, useFilters } from '@/context/Filters';
import { toRem } from '@/helpers/toRem';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  ExpandedIndex,
  HStack,
  Text,
  TextProps,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useMemo, useState } from 'react';
import { CircleIconButton } from '..';
import { useCatalog } from '@/context/Catalog';
import useMediaQuery from '../../hooks/useMediaQuery';
import { ArrowDown, CloseIcon, FilterLinesIcon } from '@/theme/Icons';
import FilterCheckboxList from '../GameFilters/FilterCheckboxList';
import { GameFilterType } from '../GameFilters/GameFilters.types';

type FiltersProps = BoxProps;
type FilterButtonProps = BoxProps & {
  onOpen: () => void;
};

const MobileButton = ({ onOpen, ...props }: FilterButtonProps) => {
  const { hasChanges, numberOfFilterSectionsActive } = useFilters();
  return (
    <Box
      onClick={onOpen}
      aria-label="filter button"
      position="relative"
      cursor="pointer"
      borderRadius={0}
      {...props}
    >
      <FilterLinesIcon w={toRem(36)} h={toRem(36)} color="white" />
      {numberOfFilterSectionsActive > 0 ? (
        <Button as="sup" variant="outline" size="xs">
          {numberOfFilterSectionsActive}
        </Button>
      ) : null}
    </Box>
  );
};

const Filters = ({ ...props }: FiltersProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useMediaQuery('(max-width: md)');
  const { dataStore } = useCatalog();

  const { t } = useTranslation();
  const {
    filterFields,
    reset,
    resetAll,
    setFilterFields,
    numberOfFilterSectionsActive,
    countByProvider
  } = useFilters();

  const initOpenendFilters = [0];
  const [index, setIndex] = useState<number[]>(initOpenendFilters);
  const [isFilterFullOpen, setIsFilterFullOpen] = useState<boolean>(false);

  const CountBadge = ({
    keyFilter,
    count,
    ...props
  }: TextProps & {
    keyFilter?: 'themes' | 'features' | 'bonuses' | 'providers' | 'volatility';
    count?: number;
  }) => {
    const choicesLength = keyFilter
      ? filterFields?.[keyFilter]?.length
      : count ?? 0;
    return (
      <>
        {choicesLength > 0 ? (
          <Text
            fontSize="0.8rem"
            fontWeight="500"
            backgroundColor="buttonPrimary"
            padding="0 0.4rem"
            borderRadius="full"
            display="flex"
            width="1.2rem"
            height="1.2rem"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            color="rgba(255, 255, 255, 1)"
            {...props}
          >
            {choicesLength}
          </Text>
        ) : null}
      </>
    );
  };

  const sortByName = (datas: object) => {
    const sort = Object.entries(datas).sort(function (a: any, b: any) {
      if (a[1].name.toUpperCase() > b[1].name.toUpperCase()) return 1;
      if (a[1].name.toUpperCase() < b[1].name.toUpperCase()) return -1;
      return 0;
    });
    return Object.fromEntries(sort);
  };

  const filterObjectsByKeys = (
    inputObject: Record<string, any>,
    validKeys: string[]
  ): Record<string, any> => {
    const filteredObject: Record<string, any> = {};

    for (const key of validKeys) {
      if (inputObject.hasOwnProperty(key)) {
        filteredObject[key] = inputObject[key];
      }
    }

    return filteredObject;
  };

  const providerWithGame = Object.keys(countByProvider);
  const filteredProviderWithNoGames = filterObjectsByKeys(
    dataStore?.providers || {},
    providerWithGame
  );

  const filtersData: GameFilterType[] = [
    // {
    //   label: t('catalog:exclusivity'),
    //   component: FilterExclusivity,
    //   key: 'exclusive',
    //   isStandalone: true,
    //   data: {},
    //   hasValue:
    //     filterFields?.exclusive === true || filterFields?.exclusive === false,
    //   onChange: (data: boolean) =>
    //     setFilterFields({ ...filterFields, exclusive: data })
    // },
    {
      label: t('catalog:provider'),
      data: sortByName(filteredProviderWithNoGames),
      component: FilterCheckboxList,
      key: 'providers',
      hasValue: filterFields?.providers?.length > 0,
      onChange: (providers: string[]) => {
        setFilterFields({ ...filterFields, providers });
      },
      badge: <CountBadge keyFilter={'providers'} />,
      isFoldable: false
    },
    {
      label: t('catalog:themes'),
      component: FilterCheckboxList,
      data: Object.entries(sortByName(dataStore?.themes || {})).reduce(
        (acc: any, [id, theme]) => {
          // const foundTranslation = (theme as any).translations.find(
          //   (translation: ThemeTranslation) => translation.locale === lang
          // );
          acc[id] = theme;
          return acc; //sortObjectByAttribute(acc, 'name');
        },
        {}
      ),
      key: 'themes',
      hasValue: filterFields?.themes?.length > 0,
      onChange: (themes: string[]) =>
        setFilterFields({ ...filterFields, themes }),
      badge: <CountBadge keyFilter={'themes'} />, //filterFields?.themes && <Text>{filterFields?.themes?.length}</Text>
      isFoldable: true
    },
    {
      label: t('catalog:volatility'),
      data: {
        1: { '@id': 1, name: '1' },
        2: { '@id': 2, name: '2' },
        3: { '@id': 3, name: '3' },
        4: { '@id': 4, name: '4' },
        5: { '@id': 5, name: '5' }
      },
      component: FilterCheckboxList,
      key: 'volatility',
      hasValue: filterFields?.volatility?.length > 0,
      onChange: (volatility: number[]) => {
        const toN = volatility.map((item) => +item);
        setFilterFields({ ...filterFields, volatility: toN });
      },
      badge: <CountBadge keyFilter={'volatility'} />,
      isFoldable: true
    }
    // {
    //   label: t('catalog:features'),
    //   component: FilterCheckboxList,
    //   data: dataStore?.features,
    //   key: 'features',
    //   hasValue: filterFields?.features?.length > 0,
    //   onChange: (features: string[]) =>
    //     setFilterFields({ ...filterFields, features }),
    //   badge: <CountBadge keyFilter={'features'} />
    // },
    // {
    //   label: t('catalog:bonuses'),
    //   component: FilterCheckboxList,
    //   data: dataStore?.bonuses,
    //   key: 'bonuses',
    //   hasValue: filterFields?.bonuses?.length > 0,
    //   onChange: (bonuses: string[]) =>
    //     setFilterFields({ ...filterFields, bonuses }),
    //   badge: <CountBadge keyFilter={'bonuses'} />
    // },
    // {
    //   label: t('catalog:betLimit'),
    //   component: FilterBetLimit,
    //   data: {},
    //   key: 'betLimit',
    //   onChange: (FilterBetLimit: BetLimit) => {
    //     setFilterFields({ ...filterFields, betLimit: FilterBetLimit });
    //   },
    //   badge: filterFields?.betLimit && (
    //     <HStack
    //       fontWeight="bold"
    //       fontSize="1rem"
    //       color="rgba(142, 148, 179, 1)"
    //     >
    //       <Text>{filterFields.betLimit.min}€</Text>
    //       <Text>-</Text>
    //       <Text>{filterFields.betLimit.max}€</Text>
    //     </HStack>
    //   )
    // }
  ];

  const onChangeAccordion = (elm: ExpandedIndex) => {
    setIndex(elm as number[]);
  };

  const coreFilter = useMemo(() => {
    const CoreFilterComponent = () => (
      <VStack width={'100%'} id="coreFilter()">
        <Accordion
          width="100%"
          allowMultiple
          variant={'withoutBorder'}
          index={index}
          onChange={onChangeAccordion}
        >
          <>
            {filtersData
              .filter(
                (filter) =>
                  isFilterFullOpen || !filter.isFoldable || filter.hasValue
              )
              .map((filterItem, key) => {
                const GenComponent = filterItem.component;
                const filterKey = filterItem.key as keyof InitialFieldsType;
                const renderComponent = (
                  content: any,
                  isStandalone: boolean = false
                ) =>
                  isStandalone ? (
                    <>{content}</>
                  ) : (
                    <AccordionItem
                      key={key}
                      border="none"
                      width="100%"
                      borderBottom="1px solid rgba(255,255,255, .2)"
                    >
                      <AccordionButton
                        as={HStack}
                        data-testid={filterItem.key}
                        paddingX="0"
                        justifyContent="space-between"
                        alignItems="center"
                        paddingBottom={'1rem'}
                        width="100%"
                        _hover={{
                          backgroundColor: 'transparent'
                        }}
                      >
                        <HStack>
                          <Text fontSize={toRem(18)} fontWeight="400">
                            {filterItem.label}
                          </Text>
                          {filterItem.hasValue && (
                            <CircleIconButton
                              fontSize="xs"
                              size="xs"
                              aria-label="reset"
                              icon={<CloseIcon />}
                              onClick={() => reset(filterKey)}
                            />
                          )}
                        </HStack>
                        <HStack>
                          {filterItem.badge}

                          <AccordionIcon />
                        </HStack>
                      </AccordionButton>
                      <AccordionPanel width="100%">{content}</AccordionPanel>
                    </AccordionItem>
                  );

                return renderComponent(
                  <GenComponent
                    key={filterKey}
                    data-testid={filterKey}
                    data={filterItem.data || {}}
                    selectedValue={filterFields?.[filterKey]}
                    onChange={filterItem.onChange}
                  />,
                  filterItem.isStandalone
                );
              })}
          </>
        </Accordion>
        <Button
          variant={'ghost'}
          // onClick={() => {
          //   // generate index of accordion depend of filtersData key
          //   const arrayOfAccordionOpen = Object.keys(filtersData || {}).map(
          //     (_e, i) => i
          //   );
          //   const reset = () => {
          //     document?.getElementById?.('coreFilter()')?.scrollIntoView();
          //     setIndex([]);
          //   };
          //   isFilterFullOpen ? setIndex(arrayOfAccordionOpen) : reset();
          // }}
          onClick={() => {
            setIsFilterFullOpen(!isFilterFullOpen);
          }}
          margin="1rem 0rem !important"
        >
          {isFilterFullOpen
            ? t('catalog:lessFilters')
            : t('catalog:moreFilters')}
          <ArrowDown
            fontSize={'1.25rem'}
            color={'white'}
            transform={isFilterFullOpen ? 'rotate(180deg)' : ''}
          />
        </Button>
        {!isMobile && !!numberOfFilterSectionsActive ? (
          <Button
            width={'100%'}
            variant="outline"
            data-test-id="clear-all-filters"
            onClick={resetAll}
            style={{ marginTop: '3.5rem' }}
          >
            {t('catalog:clearAll')}
          </Button>
        ) : null}

        {/* {children} */}
      </VStack>
    );
    CoreFilterComponent.displayName = 'CoreFilterComponent';
    return CoreFilterComponent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterFields, filteredProviderWithNoGames]);

  return (
    <>
      {isMobile ? <MobileButton onOpen={onOpen} {...props} /> : null}

      {!isMobile ? coreFilter() : null}
      {isMobile ? (
        <Drawer
          onOverlayClick={onClose}
          onEsc={onClose}
          isOpen={isOpen}
          placement="top"
          onClose={onClose}
          size="md"
          isFullHeight={true}
        >
          {/* <DrawerOverlay /> */}
          <DrawerContent>
            <DrawerHeader
              as={HStack}
              justifyContent="space-between"
              padding={['1rem', '1rem', '3rem']}
              marginBottom={['1rem', '1rem', 0]}
            >
              {isMobile && (
                <Button
                  variant="link"
                  color={'link'}
                  onClick={resetAll}
                  textDecoration="underline"
                >
                  {t('catalog:clearAll')}
                </Button>
              )}
              {/* <VStack align="flex-start">
                <Text fontWeight="bold">{t('catalog:filters')}</Text>
                {!isMobile && (
                  <Text color="rgba(142, 148, 179, 1)" fontSize="0.8rem">
                    {countPerCat.total} {t('catalog:games')}
                  </Text>
                )}
              </VStack> */}
              <DrawerCloseButton size="lg" position="static" />
            </DrawerHeader>

            <DrawerBody padding={['0 1rem', '1rem', '3rem']}>
              {coreFilter()}
            </DrawerBody>
            <DrawerFooter
              alignItems="center"
              justifyContent="center"
              // paddingY="1rem"
              // spacing="1rem"
              // padding={0}
              backgroundColor={'#1C1C1C'}
              as={HStack}
            >
              <Button
                as={HStack}
                onClick={onClose}
                data-testid="filter-results-button"
                backgroundColor="buttonPrimary"
                size="md"
              >
                <Text fontWeight="bold">{t('catalog:results')}</Text>
                {/* <Text fontSize="0.8rem">
                  {countPerCat.total} {t('catalog:games')}
                </Text> */}
              </Button>
              {/* {!isMobile && (
                <Button
                  variant="link"
                  data-test-id="clear-all-filters"
                  onClick={resetAll}
                >
                  {t('catalog:clearAll')}
                </Button>
              )} */}
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      ) : null}
    </>
  );
};

export default Filters;
