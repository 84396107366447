import { useEffect, useState } from 'react';

type TimeLeft = {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
};

function useTimer(deadline?: Date) {
  const calculateTimeLeft = (timer?: NodeJS.Timer) => {
    let timeLeft: TimeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    };

    const difference = deadline ? +deadline - +new Date() : 0;

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    } else if (timer) {
      clearInterval(timer);
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (deadline) {
      timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(timer));
      }, 1000);
    }
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deadline]);

  return timeLeft;
}

export default useTimer;
