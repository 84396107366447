export const getHeaderHeight = (isDesktop: boolean) => (isDesktop ? 64 : 56);
export const getCategoriesHeight = (): 48 => 48;
export const getJackpotsHeight = (isDesktop: boolean) => (isDesktop ? 48 : 32);

export const getCarouselMarginTop = ({
  isDesktop,
  withCategories,
  withJackpots
}: {
  isDesktop: boolean;
  withCategories?: boolean;
  withJackpots?: boolean;
}) =>
  `calc((${getHeaderHeight(isDesktop)}px + ${
    withCategories ? getCategoriesHeight() : 0
  }px + ${withJackpots ? getJackpotsHeight(isDesktop) : 0}px) * -1)`;

export const hasJackpotsInHeader = ({
  pathname,
  enabled
}: {
  pathname: string;
  enabled: boolean;
}) => {
  return (
    enabled &&
    ['/[segment]/category/[slug]', '/[segment]/section/[slug]'].some(
      (path) => path === pathname
    )
  );
};

export const hasCategoriesInHeader = ({ pathname }: { pathname: string }) => {
  return [
    '/[segment]',
    '/[segment]/category/[slug]',
    '/[segment]/tournaments'
  ].some((path) => path === pathname);
};
