import { HTTP } from '@/components/Http';
import { useAuth } from '@/context/Auth';
import { useWallet } from '@/context/Wallet';
import GTM from '@/helpers/googleTagManager';
import {
  VStack,
  Text,
  Select,
  HStack,
  Button,
  Checkbox,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Divider
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import router from 'next/router';
import React, { useState } from 'react';

const ResponsibleGaming = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [breakLimit, setBreakLimit] = useState('24-hour');
  const [confirmSelfExclusion, setConfirmSelfExclusion] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { userData, me } = useAuth();
  const { balance: userWalletBalance } = useWallet();

  const isSelfExcluded = userData?.status === 'self_excluded';

  const changeBreakLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBreakLimit(e.target.value);
  };

  const takeABreak = async ({
    isLegalSelfExclusion = false
  }: {
    isLegalSelfExclusion?: boolean;
  }) => {
    try {
      const duration = parseInt(breakLimit?.split('-')[0]) || 24;
      const unit = breakLimit?.split('-')[1] || 'hour';
      await HTTP.post('/user/self-exclusion', {
        duration,
        unit,
        reason: 'No reason',
        isLegalSelfExclusion
      });
      toast({
        status: 'success',
        position: 'top',
        title: t('common:success')
      });
      onClose();
      await me();
      router.push('/self-excluded');

      GTM.autoExclusionEvent(
        isLegalSelfExclusion
          ? 'permanent_auto-exclusion'
          : 'temporary_auto-exclusion',
        userData,
        userWalletBalance
      );
    } catch (error) {
      toast({
        status: 'error',
        position: 'top',
        title: t('common:somethingWrongHappenned')
      });
    }
  };
  return (
    <VStack height="100%" spacing="4rem" width="100%" align="baseline">
      <VStack spacing="1.5rem" width="100%" align="baseline">
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t('common:actionCannotBeReversed')}</ModalHeader>
            <ModalBody>{t('common:doYouConfirm')}</ModalBody>
            <ModalFooter justifyContent="flex-end">
              <Button
                backgroundColor="buttonPrimary"
                onClick={() => takeABreak({ isLegalSelfExclusion: false })}
              >
                {t('common:yes')}
              </Button>
              <Button variant="outline" onClick={onClose}>
                {t('common:no')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Text textStyle="h2" as="h2">
          {t('responsible-gaming:takeABreak')}
        </Text>
        <Text>{t('responsible-gaming:takeABreakDescription')}</Text>
        <Text fontWeight="bold">{t('responsible-gaming:takeABreakFor')}</Text>
        <HStack
          wrap="wrap"
          width="100%"
          spacing="1rem"
          justifyContent="space-between"
        >
          <Select
            justifySelf={['flex-start', 'flex-start', 'flex-end']}
            data-testid="take-a-break-select"
            onChange={changeBreakLimit}
            value={breakLimit}
            fontWeight="bold"
            flex={['1', '0.5']}
            bg="backgroundPrimaryDarker"
            disabled={isSelfExcluded}
          >
            <option value="24-hour">24 {t('common:hours')}</option>
            <option value="7-day">7 {t('common:days')}</option>
            <option value="30-day">30 {t('common:days')}</option>
          </Select>
          <Button
            backgroundColor="buttonPrimary"
            data-testid="take-a-break-button"
            onClick={onOpen}
            isDisabled={isSelfExcluded}
          >
            {t('responsible-gaming:takeABreak')}
          </Button>
        </HStack>
      </VStack>
      <Divider />
      {/* Self-Exclusion */}
      <VStack spacing="1.5rem" width="100%" align="baseline">
        <Text textStyle="h2" as="h2" width="100%">
          {t('responsible-gaming:selfExclusion')}
        </Text>
        <Text>{t('responsible-gaming:selfExclusionDescription')}</Text>
        <HStack wrap="wrap" width="100%" spacing="1rem">
          <Checkbox
            flex="1"
            data-testid="self-exclusion-checkbox"
            onChange={() => setConfirmSelfExclusion(!confirmSelfExclusion)}
            isChecked={confirmSelfExclusion}
            width="100%"
          >
            {t('responsible-gaming:confirmSelfExclusion')}
          </Checkbox>
          <Button
            isDisabled={!confirmSelfExclusion}
            data-testid="self-exclusion-button"
            backgroundColor="buttonPrimary"
            onClick={() => takeABreak({ isLegalSelfExclusion: true })}
          >
            {t('responsible-gaming:excludeMyself')}
          </Button>
        </HStack>
      </VStack>
      <Divider />
      {/* Definitive and global exclusion */}
      <VStack spacing="1.5rem" width="100%" align="baseline">
        <Text textStyle="h2" as="h2">
          {t('responsible-gaming:definitiveExclusion')}
        </Text>
        <Text>{t('responsible-gaming:definitiveExclusionDescription')}</Text>
        <Button
          as="a"
          alignSelf="flex-end"
          data-testid="permanent-ban-button"
          backgroundColor="buttonPrimary"
          href="https://gamingcommission.be/en/voluntary-request"
          target="_blank"
        >
          {t('responsible-gaming:permanentBan')}
        </Button>
      </VStack>
    </VStack>
  );
};

export default ResponsibleGaming;
