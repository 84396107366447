import React, { FC } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { humanDate } from '@/helpers/humanDate';
import { useRouter } from 'next/router';
interface LastSession {
  date: string;
}
const LastSession: FC<LastSession> = ({ date }) => {
  const { t } = useTranslation();
  const router = useRouter();
  return (
    <HStack fontSize={['0.5rem', '0.5rem', '0.7rem']}>
      <Text>{t('common:lastSession')}</Text>
      <Text color="customGrey.900">
        {humanDate(date, router.locale as string)}
      </Text>
    </HStack>
  );
};
export default LastSession;
