import { Category, Game } from '@/components/GamesCatalog/types';
import { InitialFields, InitialFieldsType } from '@/context/Filters';
import { toEuro } from '@/helpers/number';
import { GameCatalogItem } from '@/services/getCatalog';

type FilterGamesButIgnoreCategoryType = {
  games: GameCatalogItem[];
  filterFields: InitialFieldsType;
};

export const filterGamesButIgnoreCategory = ({
  games,
  filterFields
}: FilterGamesButIgnoreCategoryType): GameCatalogItem[] => {
  const filterArray = (
    game: GameCatalogItem,
    key: 'bonuses' | 'features' | 'themes' | 'provider' | 'volatility'
  ) => {
    const toArray = Array.isArray(game?.[key]) ? game?.[key] : [game?.[key]];
    const syncKey = key === 'provider' ? 'providers' : key;
    return filterFields && filterFields?.[syncKey]?.length > 0
      ? // ts error to complicated to resolve
        //@ts-ignore
        toArray?.some((feature) => filterFields?.[syncKey].includes(feature))
      : game;
  };

  const filtersConditions = [
    (game: GameCatalogItem) =>
      !filterFields?.exclusive
        ? game
        : game.exclusive === filterFields?.exclusive,
    // (game: GameCatalogItem) =>
    //   !filterFields?.provider || filterFields?.provider.id === ''
    //     ? game
    //     : game.provider === `/providers/${filterFields?.provider.id}`,
    (game: GameCatalogItem) => filterArray(game, 'provider'),
    (game: GameCatalogItem) => filterArray(game, 'bonuses'),
    (game: GameCatalogItem) => filterArray(game, 'features'),
    (game: GameCatalogItem) => filterArray(game, 'themes'),
    (game: GameCatalogItem) => filterArray(game, 'volatility'),
    (game: GameCatalogItem) =>
      toEuro(game.betLimit.min) >=
        (filterFields.betLimit?.min ?? InitialFields.betLimit.min) &&
      toEuro(game.betLimit.max) <=
        (filterFields.betLimit?.max ?? InitialFields.betLimit.max)
  ];

  return games.filter((game) => filtersConditions.every((fn) => fn(game)));
};

type FilterOnlyByCategoryType = {
  games: GameCatalogItem[];
  category: Category;
};

export const filterOnlyByCategory = ({
  games,
  category
}: FilterOnlyByCategoryType): GameCatalogItem[] =>
  category.id === 'all'
    ? games
    : games.filter((game) => game.categories.includes(category['@id']));
