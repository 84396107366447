import React, { PropsWithChildren } from 'react';
import {
  HStack,
  Icon,
  IconProps,
  SpaceProps,
  StackProps,
  Text,
  TextProps,
  TypographyProps,
  VStack
} from '@chakra-ui/react';
// import useMediaQuery from '@/hooks/useMediaQuery';
import { kFormatter } from '../../helpers/number';
import useTranslation from 'next-translate/useTranslation';
import { MotionProps } from 'framer-motion';
import { MotionText } from '../Motion';

type TextWithIconProps = StackProps &
  IconProps & {
    text: string | number;
    icon: any;
    iconProps?: IconProps;
    textProps?: TextProps & TypographyProps & SpaceProps;
  };

type TitleProps = SpaceProps &
  TypographyProps &
  TextProps &
  PropsWithChildren &
  MotionProps;

export const TextWithIcon = ({
  text = '0',
  icon,
  iconProps,
  textProps,
  ...props
}: TextWithIconProps) => {
  let newText = text;
  if (typeof text === 'number') {
    newText = kFormatter(text);
  }
  return (
    <HStack {...props}>
      <Icon as={icon} width="20px" height="20px" {...iconProps} />
      <Text fontWeight="bold" lineHeight="1" color="customGold" {...textProps}>
        {newText}
      </Text>
    </HStack>
  );
};

export const GameTitle = ({ children, ...props }: TitleProps & MotionProps) => {
  return (
    <MotionText
      as="h3"
      noOfLines={0}
      width="99%"
      fontWeight="500"
      fontSize={['sm', 'sm', 'md', 'lg', 'xl']}
      color="#fff"
      {...props}
    >
      {children}
    </MotionText>
  );
};

export const GameSubTitle = ({
  children,
  ...props
}: TitleProps & MotionProps) => {
  return (
    <MotionText color="whiteAlpha.600" {...props}>
      {children}
    </MotionText>
  );
};

type SectionTitleWithIconProps = PropsWithChildren &
  StackProps & {
    icon: any;
  };

export const SectionTitleWithIcon = ({
  icon,
  children,
  ...props
}: SectionTitleWithIconProps) => {
  return (
    <HStack
      width="100%"
      justifyContent={['baseline', 'baseline', 'baseline', 'center']}
      mb="1rem"
      {...props}
      id={(children as string)?.split(' ').join('').toLowerCase()}
    >
      <Icon as={icon} width={30} height={30} />
      <Text
        as="h2"
        // id={children.split(' ').join('').toLowerCase()}
        textTransform="uppercase"
        fontWeight="bold"
        fontSize="xl"
      >
        {children}
      </Text>
    </HStack>
  );
};

type BetProps = StackProps & {
  min: string;
  max: string;
};

export const Bet = ({ min = '0', max = '0', ...props }: BetProps) => {
  const { t } = useTranslation();
  return (
    <VStack align="baseline" {...props}>
      <HStack fontWeight="600">
        <Text color="customGold">{min}</Text>
        <Text color="whiteAlpha.400">{'>'}</Text>
        <Text color="customGold">{max}</Text>
      </HStack>
    </VStack>
  );
};
