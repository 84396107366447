import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
  useState
} from 'react';

type StepperProps = {
  children: ReactElement[];
};

const RealStepper = ({ children }: PropsWithChildren) => {
  return <StepperProvider>{children}</StepperProvider>;
};

export default RealStepper;

export type StepperContextProps = {
  next: () => void;
  back: () => void;
  step: number;
};

export const StepperContext = createContext<StepperContextProps>({
  next: () => undefined,
  back: () => undefined,
  step: 0
});

export const useStepper = () => useContext(StepperContext);

type StepperProviderProps = {
  children: ReactElement[];
};

export const StepperProvider = ({ children }: PropsWithChildren) => {
  const [step, setStep] = useState<number>(0);

  const next = () => {
    step <= (children as JSX.Element[])?.length && setStep(step + 1);
  };

  const back = () => {
    step >= 0 && setStep(step - 1);
  };

  return (
    <StepperContext.Provider
      value={{
        next,
        back,
        step
      }}
    >
      {(children as JSX.Element[])?.[step]}
    </StepperContext.Provider>
  );
};
