import { useWallet } from '@/context/Wallet';
import GTM from '@/helpers/googleTagManager';
import UseIframeEvent from '@/hooks/useIframeEvent';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  Button,
  ButtonProps,
  HStack,
  SpaceProps,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { MotionProps } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { GameIframe } from '../';
import { useAuth } from '../../context/Auth';
import { useLoginModal } from '../../context/LoginModal';
import { HTTP } from '../Http';
import { MotionButton } from '../Motion';
import { toRem } from '@/helpers/toRem';
import { GameCatalogItem } from '@/services/getCatalog';
import { useCatalog } from '@/context/Catalog';
import { useAppSettings } from '@/context/AppSettings';

const { publicRuntimeConfig } = getConfig() || {};

export type PlayNowButtonProps = ButtonProps &
  MotionProps &
  SpaceProps & {
    games?: GameCatalogItem;
    section?: string;
    callback?: () => void;
  };

const PlayNowButton = ({
  games,
  callback,
  section,
  ...props
}: PlayNowButtonProps) => {
  const toast = useToast();
  const [iframeUrl, setIframeUrl] = useState('');
  const [iframeContent, setIframeContent] = useState<string>('');
  const [isLoadingIndex, setIsLoadingIndex] = useState(0); // 0= none , 1= demo , 2= real
  const { isLogged, getLicences, userData } = useAuth();
  const { t, lang } = useTranslation();
  const isScreenMobile = useMediaQuery('(max-width: 37.5rem)');
  const isScreenTablet = useMediaQuery(
    '(min-width: 601px) and (max-width: 992px)'
  );
  const { getBalance, balance: userWalletBalance } = useWallet();
  const router = useRouter();
  const { onOpen } = useLoginModal();
  const { dataStore } = useCatalog();
  const appSettings = useAppSettings();

  const {
    isOpen: ifFrameIsOpen,
    onOpen: iframeOnOpen,
    onClose: iframeOnClose
  } = useDisclosure();

  // const getProviderByIri = (iri: string) => dataStore?.providers[iri]?.name;

  const launchGame = async (isDemo: boolean = false) => {
    if (!games?.playable) {
      toast({
        status: 'error',
        title: t('detail:underMaintenance'),
        description: t('common:tryAgainLater'),
        position: 'top',
        isClosable: true
      });
      return;
    }

    setIsLoadingIndex(isDemo ? 1 : 2);
    if (!isLogged && !isDemo) {
      onOpen?.();
      setIsLoadingIndex(0);
      return;
    } else if (!isDemo) {
      const userLicences = await getLicences?.();

      if (
        userLicences &&
        !userLicences.some((x) => x.name === games?.license && x.enabled)
      ) {
        toast({
          title: t('common:licenceNotAllowed', { name: games?.license }),
          description: (
            <>
              {t('common:toPlayThisGame')}
              <Button
                variant="outline"
                style={{ marginTop: '1em' }}
                onClick={() => router.push('/me/account/licences')}
                data-testid="licenses-update-button"
              >
                {t('common:updateMySettings')}
              </Button>
            </>
          ),
          status: 'warning',
          position: 'top',
          duration: 4000,
          isClosable: true
        });
        setIsLoadingIndex(0);
        return;
      }
    }

    GTM.pageView({
      page: {
        name: `games/play/${games?.id}`,
        language: router?.locale || 'fr'
      },
      game: games,
      user: userData,
      wallet: userWalletBalance,
      section,
      dataStore
    });

    if (games.redirectUrl) {
      return router.push(games.redirectUrl);
    }

    // use current domain for the close url
    const exitUrl =
      (HTTP.defaults.baseURL || publicRuntimeConfig?.NEXT_PUBLIC_HOSTNAME) +
      '/gameClose.html';

    const request: ApiGameLaunchRequest = {
      gameId: games?.id,
      lang,
      demo: isDemo,
      screenSizeType: isScreenMobile
        ? 'mobile'
        : isScreenTablet
        ? 'tablet'
        : 'desktop',
      exitUrl
    };

    const apiUrl = 'game/launch';
    try {
      if (!request.gameId) throw new Error('MISSING_GAME_ID');

      const { data } = await HTTP.post<ApiGameLaunchResponse>(apiUrl, request);

      if (data?.error) throw new Error(data.error);
      if (!data?.launchContent) {
        setIframeUrl(data.launchUrl as string);
      }
      //   throw new Error('MISSING_LAUNCH_URL');
      if (!data?.launchUrl) {
        setIframeContent(window.atob(data.launchContent as string));
      }
      //   throw new Error('MISSING_LAUNCH_CONTENT');
      // if (!data?.launchUrl) throw new Error('MISSING_LAUNCH_URL');
      appSettings.setIsPlayingGame(true);
      iframeOnOpen();

      GTM.gameEvent(
        isDemo ? 'demo_game_launch' : 'game_launch',
        games,
        userData,
        userWalletBalance,
        section,
        dataStore
      );
    } catch (e: any) {
      toast({
        title:
          e?.data?.error || e?.message || t('common:somethingWrongHappenned'),
        status: 'error',
        position: 'top',
        duration: 4000,
        isClosable: true
      });
      appSettings.setIsPlayingGame(false);
    } finally {
      setIsLoadingIndex(0);
    }
  };

  const gtmCloseIframe = () => {
    const { query, pathname } = router;
    let pageName = 'unknown';
    switch (pathname) {
      case '/[segment]/games/[id]/[slug]': // game detail page
        pageName = `games/details/${query.id}`;
        break;
      case '/[segment]/category/[slug]': // category page
        pageName = `games/list/${query.slug}`;
        break;
      default:
        pageName = `games/list/`;
    }
    GTM.pageView({
      page: { name: pageName, language: router.locale || 'fr' },
      game: games,
      user: userData,
      wallet: userWalletBalance,
      section
    });
  };

  const closeIframe = () => {
    isLogged && getBalance?.();
    setIframeUrl('');
    iframeOnClose();
    setIframeContent('');
    callback?.();
    gtmCloseIframe();
    appSettings.setIsPlayingGame(false);
  };

  UseIframeEvent({ callback: closeIframe });

  useEffect(() => {
    if (appSettings.triggerCloseGame) closeIframe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSettings.triggerCloseGame]);

  return (
    <HStack
      data-testid="PlayNowButton"
      flexWrap={'wrap'}
      alignItems="center"
      justifyContent={'center'}
      gap={'.5rem'}
      flexFlow="column"
    >
      {!games && (
        <MotionButton
          background="buttonPrimary"
          {...props}
          data-testid="PlayNowButton-none"
          size="xs"
        >
          {t('common:playNow')}
        </MotionButton>
      )}
      {games && (
        <>
          <MotionButton
            background="buttonPrimary"
            onClick={() => launchGame()}
            data-testid="PlayNowButton-play"
            isLoading={isLoadingIndex === 2}
            disabled={!!isLoadingIndex}
            fontSize={toRem(15)}
            fontWeight="500"
            lineHeight="1"
            padding={`${toRem(7.5)} ${toRem(27)}`}
            {...props}
          >
            {t('common:playNow')}
          </MotionButton>
          {games?.demo && (
            <MotionButton
              variant="link"
              onClick={() => launchGame(true)}
              data-testid="PlayNowButton-demo"
              isLoading={isLoadingIndex === 1}
              disabled={!!isLoadingIndex}
              style={{ marginLeft: 0 }}
              fontSize={toRem(15)}
              fontWeight="500"
              lineHeight="1"
              color="#fff"
              textDecoration="underline"
              {...props}
            >
              {t('common:demo')}
            </MotionButton>
          )}
          {(iframeUrl || iframeContent) && (
            <GameIframe
              games={games}
              onClose={closeIframe}
              isOpen={ifFrameIsOpen}
              url={iframeUrl}
              srcContent={iframeContent}
              data-testid="PlayNowButton-iframe"
            />
          )}
        </>
      )}
    </HStack>
  );
};
PlayNowButton.getInitialProps = async () => {
  return {};
};

export interface ApiGameLaunchRequest {
  gameId: string;
  lang: string;
  demo: boolean;
  page?: string;
  virtualSports?: string;
  screenSizeType: 'mobile' | 'tablet' | 'desktop';
  exitUrl: string; // = close page in public folder
}

export interface ApiGameLaunchResponse {
  launchUrl?: string;
  launchContent?: string;
  error?: string;
}

export default PlayNowButton;
