import { Box, Button, Divider, HStack, VStack, Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, { FC, useEffect, useState } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Arrow, ChevronIcon } from '../../theme/Icons';
interface StepperPropsTypes {
  steps: any;
  flexOrderPrevious?: number;
  flexOrderNext?: number;
  handleNextStep?: any;
  callback?: any;
  nextButtonLabel?: string;
}

const Stepper: FC<StepperPropsTypes> = ({
  steps,
  flexOrderPrevious,
  flexOrderNext,
  handleNextStep,
  callback,
  nextButtonLabel = 'next'
}) => {
  const [current, setCurrent] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (handleNextStep !== current && steps[handleNextStep]) {
      setCurrent(handleNextStep);
      callback(handleNextStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleNextStep]);

  const displayPreviousBtn = current !== 0;
  const displayNextBtn = current + 1 !== steps.length;

  const handleNext = () => {
    if (!displayNextBtn) return;
    const newValue = Math.min(current + 1, steps.length);
    setCurrent(newValue);
    callback(newValue);
  };

  const handlePrevious = () => {
    if (displayPreviousBtn) {
      const newValue = Math.max(current - 1, 0);
      setCurrent(newValue);
      callback(newValue);
    }
  };

  const isMobile = useMediaQuery('(max-width: 415px)');

  const PrevButton = isMobile ? (
    <Arrow
      width={30}
      height={30}
      order={0}
      alignSelf="flex-start"
      marginLeft="2em"
      border="1px solid rgba(142, 148, 179, 0.4)"
      borderRadius="50%"
      margin="0 1em 1em 0"
      transform="rotate(90deg)"
      onClick={handlePrevious}
    />
  ) : (
    <Button
      padding="1rem"
      height="3rem"
      variant="ghost"
      onClick={handlePrevious}
      order={flexOrderPrevious}
      leftIcon={<ChevronIcon width={9} height={9} transform="rotate(90deg)" />}
      alignSelf={isMobile && !displayPreviousBtn ? 'center' : 'flex-end'}
      data-testid="stepper-previous"
    >
      {t('common:previous')}
    </Button>
  );

  function StepRender(
    steps: {
      component: any;
      disableNextButton?: boolean;
      displayNextButton?: boolean;
    },
    key: number
  ) {
    const currentStep = key === current;

    return (
      <VStack
        align="baseline"
        height="100%"
        as="li"
        display={currentStep ? 'flex' : 'none'}
        key={key}
      >
        <Text as="span" width="100%" height={['100%', '100%', 'auto']}>
          {steps.component}
        </Text>
        {steps.displayNextButton && displayNextBtn && (
          <>
            <Divider
              display={['none', 'none', 'block']}
              marginY="2rem !important"
            />
            <HStack
              width="100%"
              justifyContent={
                !displayPreviousBtn ? 'flex-end' : 'space-between'
              }
            >
              {!isMobile && displayPreviousBtn && PrevButton}
              <Button
                padding="1rem"
                paddingLeft="2rem"
                height="3rem"
                onClick={handleNext}
                backgroundColor="buttonPrimary"
                disabled={steps.disableNextButton}
                order={flexOrderNext}
                rightIcon={
                  <ChevronIcon
                    width={9}
                    height={9}
                    transform="rotate(-90deg)"
                  />
                }
                alignSelf={
                  isMobile && !displayPreviousBtn ? 'center' : 'flex-end'
                }
                data-testid="stepper-next"
              >
                {t(`common:${nextButtonLabel}`)}
              </Button>
            </HStack>
          </>
        )}
      </VStack>
    );
  }

  return (
    <VStack width="100%" height="100%">
      <Box as="ul" order="1" height="100%" width="100%">
        {steps.map(StepRender)}
      </Box>
      {displayPreviousBtn && isMobile && PrevButton}
    </VStack>
  );
};
export default Stepper;
