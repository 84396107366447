/*
 * This HOC handle if component should be render dependent of isLogged or not
 *
 * */

import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { PropsWithChildren, useEffect, useState } from 'react';
import { isOnlyNotLoggedUrls } from '../constant/freeAccessUrl';
import { useAuth } from '../context/Auth';
import { useCurrentHost } from '@/hooks/useCurrentHost';
import { getLicenseByHost, getPathByLicense } from '@/utils/multiDomains';
import { Box, CircularProgress, Spinner } from '@chakra-ui/react';
import { useAppSettings } from '@/context/AppSettings';

const WithProtectedRoute = ({ children }: PropsWithChildren) => {
  const {
    me,
    userData,
    episMajorError,
    episValidationError,
    isLogged,
    isLoginFetching
  } = useAuth();
  const { lang, t } = useTranslation();
  const isSelfExcluded = userData?.status === 'self_excluded';
  const isBanned = userData?.status === 'banned';
  const isUserOnlyValidationError = !isBanned && episValidationError;
  const isUserOnlyEpisMajor = !isBanned && episMajorError;
  const router = useRouter();
  const [currentUrl, setCurrentUrl] = useState('');
  const currentHost = useCurrentHost();
  const license = getLicenseByHost(currentHost) || 'MAIN';
  const licensePath = getPathByLicense(license);
  const [isLoading, setIsLoading] = useState(false);
  const settings = useAppSettings();
  const themeColor = settings.customStyle?.themeColor;
  const selfExcludedAccessUrl = [
    `/${lang}${licensePath}/self-excluded`,
    `/fr${licensePath}/self-excluded`,
    `/en${licensePath}/self-excluded`,
    `/nl${licensePath}/self-excluded`,
    `${licensePath}/self-excluded`,
    `/${lang}${licensePath}/responsible-gaming`,
    `/fr${licensePath}/responsible-gaming`,
    `/nl${licensePath}/responsible-gaming`,
    `${licensePath}/responsible-gaming`
  ]; // need to explicitly add language in case of this context because the router handler change is triggered after

  const handleRedirection = (url: string) => {
    if ((isUserOnlyEpisMajor || isUserOnlyValidationError) && !isSelfExcluded) {
      if (!url.includes('/me/account/my-profile'))
        router.replace('/me/account/my-profile');
    }

    if (isBanned) {
      router.push('/banned');
      return;
    }

    if (isSelfExcluded) {
      const isAccess = selfExcludedAccessUrl.includes(url);
      if (!isAccess) {
        router.push(`/${lang}${licensePath}/self-excluded`);
        return;
      }
    }

    if (isOnlyNotLoggedUrls(url, lang, licensePath)) {
      setIsLoading(isLoginFetching || isLogged);
      if (isLogged) {
        router.push('/').then(() => {
          setIsLoading(false);
        });
        return;
      }
    }
  };

  // from @nextJs doc. Subscribe to router hooks and update currentUrl accordingly,
  // like that, I can trigger a rerender when user click on another url

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      setCurrentUrl(url);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    me();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleRedirection(currentUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentUrl,
    isSelfExcluded,
    episMajorError,
    episValidationError,
    isLogged,
    isLoginFetching
  ]);

  return (
    <>
      {isLoading ? (
        <Box
          data-testid="protected-route-loader"
          width="100%"
          height="100vh"
          display="flex"
          alignItems="center"
          className="globalBox"
          justifyContent="center"
        >
          <CircularProgress
            size="3em"
            isIndeterminate
            color={themeColor}
            title={t('common:loadingGeneric')}
          />
        </Box>
      ) : (
        children
      )}
    </>
  );
};

export default WithProtectedRoute;
