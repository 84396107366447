import { humanCurrency } from '@/helpers/humanCurrency';
import { CurrencyBase } from '@/hooks/useFormat';
import { MoneyIcon } from '@/theme/Icons';
import { VStack, Progress, Divider, Box, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import { ApiNewFastDepositResponseBody, PayloadFastDeposit } from '.';
import CountDownSimple from './CountDownSimple';
import QrCode from './QrCode';

export type FaceDepositStep2 = {
  form: PayloadFastDeposit;
  timeCompleted?: boolean;
  currentTime: number;
  expire: number;
  data: ApiNewFastDepositResponseBody;
  getRemainingTime: number;
  callback: () => void;
};
const FastDepositStep2 = ({
  form,
  timeCompleted,
  currentTime,
  expire,
  data,
  getRemainingTime,
  callback
}: FaceDepositStep2) => {
  const BoxMotion = useMemo(() => motion(Box), []);
  const { t, lang } = useTranslation();
  return (
    <BoxMotion
      initial={{ opacity: 0, x: 2000 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{
        x: -2000,
        duration: 0.7,
        opacity: 0
      }}
      transition={{
        ease: 'linear',
        duration: 0.4
      }}
      data-testid="FastDepositStep2"
    >
      <VStack style={{ marginBottom: '1rem' }}>
        <MoneyIcon width={30} height={30} />
        <Text size={'lg'}>
          {humanCurrency(+form.amount, lang, 'EUR', CurrencyBase.CENT)}
        </Text>

        <Text fontSize={'.7rem'} textAlign="center">
          {t('wallet:fastDepositScan')}
        </Text>
        <QrCode imgData={data.qrcode as string} />
        {!!getRemainingTime && !!currentTime ? (
          <Progress
            isIndeterminate={timeCompleted}
            width="100%"
            colorScheme={'red'}
            borderRadius="1rem"
            size="sm"
            background="rgba(255, 255, 255, 0.5)"
            value={getRemainingTime}
            style={{ marginTop: '1rem' }}
          />
        ) : null}

        {currentTime && !timeCompleted ? (
          <CountDownSimple currentTimeTs={currentTime} expiryTs={expire} />
        ) : (
          <Text fontSize={'.7rem'} textAlign="center">
            {t('wallet:fastDepositNearlyFinished')}
          </Text>
        )}
      </VStack>

      <Divider />
      <Text
        fontSize={'.8rem'}
        paddingTop="1rem"
        textAlign={'center'}
        display="block"
        cursor={'pointer'}
        onClick={callback}
      >
        {t('wallet:fastDepositOtherAmount')}
      </Text>
    </BoxMotion>
  );
};

export default FastDepositStep2;
