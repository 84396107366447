import { useAppSettings } from '@/context/AppSettings';
import { HStack, StackProps } from '@chakra-ui/react';
import { RefObject } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import BackButton from '../Button/BackButton';
import { Search } from '../GamesCatalog';
import Filters from '../GamesCatalog/Filters';
import { CatalogTinyInfo } from '../Profile/Profile_TinyInfo/CatalogTinyInfo';
import { ContextualNavigation, CustomHeader } from '@/components/Navbar';

import {
  ContextualLinks,
  ContextualLinksTypes,
  ContextualNavItem
} from './ContextualLinks';
import { useCurrentHost } from '@/hooks/useCurrentHost';
import { getLicenseByPath, LicensesEnabled } from '@/utils/multiDomains';
import { useAuth } from '@/context/Auth';
import { useRouter } from 'next/router';
import { LogoTestAccount } from '@/components/Logo';
import { useExtraProps } from '@/pages/_app';

type NavbarProps = StackProps & {
  observeRef?: RefObject<HTMLDivElement>;
  basic?: boolean;
  backButton?: boolean;
  showLoginButton?: boolean;
  showRegisterButton?: boolean;
  showNav?: boolean;
  showAllUsersActions?: boolean;
};

/* ORIA ONLY-TEXT LOGO
const scrollYRange = [0, 300];
const { publicRuntimeConfig } = getConfig() || {};
*/

export const NavBar = ({
  basic = false,
  backButton = false,
  showLoginButton = true,
  showRegisterButton = true,
  showNav = true,
  showAllUsersActions = true,
  ...props
}: NavbarProps) => {
  const { asPath } = useRouter();
  const isHub = getLicenseByPath(asPath) === 'MAIN';
  const isMobile = useMediaQuery('(max-width: md)');
  const appSettings = useAppSettings();
  const { license } = useExtraProps();

  const currentHost = useCurrentHost();
  const { userData } = useAuth();
  const isBanned = userData?.status === 'banned';
  const selfExcluded = userData?.status === 'self_excluded';

  const currentNav = appSettings.features
    .contextualNavigation as ContextualNavItem[];

  const enabledLicenses = LicensesEnabled();
  let filteredData = currentNav?.filter((nav) => {
    return license === 'MAIN'
      ? enabledLicenses.includes(nav.license)
      : license === nav.license;
  });

  // keep only active menu items
  filteredData = filteredData?.map((navParent: ContextualNavItem) => {
    navParent.itemMenu = navParent.itemMenu.filter((navItem) => navItem.active);
    return navParent;
  });

  const navData: ContextualLinksTypes = {
    data: filteredData,
    hallsUrl: appSettings?.hallsUrl,
    showHubUrl: appSettings?.isHubEnabled
  };

  const isMainDomain =
    appSettings.isHubEnabled && currentHost === appSettings.domains.MAIN;

  return (
    <CustomHeader {...props}>
      {/*LEFT PART*/}
      {showNav ? (
        <HStack>
          <ContextualNavigation {...navData} position="column" />

          {!isMobile && appSettings.isTopNavigationEnabled === 'true' ? (
            <ContextualLinks {...navData} />
          ) : null}
        </HStack>
      ) : null}

      {basic && backButton ? <BackButton /> : null}

      {/* CENTER PART */}
      <LogoTestAccount
        position="absolute"
        left="50%"
        transform={'translateX(-50%)'}
      />

      {/* ORIA ONLY-TEXT LOGO */}
      {/*{hasSmallHeaderScroll && <LogoTestAccount small style={{ opacity }} />}*/}

      {/*RIGHT PART*/}
      <HStack ml={'auto'} spacing={0} gap={4} mr={[2, 0]}>
        {!basic && !isMainDomain && (
          <WithSearchButton predicat={isBanned || selfExcluded} />
        )}
        {!basic && isMobile && !isMainDomain && !selfExcluded ? (
          <Filters />
        ) : null}
        {!isMobile && !selfExcluded ? (
          <CatalogTinyInfo
            showRegisterButton={isHub ? false : showRegisterButton}
            showLoginButton={isHub ? false : showLoginButton}
            showAllUsersActions={isHub ? false : showAllUsersActions}
          />
        ) : null}
        )
      </HStack>
    </CustomHeader>
  );
};
NavBar.getInitialProps = async () => {
  return {};
};

const WithSearchButton = ({ predicat }: { predicat: Boolean }) => {
  return !predicat ? <Search /> : null;
};
