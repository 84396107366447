import getConfig from 'next/config';
import { DomainLicense, getDomainByLicense } from '@/utils/multiDomains';

const {
  publicRuntimeConfig: { currentAppConfig }
} = getConfig() || {};

export const getSeoTagsConfig = ({
  license,
  openGraphType = 'website',
  robotsContent = 'all',
  url = '',
  title,
  siteName,
  description = '',
  keywords = '',
  image,
  imageWidth = '',
  imageHeight = '',
  createdAt = '',
  updatedAt = ''
}: {
  license: keyof DomainLicense;
  openGraphType?: string;
  robotsContent?: string;
  url?: string;
  siteName?: string;
  title?: string;
  description?: string;
  image?: string;
  imageWidth?: string;
  imageHeight?: string;
  createdAt?: string;
  updatedAt?: string;
  keywords?: string;
}): SeoTagsConfig => {
  if (siteName === undefined) {
    siteName = currentAppConfig?.appName ?? '';
  }

  if (!title) {
    title = siteName;
  }

  if (image === undefined) {
    const logoConfig = getDefaultLogoConfig(license);
    if (logoConfig) {
      image = logoConfig?.image;
      imageWidth = logoConfig?.imageWidth;
      imageHeight = logoConfig?.imageHeight;
    }
  }

  const config: SeoTagsConfig = {
    title,
    description,
    image,
    keywords,
    robots: robotsContent,
    googlebot: robotsContent,
    // https://developer.x.com/en/docs/twitter-for-websites/cards/guides/getting-started
    // https://developer.x.com/en/docs/twitter-for-websites/cards/overview/abouts-cards
    // https://developer.x.com/en/docs/twitter-for-websites/cards/overview/markup
    'twitter:card': 'summary_large_image',
    'twitter:site': '@GoldenPalaceBE',
    'twitter:title': title,
    'twitter:description': description,
    'twitter:creator': '@GoldenPalaceBE',
    'twitter:image:src': image,

    // The Open Graph protocol : https://ogp.me/
    'og:url': url,
    'og:title': title,
    'og:type': openGraphType,
    'og:image': image,
    'og:image:width': imageWidth,
    'og:image:height': imageHeight,
    'og:description': description,
    'og:site_name': siteName
  };

  if (createdAt) {
    config['og:published_time'] = createdAt;
  }

  if (updatedAt) {
    config['og:modified_time'] = updatedAt;
  }

  return config;
};

export const getDefaultLogoConfig = (license: keyof DomainLicense) => {
  const hostname = 'https://' + getDomainByLicense(license);
  const image = currentAppConfig?.pathLogo?.[license !== 'MAIN' ? license : 'B']
    ? hostname + currentAppConfig?.pathLogo[license !== 'MAIN' ? license : 'B']
    : undefined;

  return image
    ? {
        image,
        imageWidth: '174',
        imageHeight: '52'
      }
    : undefined;
};

export const getPageTitle = (title?: string, siteName?: string) => {
  {
    return `${title}${siteName && title !== siteName ? ` || ${siteName}` : ''}`;
  }
};

export type SeoTags =
  // crawler
  | 'robots'
  | 'googlebot'
  // page
  // page + itemProp
  | 'title'
  | 'keywords'
  | 'description'
  | 'image'
  // x / twitter
  | 'twitter:card'
  | 'twitter:site'
  | 'twitter:title'
  | 'twitter:description'
  | 'twitter:creator'
  | 'twitter:image:src'
  // FB / open-graph
  | 'og:title'
  | 'og:type'
  | 'og:url'
  | 'og:image'
  | 'og:image:width'
  | 'og:image:height'
  | 'og:description'
  | 'og:site_name'
  | 'og:published_time'
  | 'og:modified_time';

export type SeoTagsConfig = Partial<Record<SeoTags, string>>;
