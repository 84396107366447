import { Box, StackProps } from '@chakra-ui/react';
import { FC, useContext } from 'react';
import { HeaderWrapper } from '@/components/Header';
import { JackpotLevelMini, JackpotLevelType } from '@/components/Jackpots';
import { JackpotsContext } from '@/context/Jackpots';
import useMediaQuery from '@/hooks/useMediaQuery';

type JackpotsHeaderListProps = StackProps & {};

export const JackpotsHeaderList: FC<JackpotsHeaderListProps> = (props) => {
  const { jackpots } = useContext(JackpotsContext);

  const sortedJackPot =
    jackpots?.[0]?.levels?.sort((l1: JackpotLevelType, l2: JackpotLevelType) =>
      l1.ref.localeCompare(l2.ref)
    ) ?? [];

  const isMobile = useMediaQuery('(max-width: sm)');

  return sortedJackPot?.length ? (
    <HeaderWrapper height={'48px'} borderBottom={0}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={['center']}
        gap={2}
        px={['4', '4', '4', 0]}
        mx={'auto!'}
        w={['100%', '100%', '100%', '100%', 'max-content']}
        h={'full'}
        color={'black'}
        textAlign={'center'}
        {...props}
      >
        {jackpots?.[0]?.levels?.map((jackpotLevel, index) => (
          <Box
            key={`jackpot-mini-${jackpotLevel.ref}`}
            flex={isMobile ? '0 1 172px' : 1}
            minWidth={'104px'}
            h={['100%', '100%', '100%', '36px']}
            display={'flex'} // to fix svg bug display when centering
            alignItems={'stretch'} // to fix svg bug  display when centering
            justifyContent={
              index === 0 ? 'flex-end' : index === 2 ? 'flex-start' : 'center'
            }
          >
            <JackpotLevelMini
              verticalAlign={'middle'}
              jackpotLevel={jackpotLevel}
              isMobile={isMobile}
              sx={{
                // to fix svg bug  display when centering
                svg: {
                  width: '100%',
                  height: '100%'
                }
              }}
            />
          </Box>
        ))}
      </Box>
    </HeaderWrapper>
  ) : null;
};
