import { useAppSettings } from '@/context/AppSettings';
import useTranslation from 'next-translate/useTranslation';
import Script, { ScriptProps } from 'next/script';
import { FC } from 'react';
import { getFallbackLanguage } from '@/helpers/lang';

interface ChatScriptProps extends ScriptProps {
  chat?: string;
}

const ChatScript = Script as FC<ChatScriptProps>;

export default function FreshDeskChatScript() {
  const { lang } = useTranslation();
  const config = useAppSettings();
  const chatScript = config?.features?.freshDesk?.chat?.script;

  return (
    <>
      {Script({
        children: `window.fcWidgetMessengerConfig = {locale: '${getFallbackLanguage(
          lang
        )}', config: {headerProperty:{hideChatButton: true}}}`
      })}
      <ChatScript src={chatScript} chat="true" />
    </>
  );
}
