import { RefObject, useEffect } from 'react';

type UseBodyClickType = {
  onBodyClick: () => void;
  condition: boolean;
  btnSelector: string;
  refOverlay: RefObject<HTMLElement>;
};

export const useBodyClick = ({
  onBodyClick,
  btnSelector,
  refOverlay,
  condition
}: UseBodyClickType) => {
  useEffect(() => {
    if (condition && onBodyClick && btnSelector && refOverlay.current) {
      const body = document.body;
      const onClickBody = (e: MouseEvent) => {
        const target = e.target as HTMLElement;
        const btn = document.querySelector(btnSelector);

        if (
          btn &&
          refOverlay.current &&
          target &&
          !refOverlay.current.contains(target) &&
          !btn.contains(target)
        ) {
          onBodyClick();
        }
      };

      body.addEventListener('click', onClickBody);

      return () => {
        body.removeEventListener('click', onClickBody);
      };
    }
  }, [condition, onBodyClick, btnSelector, refOverlay]);
};
