import { getFullUrlByLicense } from '@/utils/multiDomains';
import { createStandaloneToast } from '@chakra-ui/react';
import { HTTP } from '../components/Http';
import { isFreeAccess } from '../helpers/isFreeAccess';
import { getFallbackLanguage } from '@/helpers/lang';

const toast = createStandaloneToast();

type requestType = 'request' | 'response';
const ids: { [key in requestType]: number[] } = {
  request: [],
  response: []
};

const whitelistUrls = ['/sports', '/me/wallet/cash-out'];

const interceptor = (context: any) => {
  const { router, t, extra } = context;
  resetInterceptors();

  // set language based on router
  HTTP.defaults.headers.common['Accept-Language'] = getFallbackLanguage(
    router.locale as string
  );
  ids.request.push(
    HTTP.interceptors.request.use(function (config: any) {
      // this is the place to do some custom things when request is made, otherwise, we simply return request config as is.
      return config;
    })
  );

  ids.response.push(
    HTTP.interceptors.response.use(
      (response: any) => {
        // Here, we simply return the response if there's no issue during the api call
        return Promise.resolve(response);
      },
      (err: any) => {
        // get only the main response
        const error = err?.response ?? err;
        const originalRequest = error?.config;
        const unauthorized = 401;
        const notFoundError = 404;
        const tooManyRequest = 429;

        // bad request : 400
        // if (error?.status === badRequest && !originalRequest?.__isRetryRequest) {
        //   // const errorToast = error?.data?.violations.map(e => ({
        //   //   id: e.type,
        //   //   type: 'warning',
        //   //   title: e.propertyPath,
        //   //   content: e.title
        //   // }))
        //   //
        //   // dispatchAlert(errorToast)
        // }

        if (!originalRequest?.__isRetryRequest) {
          // unauthorized  : 401

          if (error?.status === unauthorized) {
            if (!isFreeAccess(router)) {
              return router.push(
                getFullUrlByLicense(extra.license, router.locale) + '/logout'
              );
              //window.location.href = '/';
            }
          } else if (error?.status === tooManyRequest) {
            toast.toast({
              title: t('common:error'),
              description:
                error?.data?.detail ||
                error?.data?.error?.message ||
                error?.data?.error ||
                t('common:errorTooManyRequests'),
              status: 'error',
              position: 'top-right'
            });
          } else if (
            error?.status !== notFoundError &&
            !whitelistUrls.includes(router?.state?.asPath) &&
            // not in in register process
            !router?.state?.asPath.includes('/register')
          ) {
            toast.toast({
              title: t('common:error'),
              description:
                error?.data?.detail ||
                error?.data?.error?.message ||
                error?.data?.error ||
                t('common:somethingWrongHappenned'),
              status: 'error',
              position: 'top-right'
            });
          }
        }

        return Promise.reject(error);
      }
    )
  );
};

export const resetInterceptors = (): void => {
  (['request', 'response'] as requestType[]).forEach((type) =>
    ids[type].forEach((id) => HTTP.interceptors[type].eject(id))
  );
};

export default interceptor;
