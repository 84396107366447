import { useEffect, useState } from 'react';

export const useCountDownAnimation = (
  enabled: boolean,
  countDownTimeMilli: number,
  stepDuration: 1000
) => {
  const [countDownSeconds, setCountDownSecond] = useState(0);

  useEffect(() => {
    if (enabled) {
      const nowMilli = Date.now();

      const cd = Math.floor((+countDownTimeMilli - nowMilli) / 1000);
      setCountDownSecond(cd);

      const timerInterval = setInterval(() => {
        setCountDownSecond((cds) => {
          if (cds <= 0) {
            clearInterval(timerInterval);
            return 0;
          } else {
            return cds - 1;
          }
        });
      }, stepDuration);

      return () => clearInterval(timerInterval);
    }
  }, [enabled, stepDuration, countDownTimeMilli]);

  return countDownSeconds;
};
