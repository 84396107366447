// this component is currently not used
import { Button } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, { FC } from 'react';

export interface WalletCashOutSummaryProps {
  method?: string;
  amount: number;
  isLoading?: boolean;
  iban: string;
  bic: string;
  currencySymbol: string;
  errors?: boolean;
  errorRequestedAmountLandbase?: boolean;
  onSubmit: () => void;
}

const WalletCashOutSummary: FC<WalletCashOutSummaryProps> = ({
  isLoading,
  method,
  amount,
  currencySymbol,
  iban,
  bic,
  errors,
  errorRequestedAmountLandbase,
  onSubmit
}) => {
  //const [selectedOption, setSelectedOption] = useState('nationalIdCard');
  const methodText =
    method && method === 'bank_transfer' ? 'online' : 'landbase';

  /*  const handleOptionChange = (e: any) => {
      setSelectedOption(e.target.value);
      // TODO : TBD
    };*/
  const { t } = useTranslation();
  return (
    <div data-testid="WalletCashOutSummary" className="walletcashoutsummary">
      {method && method === 'bank_transfer' ? (
        <React.Fragment>
          <h4>
            You request a <strong>{method && methodText}</strong> Cash-Out.
          </h4>
          {errors ? (
            <p>{t('wallet:pleaseVerifyCashout')}</p>
          ) : (
            <p>Requested amount: {amount + currencySymbol}</p>
          )}
          {iban && !errors && <p>Iban: {iban}</p>}
          {bic && !errors && <p>Bic: {bic}</p>}
          {amount != 0 && amount >= 50 && !errors && (iban || bic) && (
            <Button
              onClick={onSubmit}
              textTransform="uppercase"
              margin="1em 0"
              backgroundColor="buttonPrimary"
              disabled={isLoading}
            >
              {isLoading ? 'Loading' : 'Request Cash-Out'}
            </Button>
          )}
          {/*
            <form>
              <label>
                <input
                  type="radio"
                  name="requiredDocs"
                  value="nationalIdCard"
                  checked={selectedOption === "nationalIdCard"}
                  onChange={handleOptionChange}
                  className="form-check-input"
                />
                National ID card
              </label>

              <label>
                <input
                  type="radio"
                  name="requiredDocs"
                  value="conditionName"
                  checked={selectedOption === "conditionName"}
                  onChange={handleOptionChange}
                  className="form-check-input"
                />
                Document / condition name
              </label>

              <label>
                <input
                  type="radio"
                  name="requiredDocs"
                  value="documentName"
                  checked={selectedOption === "documentName"}
                  onChange={handleOptionChange}
                  className="form-check-input"
                />
                {t('wallet:documentName')}
              </label>
            </form>
            */}
        </React.Fragment>
      ) : (
        method &&
        method === 'gaming_hall' && (
          <React.Fragment>
            <h4>
              You request a <strong>{method && methodText}</strong> Cash-Out.
            </h4>
            {<p>Requested amount: {amount + currencySymbol}</p>}
            {amount != 0 &&
              amount >= 50 &&
              amount <= 1500 &&
              !errorRequestedAmountLandbase && (
                <Button
                  onClick={onSubmit}
                  style={{ textTransform: 'uppercase', margin: '1em 0' }}
                  color="buttonPrimary"
                  disabled={isLoading}
                >
                  {isLoading ? 'Loading' : 'Request Cash-Out'}
                </Button>
              )}

            <h4>{t('wallet:addressOfOur')}</h4>
          </React.Fragment>
        )
      )}
    </div>
  );
};

export default WalletCashOutSummary;
