import {
  CashOutConfigType,
  CashOutMethodsType,
  CashOutPayoutMethods
} from '@/components/UserSettings/Wallet/CashOut/CashOutConfig';
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState
} from 'react';

export type CashoutConfigType = {
  payoutMethods: CashOutPayoutMethods[];
  methodType: CashOutMethodsType;
  setMethodType: Dispatch<SetStateAction<CashOutMethodsType>>;
  setConfigCashout: Dispatch<SetStateAction<CashOutConfigType[]>>;
  configCashout: CashOutConfigType[];
  setPayoutCfg: Dispatch<SetStateAction<CashOutPayoutMethods[]>>;
  payoutCfg: CashOutPayoutMethods[];
};
export const CashOutConfigContext = createContext<CashoutConfigType>({
  payoutMethods: [],
  methodType: 'bank_transfer',
  setMethodType: (value) => value,
  configCashout: [],
  setConfigCashout: (value) => value,
  payoutCfg: [],
  setPayoutCfg: (value) => value
});

export const CashOutConfigProvider = ({ children }: PropsWithChildren) => {
  const [configCashout, setConfigCashout] = useState<CashOutConfigType[]>([]);
  const [payoutCfg, setPayoutCfg] = useState<CashOutPayoutMethods[]>([]);
  const [currentMethodType, setCurrentMethodType] =
    useState<CashOutMethodsType>('bank_transfer');

  return (
    <CashOutConfigContext.Provider
      value={{
        payoutMethods: payoutCfg,
        methodType: currentMethodType,
        setMethodType: setCurrentMethodType,
        configCashout,
        setConfigCashout,
        payoutCfg,
        setPayoutCfg
      }}
    >
      {children}
    </CashOutConfigContext.Provider>
  );
};

export const useCashOutConfig = () => useContext(CashOutConfigContext);
