import useTranslation from 'next-translate/useTranslation';
import { useWallet } from '@/context/Wallet';
import useMediaQuery from '@/hooks/useMediaQuery';
import { getRef, getWinMessage } from '@/helpers/jackpot';
import { useJackpots } from '@/context/Jackpots';
import { formatCurrency } from '@/hooks/useFormat';
import {
  JackpotHitType,
  ModalHitDesktop,
  ModalHitMobile
} from '@/components/Jackpots';

const debug = false;
export const JackpotModalHit = () => {
  const { removeJackpotHit, modalJackpotHits } = useJackpots();
  const { lang } = useTranslation();
  const { getBalance } = useWallet();
  const isMobile = useMediaQuery('(max-width: xs)');

  // FOR DEBUGGING PURPOSES
  // useEffect(() => {
  //   if (debug) {
  //     setIsDisplayingModalHit(true);
  //     setDataModalJackpotHit({
  //       username: 'test',
  //       createdAt: 'now',
  //       userId: 'caf20b78-78a9-48ac-ac02-152413d4ef0f',
  //       jackpotPublicId: 'aa1cebb8-ba35-4fd3-9dd0-e14f63a38849',
  //       code: '1',
  //       amount: 3000000,
  //       currency: 'EUR',
  //       winMessage: [
  //         {
  //           language: 'fr',
  //           data: `
  //         <h2>You won the Gold Jackpot!</h2>
  //         <p>You won the Golden Jackpot!</p>
  //         <p>12 675,38 € are now available in your wallet.</p>`
  //         }
  //       ]
  //     });
  //   }
  // }, [setIsDisplayingModalHit, setDataModalJackpotHit]);

  if (!modalJackpotHits) return null;

  const onCloseModal = (jackpotHit: JackpotHitType) => {
    removeJackpotHit(jackpotHit);
    getBalance?.();
  };

  const Component = isMobile ? ModalHitMobile : ModalHitDesktop;

  return (
    <>
      {modalJackpotHits.map((modalHit) => {
        const { amount, winMessage, code } = modalHit;
        const level = getRef(code);
        const message = getWinMessage(winMessage, lang);

        const amountFormatted = formatCurrency({
          amount: amount,
          currency: 'EUR'
        });
        return (
          <Component
            key={modalHit.code}
            isOpen={true}
            amount={amountFormatted}
            level={level}
            message={message}
            onCloseModal={() => onCloseModal(modalHit)}
          />
        );
      })}
    </>
  );
};
