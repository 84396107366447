import { useAuth } from '@/context/Auth';
import {
  ChevronIcon,
  CreditCardIcon,
  DocumentIcon,
  EmailIcon,
  PassportIcon,
  WarningIcon
} from '@/theme/Icons';
import {
  Badge,
  ComponentWithAs,
  HStack,
  Icon,
  IconProps,
  InputGroup,
  Select,
  Spinner,
  StackProps,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState
} from 'react';
import BorderedBox from '../Box';
import { HTTP } from '../Http';
import RealStepper, { useStepper } from '../Stepper';
import Upload from '../UserSettings/Account/MyDocuments/Upload';
import { useSettings } from '../UserSettings/SettingsContext';
import BankAccountActionModal from '../UserSettings/Wallet/CashOut/BankAccount/BankAccountActionModal';
import { InputLabel } from '@/components';
import { FieldError } from 'react-hook-form';

export type Action = {
  title: string;
  link?: string;
  icon: ComponentWithAs<'svg', IconProps>;
  modal?: ReactElement;
} | null;

type ActionRowProps = StackProps & {
  onClick: () => void;
  icon: ComponentWithAs<'svg', IconProps>;
  title?: string;
  badgeTitle?: string;
  loading?: boolean;
};

const ActionRow = ({
  onClick,
  icon,
  badgeTitle = 'form:required',
  title,
  loading = false,
  ...props
}: ActionRowProps) => {
  const { t } = useTranslation();
  return (
    <BorderedBox
      borderRadius="1.3rem"
      flexDirection={['column', 'row']}
      justifyContent="space-between"
      cursor="pointer"
      onClick={onClick}
      {...props}
    >
      <HStack spacing="1rem">
        <Icon w={10} h={10} as={icon} />
        <Text fontWeight="bold" fontSize="1.2rem">
          {title ?? 'Please upload your ID'}
        </Text>
      </HStack>
      {loading ? (
        <Spinner />
      ) : (
        <HStack spacing={['0.5rem', '1rem']}>
          <Badge colorScheme="red">{t(badgeTitle)}</Badge>
          <ChevronIcon w={10} h={10} transform="rotate(-90deg)" />
        </HStack>
      )}
    </BorderedBox>
  );
};

const DocumentRow = () => {
  const { next } = useStepper();
  const { t } = useTranslation();

  return (
    <ActionRow
      title={t('form:uploadIDDocuments')}
      icon={DocumentIcon}
      onClick={next}
      data-testid="upload-documents"
    />
  );
};

const PassportRow = () => {
  const { next } = useStepper();
  const { t } = useTranslation();

  return (
    <ActionRow
      title={t('form:uploadPassport')}
      icon={PassportIcon}
      onClick={next}
      data-testid="upload-passport"
    />
  );
};

const ActionRequired = ({
  children,
  isRequired = { bankAccount: true }
}: PropsWithChildren<{ isRequired?: { bankAccount: boolean } }>) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [documentType, setDocumentType] = useState<string | null>(null);
  const {
    bankAccounts,
    currentDocuments,
    currentStatus,
    documentStatusMessages,
    isLoading
  } = useSettings();
  const { userData, me } = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loadingEmailConfirmation, setLoadingEmailConfirmation] =
    useState(false);

  const documentTitle = documentStatusMessages?.[currentStatus]?.message;

  const sendEmailConfirmation = async () => {
    setLoadingEmailConfirmation(true);
    try {
      await HTTP.post(`/user/send-email-validation`);
      toast({
        status: 'success',
        position: 'top-right',
        title: t('common:weSentALink'),
        isClosable: true
      });
      setLoadingEmailConfirmation(false);
    } catch (error) {
      toast({
        status: 'error',
        position: 'top-right',
        title: '',
        isClosable: true
      });
      setLoadingEmailConfirmation(false);
    }
  };

  useEffect(() => {
    // update context onLoad
    me();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || (!currentStatus && currentDocuments.length > 1)) {
    return <Spinner margin="0 auto" size="lg" />;
  }

  const hasValidBankAccounts = isRequired?.bankAccount
    ? bankAccounts.length
    : true;
  if (
    currentStatus === 'approved' &&
    userData?.emailVerified &&
    hasValidBankAccounts
  ) {
    return children as JSX.Element;
  }

  const approvedOrUploaded = ['approved', 'uploaded'].includes(
    currentDocuments?.[0]?.status
  );

  return (
    <BorderedBox spacing="2rem" padding={['1rem', '1rem', '1rem', '2rem']}>
      <HStack>
        <WarningIcon w={10} h={10} />
        <Text fontSize="2rem" fontWeight="bold" data-testid="action-required">
          {t('form:actionRequired')}
        </Text>
      </HStack>
      {documentTitle && (
        <BorderedBox
          borderRadius="1.3rem"
          flexDirection="row"
          justifyContent="space-between"
          cursor="pointer"
        >
          <Text fontSize="1.2rem">{documentTitle}</Text>
        </BorderedBox>
      )}

      {!approvedOrUploaded ? (
        <RealStepper>
          <DocumentRow />
          <Upload />
        </RealStepper>
      ) : null}

      {!userData?.emailVerified && (
        <ActionRow
          title={t('common:dontForgetEmailConfirmation')}
          icon={EmailIcon}
          onClick={sendEmailConfirmation}
          badgeTitle={'common:resendEmailConfirmation'}
          data-testid="resend-email-confirmation"
          loading={loadingEmailConfirmation}
        />
      )}
      {isRequired?.bankAccount ? (
        bankAccounts.length < 1 ? (
          <ActionRow
            title={t('form:addBankAccount')}
            icon={CreditCardIcon}
            onClick={onOpen}
            data-testid="add-bank-account"
          />
        ) : null
      ) : null}

      <BankAccountActionModal isOpen={isOpen} onClose={onClose} mode={'add'} />
    </BorderedBox>
  );
};

export default ActionRequired;
