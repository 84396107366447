import { Icon } from '@chakra-ui/react';
import { debounce } from 'lodash';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useEffect, useRef, useState } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import {
  DoubleCircleIcon,
  EyeIcon,
  FireIcon,
  ShootingStarIcon,
  StarIcon,
  UserIcon
} from '../../theme/Icons';
import {
  leftToRightChildrenElementAnimation,
  MotionLink,
  MotionVStack
} from '../Motion';

type AnchorsProps = {
  foryouSwitchOn?: boolean;
  router: any;
};

const Anchors = ({ foryouSwitchOn = false, router }: AnchorsProps) => {
  const [active, setActive] = useState<string | null>(
    router?.asPath.split('/')[1]
  );
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width: md)');
  const [isResized, setIsresized] = useState<number>(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefresh = useCallback(
    debounce(() => setIsresized(window?.performance.now()), 20),
    []
  );

  const anchorLinks = [
    {
      href: 'trendy',
      component: FireIcon,
      title: 'catalog:trendy'
    },
    {
      href: 'mostpopular',
      component: StarIcon,
      title: 'catalog:mostPopular'
    },
    {
      href: 'favorites',
      component: UserIcon,
      title: 'common:favorites'
    },
    {
      href: 'suggested',
      component: foryouSwitchOn ? EyeIcon : DoubleCircleIcon,
      title: 'catalog:suggested'
    },
    {
      href: 'feelinglucky',
      component: ShootingStarIcon,
      title: 'catalog:feelingLucky'
    }
    // {
    //   href: 'explore',
    //   component: TelescopeIcon
    // }
    // {
    //   href: 'biggestwins',
    //   component: TrophyIcon
    // },
    // {
    //   href: 'gambleracademy',
    //   component: PlanetIcon
    // }
  ];

  let iObserver = useRef<IntersectionObserver>(null);

  const getObserver = () => {
    anchorLinks.forEach((link) => {
      const element = document.getElementById(link.href);
      element && (iObserver?.current as IntersectionObserver).observe(element);
    });
  };

  const reconnectObserver = () =>
    setTimeout(() => {
      getObserver();
    }, 1000);

  useEffect(() => {
    const numSteps = 5;
    const buildThresholdList = () => {
      const thresholds = [];

      for (let i = 1.0; i <= numSteps; i++) {
        let ratio = i / numSteps;
        thresholds.push(ratio);
      }

      return thresholds;
    };
    const options = {
      rootMargin: '-50px 0px -55%',
      threshold: buildThresholdList()
    };
    let intersectedId: string[] = [];
    (iObserver.current as IntersectionObserver) = new IntersectionObserver(
      (entries, _observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            intersectedId.push(entry.target.id);

            // As observer is async and depend of the rates of user browser,
            // push all intersectioned element into an array and use the last id as active element
            // after a little delay

            setTimeout(() => {
              setActive(intersectedId[intersectedId.length - 1]);
            }, 100);
          }
        });
      },
      options
    );
    getObserver();

    const timer = setTimeout(() => {
      const anchors = document.getElementById('anchors');
      const categories = document.getElementById('categories');
      anchors?.classList.add('visible');
      categories?.classList.add('visible');
    }, 20);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      (iObserver?.current as IntersectionObserver).disconnect();
      clearTimeout(timer);
      clearTimeout(reconnectObserver());
      intersectedId = [];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResized]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(debouncedRefresh);

    resizeObserver.observe(document?.body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isMobile) return null;

  return (
    <MotionVStack
      zIndex="sticky"
      as="aside"
      ml="5px"
      spacing="30px"
      position="fixed"
      top="50%"
      left="0.5rem"
      id="anchors"
      className="anchors"
      initial={'initial'}
      transition={{
        staggerChildren: 0.1,
        delayChildren: 0.1
      }}
      whileInView={'animate'}
      viewport={{ once: true, amount: 0.1 }}
    >
      {anchorLinks?.map((link, index) => (
        <MotionLink
          display="block"
          key={link.href}
          data-testid={link.href}
          title={t(link.title)}
          variants={leftToRightChildrenElementAnimation}
          onClick={() => {
            setActive(link.href);
            (iObserver?.current as IntersectionObserver).disconnect();
            const element = document.getElementById(link.href);
            const bodyRect = document?.body?.getBoundingClientRect();
            const elemRect = element?.getBoundingClientRect();
            const offset = (elemRect?.top || 0) - bodyRect.top;
            window?.scrollTo({
              top: offset - 100,
              behavior: 'smooth'
            });
            // add an arbitrary values to reconnect Observer
            reconnectObserver();
          }}
          _after={
            active === link.href
              ? {
                  content: '""',
                  position: 'absolute',
                  height: '30px',
                  width: '2px',
                  top: `${index * 2}px`,
                  left: '-5px',
                  backgroundColor: '#fff'
                }
              : {}
          }
        >
          <Icon
            opacity={active === link.href ? 1 : 0.5}
            display="block"
            as={link.component}
            width={30}
            height={30}
          />
        </MotionLink>
      ))}
    </MotionVStack>
  );
};

export default Anchors;
