import { Box, BoxProps } from '@chakra-ui/react';
import { FC, useLayoutEffect, useRef } from 'react';
import { throttle } from 'lodash';
import useMediaQuery from '@/hooks/useMediaQuery';
import { getHeaderHeight } from '@/helpers/header';
import { getElementProperty, setElementProperty } from '@/helpers/cssVars';

type HeaderPortalProps = BoxProps;

export const HeaderApp: FC<HeaderPortalProps> = (props) => {
  const portalRef = useRef<HTMLDivElement | null>(null);
  const previousScrollY = useRef<number>(0);
  const heightNotifications = useRef<number>(0);

  const headerTop = useRef<number>(0);
  const isDesktop = useMediaQuery('(min-width: md)'); // 768px
  const headerHeight = getHeaderHeight(isDesktop);

  // update position when scrolling
  useLayoutEffect(() => {
    if (portalRef.current) {
      const onScroll = (e: Event) => {
        heightNotifications.current =
          parseInt(getElementProperty('height-notifications')) ?? 0;

        headerTop.current = heightNotifications.current;
        if (window.scrollY > 0) {
          portalRef.current?.classList.add('scrolled');
          headerTop.current = -headerHeight;
        } else {
          headerTop.current = 0;
        }

        if (window.scrollY < previousScrollY.current) {
          headerTop.current = heightNotifications.current;
        }
        setElementProperty('top-header', headerTop.current + 'px');

        previousScrollY.current = window.scrollY;
      };

      const throttledScroll = throttle(onScroll, 100, { trailing: true });

      window.addEventListener('scroll', throttledScroll);
      return () => {
        window.removeEventListener('scroll', throttledScroll);
      };
    }
  }, [headerHeight]);

  // update position when change on notification list
  useLayoutEffect(() => {
    const notifications = document.querySelector('.general-notification-list');
    if (notifications) {
      const config = { attributes: false, childList: true, subtree: false };

      const callback = (
        mutationList: MutationRecord[],
        observer: MutationObserver
      ) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            heightNotifications.current =
              parseInt(getElementProperty('height-notifications')) ?? 0;
            headerTop.current = heightNotifications.current;
            setElementProperty('top-header', headerTop.current + 'px');
          }
        }
      };

      const observer = new MutationObserver(callback);
      observer.observe(notifications, config);

      return () => observer.disconnect();
    }
  }, []);

  return (
    <>
      <Box
        id={'portal-header'}
        ref={portalRef}
        position={'sticky'}
        top={'var(--top-header, 0)'}
        transition={'top ease .3s'}
        zIndex={'sticky'}
        {...props}
      />
      <Box id={'portal-banner'} />
    </>
  );
};
