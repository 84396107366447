import { Button, ButtonProps } from '@chakra-ui/react';
import { toRem } from '@/helpers/toRem';
import { ChevronLeft } from '@/theme/Icons';
import React from 'react';

type ChevronButtonRoundProps = Omit<ButtonProps, 'children'> & {
  arrowSize?: number;
  direction: 'left' | 'right';
};

export const ChevronButtonRound = ({
  arrowSize = 24,
  direction,
  ...props
}: ChevronButtonRoundProps) => {
  return (
    <Button
      data-testid={`navigate-${direction}`}
      display="flex"
      alignItems="center"
      justifyContent="center"
      background="buttonPrimary"
      p="0"
      filter="drop-shadow(0px 10px 34px rgba(0, 0, 0, 0.34))"
      _disabled={{
        cursor: 'default',
        backgroundColor: 'figma.neutral.700 !important'
      }}
      _hover={{
        borderWidth: 0
      }}
      minWidth={'unset'}
      width={toRem(arrowSize)}
      height={toRem(arrowSize)}
      {...props}
    >
      <ChevronLeft
        fontSize={toRem(arrowSize)}
        transform={direction === 'right' ? 'scaleX(-1)' : undefined}
        position="absolute"
      />
    </Button>
  );
};
