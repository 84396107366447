import { PropsWithChildren } from 'react';

export type WithFallbackComponent = PropsWithChildren & {
  predicat: boolean;
  FallbackComponent: React.ComponentType;
};
const WithFallbackComponent = ({
  children,
  predicat,
  FallbackComponent
}: WithFallbackComponent) => {
  return predicat ? <>{children}</> : <FallbackComponent />;
};

export default WithFallbackComponent;
