import { JackpotLevelRefType } from '@/types/api/ge-jackpot/jackpot';
import { getRef } from '@/helpers/jackpot';
import { getFallbackLanguage } from '@/helpers/lang';
import useTranslation from 'next-translate/useTranslation';
import { useAppSettings } from '@/context/AppSettings';

export const getBackgroundUrl = (
  level: JackpotLevelRefType,
  isMobile: boolean
) => {
  const ref = getRef(level);

  if (isMobile) {
    switch (ref) {
      case '1':
        return '/jackpots/gold/hit/bkg-mobile.webp';
      case '2':
        return '/jackpots/silver/hit/bkg-mobile.webp';
      case '3':
        return '/jackpots/bronze/hit/bkg-mobile.webp';
    }
  }

  switch (ref) {
    case '1':
      return '/jackpots/gold/hit/bkg-desktop.webp';
    case '2':
      return '/jackpots/silver/hit/bkg-desktop.webp';
    case '3':
      return '/jackpots/bronze/hit/bkg-desktop.webp';
  }
};

export const useJackpotLinkInfo = () => {
  const { lang } = useTranslation();
  const {
    features: {
      jackpot: { linkInfo }
    }
  } = useAppSettings();

  return linkInfo[getFallbackLanguage(lang)];
};
