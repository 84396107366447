import { useEffect, useRef, useState } from 'react';

export type UseNumberAnimation = {
  value: number;
  animationValueDuration: number;
  animationValueStepDuration: number;
};
export const useNumberAnimation = ({
  value,
  animationValueDuration = 3000,
  animationValueStepDuration = 100
}: UseNumberAnimation) => {
  const valueStepRef = useRef(0);
  const [goalValue, setGoalValue] = useState(value);
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    if (value !== goalValue) {
      valueStepRef.current =
        (value - currentValue) /
        (animationValueDuration / animationValueStepDuration);
      setGoalValue(value);
    }
  }, [
    value,
    currentValue,
    goalValue,
    animationValueDuration,
    animationValueStepDuration
  ]);

  useEffect(() => {
    if (currentValue !== value) {
      const intervalId = setInterval(() => {
        if (currentValue < goalValue) {
          setCurrentValue((cv) => cv + valueStepRef.current);
        } else {
          clearInterval(intervalId);
          setCurrentValue(goalValue);
        }
      }, animationValueStepDuration);
      return () => clearInterval(intervalId);
    }
  }, [value, goalValue, currentValue, animationValueStepDuration]);

  return currentValue;
};
