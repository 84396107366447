import Link from 'next/link';
import { ChevronIcon } from '../../theme/Icons';
import React from 'react';

const BackButton = () => {
  return (
    <Link href="/" scroll={false} passHref>
      <ChevronIcon
        as="a"
        cursor="pointer"
        transform="rotate(90deg)"
        padding="3px"
        height="45px"
        width="auto"
        background="backgroundPrimaryDarker"
        border="1px solid"
        borderColor="whiteAlpha.500"
        borderRadius="40px"
        zIndex="modal"
      />
    </Link>
  );
};

export default BackButton;
