import { useAppSettings } from '@/context/AppSettings';
import { useAuth } from '@/context/Auth';
import WithFeaturesDisable from '@/hoc/WithFeaturesDisable';
import { useExtraProps } from '@/pages/_app';

import { GoldenPalaceIcon, OriaCasinoIcon } from '@/theme/Icons';
import { getFullUrlByLicense } from '@/utils/multiDomains';
import {
  Badge,
  BadgeProps,
  BoxProps,
  IconProps,
  Img,
  ImgProps,
  Link
} from '@chakra-ui/react';
import { MotionProps } from 'framer-motion';
import { useRouter } from 'next/router';
import { MotionBox } from '../Motion';
import useTranslation from 'next-translate/useTranslation';

type OriaTestProps = (BoxProps | MotionProps) & {
  iconProps?: IconProps | ImgProps;
  badgeProps?: BadgeProps;
  small?: boolean;
};

export const LogoTestAccount = ({
  iconProps,
  badgeProps,
  small = false,
  ...props
}: OriaTestProps) => {
  const { userData } = useAuth();
  const config = useAppSettings();
  const router = useRouter();
  const { license } = useExtraProps();
  const { lang: locale } = useTranslation();
  const currentLogo =
    license === 'MAIN'
      ? config.pathLogo.B // dice is the default one
      : config.pathLogo[license];

  const logoSize =
    config.customStyle.logoSizeHeight[license === 'MAIN' ? 'B' : license];

  return (
    <MotionBox
      zIndex="modal"
      position="relative"
      as={Link}
      href={
        license === 'MAIN' || router.pathname === '/'
          ? getFullUrlByLicense('MAIN', locale)
          : getFullUrlByLicense(license, locale)
      }
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      height={'100%'}
      {...props}
    >
      {userData?.type === 'test' && (
        <Badge
          className="testBadge"
          position="absolute"
          // right={config.appName === 'oria' ? '-70%' : '-30%'}
          {...badgeProps}
        >
          Test
        </Badge>
      )}

      {!small && (
        <Img
          src={currentLogo}
          loading="lazy"
          alt={`${config.appName} logo`}
          // maxWidth={{
          //   base: `${logoSize['base']}`,
          //   sm: `${logoSize['sm']}`,
          //   md: `${logoSize['md']}`
          // }}
          // maxHeight="inherit"

          height={{
            base: `${logoSize['base']}`,
            sm: `${logoSize['sm']}`,
            md: `${logoSize['md']}`
          }}
          {...(iconProps as ImgProps)}
        />
      )}

      {WithFeaturesDisable(
        small ? <OriaCasinoIcon width="3.5rem" height="3rem" /> : null,
        'OriaCasinoIcon'
      )}

      {WithFeaturesDisable(
        small ? (
          <GoldenPalaceIcon
            width="9rem"
            height="3rem"
            className="customLogoColor"
          />
        ) : null,
        'GoldenPalaceIcon'
      )}
    </MotionBox>
  );
};
