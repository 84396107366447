/** @jsxImportSource @emotion/react */
import UseIntersectionObserver from '@/hooks/useIntersectionObserver';
import { Box, BoxProps, StackProps } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { useRef } from 'react';
import { ScrollWithoutScrollBar } from '../GamesCatalog';

type Props<T> = BoxProps &
  StackProps & {
    data: T[];
    onSlideClick?: (element: T) => void;
  };

function SliderSnap<
  T extends {
    id: string;
    customComponent?: JSX.Element;
    icon?: string;
    src?: string;
    title?: string;
  }
>({ data, onSlideClick, ...props }: Props<T>) {
  const refContainer = useRef<HTMLDivElement>(null);

  // callback for each observed item, this is the place where all stuff is made.
  const handlerIntersection = (
    entries: IntersectionObserverEntry[],
    _observer: IntersectionObserver
  ) => {
    // const correction = 0.1;
    // for (const entry of entries) {
    //   (entry.target as HTMLElement).style.transform = `scale(${
    //     entry.intersectionRatio < 0.99
    //       ? entry.intersectionRatio - correction * 1
    //       : entry.intersectionRatio * 1
    //   })`;
    // }
  };

  const numSteps = 100;
  const buildThresholdList = () => {
    const thresholds = [];

    for (let i = 1.0; i <= numSteps; i++) {
      let ratio = i / numSteps;
      thresholds.push(ratio);
    }

    return thresholds;
  };

  const { addNode } = UseIntersectionObserver({
    options: {
      root: refContainer.current,
      threshold: buildThresholdList()
    },
    handler: handlerIntersection
  });

  const styleSliderItemRoot = css`
    display: flex;
    align-items: flex-start;
    justify-content: center;
  `;
  const styleSliderItem = css`
    display: flex;
    align-items: center;
    justify-content: center;
    //scroll-snap-align: center;
    margin: 0 0 0 1rem;
    height: 140px;
    width: 220px;
    color: #000;
    transition: all 0.15s ease;
    position: relative;
    background: #fff;
    border-radius: 20px;
    transition: all 0.3s ease;
    cursor: pointer;
    padding: 1rem;
    &:first-of-type {
      margin-left: 0;
    }
    img {
      height: 90%;
      // position: absolute;
      // top: 0;
      // left: 50%;
      // transform: translateX(-50%)
      // width: auto;
      // height: 90%;
    }
  `;

  return (
    <Box
      as="section"
      display="flex"
      alignItems="flex-end"
      overflowX="scroll"
      paddingX="50px"
      css={ScrollWithoutScrollBar}
      id="sliderSnap"
      style={{
        scrollSnapMarginLeft: '100px',
        scrollSnapType: 'x mandatory'
      }}
      ref={refContainer}
      {...props}
    >
      <div css={styleSliderItemRoot}>
        {data?.map((element, index) => (
          <div
            key={index}
            ref={addNode}
            css={styleSliderItem}
            onClick={() => onSlideClick?.(element)}
            data-testid={element.id}
          >
            {element?.customComponent ? (
              element.customComponent
            ) : (
              // eslint-disable-next-line @next/next/no-img-element
              <img src={element?.icon || element?.src} alt={element?.title} />
            )}
          </div>
        ))}
      </div>
    </Box>
  );
}
export default SliderSnap;
