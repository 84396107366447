import BorderedBox from '@/components/Box';
import { Button, HStack, Stack, StackProps, Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useWallet } from '../../../../context/Wallet';
import { WarningIcon } from '../../../../theme/Icons';
import RemainingProgressBar from './RemainingProgressBar';

type WalletQuickViewType = StackProps & {
  withRedirection?: boolean;
};

const WalletQuickView = ({
  withRedirection = true,
  ...props
}: WalletQuickViewType) => {
  const { depositLimit } = useWallet();
  const { t } = useTranslation();

  return (
    <BorderedBox padding={['1rem', '1rem', '4em']} {...props}>
      <Text
        as="h2"
        textStyle="h2"
        fontWeight="bold"
        marginBottom="2rem !important"
      >
        {t('wallet:weeklyAllowableDeposit')}
      </Text>
      <RemainingProgressBar
        remaining={depositLimit?.remaining || 0}
        max={depositLimit?.amount || 0}
      />

      <Stack
        justifyContent="space-between"
        width="100%"
        marginTop="2rem !important"
        direction={['column', 'column', 'row']}
      >
        {withRedirection && (
          <Link href={'/me/settings/depositLimit?openModal=true'} passHref>
            <Button
              data-testid="update-deposit-limit"
              variant="link"
              as="a"
              cursor="pointer"
              textDecor="underline"
              // leftIcon={<LockedIcon />}
            >
              {t('wallet:updateDepositLimit')}
            </Button>
          </Link>
        )}
        <HStack>
          <WarningIcon />
          <Text fontSize="0.8rem" fontWeight="bold">
            {t('wallet:depositWillReset')}
          </Text>
        </HStack>
      </Stack>
    </BorderedBox>
  );
};

export default WalletQuickView;
