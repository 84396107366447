import { withProse } from '@nikolovlazar/chakra-ui-prose';
import { extendTheme, StyleFunctionProps } from '@chakra-ui/react';
import { toRem } from '@/helpers/toRem';

export const breakpoints: any = {
  sm: '48rem',
  md: '62rem',
  lg: '80rem',
  xl: '90rem',
  '2xl': '105rem',
  '3xl': '120rem',
  xs: '37.5rem'
};

const borderRadius = {
  radii: {
    none: '0',
    sm: '0.125rem',
    base: '0.25rem',
    md: '0.375rem',
    lg: '0.5rem',
    xl: '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    full: '9999px',

    ten: '0.625rem' // 10px
  }
};

export const baseConfig = {
  breakpoints,
  ...borderRadius,
  styles: {
    global: {
      '*': {
        outline: 0
      },
      a: {
        textDecoration: 'none'
      },
      ul: {
        margin: 0,
        padding: 0
      },
      li: {
        margin: 0,
        padding: 0
      },
      '#__next': {
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      },
      '.blurry': {
        backdropFilter: 'blur(30px)',
        WebkitBackdropFilter: 'blur(30px)'
      },
      '.list-dot': {
        marginBottom: '1rem',

        li: {
          listStyleType: 'disc !important'
        }
      },

      '.list-dash': {
        marginBottom: '1rem',

        'li:before': {
          content: '"- "',
          color: 'white'
        }
      }
    }
  },
  textStyles: {
    h1: {
      fontSize: ['3rem', '3rem', '3.5rem'],
      fontWeight: 'bold'
    },
    h2: {
      fontSize: ['2rem', '2rem', '2.5rem'],
      fontWeight: '600'
    },
    h3: {
      fontSize: ['1.1rem', '1.1rem', '1.3rem'],
      fontWeight: '600'
    },
    p: {
      fontSize: ['0.9rem', '0.9rem', '1rem']
    },
    article: {
      fontSize: ['1rem'],
      color: 'customGrey.900',
      fontWeight: '600'
    }
  },
  shadows: {
    outline: '#fff solid 2px'
  },
  layerStyles: {
    base: {
      color: '#fff'
    }
  },
  components: {
    Table: {
      variants: {
        unstyled: {
          td: {
            px: '1em',
            py: '2rem',
            borderY: '1px solid var(--chakra-colors-whiteAlpha-300)'
          }
        },
        simple: {
          th: {
            borderColor: 'whiteAlpha.300',
            fontWeight: 'medium',
            padding: '1.4rem 1em'
          },
          tr: {
            borderY: '1px solid var(--chakra-colors-whiteAlpha-300)'
          }
        },
        jackpot: {
          thead: {
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: 'buttonPrimary',
            th: {
              padding: 0,
              textTransform: 'none',
              fontFamily: 'avertastd',
              fontWeight: '400',
              fontSize: '0.75rem',
              lineHeight: '1rem'
            }
          },
          tbody: {
            _before: {
              content: '"@"',
              display: 'block',
              lineHeight: '1.25rem',
              textIndent: '-99999px'
            },
            td: {
              textAlign: 'left',
              padding: '0 0 0.25rem 0',
              fontSize: '0.75rem',
              lineHeight: '1rem'
            }
          }
        }
      }
    },
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: 'buttonPrimary'
        },
        track: {
          bg: 'customGrey.900'
        }
      }
    },
    Button: {
      baseStyle: {
        borderRadius: '60px',
        fontWeight: 700
      },
      sizes: {
        lg: {
          fontSize: '1.2rem',
          h: '4rem',
          minW: '10rem'
        },
        md: {
          padding: '8px 24px'
        },
        sm: {
          padding: '8px 16px'
        }
      },
      variants: {
        solid: {
          color: '#fff',
          // padding: '10px',
          fontSize: '1rem',
          border: '2px solid transparent',
          shadow: 'lg',
          _hover: {
            border: '2px solid white',
            bg: 'buttonPrimary'
          }
        },
        ghost: {
          color: '#fff',
          _hover: {
            bg: 'rgba(255, 255, 255, 0.7)',
            textDecoration: 'underline'
          }
        },
        outline: (props: StyleFunctionProps) => ({
          color: 'colorPrimary',
          borderColor: 'whiteAlpha.400',
          shadow: 'lg',
          _hover: {
            borderColor: 'colorPrimary',
            bg: 'transparent'
          }
        }),
        link: {
          color: 'customGrey.900',
          whiteSpace: 'normal' // prevent body overflow-x when link are too long
        }
      }
    },
    Switch: {
      baseStyle: {
        track: {
          _checked: {
            bg: '#04DE00'
          }
        }
      }
    },

    Divider: {
      baseStyle: {
        backgroundColor: 'rgba(255,255,255, 0.2)'
      }
    },
    Input: {
      variants: {
        outline: {
          field: {
            h: '4rem',
            border: '1px solid',
            borderColor: 'brand.200', //'rgba(255,255,255,0.2)',
            bg: 'backgroundPrimary.900',
            fontSize: '1.2rem',
            padding: '1.5rem',
            fontWeight: 'bold',
            _hover: {
              borderColor: 'brand.1000',
              boxShadow: '0 0 0 1px var(--chakra-colors-brand-1000)'
            },
            _focus: {
              borderColor: 'brand.1000',
              boxShadow: '0 0 0 1px var(--chakra-colors-brand-1000)'
            },
            _focusVisible: {
              borderColor: 'brand.1000',
              boxShadow: '0 0 0 1px var(--chakra-colors-brand-1000)'
            }
          }
        }
      }
    },
    Select: {
      variants: {
        outline: {
          field: {
            h: '4rem',
            border: '1px solid',
            borderColor: 'brand.200', //'rgba(255,255,255,0.2)',
            bg: 'backgroundPrimary.900',
            fontSize: '1.2rem',
            padding: '0 1.5rem',
            fontWeight: 'bold',
            _hover: {
              borderColor: 'brand.1000',
              boxShadow: '0 0 0 1px var(--chakra-colors-brand-1000)'
            },
            _focus: {
              borderColor: 'brand.1000',
              boxShadow: '0 0 0 1px var(--chakra-colors-brand-1000)'
            },
            _focusVisible: {
              borderColor: 'brand.1000',
              boxShadow: '0 0 0 1px var(--chakra-colors-brand-1000)'
            }
          }
        }
      }
    },
    NumberInput: {
      sizes: {
        lg: {
          h: '3.2rem'
        }
      },
      variants: {
        outline: {
          field: {
            border: '1px solid',
            borderColor: 'rgba(255,255,255,0.2)',
            bg: 'backgroundPrimary.900'
          }
        }
      }
    },
    Checkbox: {
      baseStyle: {
        control: {
          _checked: {
            color: 'white',
            borderColor: 'brand.900',
            backgroundColor: 'brand.900'
          }
        },
        label: {
          width: '100%',
          marginLeft: '12px'
        }
      }
    },
    Drawer: {
      baseStyle: {
        dialog: {
          bg: 'rgba(1, 9, 37, 0.3)',
          backdropFilter: 'blur(30px)',
          WebkitBackdropFilter: 'blur(30px)',
          height: '100%'
        },
        footer: {
          backgroundColor: '#B3000518'
        },
        overlay: {
          bg: 'transparent'
        }
      }
    },
    Modal: {
      baseStyle: {
        overlay: {
          bg: '',
          backdropFilter: 'blur(5px)',
          WebkitBackdropFilter: 'blur(5px)'
        },
        dialog: {
          backdropFilter: 'blur(30px)',
          WebkitBackdropFilter: 'blur(30px)',
          bg: 'rgba(1, 9, 37, 0.3)',
          borderRadius: '2rem',
          border: '1px solid rgba(142, 148, 179, 0.2)'
        }
      }
    },
    Menu: {
      baseStyle: {
        list: {
          bg: 'transparent',
          backdropFilter: 'blur(30px)',
          WebkitBackdropFilter: 'blur(30px)',
          padding: '1rem'
        },
        item: {
          _focus: {
            bg: 'rgba(255,255,255,0.3)',
            borderRadius: '0.3rem'
          }
        }
      }
    },
    Tag: {
      variants: {
        subtle: {
          bg: 'transparent'
        }
      },
      baseStyle: {
        label: {
          lineHeight: '1'
        }
      },
      defaultProps: {
        size: 'sm',
        variant: 'outline'
      }
    },
    Tabs: {
      baseStyle: {
        position: 'relative'
      },
      defaultProps: {
        variant: 'unstyled'
      },
      variants: {
        unstyled: {
          tablist: {
            width: '100%',
            borderBottom: '1px solid rgba(255, 255, 255, 0.4)'
          },
          tab: {
            minW: 'fit-content',
            mr: '1rem',
            position: 'relative',
            fontSize: '1.1rem',
            fontWeight: 'bold',
            // height: '100%',
            color: 'rgba(255, 255, 255, 0.4)',
            cursor: 'pointer',
            _hover: {
              color: '#FFF'
            },
            _selected: {
              color: 'rgba(255, 255, 255, 1)',
              borderBottom: '1px solid rgba(255, 255, 255, 1)'
            }
          }
        },
        tiles: {
          tablist: {
            width: '100%'
          },
          tab: {
            minW: 'fit-content',
            mr: '1rem',
            position: 'relative',
            fontSize: '1rem',
            fontWeight: '500',
            color: 'rgba(255, 255, 255, 1)',
            cursor: 'pointer',
            border: '1px solid #565656',
            borderRadius: '1rem',
            width: '8rem',
            height: '8rem',
            aspectRatio: '1.2 / 1',
            padding: '0.5rem',
            textAlign: 'center',
            backgroundColor: '#2c2c2c',
            flexDirection: 'column',
            _hover: {
              backgroundColor: '#565656',
              borderColor: 'transparent'
            },
            _selected: {
              backgroundColor: '#565656',
              borderColor: 'transparent'
            }
          }
        }
      }
    },
    Accordion: {
      variants: {
        unstyled: {
          container: {
            border: 'none'
          },
          button: {
            paddingLeft: 0
          }
        },
        separate: {
          container: {
            borderRadius: '10px',
            border: '1px solid rgba(255, 255, 255, 0.20)',
            boxShadow: '0px 2px 4px 0px rgba(255, 255, 255, 0.10)',
            marginBottom: '20px',
            padding: '24px 32px'
          },
          button: {
            padding: 0,
            _hover: {
              background: 'none'
            }
          },
          icon: {
            color: '#fff'
          }
        }
      },
      baseStyle: {
        // root: {
        //   border: 'none',
        //   paddingBottom: '0px'
        // },
        button: {
          borderBottom: '0px',
          paddingBottom: '0px',
          cursor: 'pointer'
        },
        panel: {
          paddingLeft: '0px',
          paddingRight: '0px'
        }
      }
    }
  },
  fonts: {
    body: 'Galano, Helvetica, Arial, sans-serif',
    heading: 'Galano, Helvetica, Arial, sans-serif'
  }
};

const commonTheme = extendTheme(baseConfig, withProse());

export default commonTheme;

export const figmaTypoStyles = {
  h1: {
    fontSize: [toRem(32), toRem(48)],
    lineHeight: [toRem(38), toRem(52)]
  },
  h2: {
    fontSize: [toRem(24), toRem(32)],
    lineHeight: [toRem(32), toRem(38)]
  },
  h3: {
    fontSize: [toRem(18), toRem(24)],
    lineHeight: [toRem(24), toRem(30)]
  },
  h4: {
    fontSize: [toRem(14), toRem(16)],
    lineHeight: [toRem(20), toRem(24)]
  },
  p: {
    fontSize: [toRem(14), toRem(16)],
    lineHeight: [toRem(20), toRem(24)],
    letterSpacing: [toRem(0.035), toRem(0.08)],
    textWrap: 'pretty'
  },
  category: {
    fontSize: [toRem(12), toRem(14)],
    lineHeight: [toRem(16), toRem(17)],
    letterSpacing: [toRem(1.2), toRem(1.6)]
  },
  small: {
    fontSize: [toRem(12), toRem(14)],
    lineHeight: [toRem(16), toRem(17)],
    letterSpacing: [toRem(0.048), toRem(1.6)]
  },
  tag: {
    fontSize: [toRem(10), toRem(14)],
    lineHeight: [toRem(12), toRem(17)],
    letterSpacing: [toRem(0.04), toRem(0.048)],
    textTransform: 'uppercase'
  },
  notification: {
    fontSize: [toRem(10)],
    lineHeight: [toRem(12)],
    letterSpacing: [toRem(0.04)],
    textTransform: 'uppercase',
    fontWeight: 700
  },
  navigationLabels: {
    fontSize: [toRem(9)],
    lineHeight: [toRem(10)],
    fontWeight: 500
  },
  buttonSmallText: {
    fontSize: [toRem(12)],
    lineHeight: [toRem(16)],
    letterSpacing: [toRem(0.048)],
    fontWeight: 500
  }
};

// Margin configuration from default https://github.com/nikolovlazar/chakra-ui-prose/blob/main/packages/chakra-ui-prose/src/theme.ts
// TODO - investigate why these styles are not applied when used in withProse({baseStyle: proseStyles})
export const proseBaseStyles = {
  h1: { ...figmaTypoStyles.h1, mb: { base: 8, md: 10 } },
  h2: {
    ...figmaTypoStyles.h2,
    mt: { base: 12, md: 14 },
    mb: { base: 6, md: 8 }
  },
  h3: {
    ...figmaTypoStyles.h3,
    mt: { base: 8, md: 10 },
    mb: { base: 3, md: 4 }
  },
  h4: { ...figmaTypoStyles.h4, mt: { base: 6, md: 8 }, mb: 2 },
  p: { ...figmaTypoStyles.p, my: 6 },
  'h1, h2, h3, h4, h5, h6': {
    fontWeight: 500,
    textWrap: 'balance',
    '&:first-child': {
      mt: 0
    }
  },

  'h1 + *, h2 + *, h3 + *, h4 + *, hr + *': {
    mt: 0
  },

  '*:first-child': {
    mt: 0
  },

  '*:last-child': {
    mb: 0
  },

  'ul, ol': {
    marginLeft: '1rem'
  },

  'ol,ol > li': {
    listStyleType: 'decimal'
  },

  'ul,ul > li': {
    listStyleType: 'disc'
  }
};
