import { useFilters } from '@/context/Filters';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { StackProps, Tag } from '@chakra-ui/react';
import { useRef } from 'react';
import { ScrollWithoutScrollBar } from '.';
import { PlanetIcon } from '../../theme/Icons';
import { GameTitle } from './Artefacts';
import GameImage from './GameImage';
import SectionDescription from './SectionDescription';

type GamblerAcademyProps = StackProps & {};

const GamblerAcademy = ({ ...props }: GamblerAcademyProps) => {
  const { filteredGames } = useFilters();
  const ref = useRef(null);
  const data = filteredGames
    ?.sort((a: any, b: any) => a.sections['forYou'] - b.sections['forYou'])
    ?.slice(0, 15);

  return (
    <SectionDescription
      icon={PlanetIcon}
      title="Gambler Academy"
      subTitle="Discover our last tips to improve your game experiences"
      customRef={ref}
      {...props}
    >
      <HStack
        alignItems="stretch"
        css={ScrollWithoutScrollBar}
        spacing="1.8rem"
        paddingY="1rem"
        data-inviewport="slide-to-left-multiple"
        ref={ref}
      >
        {data?.map((game, index) => (
          <VStack
            align="baseline"
            justifyContent="space-between"
            minWidth="18rem"
            key={game['@id']}
          >
            <GameImage
              href={`/games/${game.id}/${game.slug}`}
              src={game?.mediaUrls?.banner_desktop}
              data-testid={`gambler-game-${index}`}
              width="100%"
              height="13rem"
            />
            <GameTitle marginBottom="1rem !important">{game.name}</GameTitle>
            <VStack align="baseline">
              <Text fontSize="sm" color="rgba(255,255,255,0.4)">
                August 13, 2021
              </Text>
              <HStack color="rgba(255,255,255,0.6)">
                {game.tags.slice(0, 2).map((tag) => (
                  <Tag
                    key={tag}
                    fontSize="9px"
                    fontWeight="bold"
                    textTransform="uppercase"
                  >
                    {tag}
                  </Tag>
                ))}

                <Tag fontSize="9px" fontWeight="bold" textTransform="uppercase">
                  ...
                </Tag>
              </HStack>
            </VStack>
          </VStack>
        ))}
      </HStack>
    </SectionDescription>
  );
};

export default GamblerAcademy;
3;
