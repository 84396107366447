import { ViolationsType } from '@/components/Register/types';

export type BuildErrorsType = {
  fields: string[];
  violations: ViolationsType[];
  useFormInstance: any; // @todo: add the right type either useForm instance or only setError method
};
export const errorsFormBuilder = ({
  violations,
  fields,
  useFormInstance
}: BuildErrorsType) => {
  type ErrorMessageType = { [key in string]: string };
  const errorMessages: ErrorMessageType = {};

  // build object of errrors based on fields param
  fields?.forEach((field) => {
    errorMessages[field] = '';
  });

  // assign each error on the corresponding key
  violations?.forEach((violation) => {
    const propertyPath = violation?.propertyPath;
    if (propertyPath) {
      // concat all errors with the same key in a single string
      errorMessages[propertyPath] = errorMessages[propertyPath]
        ?.concat(';', violation.title || violation.message) // sometimes, we receive 'title', sometimes 'message'...
        .replaceAll(';', '\n')
        .trim();
    }
  });

  // assign errors on each field with setError of RHFnewline
  Object.keys(errorMessages).forEach((fieldKey) => {
    if (errorMessages[fieldKey])
      useFormInstance.setError(fieldKey as any, {
        type: 'manual',
        message: errorMessages[fieldKey]
      });
  });
};
