import { HTTP } from '@/components/Http';
import { ChevronIcon } from '@/theme/Icons';
import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuItemOption,
  MenuList,
  useToast
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { FC, ReactNode, useEffect, useState } from 'react';

const GHSelector: FC<GHSelectorProps> = ({
  onSelect,
  selectedId,
  groupBy = 'division'
}) => {
  const [gamingHallList, setGamingHallList] = useState<GamingHallEntity[]>([]);
  const [selected, setSelected] = useState<GamingHallEntity>();

  const { t, lang } = useTranslation();
  const toast = useToast();

  const getGamingHalls = async () => {
    try {
      const { data } = await HTTP.get('/gaming_halls');

      setGamingHallList(data?.['hydra:member'] || data || []);
    } catch (error) {
      toast({
        title: t('common:somethingWrongHappenned'),
        description: t('wallet:gamingHallListWithError', {
          errorDescription: t('common:somethingWrongHappenned')
        }),
        status: 'warning',
        position: 'top',
        duration: 4000,
        isClosable: true
      });
    }
  };
  useEffect(() => {
    getGamingHalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // without setTimeout, the previous lang is used
    setTimeout(() => getGamingHalls(), 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const groupItems = (groupBy: keyof GamingHallEntity): ReactNode => {
    let groups: { [key in string]: GamingHallEntity[] } = {};
    gamingHallList.forEach((hall) => {
      const header = (hall as any)[groupBy];
      if (!groups[header]) groups[header] = [];

      // use this loop to load full entity based on selected id
      if (selected?.id !== selectedId && hall.id === selectedId)
        setSelected(hall);

      groups[header].push(hall);
    });

    return Object.keys(groups).map((groupName) => (
      <MenuGroup key={groupName} title={groupName + ':'} color={'GrayText'}>
        {groups[groupName].map((hall, index) => (
          <MenuItemOption
            key={hall.id}
            data-testid={`gh-selector-item-${index}`}
            bg={'transparent'}
            onClick={() => onSelect(hall)}
            isChecked={hall.id === selectedId}
            value={hall.id}
            title={`${hall.number} ${hall.street}, ${hall.zipCode} ${hall.city} (${hall.country})`}
          >
            <b>{hall.name}</b>
          </MenuItemOption>
        ))}
      </MenuGroup>
    ));
  };

  return (
    <Menu isLazy colorScheme={'pink'}>
      <MenuButton
        py={2}
        px={4}
        margin={[2, 0]}
        transition="all 0.2s"
        borderRadius="md"
        borderWidth="1px"
        _hover={{ bg: 'gray.800', color: 'white' }}
        _expanded={{ bg: 'gray.800', color: 'white' }}
        _focus={{ boxShadow: 'outline' }}
        data-testid={`gh-selector-select`}
      >
        <b>{selected?.name || t('wallet:gamingHallSelectButton')}</b>
        <ChevronIcon w={'7'} h={'7'} />
      </MenuButton>
      <MenuList maxH={'60vh'} overflowY={'scroll'} bg={'gray.800'} zIndex={'2'}>
        {groupItems(groupBy)}
      </MenuList>
    </Menu>
  );
};

export default GHSelector;

export interface GHSelectorProps {
  onSelect: (selected?: GamingHallEntity) => void;
  selectedId?: string;
  groupBy?: keyof GamingHallEntity;
}

export interface GamingHallEntity {
  id: string; // '3fa85f64-5717-4562-b3fc-2c963f66afa6';
  name: string; // 'Charleroi';
  street: string; // 'Rue du Garde-barrière';
  number: string; // '33';
  zipCode: string; // '6000';
  city: string; // 'Bruxelles';
  country: string; // 'BE';
  division: string; // 'Flandre Orientale';
  features: ('cashin' | 'cashout')[];
}
