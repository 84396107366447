import {
  DomainLicense,
  getLicenseByPath,
  getPathByLicense
} from '@/utils/multiDomains';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

type License = {
  license: string;
  licensePath: string;
};

export function useGetLicense(): License {
  const router = useRouter();
  const [currentLicense, setCurrentLicense] =
    useState<keyof Omit<DomainLicense, 'MAIN'>>('B');

  useEffect(() => {
    setCurrentLicense(
      getLicenseByPath(router.pathname) as keyof Omit<DomainLicense, 'MAIN'>
    );
  }, [router.pathname]);
  return {
    license: currentLicense,
    licensePath: getPathByLicense(currentLicense)
  };
}
