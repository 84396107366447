import { chakra } from '@chakra-ui/react';
import { coinsAnimationStyles } from './style';
import { gold } from './base64';
import React from 'react';
import {
  JackpotLevelAmount,
  JackpotLevelDropsBefore,
  JackpotLevelInfoButton,
  JackpotLevelWinnerButton,
  lightsTitle,
  SVGGoldTitle
} from '@/components/Jackpots';

type GoldLevelProps = {
  onClickWinnerHistory: () => void;
  onClickInformation: () => void;
  amount: string;
  dropsBefore: string;
};

export const SVGGoldLevel = ({
  onClickWinnerHistory,
  onClickInformation,
  amount,
  dropsBefore
}: GoldLevelProps) => {
  const borderColor = '#FFD780';
  // @ts-ignore
  return (
    <chakra.svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={'100%'}
      viewBox="0 0 381 188"
      css={coinsAnimationStyles}
    >
      {/* BITMAPS + MASK + FILTER + GRADIENT */}
      <defs>
        <image
          id="gold-coins1"
          x="0"
          y="0"
          width={67 / 2}
          xlinkHref={gold.coins1}
          // xlinkHref={'/goldenPalace/jackpots/gold/levels/coins-1.webp'}
        />

        <image
          id="gold-coins2"
          x="0"
          y="0"
          width={86 / 2}
          xlinkHref={gold.coins2}
          // xlinkHref={'/goldenPalace/jackpots/gold/levels/coins-2.webp'}
        />

        <image
          id="gold-coins3"
          x="0"
          y="0"
          width={80 / 2}
          xlinkHref={gold.coins3}
          // xlinkHref={'/goldenPalace/jackpots/gold/levels/coins-3.webp'}
        />

        <mask id="mask">
          <rect x="0" y="12" width="381" height="176" rx="11" fill="white" />
        </mask>

        {/* TEXT EFFECT */}
        {SVGGoldTitle.textEffect}
      </defs>

      {/* BACKGROUND + COINS */}
      <g mask="url(#mask)">
        {/* BACKGROUND */}
        <image
          // href="/goldenPalace/jackpots/gold/levels/background.webp"
          href={gold.bg}
          width={'100%'}
          y={11}
        />

        {/* COINS */}

        <use
          data-name={'coin-1'}
          xlinkHref="#gold-coins1"
          className={'coin'}
          style={
            {
              '--coin-x': '35px',
              '--coin-y': '40px',
              '--coin-to-y': '48px',
              '--coin-duration': '3.2s',
              '--coin-transform': 'rotate(153deg)'
            } as React.CSSProperties
          }
        />

        <use
          data-name={'coin-2'}
          xlinkHref="#gold-coins1"
          className={'coin'}
          style={
            {
              '--coin-x': '345px',
              '--coin-y': '5px',
              '--coin-to-y': '25px',
              '--coin-duration': '4s',
              '--coin-transform': 'rotate(103deg)'
            } as React.CSSProperties
          }
        />

        <use
          data-name={'coin-3'}
          xlinkHref="#gold-coins1"
          className={'coin'}
          style={
            {
              '--coin-x': '20px',
              '--coin-y': '80px',
              '--coin-to-y': '95px',
              '--coin-duration': '4s',
              '--coin-transform': 'rotate(0deg)'
            } as React.CSSProperties
          }
        />

        <use
          data-name={'coin-4'}
          xlinkHref="#gold-coins2"
          className={'coin'}
          style={
            {
              '--coin-x': '50px',
              '--coin-y': '120px',
              '--coin-to-y': '140px',
              '--coin-duration': '3.5s',
              '--coin-transform': 'rotate(0deg)'
            } as React.CSSProperties
          }
        />

        <use
          data-name={'coin-5'}
          xlinkHref="#gold-coins2"
          className={'coin'}
          style={
            {
              '--coin-x': '380px',
              '--coin-y': '60px',
              '--coin-to-y': '75px',
              '--coin-duration': '3.5s',
              '--coin-transform': 'rotate(140deg)'
            } as React.CSSProperties
          }
        />

        <use
          data-name={'coin-6'}
          xlinkHref="#gold-coins2"
          className={'coin'}
          style={
            {
              '--coin-x': '370px',
              '--coin-y': '90px',
              '--coin-to-y': '100px',
              '--coin-duration': '3s',
              '--coin-transform': 'rotate(55deg)'
            } as React.CSSProperties
          }
        />

        <use
          data-name={'coin-7'}
          xlinkHref="#gold-coins3"
          className={'coin'}
          style={
            {
              '--coin-x': '90px',
              '--coin-y': '50px',
              '--coin-to-y': '60px',
              '--coin-duration': '3s',
              '--coin-transform': 'scale(.9) rotate(86deg)'
            } as React.CSSProperties
          }
        />

        <use
          data-name={'coin-8'}
          xlinkHref="#gold-coins3"
          className={'coin'}
          style={
            {
              '--coin-x': '300px',
              '--coin-y': '145px',
              '--coin-to-y': '130px',
              '--coin-duration': '4s',
              '--coin-transform': 'rotate(-13deg)'
            } as React.CSSProperties
          }
        />
      </g>

      {/* BORDER */}
      <rect
        x="1"
        y="13"
        width="379"
        height="174"
        rx="10"
        stroke={borderColor}
        strokeWidth="1"
        fill={'transparent'}
      />

      {/*  JACKPOT + PILL */}
      {SVGGoldTitle.textAndPill}

      {/* JACKPOT LIGHTS*/}
      {lightsTitle}

      {/* AMOUNT */}
      <JackpotLevelAmount amount={amount} />

      {/* DROPS BEFORE */}
      <JackpotLevelDropsBefore dropsBefore={dropsBefore} />

      {/*WINNER BUTTON*/}
      <JackpotLevelWinnerButton
        onclick={onClickWinnerHistory}
        borderColor={borderColor}
      />

      {/*INFO BUTTON*/}
      <JackpotLevelInfoButton
        onclick={onClickInformation}
        borderColor={borderColor}
      />
    </chakra.svg>
  );
};
