import {
  WalletIcon,
  MessageIcon,
  AccountIcon,
  SettingsIcon
} from '../../../theme/Icons';

export const profileNavLinks = [
  {
    icon: WalletIcon,
    label: 'wallet',
    height: 34,
    width: 34,
    url: '/me/wallet/cash-in'
  },
  // {
  //   icon: MessageIcon,
  //   label: 'messages',
  //   height: 34,
  //   width: 34,
  //   url: '#'
  // },
  {
    icon: AccountIcon,
    label: 'account',
    height: 30,
    width: 30,
    url: '/me/account/my-profile'
  },
  {
    icon: SettingsIcon,
    label: 'settings',
    height: 26,
    width: 26,
    url: '/me/settings/depositLimit'
  }
];

// export const profileNavLinksExtended = [
//   {
//     icon: SupportIcon,
//     label: 'support',
//     height: 28,
//     width: 28,
//     url: '#'
//   }
// ];
