import { Stack, StackProps } from '@chakra-ui/react';

const BorderedBox = ({ ...props }: StackProps) => {
  return (
    <Stack
      width="100%"
      padding={['1rem', '1rem', '1rem', '2rem']}
      border="1px solid rgba(255,255,255, 0.2)"
      borderRadius="1rem"
      background="backgroundPrimaryDarker"
      className="blurry"
      overflow="hidden"
      {...props}
    />
  );
};

export default BorderedBox;
