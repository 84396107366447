import { HStack, StackProps, Switch, Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, { FC } from 'react';

type FilterExclusivityProps = StackProps & {
  onChange: (data: boolean) => void;
  selectedValue: boolean;
};

const FilterExclusivity: FC<FilterExclusivityProps> = ({
  onChange,
  selectedValue,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <HStack
      width="100%"
      justifyContent="space-between"
      marginBottom="2rem"
      {...props}
    >
      <Text fontSize="1.2rem">{t('catalog:onlyOnOria')}</Text>
      <Switch
        colorScheme="yellow"
        isChecked={!!selectedValue}
        data-testid="exlusivity-switch"
        onChange={(e) => onChange(e.target.checked)}
        size="lg"
      />
    </HStack>
  );
};

export default FilterExclusivity;
