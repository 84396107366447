import { SVGProps } from 'react';
import {
  lightsTitle,
  ModalHitAmount,
  SVGSilverTitle
} from '@/components/Jackpots';

type SVGLevelMiniProps = SVGProps<SVGElement> & {
  amount: string;
};
export const SVGModalHitSilver = ({ amount }: SVGLevelMiniProps) => {
  return (
    <svg
      viewBox="0 0 381 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ position: 'relative', zIndex: 1 }}
    >
      <defs>{SVGSilverTitle.textEffect}</defs>

      {/* UI */}
      {SVGSilverTitle.textAndPill}
      {lightsTitle}

      {/* TEXT */}
      <ModalHitAmount amount={amount} />
    </svg>
  );
};
