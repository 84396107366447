import { toRem } from '@/helpers/toRem';
import { Box, BoxProps } from '@chakra-ui/react';
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';
import { MotionBox } from '../Motion';
import useMediaQuery from '@/hooks/useMediaQuery';
import { ChevronButtonRound } from '@/components/Button/ChevronButtonRound';
import useTranslation from 'next-translate/useTranslation';
import useResizeObserver from '@/hooks/useResizeObserver';

/**
 * Example of use:
 *  <Slider>
 <WrapItem>
 <Center w="180px" h="80px" bg="red.200">
 Box 1
 </Center>
 </WrapItem>
 <WrapItem>
 <Center w="180px" h="80px" bg="green.200">
 Box 2
 </Center>
 </WrapItem>
 <WrapItem>
 <Center w="180px" h="80px" bg="tomato">
 Box 3
 </Center>
 </WrapItem>
 <WrapItem>
 <Center w="180px" h="80px" bg="blue.200">
 Box 4
 </Center>
 </WrapItem>
 <WrapItem>
 <Center w="180px" h="80px" bg="red.200">
 Box 5
 </Center>
 </WrapItem>
 <WrapItem>
 <Center w="180px" h="80px" bg="green.200">
 Box 6
 </Center>
 </WrapItem>
 <WrapItem>
 <Center w="180px" h="80px" bg="tomato">
 Box 7
 </Center>
 </WrapItem>
 <WrapItem>
 <Center w="180px" h="80px" bg="blue.200">
 Box 8
 </Center>
 </WrapItem>
 </Slider>
 */

export interface SliderProps extends BoxProps {
  /**
   * children must be a list of element.
   * As it's a slider, you need a list.
   */
  children: ReactNode;
  /**
   * This props is designed to change the parent of the component.
   * By default, the value is an <ul\> element, so it's important to maintain a certain semantics if you use it.
   * In this case, the children must be a list of <li\> element.
   * Feel free to change it if you want another html output.
   */
  root?: string;
  /**
   * Change the gap between item. Default 1rem.
   */
  gap?: string;
  /**
   * Enable arrows. Default true.
   */
  enableArrows?: boolean;
  /**
   * Changes size (in pixels) of arrows. Default: 40.
   */
  arrowSize?: number;
  justifyButtons?: 'center' | 'start' | 'end';
}

type StateButtonsType = {
  isDisabled: boolean;
  isShowing: boolean;
};

const Slider: React.FC<SliderProps> = ({
  children,
  root = 'ul',
  gap = '1rem',
  enableArrows = true,
  arrowSize = 24,
  justifyButtons = 'center',
  ...props
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const sliderTrackRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery('(max-width: md)');
  const { lang } = useTranslation();
  const dimensions = useResizeObserver(sliderRef);
  const [statePrevArrow, setStatePrevArrow] = useState<StateButtonsType>({
    isDisabled: true,
    isShowing: false
  });
  const [stateNextArrow, setStateNextArrow] = useState<StateButtonsType>({
    isDisabled: false,
    isShowing: false
  });

  useEffect(() => {
    const sliderTrack = sliderTrackRef.current;
    const slider = sliderRef.current;
    if (!sliderTrack || !slider) return;

    function getTotalMargin(element: Element): number {
      // Get the computed styles of the element
      const computedStyles = window.getComputedStyle(element);

      // Get the left and right margin values and convert them to numbers
      const marginLeft = parseFloat(computedStyles.marginLeft);
      const marginRight = parseFloat(computedStyles.marginRight);

      // Calculate and return the total margin
      return marginLeft + marginRight;
    }

    function getTotalGap(element: Element): number {
      // Get the computed styles of the slider
      const computedStylesSlider = window.getComputedStyle(element);

      // Get the gap value and convert them to numbers
      const gap = parseFloat(computedStylesSlider.gap);

      // Calculate and return the total gap
      return gap * (element.children.length - 1);
    }

    // Element.outerWidth doesn't give margin so this function compute all margin of element provided by react.Children
    const totalMargin = Array.from(sliderTrack.children).reduce(
      (total, item) => total + getTotalMargin(item),
      getTotalGap(sliderTrack)
    );

    const computeTotal = (array: [], propertyName: string) => {
      return array.reduce((total, item) => total + item[propertyName], 0);
    };

    const totalItems =
      computeTotal(Array.from(sliderTrack.children) as [], 'offsetWidth') +
      (totalMargin || 0);

    // this correction is used in the calculation of the entire viewable scroll zone, sometimes, there is some miscalculation with the box model so, put this arbiratry but not high level value.
    const correction = 2;

    const updateStateButtons = (
      state: StateButtonsType,
      direction: 'prev' | 'next'
    ) => {
      if (direction === 'prev') {
        return {
          ...state,
          isDisabled: sliderTrack.scrollLeft <= 0
        };
      }

      return {
        ...state,
        isDisabled:
          sliderTrack.scrollLeft + sliderTrack.clientWidth >
          sliderTrack.scrollWidth - correction
      };
    };

    // show/hide arrows depending on the number of items
    setStatePrevArrow((state) => ({
      ...state,
      isShowing: isMobile ? false : totalItems > slider.clientWidth
    }));

    setStateNextArrow((state) => ({
      ...state,
      isShowing: isMobile ? false : totalItems > slider.clientWidth
    }));

    const handleScroll = () => {
      setStatePrevArrow((state) => updateStateButtons(state, 'prev'));

      setStateNextArrow((state) => updateStateButtons(state, 'next'));
    };

    sliderTrack.addEventListener('scroll', handleScroll);

    return () => {
      sliderTrack.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile, lang, dimensions]);

  // this value is optional because this component use CSS Snap to calculate the scrollable item value so this function is a callback if the style changes.
  const itemScrollableValue = (slider: HTMLDivElement): number =>
    slider.clientWidth / (children as [])?.length;

  const handleScrollValue = (direction: 'prev' | 'next') => {
    const slider = sliderTrackRef.current;
    if (slider) {
      const scrollAmount = itemScrollableValue(slider);
      slider.scrollBy({
        left: direction === 'prev' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      });
    }
  };

  const handlePrevClick = () => {
    handleScrollValue('prev');
  };

  const handleNextClick = () => {
    handleScrollValue('next');
  };

  return (
    <Box
      position="relative"
      width="100%"
      display="flex"
      justifyContent={justifyButtons} // center is breaking menu on TournamentPage -> need to be in start
      data-testid="slider"
      ref={sliderRef}
      {...props}
    >
      <MotionBox
        data-testid="slider-track"
        as={root}
        display="flex"
        width="auto"
        maxW="container.xl"
        className="no-scrollbar"
        overflowX="scroll"
        overflowY="hidden"
        border="none"
        initial={'initial'}
        justifyContent={['start']}
        transition={{
          staggerChildren: 0.15,
          delayChildren: 0.3
        }}
        whileInView={'animate'}
        viewport={{ once: true, amount: 0.1 }}
        ref={sliderTrackRef}
        scrollSnapType="x mandatory"
        listStyleType="none"
        gap={gap}
        pr={[gap, gap, 0]}
        style={{ scrollBarWidth: 'none' }}
        marginLeft={
          enableArrows && statePrevArrow.isShowing
            ? toRem((arrowSize / 3) * 2)
            : undefined
        }
        marginRight={
          enableArrows && stateNextArrow.isShowing
            ? toRem((arrowSize / 3) * 2)
            : undefined
        }
      >
        {React.Children.map(children, (child) => {
          return child
            ? React.cloneElement(child as ReactElement, {
                style: {
                  display: 'flex',
                  scrollSnapAlign: 'start',
                  scrollBehavior: 'smooth',
                  minWidth: 'auto'
                }
              })
            : null;
        })}
      </MotionBox>
      {enableArrows ? (
        <>
          {statePrevArrow.isShowing ? (
            <ChevronButtonRound
              direction={'left'}
              onClick={handlePrevClick}
              isDisabled={statePrevArrow.isDisabled}
              arrowSize={arrowSize}
              position="absolute"
              top="50%"
              left={0}
              transform="translateY(-50%)"
              data-testid={'prev'}
            />
          ) : null}
          {stateNextArrow.isShowing ? (
            <ChevronButtonRound
              direction={'right'}
              onClick={handleNextClick}
              isDisabled={stateNextArrow.isDisabled}
              arrowSize={arrowSize}
              position="absolute"
              top="50%"
              right={0}
              transform="translateY(-50%)"
              data-testid={'next'}
            />
          ) : null}
        </>
      ) : null}
    </Box>
  );
};

export default Slider;
