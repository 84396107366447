import { VStack } from '@chakra-ui/react';
import { PlanetSatelliteIcon } from '../../theme/Icons';
import { GameTitle, GameSubTitle } from '.';
import React from 'react';
import useTranslation from 'next-translate/useTranslation';

const NoResultsFound = ({}) => {
  const { t } = useTranslation();
  return (
    <VStack
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      minHeight="50vh"
      textAlign="center"
      spacing="1rem"
      data-testid="no-result"
    >
      <PlanetSatelliteIcon width={150} height={150} />
      <GameTitle>{t('catalog:noResultsFound')}</GameTitle>
      <GameSubTitle>{t('catalog:noResultsDescription')}</GameSubTitle>
    </VStack>
  );
};

export default NoResultsFound;
