import { BankAccountPropsTypes } from '@/components/UserSettings/Wallet/CashOut/steps/CashoutForm.types';
import { Arrow, CheckedIcon, PenIcon, TrashIcon } from '@/theme/Icons';
import {
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { FC, useState } from 'react';
import { useSettings } from '../../../SettingsContext';
import {
  BankAccountSelectorPropsTypes,
  modeTypes
} from './BankAccountSelector.types';

const BankAccountSelector: FC<BankAccountSelectorPropsTypes> = ({
  openModal,
  modalMode
}) => {
  const { t } = useTranslation();

  const {
    selectedBankAccount,
    defaultBankAccount,
    setSelectedBankAccount,
    bankAccounts,
    changeDefaultBankAccount
  } = useSettings();

  const handleActionClick = (mode: modeTypes) => {
    modalMode(mode);
    openModal();
  };

  const [menuWidth, setMenuWidth] = useState<{ width: number }>({ width: 0 });

  const triggerGetDimension = (id: string) => {
    const button = document.querySelector(`[data-testid="${id}"]`);
    if (button) {
      setMenuWidth({ width: button?.clientWidth });
    }
  };

  return (
    <Menu isLazy>
      <MenuButton
        as={Button}
        id="bank-account-select"
        data-testid="bank-account-select"
        variant="unstyled"
        border="1px solid transparent"
        borderColor="figma.neutral.300 !important"
        borderRadius="0.5rem"
        height="4rem"
        width="100%"
        onClick={() => triggerGetDimension('bank-account-select')}
      >
        <HStack padding="1rem" width="100%" justifyContent="space-between">
          <Text data-testid="defaultText">
            {!selectedBankAccount?.id?.length
              ? defaultBankAccount?.iban
                ? defaultBankAccount?.iban
                : t('form:selectBankAccount')
              : selectedBankAccount?.iban}
          </Text>
          <Arrow height={6} width={6} />
        </HStack>
      </MenuButton>
      <MenuList
        id="bank-account-list"
        minWidth={`${menuWidth.width}px`}
        maxWidth={`${menuWidth.width}px`}
        // width="100%"
        zIndex="overlay"
        border="1px solid figma.neutral.300"
        // borderColor="figma.Fneutral.300"
        className="blurryFallback"
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
      >
        {bankAccounts.map((bankAccount: BankAccountPropsTypes) => (
          <HStack
            as={MenuItem}
            isFocusable={false}
            key={bankAccount.id}
            width="100%"
            justifyContent="space-between"
            marginBottom={['1rem', '1rem', '.5rem']}
            flexWrap={['wrap', 'wrap', 'nowrap']}
          >
            <HStack>
              <CheckedIcon
                opacity={
                  bankAccount?.id ==
                  (selectedBankAccount?.id || defaultBankAccount?.id)
                    ? 1
                    : 0
                }
              />
              <Text
                id={bankAccount.id}
                cursor="pointer"
                data-testid="options"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedBankAccount?.(bankAccount);
                  e.stopPropagation();
                }}
              >
                {bankAccount.iban}
              </Text>
            </HStack>
            <HStack id={bankAccount.id}>
              <Button
                background={
                  bankAccount.default ? 'buttonPrimary' : 'transparent'
                }
                border="1px solid rgba(255,255,255,0.3)"
                borderRadius="10px"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  changeDefaultBankAccount?.(bankAccount);
                }}
                fontSize={['0.7rem', '0.7rem', '1rem']}
                height={['1.5rem', '1.5rem', 'initial']}
                _hover={{
                  borderWidth: '1px'
                }}
              >
                {t('common:default')}
              </Button>
              <PenIcon
                data-testid="editIcon"
                w={[4, 4, 5]}
                h={[4, 4, 5]}
                cursor="pointer"
                opacity="0.7"
                _hover={{
                  opacity: '1'
                }}
                onClick={() => {
                  handleActionClick('edit');
                  setSelectedBankAccount?.(bankAccount);
                }}
              />
              <TrashIcon
                data-testid="deleteIcon"
                w={[4, 4, 5]}
                h={[4, 4, 5]}
                cursor="pointer"
                color="buttonPrimary"
                opacity="0.7"
                _hover={{
                  opacity: '1'
                }}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleActionClick('delete');
                  setSelectedBankAccount?.(bankAccount);
                }}
              />
            </HStack>
          </HStack>
        ))}
        <MenuDivider marginY="1rem" />
        <Button
          data-testid="addButton"
          backgroundColor="buttonPrimary"
          size="md"
          type="button"
          whiteSpace={'normal'}
          height={'auto'}
          wordBreak={'break-word'}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleActionClick('add');
          }}
        >
          {t('form:addBankAccount')}
        </Button>
      </MenuList>
    </Menu>
  );
};

export default BankAccountSelector;
