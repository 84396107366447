import React, { FC } from 'react';
import { GuideProps } from './Guide.types';
import { Text, Button, Box } from '@chakra-ui/react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { motion } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';

const MotionBox = motion(Box);

const Guide: FC<GuideProps> = ({ title, buttonText, action }) => {
  const isMobile = useMediaQuery('(max-width: md)');
  const { t } = useTranslation();
  if (isMobile) {
    return (
      <Button
        position="absolute"
        bottom="0.5rem"
        right="0.5rem"
        onClick={action}
        borderRadius="full"
        variant="outline"
        backgroundColor="rgba(1, 9, 37, 0.2)"
        padding="1.5rem"
        className="blurry"
      >
        ?
      </Button>
    );
  }
  return (
    <MotionBox
      drag="x"
      cursor="pointer"
      whileDrag={{
        scale: 0.9
      }}
      position="absolute"
      align="baseline"
      bottom="1rem"
      right="1rem"
      width="12rem"
      border="1px solid rgba(255, 255, 255, 0.2)"
      borderRadius="1.5rem"
      backgroundColor="rgba(1, 9, 37, 0.2)"
      padding="1.5rem"
      className="blurry"
    >
      <Text
        as="span"
        color="whiteAlpha.600"
        fontSize="0.6rem"
        textTransform="uppercase"
      >
        {t('catalog:startingGuide')}
      </Text>
      <Text as="h4" fontSize="1rem" fontWeight="bold" marginBottom="1rem">
        {title}
      </Text>
      <Button variant="outline" onClick={action}>
        {buttonText}
      </Button>
    </MotionBox>
  );
};

export default Guide;
