/* eslint-disable react/display-name */
import {
  Box,
  BoxProps,
  ImgProps,
  StackProps,
  Text,
  Image as ChakraImage
} from '@chakra-ui/react';
import { MotionProps } from 'framer-motion';
import { ReactElement, useEffect, useState } from 'react';
import { MotionBox } from '../Motion';
import { NoPreviewIcon } from '@/theme/Icons';
import { getLocaleTranslation } from '@/helpers/translation';
import useTranslation from 'next-translate/useTranslation';
import Image, { ImageProps } from 'next/future/image';
import { getBrandComponent } from '@/helpers/getBrandComponent';
import { useAppSettings } from '@/context/AppSettings';
import { GameCatalogItem } from '@/services/getCatalog';

type GameImageProps = ImgProps &
  MotionProps & {
    src?: string;
    borderRadius?: string;
    children?: ReactElement | ReactElement[] | null | undefined;
    stackProps?: StackProps & BoxProps;
    boxProps?: BoxProps;
    href?: string;
    game?: GameCatalogItem;
    isNativeImage?: boolean;
    withoutBackground?: boolean;
  };

type CustomImageProps = ImgProps &
  Pick<GameImageProps, 'src' | 'borderRadius'> & {
    label: string;
    isNewGame?: boolean;
  };

type NoPreviewImageProps = {
  label?: string;
  'data-testid'?: string;
};

type CustomChakraImage = {
  src?: string;
  borderRadius?: string;
};

const NewLabel = getBrandComponent('NewLabel');
const NoPreviewImage = ({
  label,
  'data-testid': dataTestId
}: NoPreviewImageProps) => {
  const { t } = useTranslation();
  return (
    <Box
      width="100%"
      height="100%"
      minHeight="100%"
      minWidth="100%"
      textAlign="center"
      background={
        "no-repeat center 25% / 80% url('/placeholders/no-preview-stars.svg')"
      }
      style={{
        aspectRatio: '1.5'
      }}
      data-testid={dataTestId}
    >
      <NoPreviewIcon
        width={['80%', '80%', '30%']}
        height="auto"
        marginBottom="4%"
        marginTop={['45%', '45%', '12%']}
      />
      {label && (
        <Text fontSize="80%" lineHeight="normal">
          {label}
        </Text>
      )}
      <Text fontSize="70%" lineHeight={'normal'} opacity="0.6">
        {t('catalog:noPreview')}
      </Text>
    </Box>
  );
};

export const CustomImage = ({
  src,
  borderRadius,
  label,
  height,
  width,
  isNewGame = false,
  ...props
}: CustomImageProps) => {
  const [currentSrc, setCurrentSrc] = useState<string | null | undefined>(
    src || null
  );
  useEffect(() => {
    setCurrentSrc(src);
  }, [src]);

  const currentSrcDependofEnv =
    process.env.NODE_ENV === 'development'
      ? currentSrc?.replace(
          'https://minio.kubegames.be',
          'http://ge-minio:9000'
        )
      : currentSrc;

  return currentSrc ? (
    <>
      {isNewGame ? <NewLabel /> : null}

      <Image
        // onError={handleError}
        loading="lazy"
        // layout="fill"
        style={{
          borderRadius,
          transition: 'all .3s ease',
          objectFit: 'cover'
        }}
        onInvalid={() => <NoPreviewImage label={label} />}
        width={!width || width === 'auto' ? undefined : (width as string)}
        height={!height || height === 'auto' ? undefined : (height as string)}
        // height={'100%'}
        fill={width === 'auto'}
        {...(props as ImageProps)}
        alt={(label || 'Game image').replace(/"/g, "'")}
        src={currentSrcDependofEnv + ''}
      />
    </>
  ) : (
    <NoPreviewImage label={label} {...props} />
  );
};

const CustomChakraImage = ({
  borderRadius,
  src,
  ...props
}: CustomChakraImage) => {
  const config = useAppSettings();
  const placeholderUrl = config?.customStyle.placeholderUrl;
  const [placeHolderSrc, setPlaceHolderSrc] = useState(src ?? placeholderUrl);

  const [currentSrc, setCurrentSrc] = useState<string | null | undefined>(
    src || null
  );

  const handleError = () => setPlaceHolderSrc(placeHolderSrc);

  useEffect(() => {
    setCurrentSrc(src);
  }, [src]);

  return (
    <>
      {currentSrc ? (
        <ChakraImage
          src={currentSrc}
          onError={handleError}
          loading="lazy"
          borderRadius={borderRadius}
          objectFit="cover"
          htmlWidth="100%"
          minWidth="100%"
          htmlHeight="100%"
          minHeight="100%"
          alt="Game image"
          style={{ transition: 'all .3s ease' }}
          {...props}
        />
      ) : (
        <NoPreviewImage />
      )}
    </>
  );
};

const propsImageParent = (
  isNativeImage: boolean,
  withoutBackground: boolean = false
) =>
  isNativeImage
    ? { width: ['100%', '100%', '100%', 'auto'] }
    : {
        position: 'relative',
        height: 'auto',
        width: ['100%', '100%', 'auto'],
        minWidth: ['9.38rem', '9.38rem', '12.7rem'],
        minHeight: ['6.20rem', '6.20rem', '8.5rem'],
        style: { transition: 'all .3s ease' },
        background: withoutBackground ? 'transparent' : 'whiteAlpha.300',
        overflow: 'hidden'
      };

const GameImage = ({
  src,
  borderRadius = '1.875rem',
  children,
  stackProps,
  href,
  game,
  withoutBackground = false,
  isNativeImage = false,
  boxProps,
  ...props
}: GameImageProps) => {
  const hrefIsValid = !href?.includes('undefined');
  const label = game ? game.name : 'Game image';

  return (
    <MotionBox
      {...propsImageParent(isNativeImage, withoutBackground)}
      className={href ? 'game-image' : ''}
      borderRadius={borderRadius}
      {...stackProps}
    >
      {href && hrefIsValid ? (
        <Box
          as="a"
          href={href}
          position="absolute"
          display="block"
          height={'100%'}
          {...boxProps}
        >
          {isNativeImage ? (
            <CustomChakraImage
              src={src}
              borderRadius={borderRadius}
              {...props}
            />
          ) : (
            <CustomImage
              src={src}
              label={label}
              borderRadius={borderRadius}
              isNewGame={game?.new}
              {...props}
            />
          )}
        </Box>
      ) : (
        <>
          {isNativeImage ? (
            <CustomChakraImage
              src={src}
              borderRadius={borderRadius}
              {...props}
            />
          ) : (
            <CustomImage
              src={src}
              label={label}
              borderRadius={borderRadius}
              isNewGame={game?.new}
              {...props}
            />
          )}
        </>
      )}
      {children}
    </MotionBox>
  );
};

export default GameImage;
