import { Locale } from '@/components/GamesCatalog/types';
import { HTTP } from '@/components/Http';
import { useExtraProps } from '@/pages/_app';
import {
  getDomainByLicense,
  getFullUrlByLicense,
  getPathByLicense
} from '@/utils/multiDomains';
import { ToastId, useToast } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react';
import { isFreeAccess, isOnlineAccess } from '../helpers/isFreeAccess';
import {
  handleAddFavorite,
  handleAuth,
  handleFavorites,
  handleLicences,
  handleLogout,
  handleRemoveFavorite
} from '../services/handleAuth';
import {
  APIUserIdentityType,
  APIUserType,
  AuthProviderContextType
} from './Auth.types';

export const UserContext = createContext<AuthProviderContextType>({
  isLogged: false,
  isLoginFetching: false,
  countOfLoginAttempts: 0,
  isFavorite: () => false,
  addFavorite: () => Promise.resolve(false),
  removeFavorite: () => Promise.resolve(false),
  setUserInfos: (value) => value,
  favorites: [],
  loadFavorites: () => false
});

export type UserInfos = {
  person: {
    address: {
      street: string;
      secondLine?: string;
      number: string;
      zipCode: string;
      city: string;
      country: string;
    };
    birthdate: string;
    birthplace: string;
    email: string;
    firstName: string;
    lastName: string;
    gender: 'male' | 'female';
    mobile: { country: string; numbers: string; msisdn: string };
    nationalRegistrationNumber: string;
    nationality: 'BE';
    occupation: string;
    optInCommercialMessages: boolean;
    passportNumber?: string;
    preferredLanguage: Locale;
    username: string;
  };
};

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [isLogged, setIsLogged] = useState(false);
  const [countOfLoginAttempts, setcountOfLoginAttempts] = useState<number>(0);
  const [isLoginFetching, setIsLoginFetching] = useState(true);
  const [userData, setUserData] = useState<APIUserType>();
  const [favorites, setFavorites] = useState<string[]>([]);
  const [userInfos, setUserInfos] = useState<UserInfos>();
  const [loginResult, setLoginResult] = useState<string>();
  const router = useRouter();
  const toast = useToast();
  const { t, lang } = useTranslation();
  const { license } = useExtraProps();

  // it's only called on call success
  const updateAuth = (userData: APIUserType) => {
    setUserData(userData);
    setIsLoginFetching(false);
    setIsLogged(true);
    handleEpis(userData?.identity);
    handlePersistedLanguage();
  };

  const handlePersistedLanguage = (remove: boolean = false) => {
    if (remove) {
      window?.localStorage?.removeItem('locale');
    } else {
      window?.localStorage.setItem(
        'locale',
        userData?.preferredLanguage || 'fr'
      );
    }
  };

  const handleEpis = (userIdentity?: APIUserIdentityType) => {
    if (userIdentity?.error) toast.closeAll();

    switch (userIdentity?.error) {
      case 'MAJOR':
        toast({
          status: 'error',
          title: 'Major Error',
          description: t('common:episMajorError'),
          duration: 8000,
          position: 'top',
          isClosable: true,
          onCloseComplete: () => {
            if (!router.asPath.includes('/me/account/my-profile'))
              router.push('/me/account/my-profile');
          }
        });
        break;

      case 'MINOR':
        const isPending =
          userIdentity?.error === null && userIdentity?.checkPending;
        toast({
          status: 'info',
          title: isPending ? 'Check Pending' : 'Minor Error',
          description: isPending
            ? t('common:episCheckPending')
            : t('common:episMinorError'),
          duration: null,
          position: 'top',
          isClosable: true
        });
        break;

      case 'VALIDATION':
        toast({
          status: 'error',
          title: 'EPIS Validation Error',
          description: t('common:episValidationError'),
          duration: 8000,
          position: 'top',
          isClosable: true
        });
    }
  };

  const episMinorError = userData?.identity?.error === 'MINOR';
  const episMajorError = userData?.identity?.error === 'MAJOR';
  const episValidationError = userData?.identity?.error === 'VALIDATION';
  const episCheckPending =
    userData?.identity?.error === null && userData?.identity?.checkPending;

  const disconnect = (doRedirect: boolean = true) => {
    setIsLogged(false);
    setUserData(undefined);
    setUserInfos(undefined);
    setIsLoginFetching(false);
    setLoginResult(undefined);
    handlePersistedLanguage(true);
    if (doRedirect) {
      const homeUrl = 'https://' + getDomainByLicense(license);
      if (isOnlineAccess(router)) {
        /**
         * we keep existing query params for building loginPageUrl
         */
        const currentUrl = new URL(homeUrl + router.asPath);
        const loginPageUrl = new URL(
          getFullUrlByLicense(license, lang) +
            '/login?' +
            currentUrl.searchParams.toString()
        );
        loginPageUrl.searchParams.set('redirect', currentUrl.pathname);

        // console.log('disconnect redirect: ', { currentUrl, loginPageUrl });
        router.replace(loginPageUrl);
      } else {
        // console.log('disconnect redirect: ', homeUrl);
        router.replace(homeUrl);
      }
    }
  };

  const resetAuth = (force = false) => {
    if (force || !isFreeAccess(router)) {
      disconnect(!force);
    }
  };

  const me = async () => {
    setcountOfLoginAttempts(countOfLoginAttempts + 1);
    setIsLoginFetching(true);
    return handleAuth()
      .then((authResponse) => updateAuth(authResponse))
      .catch(() => resetAuth())
      .finally(() => setIsLoginFetching(false));
  };

  const logOut = async (doRedirect: boolean = true) => {
    return handleLogout()
      .then(() => disconnect(doRedirect))
      .catch(() => setIsLogged(false));
  };

  const getLicences = () => {
    return handleLicences();
  };

  const getUserInfos = async () => {
    try {
      const { data } = await HTTP.get('user/profile');
      const newPerson = {
        person: {
          ...data.person,
          birthdate:
            new Date(data.person.birthdate).toISOString().split('T')[0] ?? ''
        }
      };
      setUserInfos(newPerson);
    } catch (error) {
      toast({
        title: t('common:somethingWrongHappenned'),
        description: 'We cannot get your profile',
        status: 'error',
        duration: 4000,
        isClosable: true,
        position: 'top'
      });
    }
  };

  // as this context is root, trigger a me call to refresh state
  useEffect(() => {
    const asyncMe = async () => {
      if (isLogged) {
        getUserInfos();
        loadFavorites();
      } else {
        await me();
      }
    };
    asyncMe();

    return () => setIsLoginFetching(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  // Favorites
  const loadFavorites = () => {
    // load/unload favs depending on session status
    if (isLogged) {
      handleFavorites().then((data) => setFavorites(data.map((x) => x.game)));
    } else setFavorites([]);
  };
  const isFavorite = (gameId: string) => favorites.includes('/games/' + gameId);
  const addFavorite = async (gameId: string) => {
    // avoid duplicate
    if (isFavorite(gameId)) return true;

    try {
      // API request
      const userId = userData?.id as string;
      const res = await handleAddFavorite(gameId, userId);

      if (res && res.game) {
        // update local list
        setFavorites([...favorites, res.game]);
        return true;
      }
    } catch (err) {
      console.error('addFavorite ERROR: ', err);
    }
    return false;
  };
  const removeFavorite = async (gameId: string) => {
    // find index + return true if not exist
    const index = favorites.indexOf('/games/' + gameId);
    if (index === -1) return true;

    try {
      // API request
      const res = await handleRemoveFavorite(gameId);

      if (res && res.status === 204) {
        // update local list + return
        const temp = [...favorites];
        temp.splice(index, 1);
        setFavorites(temp);

        return true;
      }
    } catch (err) {
      console.error('removeFavorite ERROR: ', err);
    }
    return false;
  };

  return (
    <UserContext.Provider
      value={{
        isLogged,
        userData,
        me,
        logOut,
        userInfos,
        setIsLogged,
        setUserData,
        updateAuth,
        resetAuth,
        isLoginFetching,
        episMinorError,
        episMajorError,
        episValidationError,
        episCheckPending,
        getLicences,
        isFavorite,
        addFavorite,
        removeFavorite,
        setUserInfos,
        countOfLoginAttempts,
        loginResult,
        setLoginResult,
        favorites,
        loadFavorites
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
// export directly context with the useContext hooks
export const useAuth = () => useContext(UserContext);
