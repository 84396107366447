/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { Badge, Box, BoxProps, Img } from '@chakra-ui/react';
import Link from 'next/link';
import { useAuth } from '@/context/Auth';
import { useAppSettings } from '@/context/AppSettings';

type LogoProps = BoxProps & {};

const Logo = ({ ...props }: LogoProps) => {
  const { userData } = useAuth();
  const config = useAppSettings();

  return (
    <Box position="relative" {...props}>
      {userData?.type === 'test' && (
        <Badge
          position="absolute"
          fontSize="0.9rem"
          top="0.5rem"
          right={['-1rem', '-0.5rem']}
        >
          Test
        </Badge>
      )}
      <Link passHref href="/">
        <a>
          <Img
            src={`${config.pathLogo}`}
            loading="lazy"
            alt={`${config.appName} logo`}
            width="100%"
            height="100%"
          />
        </a>
      </Link>
    </Box>
  );
};
export default Logo;
