import React from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';

const ProfileFooter = () => {
  const { t } = useTranslation();
  return (
    <div className="profileFooter">
      <ul>
        <li>
          <Link href="/">
            <a>@ Oria 2020</a>
          </Link>
        </li>
        <li>
          <Link href="/">
            <a>{t('footer:termsOfUse')}</a>
          </Link>
        </li>
        <li>
          <Link href="/">
            <a>{t('footer:legalNotices')}</a>
          </Link>
        </li>
        <li>
          <Link href="/">
            <a>{t('account:responsibleGaming')}</a>
          </Link>
        </li>
      </ul>
    </div>
  );
};
export default ProfileFooter;
