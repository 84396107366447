import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useRef } from 'react';

const ExitDialog = ({
  isDialogOpen,
  alertOnAccept,
  alertOnDenied
}: {
  isDialogOpen: boolean;
  alertOnDenied: () => void;
  alertOnAccept: () => void;
}) => {
  //   const { onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const { t } = useTranslation();

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={alertOnDenied}
      isOpen={isDialogOpen}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>{t('detail:closeGame')}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>{t('detail:sureCloseGame')}</AlertDialogBody>
        <AlertDialogFooter>
          <Button variant="outline" ref={cancelRef} onClick={alertOnDenied}>
            {t('common:no')}
          </Button>
          <Button onClick={alertOnAccept} colorScheme="red" ml={3}>
            {t('common:yes')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
export default ExitDialog;
