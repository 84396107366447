import useTranslation from 'next-translate/useTranslation';
import { useEffect, useMemo, useState } from 'react';
import { HelpIcon } from '@/theme/Icons';
import { MotionBox } from '@/components/Motion';
import { Button, Box } from '@chakra-ui/react';

import FreshdeskScript from '@/components/Script/FreshDesk/FreshDeskScript';
import FreshdeskChatScript from '@/components/Script/FreshDesk/FreshDeskChatScript';
import { useRouter } from 'next/router';
import { useAppSettings } from '@/context/AppSettings';
import { getFallbackLanguage } from '@/helpers/lang';

const FreshdeskButton = () => {
  const { t, lang } = useTranslation();
  const router = useRouter();
  const config = useAppSettings();
  const isChatEnabled: boolean = config?.features?.freshDesk?.chat?.enabled;

  useEffect(() => {
    const mouseDownListener = () => {
      if (typeof document !== 'undefined') {
        if (document.getElementById('fc_widget')) {
          window.fcWidget?.close();
        }
        if (document.getElementById('freshworks-frame-wrapper')) {
          window.FreshworksWidget?.('close');
        }
      }
    };
    window.addEventListener('mousedown', mouseDownListener);
    return () => {
      window.removeEventListener('mousedown', mouseDownListener);
    };
  }, []);

  const [isScrolling, setIsScrolling] = useState<boolean>(false);

  useEffect(() => {
    let isScrollingTimeout: any;
    const scrollListener = () => {
      setIsScrolling(true);
      if (isScrollingTimeout) {
        clearTimeout(isScrollingTimeout);
      }
      isScrollingTimeout = setTimeout(() => {
        setIsScrolling(false);
      }, 150);
    };
    window.addEventListener('scroll', scrollListener, { passive: true });

    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const freshdeskScript = useMemo(() => <FreshdeskScript />, [lang]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const freshdeskChatScript = useMemo(() => <FreshdeskChatScript />, [lang]);

  const disabledRoutes = ['/sports', '/dice/virtual-sports'];

  const isDisabledRoute = useMemo(
    () => disabledRoutes.includes(router.pathname),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router]
  );

  const hideButton = isChatEnabled
    ? !(typeof window !== 'undefined' && window.fcWidget)
    : !(typeof window !== 'undefined' && window.FreshworksWidget);

  return (
    <>
      {freshdeskScript}
      {isChatEnabled ? freshdeskChatScript : null}
      {isDisabledRoute ? null : (
        <Button
          position="fixed"
          bottom="9.62rem"
          right="2rem"
          zIndex="modal"
          background="buttonPrimary"
          padding="1rem"
          fontSize="0.9375rem"
          borderRadius={!isScrolling ? '3.75rem' : '6.25rem'}
          onClick={() => {
            if (isChatEnabled && window.fcWidget) {
              if (!window.fcWidget.isInitialized()) {
                window.fcWidgetMessengerConfig = {
                  locale: `${getFallbackLanguage(lang)}`,
                  config: { headerProperty: { hideChatButton: true } }
                };

                window.fcWidget.init({
                  token: config?.features?.freshDesk?.chat?.token,
                  host: config?.features?.freshDesk?.chat?.host
                });
              }
              setTimeout(() => window.fcWidget?.open(), 100);
            } else {
              window.FreshworksWidget?.('open');
            }
          }}
          hidden={hideButton}
          overflow="hidden"
        >
          <HelpIcon width="1.5rem" height="1.5rem" />
          <MotionBox
            display={'grid'}
            animate={{
              paddingLeft: !isScrolling ? '0.75rem' : 0,
              gridTemplateColumns: !isScrolling
                ? ['0fr', '1fr']
                : ['1fr', '0fr'],
              opacity: !isScrolling ? [0, 1] : [1, 0]
            }}
            transition={{ duration: 0.5 }}
            initial={{
              gridTemplateColumns: '1fr',
              paddingLeft: '0.75rem'
            }}
          >
            <Box overflow="hidden">{t('common:help')}</Box>
          </MotionBox>
        </Button>
      )}
    </>
  );
};

declare global {
  interface Window {
    FreshworksWidget?: (action: string, target?: string) => void;
    fcWidget?: {
      destroy: () => void;
      init: (options: { token: string; host: string }) => void;
      isInitialized: () => boolean;
      open: () => void;
      close: () => void;
      user: {
        setLocale: (lang: string) => void;
      };
    };
    fcWidgetMessengerConfig?: {
      locale: string;
      config: { headerProperty: { hideChatButton: boolean } };
    };
  }
}

export default FreshdeskButton;
