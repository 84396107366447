import { css } from '@emotion/react';
import { ReactNode } from 'react';
import Slide from '@/components/Slide';
import { Swiper } from 'swiper/types';

type JackpotSliderProps = {
  children: ReactNode[];
  onSlideChange?: (slide: Swiper) => void;
};

export const JackpotSlider = ({
  children,
  onSlideChange,
  ...props
}: JackpotSliderProps) => {
  return (
    <Slide
      containerStyle={{ width: '100%', ...props }}
      sliderTestId="slider-jackpot-mobile"
      bulletClass={customBulletName}
      bulletActiveClass={customBulletActiveName}
      bulletStyles={bulletStyles}
      showArrowNavigation={false}
      onSlideChange={onSlideChange}
      delayAutoPlay={4000}
      loop
    >
      {children}
    </Slide>
  );
};

const customBulletName = 'custom-catalog-bullet';
const customBulletActiveName = 'custom-catalog-bullet-active';
const bulletStyles = css`
  .${customBulletName} {
    display: inline-flex;
    width: 8px;
    height: 8px;
    background: transparent;
    border-radius: 50%;
    margin: 0 5px;
    border: 1px solid #fff;
    transition: background-color 0.3s ease;
  }

  .${customBulletActiveName} {
    background: #fff;
  }
`;
