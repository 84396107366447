import { useWallet } from '@/context/Wallet';
import { getLocaleTranslation } from '@/helpers/translation';
import GTM from '@/helpers/googleTagManager';
import { IconButtonProps, useToast } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { CircleIconButton } from '..';
import { useAuth } from '../../context/Auth';
import { useLoginModal } from '../../context/LoginModal';
import { HeartIcon } from '../../theme/Icons';
import { GameCatalogItem } from '@/services/getCatalog';
import { useCatalog } from '@/context/Catalog';

type FavoriteButtonProps = Omit<IconButtonProps, 'aria-label'> & {
  game: GameCatalogItem;
  section?: string;
};

const FavoriteButton = ({ game, section, ...props }: FavoriteButtonProps) => {
  const { balance: userWalletBalance } = useWallet();
  const { onOpen } = useLoginModal();
  const {
    addFavorite,
    isFavorite,
    removeFavorite,
    isLogged,
    userData,
    loadFavorites
  } = useAuth();
  const toast = useToast();
  const { t, lang } = useTranslation();
  const { dataStore } = useCatalog();
  const toasting = ({
    action,
    result
  }: {
    action: 'added' | 'removed';
    result: boolean;
  }) => {
    const title = result
      ? action === 'added'
        ? t('common:gameAddedToFavorite', {
            name: game.name
          })
        : t('common:gameRemovedFromFavorite', {
            name: game.name
          })
      : t('common:somethingWrongHappenned');
    const status = !result ? 'error' : 'info';
    return toast({
      title,
      status,
      duration: 3000,
      position: 'top-right',
      isClosable: true
    });
  };

  const isInFavList = isFavorite(game.publicId || game.id);

  const handleFavorite = async () => {
    if (!isLogged) {
      onOpen();
      return;
    }
    if (isInFavList) {
      const result = await removeFavorite(game.publicId || game.id);
      toasting({ action: 'removed', result });
      loadFavorites?.();
    } else {
      const result = await addFavorite(game?.publicId || game.id);
      toasting({ action: 'added', result });

      GTM.gameEvent(
        'game_favorites',
        game,
        userData,
        userWalletBalance,
        section,
        dataStore
      );
    }
  };

  return (
    <CircleIconButton
      data-testid={`${game?.name}-favorite-button`}
      backgroundColor={isInFavList ? 'buttonPrimary' : '#303339'}
      onClick={handleFavorite}
      aria-label={t('common:likeButton')}
      icon={<HeartIcon height="1.5rem" width="1.5rem" marginTop={'3px'} />}
      size="xs"
      {...props}
    />
  );
};

export default FavoriteButton;
