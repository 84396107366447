import { IconButton, IconButtonProps } from '@chakra-ui/react';
import React from 'react';

type CircleIconButtonProps = IconButtonProps & {
  icon: any;
};

const CircleIconButton = ({ icon, ...props }: CircleIconButtonProps) => {
  return (
    <IconButton
      icon={icon}
      backgroundColor="rgba(88, 88, 86, 1)"
      borderRadius="full"
      fontSize="3xl"
      {...props}
    />
  );
};

export default CircleIconButton;
