export const setElementProperty = (
  name: string,
  value: number | string | boolean,
  element?: HTMLElement
) => {
  (element ?? document.documentElement).style.setProperty(
    `--${name}`,
    value.toString()
  );
};

export const getElementProperty = (name: string, element?: HTMLElement) =>
  (element ?? document.documentElement).style.getPropertyValue(`--${name}`);
