import ActionRequired from '@/components/ActionRequired';
import CashOutPaypal from './CashOutPaypal';

const WalletPaypalCashOut = () => {
  return (
    <ActionRequired isRequired={{ bankAccount: false }}>
      <CashOutPaypal />
    </ActionRequired>
  );
};

export default WalletPaypalCashOut;
