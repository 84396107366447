/* Type for this config is not yet correct: https://github.com/getsentry/sentry-javascript/issues/10403. */

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

/* Using DD_ENV for now because NODE_ENV isn't setup with the right value in test environment
   @todo: use NODE_ENV when devops will make the changes with these values testing, staging and production
*/

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
const isDev = publicRuntimeConfig.NEXT_PUBLIC_DD_ENV === 'development';

Sentry.init({
  enabled: !isDev,
  dsn: 'https://c48da644e8df4e88bd906afe1ef3799e@o970222.ingest.sentry.io/5921827',
  // Replay may only be enabled for the client-side
  integrations: [],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  debug: false,
  environment: isDev ? 'development' : publicRuntimeConfig.NEXT_PUBLIC_DD_ENV

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // replaysSessionSampleRate: 0.1,
  // replaysOnErrorSampleRate: 1.0

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
