import { languageMapping } from '@/constant/languageMapping';
import { capitalize } from 'lodash';

const options = {
  // weekday: 'short',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
} as Intl.DateTimeFormatOptions;

export const humanDate = (
  date: string,
  local: string,
  config = options
): string => {
  const convertedDate = new Date(date);
  if (!date) return '';
  return new Intl.DateTimeFormat(languageMapping[local], config).format(
    convertedDate
  );
};

export const newsDate = (
  date: string,
  locale: string,
  type: 'short' | 'long'
) => {
  const convertedDate = new Date(date);
  if (!date) return '';
  const days = new Intl.DateTimeFormat(languageMapping[locale], {
    day: '2-digit'
  }).format(convertedDate);
  const month = new Intl.DateTimeFormat(languageMapping[locale], {
    month: type
  }).format(convertedDate);
  const year = new Intl.DateTimeFormat(languageMapping[locale], {
    year: 'numeric'
  }).format(convertedDate);
  return `${days} ${capitalize(month)} ${year}`;
};

export const timeAgo = (timestamp: Date, locale = 'en') => {
  let value;
  const diff = Math.round((new Date().getTime() - timestamp.getTime()) / 1000);
  const minutes = Math.floor(diff / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);
  const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });

  if (years > 0) {
    value = rtf.format(0 - years, 'year');
  } else if (months > 0) {
    value = rtf.format(0 - months, 'month');
  } else if (days > 0) {
    value = rtf.format(0 - days, 'day');
  } else if (hours > 0) {
    value = rtf.format(0 - hours, 'hour');
  } else if (minutes > 0) {
    value = rtf.format(0 - minutes, 'minute');
  } else {
    value = rtf.format(0 - diff, 'second');
  }

  return value;
};
