export type OperatingSystemType =
  | 'windows-phone'
  | 'android'
  | 'ios'
  | 'unknown';
/**
 * Determine the mobile operating system.
 *
 * @returns {String}
 */
export const getMobileOperatingSystem = (): OperatingSystemType => {
  const userAgent =
    window.navigator.userAgent || window.navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'windows-phone';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return 'unknown';
};
