import { CheckedIcon } from '@/theme/Icons';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UseModalProps
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { ReactNode } from 'react';

type SuccessModalProps = UseModalProps & {
  children?: ReactNode;
};

const SuccessModal = ({ children, ...props }: SuccessModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isCentered {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontSize="1.8rem">
          <CheckedIcon
            border="2px solid green"
            borderRadius="full"
            padding="3px"
            marginRight="1rem"
          />
          {t('common:success')} !
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SuccessModal;
