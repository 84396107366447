import { useFilters } from '@/context/Filters';
import { Button, HStack, StackProps } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useAuth } from '../../../context/Auth';
import { ExitIcon } from '../../../theme/Icons';

type ProfileLogoutProps = StackProps & {};

const ProfileLogOut = ({ ...props }: ProfileLogoutProps) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const { resetAll } = useFilters();

  const logout = async () => {
    await auth.logOut?.();
    resetAll();
  };

  return (
    <HStack as="a" onClick={logout} data-testid="logout-button" {...props}>
      <Button
        fontSize={['.8rem', '.8rem', '1rem']}
        variant="link"
        leftIcon={<ExitIcon width="2rem" height="1.5rem" />}
      >
        {t(`common:logout`)}
      </Button>
    </HStack>
  );
};
export default ProfileLogOut;
