import {
  GenericObject,
  Translation,
  TranslationEntry
} from '@/components/GamesCatalog/types';

export const getLocaleTranslation = <Type extends TranslationEntry>(
  translations: Type[],
  lang: string
): Type =>
  translations?.filter(
    (translation) => translation.locale.toLowerCase() === lang.toLowerCase()
  )?.[0];

export const getRedirectBadSlugLocale = <T>({
  data,
  locale,
  url,
  slug
}: {
  data: T extends GenericObject ? T : { translations: Translation };
  locale: string;
  slug: string;
  url: (translationData: TranslationEntry) => string;
}) => {
  const translation = data.translations?.find((item) => item.locale === locale);

  if (translation && translation.slug !== slug) {
    return {
      redirect: {
        destination: url(translation),
        permanent: true
      }
    };
  }
  return;
};
