import { useAppSettings } from '@/context/AppSettings';
import { useAuth } from '@/context/Auth';
import { useCurrentHost } from '@/hooks/useCurrentHost';
import { useGetLicense } from '@/hooks/useGetLicense';
import useMediaQuery from '@/hooks/useMediaQuery';
import { StackProps } from '@chakra-ui/react';
import { useMotionValue, useScroll, useTransform } from 'framer-motion';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { SignInAndRegisterButtons } from '../Button/SignInButton';
import { CatalogTinyInfo } from '../Profile/Profile_TinyInfo/CatalogTinyInfo';
import NotificationOverview from '@/components/Notification/User/NotificationOverview';
import { MotionHStack } from '@/components/Motion';
import { HeaderWrapper } from '@/components/Header/HeaderWrapper';
import { getHeaderHeight } from '@/helpers/header';
import { setElementProperty } from '@/helpers/cssVars';

type CustomHeaderProps = StackProps & PropsWithChildren & {};

let scrollThreshold = [0, 50];

export const CustomHeader = ({ children, ...props }: CustomHeaderProps) => {
  const config = useAppSettings();
  const { isLogged, userData } = useAuth();
  const isSelfExcluded = userData?.status === 'self_excluded';
  const isScreenMobile = useMediaQuery('(max-width: 37.5rem)');
  const isMobile = useMediaQuery('(max-width: md)');
  const { license } = useGetLicense();
  const currentLicense = license === 'MAIN' ? 'B' : license;
  const currentHost = useCurrentHost();
  const backgroundArrayColors =
    config?.customStyle?.headerBackgroundColorsArray[currentLicense];

  // CONTENT MOBILE BOTTOM MENU
  const isHub = config.isHubEnabled && currentHost === config.domains.MAIN;
  const catalogTinyInfo = !isHub ? <CatalogTinyInfo width={'100%'} /> : null;
  const signInAndRegisterButtons = !isHub ? (
    <SignInAndRegisterButtons isCustomHeader />
  ) : null;

  // SHOW HIDE MOBILE BOTTOM MENU
  const { scrollY } = useScroll();
  let scrollYOnDirectionChange = useRef(scrollY.get());
  let lastPixelsScrolled = useRef<number>(0);
  let lastScrollDirection = useRef<'down' | 'up'>();
  let pixelsScrolled = useMotionValue(0);
  let mobilePosition = useTransform(pixelsScrolled, scrollThreshold, [
    '0',
    '100px'
  ]);

  useEffect(() => {
    return scrollY.onChange((latest) => {
      if (latest < 0) return;

      let isScrollingDown = scrollY.getPrevious() - latest < 0;
      let scrollDirection: 'down' | 'up' = isScrollingDown ? 'down' : 'up';
      let currentPixelsScrolled = pixelsScrolled.get();
      let newPixelsScrolled;

      if (lastScrollDirection.current !== scrollDirection) {
        lastPixelsScrolled.current = currentPixelsScrolled;
        scrollYOnDirectionChange.current = latest;
      }

      if (isScrollingDown) {
        newPixelsScrolled = Math.min(
          lastPixelsScrolled?.current +
            (latest - scrollYOnDirectionChange.current),
          scrollThreshold[1]
        );
      } else {
        newPixelsScrolled = Math.max(
          lastPixelsScrolled.current -
            (scrollYOnDirectionChange.current - latest),
          scrollThreshold[0]
        );
      }

      pixelsScrolled.set(newPixelsScrolled);
      lastScrollDirection.current = scrollDirection;

      setElementProperty(
        'height-mobile-menu-bottom',
        scrollDirection === 'up' && isMobile ? '52px' : '0px'
      );
    });
  }, [isMobile, pixelsScrolled, scrollY]);

  return (
    <>
      <HeaderWrapper
        data-testid={'custom-header'}
        height={`${getHeaderHeight(!isMobile)}px`}
        containerProps={{
          display: 'flex',
          justifyContent: 'space-between',
          position: 'relative'
        }}
        width={'100%'}
        {...props}
      >
        {children}
      </HeaderWrapper>

      {isMobile && !isSelfExcluded && (
        <MotionHStack
          className={'mobile-bottom-menu'}
          justifyContent={'center'}
          zIndex="sticky"
          as="header"
          width="100%"
          paddingX={['1rem', '1rem', '2rem']}
          paddingY="0.5rem"
          position="fixed"
          bottom="0"
          style={{
            y: mobilePosition,
            background: backgroundArrayColors.at(1)
          }}
        >
          {isLogged ? catalogTinyInfo : signInAndRegisterButtons}
        </MotionHStack>
      )}
      {isScreenMobile && isLogged && <NotificationOverview />}
    </>
  );
};
