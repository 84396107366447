import Image from 'next/image';

const QrCode = ({ imgData }: { imgData: string }) => {
  return (
    <Image
      data-testid="QrCode"
      src={imgData}
      width={'150px'}
      height={'150px'}
      style={{ borderRadius: '5px' }}
      alt="scan QR code"
    />
  );
};

export default QrCode;
