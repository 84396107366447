import { EyeCloseIcon, EyeIcon } from '@/theme/Icons';
import {
  FormControl,
  FormLabelProps,
  HStack,
  Input as CInput,
  InputGroup,
  InputProps,
  InputRightElement,
  useMergeRefs
} from '@chakra-ui/react';
import React, { ChangeEvent, useMemo, useState } from 'react';
import {
  FieldError,
  FieldValues,
  RegisterOptions,
  useFormContext
} from 'react-hook-form';
import InputError from './InputError';
import InputLabel from './InputLabel';
import { get } from 'lodash';

export interface Props extends InputProps {
  name: string;
  label?: string;
  subLabel?: string;
  validation?: RegisterOptions<FieldValues>;
  forceTextType?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  labelProps?: FormLabelProps;
  maskRef?: React.MutableRefObject<HTMLInputElement | null>;
}

const Input = ({
  label,
  subLabel,
  validation,
  width,
  forceTextType = true,
  onChange,
  labelProps,
  maskRef,
  ...props
}: Props) => {
  const {
    register,
    formState: { errors },
    setFocus,
    getValues,
    watch
  } = useFormContext();

  const [show, setShow] = useState<boolean>(false);

  const fieldError = get(errors, props.name) as unknown as FieldError | string;
  const value = getValues(props.name);
  const watchValue = watch(props.name); // will rerender on each onChange

  const handlePassword = () => setShow(!show);

  const handleTypeInput = useMemo(() => {
    if (!forceTextType) {
      return show ? 'text' : 'password';
    } else {
      return props.type ?? 'text';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const reactHookFormProps = register(props.name, { ...validation, onChange });
  const mergedRefs = useMergeRefs(maskRef, reactHookFormProps.ref);

  return (
    <FormControl
      position="relative"
      isInvalid={!!fieldError}
      isRequired={!!validation?.required || props.isRequired}
      width={width}
    >
      <HStack as={InputGroup}>
        <CInput
          size="lg"
          fontSize={['0.8rem', '1rem', '1.2rem']}
          className="custom-input"
          _readOnly={{
            backgroundColor: 'rgba(142, 148, 179, 0.2)'
          }}
          borderRadius="8px"
          style={{ marginTop: '0px' }}
          {...reactHookFormProps}
          ref={mergedRefs}
          {...props}
          type={handleTypeInput}
        />
        {props.type === 'password' ? (
          <InputRightElement
            width="4.5rem"
            onClick={handlePassword}
            style={{ top: '50%', transform: 'translateY(-50%)' }}
          >
            {show ? (
              <EyeIcon style={{ width: '2rem', height: '2rem' }} />
            ) : (
              <EyeCloseIcon
                style={{ width: '1.6rem', height: '1.6rem', marginTop: '4px' }}
              />
            )}
          </InputRightElement>
        ) : null}
      </HStack>
      <InputLabel
        label={label}
        title={label}
        subLabel={subLabel}
        name={props.name}
        color="whiteAlpha.600"
        position="absolute"
        zIndex={10}
        left="1.5rem"
        maxWidth={'calc(100% - 3rem)'}
        transition="all .1s ease-in-out"
        fontSize={['0.8rem', '1rem', '1.2rem']}
        top="2rem" // fix this value because error text can expand height of container and so label goes down
        transform="translateY(-50%)"
        // width="99%"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        className="float-label"
        htmlFor={props.id}
        onClick={() => {
          setFocus(props.name);
        }}
        style={
          watchValue || !!(fieldError as FieldError)?.message || !!value
            ? { top: '0.8rem', fontSize: '0.7rem' }
            : {}
        }
        {...labelProps}
      />

      <InputError error={fieldError} />
    </FormControl>
  );
};

export default Input;
