import CancelRequestedCashOut from '@/components/CancelRequestedCashOut';
import SupportButtonModal from '@/components/Modal/SupportButtonModal';
import { useAppSettings } from '@/context/AppSettings';
import { Button, Icon, StackProps, VStack } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { ProfileLogOut, profileNavLinks, ProfileTinyInfo } from '../../';

const ProfileSideNav = ({ ...props }: StackProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  const appSettings = useAppSettings();

  return (
    <VStack
      as="nav"
      align="baseline"
      spacing="7rem"
      padding="4rem"
      height="100vh"
      minWidth="23rem"
      borderRight="1px solid rgba(255, 255, 255, 0.2)"
      display={['none', 'none', 'flex']}
      {...props}
    >
      <ProfileTinyInfo />

      <VStack
        align="baseline"
        textTransform="capitalize"
        spacing="1.5rem"
        as="ul"
        listStyleType="none"
      >
        {profileNavLinks?.map((el: any) => (
          <li key={el.url}>
            <Link href={el.url} passHref>
              <Button
                as="a"
                data-testid={`${el?.label}-side-nav-link`}
                variant="ghost"
                color="white"
                leftIcon={<Icon as={el.icon} height="2rem" width="2rem" />}
                fontSize="1.5rem"
                opacity={
                  router.asPath.includes(
                    `${el.url.split('/')[1]}/${el.url.split('/')[2]}`
                  )
                    ? '1'
                    : '0.5'
                }
                _hover={{ opacity: 1 }}
              >
                {t(`common:${el.label}`)}
              </Button>
            </Link>
          </li>
        ))}
      </VStack>
      <CancelRequestedCashOut marginTop="0" />

      <VStack align="baseline" marginTop="auto !important" spacing="1rem">
        <SupportButtonModal
          flexDirection="row"
          variant="link"
          iconProps={{ marginRight: '1rem', width: '1.5rem', height: '1.5rem' }}
        />
        <ProfileLogOut />
      </VStack>
    </VStack>
  );
};

export default ProfileSideNav;
