import React from 'react';
import { HStack, Icon, Text } from '@chakra-ui/react';
import { Arrow } from '../../theme/Icons';
import { ProfileHeaderProps } from './ProfileHeader.types';

const ProfileHeader = ({ onClick, icon, title }: ProfileHeaderProps) => {
  return (
    <HStack marginY="2rem" marginX="1rem">
      <Arrow
        height={['40px', '50px', '60px']}
        width={['40px', '50px', '60px']}
        transform="rotate(90deg)"
        display={['block', 'block', 'block', 'none']}
        onClick={() => onClick()}
      />
      <Icon
        as={icon}
        height={['40px', '50px', '60px']}
        width={['40px', '50px', '60px']}
      />
      <Text
        data-testid="profile-title"
        as="h1"
        fontWeight="800"
        fontSize={['1.2rem', '2.2rem', '3rem']}
      >
        {title}
      </Text>
    </HStack>
  );
};

export default ProfileHeader;
