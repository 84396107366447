import { toRem } from '@/helpers/toRem';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ComponentWithAs,
  HStack,
  ListItem,
  Text,
  UnorderedList,
  VStack,
  IconProps,
  ImageProps
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, { CSSProperties, ReactElement, ReactNode } from 'react';

export type CashOutConfigImage =
  | ComponentWithAs<'svg', IconProps>
  | ComponentWithAs<'img', ImageProps>
  | ReactElement;
export type CashOutConfigType = {
  key: string;
  component?: React.ReactNode;
  isAvailable: boolean;
  header: {
    icon: CashOutConfigImage;
    hasIconBackground: boolean;
    methodPayment: string;
    introTxt?: string;
    /**
     * @extraInfos {Record} - Record of key translations and values
     */
    extraInfos?: Record<string, string>[];
  };
};

export type CashOutMethodsType = 'bank_transfer' | 'paypal' | 'gaming_hall';

export type CashOutPayoutMethods = {
  name: CashOutMethodsType;
  provider: {
    minimumAmount: number;
    maximumAmount: number;
    isAllowed: boolean;
  };
};

export const CashOutProviderImage = ({
  children,
  style
}: {
  children: ReactNode | CashOutConfigImage;
  style?: CSSProperties;
}) => {
  return React.cloneElement(children as ReactElement, {
    style
  });
};
const CashOutConfig = ({ config }: { config: CashOutConfigType[] }) => {
  const { t } = useTranslation();

  const availablePaymentMethod = config.filter((config) => config.isAvailable);

  const notAvailablePaymentMethod = config.filter(
    (config) => !config.isAvailable
  );

  return (
    <Box maxWidth={['100%', '100%', '75%']}>
      <Text
        as="h2"
        textStyle="h2"
        mb={'2.5rem'}
        fontSize={['2rem', '2rem', '3rem']}
        lineHeight={['2.375rem', '2.375rem', '3.25rem']}
        fontWeight={500}
      >
        {t('wallet:cashoutMainTitle')}
      </Text>
      <Text
        as="h3"
        textStyle="h3"
        mb="2.5rem"
        fontWeight={500}
        fontSize={['1.125rem', '1.125rem', '1.5rem']}
        lineHeight={['1.5rem', '1.5rem', '1.875rem']}
      >
        {t('wallet:cashoutSubtitle')}
      </Text>

      <Accordion allowToggle variant="separate">
        {availablePaymentMethod?.map((comp) => (
          <AccordionItem key={comp.key}>
            <h2>
              <AccordionButton
                as="button"
                role="button"
                name="Accordion Header"
              >
                <HStack width="100%" textAlign="left">
                  <Box>
                    <Box
                      data-testid="iconContainer"
                      bg={
                        comp.header.hasIconBackground ? '#fff' : 'transparent'
                      }
                      borderRadius="5px"
                      color={comp.header.hasIconBackground ? '#000' : '#fff'}
                      width="48px"
                      height="36px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      style={{ aspectRatio: 2 / 1 }}
                    >
                      <CashOutProviderImage
                        style={{
                          height: '40px',
                          width: '26px',
                          fill: '#fff'
                        }}
                      >
                        {comp?.header.icon}
                      </CashOutProviderImage>
                    </Box>
                  </Box>
                  <Box flex="0 0 80%" paddingLeft={'.5rem'}>
                    <VStack alignItems="flex-start">
                      <Box>
                        <Text
                          as="h4"
                          textStyle="h4"
                          color={'figma.neutral.100'}
                          fontSize="1rem"
                        >
                          {comp?.header.methodPayment}
                        </Text>
                      </Box>
                      {comp?.header.extraInfos?.length ? (
                        <Box>
                          <UnorderedList
                            data-testid="extra"
                            listStyleType="none"
                            margin={0}
                            color={'rgba(255, 255, 255, 0.6)'}
                            fontSize={toRem(12)}
                          >
                            {comp?.header.extraInfos.map((infos, idx) => (
                              <ListItem key={idx}>
                                {Object.keys(infos)} : {Object.values(infos)}
                              </ListItem>
                            ))}
                          </UnorderedList>
                        </Box>
                      ) : null}
                    </VStack>
                  </Box>
                </HStack>
                <AccordionIcon fontSize="2rem" />
              </AccordionButton>
            </h2>
            {comp?.component ? (
              <AccordionPanel pb={4}>{comp?.component}</AccordionPanel>
            ) : null}
          </AccordionItem>
        ))}
      </Accordion>
      {notAvailablePaymentMethod.length > 0 ? (
        <Box marginTop="3rem">
          <Text
            as="h3"
            textStyle="h3"
            mb="1.25rem"
            fontSize={['1.125rem', '1.125rem', '1.5rem']}
            lineHeight={['1.5rem', '1.5rem', '1.875rem']}
            fontWeight={500}
          >
            {t('wallet:cashoutUnavailableTitle')}
          </Text>
          <Text
            as="p"
            textStyle="p"
            marginBottom="3rem"
            fontSize={['0.875rem', '0.875rem', '1rem']}
            lineHeight={['1.25rem', '1.25rem', '1.5rem']}
          >
            {t('wallet:cashoutUnavailableExplanation')}
          </Text>

          {notAvailablePaymentMethod.map((comp, idx) => (
            <HStack
              key={idx}
              p="24px 32px"
              border={'1px solid rgba(255, 255, 255, 0.2)'}
              borderRadius="10px"
              alignItems="center"
              color={!comp.isAvailable ? 'figma.neutral.600' : 'inherit'}
              marginBottom="20px"
              data-testid="unavailableMethod"
            >
              <Box
                bg="#fff"
                // p="9.5px 4px"
                borderRadius="5px"
                width={'48px'}
                height={'36px'}
                display="flex"
                alignItems="center"
                justifyContent="center"
                // style={{ aspectRatio: 2 / 1 }}
              >
                <CashOutProviderImage style={{ width: '40px', height: '26px' }}>
                  {comp.header.icon}
                </CashOutProviderImage>
              </Box>
              <Box flex="0 0 90%" paddingLeft={'.5rem'}>
                <Text as="h3" textStyle="h3" fontSize={'1rem'} display="flex">
                  {comp.header.methodPayment}
                </Text>
                <Text as="span" textStyle="p" fontSize={toRem(12)}>
                  {comp.header.introTxt}
                </Text>
              </Box>
            </HStack>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};
export default CashOutConfig;
