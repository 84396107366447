import {
  Alert,
  HStack,
  Switch,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, { FC, useEffect, useState } from 'react';
import { HTTP } from '../../../Http';
import {
  MyLicenceApiItem,
  MyLicenceApiPostData,
  MyLicencesProps
} from './MyLicences.types';

export const apiUserLicencesEndpoint = '/user/licenses';

const MyLicences: FC<MyLicencesProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [licences, setLicences] = useState<Array<MyLicenceApiItem>>();
  const { t, lang } = useTranslation();

  const [error, setError] = useState<string>('');
  const toast = useToast();

  useEffect(() => {
    const getLicences = async () => {
      try {
        const { data } = await HTTP.get<MyLicenceApiItem[]>(
          apiUserLicencesEndpoint
        );
        if (data && data.length) {
          setLicences(data);
        } else {
          setError(t('account:noLicenceFound'));
        }
      } catch (e) {
        // console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    getLicences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const handleClick = (name: string, checked: boolean) => {
    const list: Array<MyLicenceApiItem> = licences ? [...licences] : [];
    const item = list.find((l) => l.name === name);

    if (item) enableSwitch(item.name, checked);
  };

  async function enableSwitch(name: string, enabled: boolean) {
    const dataPost: MyLicenceApiPostData = {
      licenses: [
        {
          name,
          enabled
        }
      ]
    };

    try {
      const { status, data } = await HTTP.post<MyLicenceApiItem[]>(
        apiUserLicencesEndpoint,
        dataPost
      );

      if (status === 200) {
        toast({
          status: enabled ? 'info' : 'warning',
          position: 'top-right',
          title: t(
            enabled ? 'account:licenceEnabled' : 'account:licenceDisabled',
            {
              name
            }
          ),
          isClosable: true
        });

        setLicences(data);
      }
    } catch (e) {
      toast({
        status: 'error',
        position: 'top-right',
        title: t('common:somethingWrongHappenned')
      });
    }
  }

  const licencesForm =
    licences &&
    licences.map((licence) => {
      const licenceSlug = licence.name.slice(0, 1).toUpperCase();
      return (
        licenceSlug && (
          <HStack
            width="100%"
            key={licence.name}
            opacity={licence.available ? 1 : 0.2}
            justifyContent="space-between"
            borderBottom="1px solid rgba(255,255,255,0.4)"
            paddingY="2rem"
          >
            <HStack align="flex-start" spacing="2rem" flex={1}>
              <Text
                fontWeight="bold"
                backgroundColor="brand.1000"
                borderRadius="6px"
                padding="6px"
                fontSize="1.2rem"
              >
                {licence.name}
              </Text>
              <VStack align="baseline" lineHeight="1" flex={1}>
                <Text fontSize="1.5rem" fontWeight="bold">
                  {t('account:licenceLabel' + licenceSlug)}
                </Text>
                <Text
                  maxWidth="60%"
                  color="rgba(142, 148, 179, 1)"
                  fontSize="0.9rem"
                >
                  {t('account:licenceDescription' + licenceSlug)}
                </Text>
              </VStack>
            </HStack>

            <Switch
              colorScheme="yellow"
              isChecked={licence.enabled}
              onChange={(e: any) => handleClick(licence.name, e.target.checked)}
              size="lg"
              data-testid={`my-licences-switch-${licenceSlug}`}
              disabled={!licence.available}
            />
          </HStack>
        )
      );
    });

  return (
    <VStack align="baseline" data-testid="my-licences">
      {isLoading && (
        <p data-testid={`my-licences-loading`}>
          {t('account:loadingLicenses')}
        </p>
      )}
      {!isLoading && error && (
        <Alert status="error" data-testid={`my-licences-error`}>
          {error}
        </Alert>
      )}
      {!isLoading && !error && licences && (
        <>
          <Text as="h2" fontWeight="bold" fontSize="2rem">
            {t('account:gameLicenses')}
          </Text>
          <Text as="h3" fontSize="1.5rem" marginBottom="3rem !important">
            {t('account:defineContent')}
          </Text>
          <VStack width={'full'}>{licencesForm}</VStack>
        </>
      )}
    </VStack>
  );
};

export default MyLicences;
