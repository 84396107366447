import { HTTP } from '@/components/Http';
import { useToast } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { UserContext } from './Auth';

export type WalletBalanceType = {
  depositLimit: number;
  available: number;
  used: number;
  promptDeposit: boolean;
  amount: number;
  currency: 'EUR';
};

type WalletProviderContextType = {
  balance?: WalletBalanceType;
  getDepositLimit?: () => Promise<void>;
  getBalance?: () => Promise<unknown>;
  loadingDepositLimit: boolean;
  setBalance?: any;
  isGettingBalance?: boolean;
  depositLimit?: DepositLimit | null;
  setDepositLimit?: Dispatch<SetStateAction<DepositLimit | null>>;
  isBalanceError?: boolean;
  setAside?: any;
  asideChildren?: any;
  promptDeposit?: boolean;
  isRetrieved?: boolean;
  getPendingCashOuts?: () => void;
  setMaxLimitAmount?: Dispatch<SetStateAction<number>>;
  pendingCashOuts: CashOutRequest[];
  minimumCashInRequired: number;
  minimumCashOutRequired: number;
  deadline?: Date;
  setDeadline?: Dispatch<SetStateAction<Date>>;
  isFastDepositOpen: boolean;
  setIsFastDepositOpen: (b: boolean) => void;
};

export type CashOutRequest = {
  createdAt: string;
  amount: number;
  '@id': string;
  '@type': 'BankTransfer' | 'GamingHallPayout';
  BIC: string;
  IBAN: string;
  currency: 'EUR' | 'DOLLAR';
  id: string;
  playerId: string;
  status: 'pending';
  type: 'bank_transfer' | 'gaming_hall_payout';
};

export type LimitType = 'legal' | 'no_limit' | 'responsible';

export type DepositLimit = {
  amount: number;
  deposited: number;
  remaining: number;
  type: LimitType;
  canExceedLegalLimit: boolean;
  pending?: {
    amount: number;
    status: 'requested' | 'active' | 'refused' | 'terminated' | 'deleted';
    type: LimitType;
    createdAt: string;
    deadline?: string;
  } | null;
};

export const WalletContext = createContext<WalletProviderContextType>({
  pendingCashOuts: [],
  minimumCashInRequired: 5000,
  minimumCashOutRequired: 15000,
  loadingDepositLimit: false,
  isFastDepositOpen: false,
  setIsFastDepositOpen: (b: boolean) => {}
});

export const WalletProvider = ({ children }: PropsWithChildren) => {
  const [balance, setBalance] = useState<WalletBalanceType>();
  const [deadline, setDeadline] = useState<Date>(new Date());
  const [depositLimit, setDepositLimit] = useState<DepositLimit | null>(null);
  const [loadingDepositLimit, setLoadingDepositLimit] = useState(false);
  const [isGettingBalance, setIsGettingBalance] = useState(false);
  const [isBalanceError, setIsBalanceError] = useState(false);
  const { isLogged } = useContext(UserContext);
  const [isRetrieved, setIsRetrieved] = useState(false);
  const [pendingCashOuts, setPendingCashOuts] = useState<CashOutRequest[]>([]);
  const toast = useToast();
  const { t } = useTranslation();
  const minimumCashInRequired = 500;
  const minimumCashOutRequired = 1500;

  const [asideChildren, setAsideChildren] = useState<JSX.Element | null>(null);

  const getBalance = async () => {
    setIsGettingBalance(true);
    try {
      // const { data } = await HTTP.get('/balance');
      const { data } = await HTTP.get('wallet/get_balance');
      setIsBalanceError(false);
      setBalance(data);
      setIsGettingBalance(false);
      setIsRetrieved(true);
    } catch (e) {
      console.error(e);
      setIsBalanceError(true);
      setIsGettingBalance(true);
    }
  };

  const getPendingCashOuts = async () => {
    if (!isLogged) return;
    try {
      const response = await HTTP.get(
        '/payout_requests?page=1&limit=30&status=requested'
      );
      setPendingCashOuts(response.data['hydra:member']);
    } catch (error: any) {
      toast({
        status: 'error',
        position: 'top',
        title: t('common:somethingWrongHappenned')
      });
    }
  };

  const getDepositLimit = async () => {
    if (!isLogged) return;
    setLoadingDepositLimit(true);
    try {
      const { data } = await HTTP.get('/user/deposit_limit');
      setDepositLimit(data);
      setLoadingDepositLimit(false);
    } catch (e: any) {
      toast({
        status: 'error',
        position: 'top-right',
        title: t('common:somethingWrongHappenned')
      });
      setLoadingDepositLimit(false);
    }
  };

  // const cancelPendingCashOut = async (id: string) => {
  //   try {
  //     await HTTP.put(
  //       `/payout_requests/${id}/payout_request_vivawallet/cancel?confirm=true`
  //     );
  //   } catch (error: any) {}
  // };

  useEffect(() => {
    if (isLogged) {
      getBalance();
      getDepositLimit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  useEffect(() => {
    if (depositLimit?.pending?.deadline) {
      setDeadline(new Date(depositLimit.pending.deadline));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositLimit]);

  const [isFastDepositOpen, setIsFastDepositOpen] = useState(false);

  return (
    <WalletContext.Provider
      value={{
        isGettingBalance,
        getDepositLimit,
        loadingDepositLimit,
        deadline,
        setDeadline,
        depositLimit,
        setDepositLimit,
        balance,
        minimumCashInRequired,
        minimumCashOutRequired,
        setBalance,
        getBalance,
        isBalanceError,
        asideChildren,
        setAside: (children: any) => setAsideChildren(children),
        isRetrieved,
        getPendingCashOuts,
        pendingCashOuts,
        isFastDepositOpen,
        setIsFastDepositOpen
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);
