import { Text, TextProps, Box } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { ReactElement } from 'react';

type ComingSoonProps = TextProps & {
  children?: ReactElement | ReactElement[];
};

const ComingSoon = ({ ...props }: ComingSoonProps) => {
  const { t } = useTranslation();
  return (
    <Text
      fontSize={['3rem', '3rem', '4rem']}
      fontWeight="bold"
      textAlign="center"
      background="linear-gradient(to right, #FFF 20%, #001996 40%, #001996 60%, #FFF 80%)"
      backgroundSize="200% auto"
      color="#000"
      backgroundClip="text"
      style={{
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent'
      }}
      animation="shine 2s linear infinite"
      {...props}
    >
      {t('common:comingSoon')}
    </Text>
  );
};

export const ComingSoonPill = ({ children, ...props }: ComingSoonProps) => {
  const { t } = useTranslation();
  return (
    <Box position="relative" width="fit-content">
      {children}
      <Text
        width="fit-content"
        position="absolute"
        top="-10%"
        right="0"
        fontSize={['0.5rem', '0.5rem', '1rem']}
        fontWeight="bold"
        textAlign="center"
        background="linear-gradient(to right, #FFF 20%, #001996 40%, #001996 60%, #FFF 80%)"
        backgroundSize="200% auto"
        color="#000"
        backgroundClip="text"
        style={{
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent'
        }}
        animation="shine 2s linear infinite"
        {...props}
      >
        {t('common:comingSoon')}
      </Text>
    </Box>
  );
};

export default ComingSoon;
