import { HStack, StackProps } from '@chakra-ui/react';
import BackButton from '@/components/Button/BackButton';
import { useScroll, useTransform } from 'framer-motion';
import useMediaQuery from '@/hooks/useMediaQuery';
import { RefObject } from 'react';
import { Game } from '../GamesCatalog/types';
import { LogoTestAccount } from '@/components/Logo';
import { MotionVStack } from '../Motion';
import { CatalogTinyInfo } from '../Profile/Profile_TinyInfo/CatalogTinyInfo';
import { CustomHeader } from './CustomHeader';
import { useAppSettings } from '@/context/AppSettings';

type NavbarProps = StackProps & {
  playRef?: RefObject<HTMLDivElement>;
  games?: Game;
};

const scrollYRange = [0, 300];

export const DetailNavBar = ({ playRef, games, ...props }: NavbarProps) => {
  const isMobile = useMediaQuery('(max-width: md)');
  const { scrollY: scrollYViewport } = useScroll();
  const config = useAppSettings();
  const logoPositionStyle = config.customStyle?.logoPositionDetailGame || [];

  const logoPosition = useTransform(
    scrollYViewport,
    scrollYRange,
    logoPositionStyle
  );
  // const leftLogoPosition = useTransform(scrollYViewport, scrollYRange, [
  //   '50%',
  //   isMobile ? '10%' : '3%'
  // ]);

  // const appearLogoPosition = useTransform(
  //   scrollYViewport,
  //   [0, 500],
  //   ['100px', '0px']
  // );

  return (
    <CustomHeader {...props} overflow="hidden" alignItems={'center'}>
      <BackButton />
      <MotionVStack
        spacing="2rem"
        style={{
          transform: logoPosition,
          left: '50%',
          position: 'absolute'
        }}
      >
        <LogoTestAccount
          iconProps={{
            maxWidth: '7rem'
          }}
        />
      </MotionVStack>
      <HStack>
        {/* <MotionHStack
          style={{
            y: appearLogoPosition
          }}
        >
          <GameImage
            isNativeImage={true}
            src={games?.mediaUrls?.square}
            width="48"
            height="48"
            borderRadius="0.7rem"
          />
          <HStack spacing={['0.2rem', '0.2rem', '1rem']}>
            <FavoriteButton game={games as GameDetail} />
            <PlayNowButton games={games} />
          </HStack>
        </MotionHStack> */}
        {!isMobile && <CatalogTinyInfo />}
      </HStack>
    </CustomHeader>
  );
};
