import { useState } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { formatCurrency } from '@/hooks/useFormat';

import {
  JackpotLevelInfo,
  JackpotLevelType,
  JackpotLevelWinners,
  SVGBronzeLevel,
  SVGGoldLevel,
  SVGSilverLevel
} from '@/components/Jackpots';
import { useCountDownAnimation } from '@/hooks/useCountDownAnimation';
import { useNumberAnimation } from '@/hooks/useNumberAnimation';
import useTranslation from 'next-translate/useTranslation';
import { getDropsBeforeI18nKeyAndValue } from '@/helpers/jackpot';
import { useJackpots } from '@/context/Jackpots';

type JackpotLevelProps = BoxProps & {
  jackpotLevel: JackpotLevelType;
  onClick: () => void;
  isOpen: boolean;
};
export const JackpotLevel = ({
  jackpotLevel,
  onClick,
  isOpen,
  ...props
}: JackpotLevelProps) => {
  const { t } = useTranslation();
  const [isWinnerOpened, setIsWinnerOpened] = useState(false);
  const { setJackpotUpdateTime } = useJackpots();

  // Animate amount
  const amount = useNumberAnimation({
    value: jackpotLevel?.amount,
    animationValueDuration: 10000,
    animationValueStepDuration: 100
  });

  // Animate time remaining
  const secondsRemaining = useCountDownAnimation(
    jackpotLevel?.type === 'draw_time',
    +jackpotLevel?.maxValue,
    1000
  );

  const showAnimationType = 'ease';
  const showAnimationDuration = 0.3;

  const renderSVG = (
    jackpotLevel: JackpotLevelType,
    amount: number,
    timeRemaining: number
  ) => {
    const onClickWinnerHistory = () => {
      setIsWinnerOpened(true);
      setJackpotUpdateTime(Date.now());
      onClick();
    };
    const onClickInformation = () => {
      setIsWinnerOpened(false);
      onClick();
    };

    const amountFormatted = formatCurrency({
      amount: amount,
      currency: 'EUR'
    });
    const { key, value } = getDropsBeforeI18nKeyAndValue({
      jackpotLevel,
      timeRemaining
    });
    const dropsBefore = t(key, { value });

    switch (jackpotLevel.ref) {
      case '1':
        return (
          <SVGGoldLevel
            amount={amountFormatted}
            onClickInformation={onClickInformation}
            onClickWinnerHistory={onClickWinnerHistory}
            dropsBefore={dropsBefore}
          />
        );
      case '2':
        return (
          <SVGSilverLevel
            amount={amountFormatted}
            onClickInformation={onClickInformation}
            onClickWinnerHistory={onClickWinnerHistory}
            dropsBefore={dropsBefore}
          />
        );
      default:
      case '3':
        return (
          <SVGBronzeLevel
            amount={amountFormatted}
            onClickInformation={onClickInformation}
            onClickWinnerHistory={onClickWinnerHistory}
            dropsBefore={dropsBefore}
          />
        );
    }
  };
  return (
    <Box display={'grid'} alignItems={'center'} flex={1} {...props}>
      <Box
        zIndex={isOpen ? 0 : 1}
        opacity={isOpen ? 0 : 1}
        gridColumn={'1 / 2'}
        gridRow={'1 / 2'}
        w={'full'}
        transition={`opacity ${showAnimationType} ${showAnimationDuration}s`}
      >
        {renderSVG(jackpotLevel, amount, secondsRemaining)}
      </Box>
      <Box
        zIndex={isOpen ? 1 : 0}
        opacity={isOpen ? 1 : 0}
        display={'grid'}
        gridTemplateRows={isOpen ? '1fr' : '0fr'}
        transition={`all ${showAnimationType} ${
          isOpen ? showAnimationDuration : showAnimationDuration * 2
        }s`}
        gridColumn={'1 / 2'}
        gridRow={'1 / 2'}
        transform={`translateY(${isOpen ? 0 : 'calc(50% - 40px)'})`} // 40px === padding block of the inner box
      >
        <Box
          gridColumn={'1 / 2'}
          gridRow={'1 / 2'}
          overflow="hidden"
          borderRadius={8}
        >
          {isWinnerOpened ? (
            <JackpotLevelWinners
              jackpotLevel={jackpotLevel}
              onClose={onClick}
            />
          ) : (
            <JackpotLevelInfo jackpotLevel={jackpotLevel} onClose={onClick} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
