import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

export function useCurrentHost() {
  const [currentHost, setCurrentHost] = useState<string>('');

  const router = useRouter();

  useEffect(() => {
    if (window && window.location) {
      setCurrentHost(window.location.host);
    }
  }, []);

  useEffect(() => {
    if (window && window.location) {
      setCurrentHost(window.location.host);
    }
  }, [router.asPath]);

  return currentHost;
}
