import { CheckedIcon } from '@/theme/Icons';
import { VStack, Button, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { TextWithIcon } from '@/components/GamesCatalog';
import { useSettings } from '../../../SettingsContext';
import { useFormatCurrency } from '@/hooks/useFormat';
import useTranslation from 'next-translate/useTranslation';
import { useWallet } from '@/context/Wallet';
import { useAuth } from '@/context/Auth';
import { useEffect } from 'react';
import GTM from '@/helpers/googleTagManager';
import { useCashOutConfig } from '@/context/CashoutConfig';

const Success = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { sliderValue, selectedBankAccount, defaultBankAccount } =
    useSettings();
  const numberAmount = parseFloat(sliderValue.toString()) * 100;
  const selectedAmountFormatted = useFormatCurrency({
    amount: numberAmount
  });

  const { balance: userWalletBalance, getPendingCashOuts } = useWallet();
  const { userData } = useAuth();
  const { methodType } = useCashOutConfig();
  const bankAccount = !selectedBankAccount.iban.length
    ? defaultBankAccount?.iban
    : selectedBankAccount.iban;

  const confirmationSuccess = () => {
    switch (methodType) {
      case 'bank_transfer':
        return (
          <Text
            fontSize="1.2em"
            data-testid="cashout-bank-transfer-success-text"
          >
            {t('wallet:soonYouWillReceive', {
              amount: selectedAmountFormatted,
              bankAccount
            })}
          </Text>
        );
        break;
      case 'paypal':
        return (
          <Text fontSize="1.2em" data-testid="cashout-paypal-success-text">
            {t('wallet:soonYouWillReceivePaypal', {
              amount: selectedAmountFormatted
            })}
          </Text>
        );

      default:
        break;
    }
  };

  useEffect(() => {
    GTM.ecommerceEvent(
      'refund',
      numberAmount,
      undefined,
      userData,
      userWalletBalance
    );
    GTM.walletEvent(
      'payout_request',
      -1 * numberAmount,
      userData,
      userWalletBalance
    );
    getPendingCashOuts?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <VStack width="100%" align="baseline" spacing="2rem">
      <TextWithIcon
        data-testid="cashout-success-header"
        icon={CheckedIcon}
        text={t('wallet:cashOutSuccessful')}
        iconProps={{
          width: '25px',
          height: '25px',
          padding: '0.3em',
          border: '1px solid #04DE00',
          borderRadius: '50%'
        }}
        textProps={{ color: '#fff', fontSize: '1.3em', fontWeight: 'bold' }}
      />
      {confirmationSuccess()}
      <Button
        data-testid="cashout-success-history-button"
        whiteSpace="normal"
        variant="link"
        onClick={() =>
          router.push({
            search: 'tab=history'
          })
        }
      >
        {t('wallet:keepTrackOfCashOut')}
      </Button>
    </VStack>
  );
};

export default Success;
