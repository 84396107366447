import { HttpError } from '@/components/Http';
import { APIUserType } from '@/context/Auth.types';
import { AxiosResponse } from 'axios';
import { HTTP } from '../components';

let loadingMe: Promise<APIUserType> | undefined;

export const handleAuth = async () => {
  if (loadingMe) return loadingMe;

  loadingMe = new Promise((resolve, reject) => {
    HTTP.get('user/me')
      .then((response: AxiosResponse<APIUserType>) => {
        // if 200, we can consider that we are connected
        if (response?.status === 200) {
          resolve(response.data);
        }
        reject('USER_OFFLINE');
      })
      .catch((error: HttpError) => {
        reject(error.status);
      })
      .finally(() => {
        loadingMe = undefined;
      });
  });
  return loadingMe;
};

export const handleLogout = () => {
  return new Promise((resolve, reject) => {
    HTTP.get('user/logout')
      .then((response: any) => {
        // console.log(response?.data);

        if (response?.data === 'OK') {
          resolve(response?.data);
        }
        reject('LOGOUT_ERROR');
      })
      .catch((error: HttpError) => {
        // console.log(error);
        if (error.status === '401') resolve({});
        reject('LOGOUT_ERROR');
      });
  });
};

export const handleLicences = (): Promise<UserLicencesType[]> => {
  return new Promise((resolve, reject) => {
    HTTP.get('user/licenses') // 's 'in place of 'c'!!
      .then((response: any) => {
        const res: ApiUserLicencesResponseData = response?.data || {};
        const licences = res['hydra:member'] || res;
        if (Array.isArray(licences)) resolve(licences);
        resolve([]);
      })
      .catch((error) => reject(error));
  });
};

export const handleFavorites = (): Promise<UserFavoriteType[]> => {
  return new Promise((resolve, reject) => {
    HTTP.get('game/users/favorites?pagination=false')
      .then((response: any) => {
        const res: ApiGameUserFavoritesType = response?.data || [];
        const favs = res['hydra:member'] || res;
        // console.log({ favs });

        resolve(Array.isArray(favs) ? favs : []);
      })
      .catch((error) => reject(error));
  });
};

export const handleAddFavorite = async (
  gameId: string,
  // gameIri: string,
  userId: string
): Promise<UserFavoriteType> => {
  const { data }: { data: UserFavoriteType } = await HTTP.post(
    'game/users/favorites/' + gameId,
    {
      user: userId
      // game: gameIri
    }
  );
  return data;
};

export const handleRemoveFavorite = (gameId: string): Promise<any> => {
  return HTTP.delete('game/users/favorites/' + gameId);
};

type ApiUserLicencesResponseData = {
  'hydra:member': UserLicencesType[];
};
export type UserLicencesType = {
  '@id': string;
  '@type': string;
  enabled: boolean;
  banned: boolean;
  bannedUntil?: string;
  name: string;
  available: boolean;
  minAge: number;
};

export type UserFavoriteType = {
  '@id': string;
  '@type': string;
  game: string;
};
type ApiGameUserFavoritesType = {
  'hydra:member': UserFavoriteType[];
  'hydra:totalItems': number;
};
