export const padTime = (time: number) => time.toString().padStart(2, '0');

export const getHMS = (timeInSeconds: number) => {
  const h = padTime(Math.floor(timeInSeconds / 3600));
  const m = padTime(Math.floor((timeInSeconds % 3600) / 60));
  const s = padTime(timeInSeconds % 60);
  return { h, m, s };
};

export const getHMSFormat = (timeInSeconds: number) => {
  const { h, m, s } = getHMS(timeInSeconds);
  return `${h}h:${m}m:${s}s`;
};
