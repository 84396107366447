import { TournamentType } from '@/components/GamesCatalog/types';
import {
  DomainLicense,
  LicenceType,
  getFullUrlByLicense,
  getLicenseURLParam
} from '@/utils/multiDomains';
import { daysLeftFromNow, hoursFromNow, minutesFromNow } from './date';
import { TournamentStatusType } from '@/components/Tournaments';
import { getLocaleTranslation } from './translation';
import { TournamentFilter } from '@/components/Tournaments/types';
import { GameDataStore } from '@/services/getCatalog';

export const getSortedAndGroupedTournaments = (
  tournaments: TournamentType[]
) => {
  const grouped =
    tournaments?.reduce(
      (acc, tournament) => {
        const status = getTournamentStatus(tournament);
        acc[status] = [...(acc[status] || []), tournament];
        return acc;
      },
      {} as {
        [key: string]: TournamentType[];
      }
    ) || {};

  const all: TournamentType[] = [
    ...(grouped[TournamentFilters.ONGOING] || []),
    ...(grouped[TournamentFilters.UPCOMING] || []),
    ...(grouped[TournamentFilters.ENDED] || [])
  ];

  return {
    grouped,
    all
  };
};

export const getTournamentsApiPath = ({
  baseUrl,
  license,
  limit = '3',
  highlighted = false
}: {
  baseUrl: string;
  license?: LicenceType;
  limit?: string;
  highlighted?: boolean;
}) => {
  const now = new Date().toJSON();

  const params = {
    pagination: 'false',
    license: license ? getLicenseURLParam(license) : '',
    [`${encodeURIComponent('visibleTo[strictly_after]')}`]: now,
    status: 'published',
    [`${encodeURIComponent('order[startDate]')}`]: 'asc'
  };

  if (highlighted) {
    params.limit = limit;
    params.pagination = 'true';
    params.highlighted = 'true';
  }

  const stringParams = Object.entries(params)
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join('&');

  return `${baseUrl}/tournaments.jsonld?${stringParams}`;
};

export const getOnGoingTournamentsApiPath = ({
  baseUrl,
  license
}: {
  baseUrl: string;
  license: LicenceType;
}) => {
  const now = new Date().toJSON();

  const params = {
    pagination: 'false',
    license: getLicenseURLParam(license),
    [`${encodeURIComponent('startDate[before]')}`]: now,
    [`${encodeURIComponent('endDate[strictly_after]')}`]: now,
    status: 'published',
    [`${encodeURIComponent('order[startDate]')}`]: 'asc'
  };

  const stringParams = Object.entries(params)
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join('&');

  return `${baseUrl}/tournaments.jsonld?${stringParams}`;
};

export const getUpcomingTournamentsApiPath = ({
  baseUrl,
  license
}: {
  baseUrl: string;
  license: LicenceType;
}) => {
  const now = new Date().toJSON();

  const params = {
    pagination: 'false',
    license: getLicenseURLParam(license),
    [`${encodeURIComponent('visibleFrom[after]')}`]: now,
    [`${encodeURIComponent('startDate[strictly_after]')}`]: now,
    status: 'published',
    [`${encodeURIComponent('order[startDate]')}`]: 'asc'
  };

  const stringParams = Object.entries(params)
    .map(([key, value]) => {
      return `${key}=${value}`;
    })
    .join('&');

  return `${baseUrl}/tournaments.jsonld?${stringParams}`;
};

export const getGamesAvailableInTournaments = ({
  dataStore,
  enabled
}: {
  dataStore: GameDataStore;
  enabled: boolean;
}) => {
  const gamesTournaments: Map<string, { id: string; slug: string }[]> =
    new Map();

  if (enabled) {
    const ongoingTournaments = Object.values(
      dataStore?.ongoingTournaments || {}
    );
    ongoingTournaments.forEach((tournament) => {
      const tournamentData = {
        id: tournament.id,
        slug: tournament.slug
      };
      tournament.games?.forEach((game: string) => {
        const gameId = game.replace('/games/', '');
        if (gamesTournaments.has(gameId)) {
          const tournaments = gamesTournaments.get(gameId);

          if (tournaments?.findIndex((t) => t.id === tournament.id) === -1) {
            tournaments.push(tournamentData);
            gamesTournaments.set(gameId, tournaments);
          }
        } else {
          gamesTournaments.set(gameId, [tournamentData]);
        }
      });
    });
  }

  return gamesTournaments;
};

export const isVisibleTournament = (tournament: TournamentType) => {
  const now = Date.now();
  const visibleTo = Date.parse(tournament?.visibleTo);
  return now <= visibleTo && tournament?.status === 'published';
};

export const TournamentStatus = {
  UPCOMING: 'upcoming' as TournamentStatusType,
  ENDED: 'ended' as TournamentStatusType,
  ONGOING: 'ongoing' as TournamentStatusType
};

export const TournamentFilters = {
  ALL: 'all' as TournamentFilter,
  UPCOMING: 'upcoming' as TournamentFilter,
  ENDED: 'ended' as TournamentFilter,
  ONGOING: 'ongoing' as TournamentFilter
};

export function getTournamentTimeTo(
  tournament: TournamentType,
  type: 'start' | 'end',
  now?: number
) {
  const date = Date.parse(
    type === 'start' ? tournament.startDate : tournament.endDate
  );

  const days = Math.floor(Math.abs(daysLeftFromNow(date, now)));
  const hours = Math.abs(hoursFromNow(date, now));
  const minutes = Math.abs(minutesFromNow(date, now));

  return {
    days,
    hours,
    minutes,
    dif: now ? date - now : 0
  };
}

export function getTournamentDayLabel(tournament: TournamentType) {
  const now = Date.now();
  const timeToStartObject = getTournamentTimeTo(tournament, 'start', now);
  const timeToEndObject = getTournamentTimeTo(tournament, 'end', now);
  const started = timeToStartObject.dif <= 0;
  const finished = timeToEndObject.dif < 0;

  if (finished) {
    // days
    if (timeToEndObject.days >= 1) {
      return {
        key: 'tournament:finishedSinceDays',
        options: { count: Math.ceil(timeToEndObject.days) }
      };
    }

    // hours
    if (timeToEndObject.hours > 1) {
      return {
        key: 'tournament:finishedInHours',
        options: { count: Math.ceil(timeToEndObject.hours) }
      };
    }

    // minutes
    return {
      key: 'tournament:finishedInMinutes',
      options: { count: Math.ceil(timeToEndObject.minutes) }
    };
  }

  if (started) {
    // days
    if (timeToEndObject.days >= 1) {
      return {
        key: 'tournament:finishesInDays',
        options: { count: Math.ceil(timeToEndObject.days) }
      };
    }

    // hours
    if (timeToEndObject.hours > 1) {
      return {
        key: 'tournament:finishesInHours',
        options: { count: Math.ceil(timeToEndObject.hours) }
      };
    }

    // minutes
    return {
      key: 'tournament:finishesInMinutes',
      options: { count: Math.ceil(timeToEndObject.minutes) }
    };
  }

  // days
  if (timeToStartObject.days >= 1) {
    return {
      key: 'tournament:startInDays',
      options: { count: Math.ceil(timeToStartObject.days) }
    };
  }

  // hours
  if (timeToStartObject.hours > 1) {
    return {
      key: 'tournament:startInHours',
      options: { count: Math.ceil(timeToStartObject.hours) }
    };
  }

  // minutes
  return {
    key: 'tournament:startInMinutes',
    options: { count: Math.ceil(timeToStartObject.minutes) }
  };
}

export function getNoTournamentsLabel(filter: TournamentFilter) {
  switch (filter) {
    default:
    case TournamentFilters.ALL:
      return 'tournament:noTournaments';
    case TournamentFilters.ONGOING:
      return 'tournament:noOngoingTournaments';
    case TournamentFilters.UPCOMING:
      return 'tournament:noUpcomingTournaments';
    case TournamentFilters.ENDED:
      return 'tournament:noEndedTournaments';
  }
}

export function getTournamentStatus(tournament: TournamentType) {
  const daysToStart = daysLeftFromNow(Date.parse(tournament.startDate));
  const daysToEnd = daysLeftFromNow(Date.parse(tournament.endDate));

  if (daysToStart > 0) {
    return TournamentStatus.UPCOMING;
  } else if (daysToEnd < 0) {
    return TournamentStatus.ENDED;
  } else {
    return TournamentStatus.ONGOING;
  }
}

export function getCardLabel(tournament: TournamentType) {
  const label: TournamentStatusType = getTournamentStatus(tournament);

  switch (label) {
    default:
    case TournamentStatus.UPCOMING:
      return 'tournament:upcoming';
    case TournamentStatus.ENDED:
      return 'tournament:ended';
    case TournamentStatus.ONGOING:
      return 'tournament:ongoing';
  }
}

export function getCardTagColorScheme(tournament: TournamentType) {
  return getTournamentStatus(tournament);
}

export const getTournamentTranslation = ({
  locale,
  tournament
}: {
  locale: string;
  tournament: TournamentType;
}) => {
  const translations = tournament.translations;
  if (!translations) return null;
  return getLocaleTranslation(translations, locale);
};

export const getTournamentUrl = ({
  id,
  license,
  locale,
  slug
}: {
  id: string;
  slug: string;
  license: LicenceType;
  locale: string;
}) => {
  return `${getFullUrlByLicense(license, locale)}/tournaments/${id}/${slug}`;
};

export const getTournamentsUrl = ({
  locale,
  license
}: {
  locale: string;
  license: keyof DomainLicense;
}) => {
  license = license === 'MAIN' ? 'B' : license;
  return `${getFullUrlByLicense(license, locale)}/tournaments`;
};

export const getRedirectTournamentDisabled = ({
  enabled,
  locale,
  license
}: {
  enabled: boolean;
  locale: string;
  license: keyof DomainLicense;
}) => {
  if (enabled) return;
  return {
    redirect: {
      destination: `${getFullUrlByLicense(license, locale)}`,
      permanent: false
    }
  };
};

export const hasHighlightedTournaments = (
  dataStore: GameDataStore | { highlightedTournaments: TournamentType[] }
) => {
  return Object.values(dataStore?.highlightedTournaments ?? {}).length > 0;
};

export const getDeletedTournamentRedirect = ({
  tournament,
  license,
  locale = 'en'
}: {
  tournament: TournamentType;
  license: LicenceType;
  locale?: string;
}) => {
  if (tournament?.status !== 'deleted') return;
  return {
    redirect: {
      destination: `${getFullUrlByLicense(license, locale)}/tournaments`,
      permanent: false
    }
  };
};
