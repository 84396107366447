import { useFilters } from '@/context/Filters';
import { getLocaleTranslation } from '@/helpers/translation';
import WithFallbackComponent from '@/hoc/WithFallbackComponent';
import { Box, HStack, SpaceProps, StackProps, Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useCatalog } from '../../context/Catalog';
import { EyeIcon, StarIcon, VitalIcon } from '../../theme/Icons';
import FavoriteButton from '../Button/FavoriteButton';
import NoGameFallback from '../Fallback/NoGameFallback';
import {
  MotionHStack,
  MotionVStack,
  rightToLeftChildrenElementAnimation
} from '../Motion';
import NewGame from '../Tag/NewGame';
import { GameImage, ScrollWithoutScrollBar, SectionDescription } from './';
import { GameSubTitle, GameTitle, TextWithIcon } from './Artefacts';
import { DomainLicense, getFullUrlByLicense } from '@/utils/multiDomains';

type PopularProps = StackProps & SpaceProps & {};

const Popular = ({ ...props }: PopularProps) => {
  const { dataStore } = useCatalog();
  const { filteredGames } = useFilters();
  const { t, lang } = useTranslation();
  const data = filteredGames
    ?.sort(
      (a: any, b: any) =>
        (a.sections['popular'] !== null ? a.sections['popular'] : Infinity) -
        (b.sections['popular'] !== null ? b.sections['popular'] : Infinity)
    )
    ?.slice(0, 15);

  const formatPlace = (index: number) => {
    switch (index) {
      case 0:
        return `${index + 1}st`;
      case 1:
        return `${index + 1}nd`;
      case 2:
        return `${index + 1}rd`;
      default:
        return `${index + 1}th`;
    }
  };

  const license = data[0].license.slice(0, 1);

  return (
    <SectionDescription
      icon={StarIcon}
      title={t('catalog:mostPopular')}
      id="mostpopular"
      seeAllRoute={`${getFullUrlByLicense(
        license as keyof DomainLicense,
        lang
      )}/most-popular`}
      subTitle={t('catalog:discoverPopular')}
      {...props}
    >
      <WithFallbackComponent
        predicat={data.length > 0}
        FallbackComponent={NoGameFallback}
      >
        <MotionHStack
          spacing="1.8rem"
          align="baseline"
          css={ScrollWithoutScrollBar}
          paddingY="1rem"
          initial={'initial'}
          transition={{
            staggerChildren: 0.15,
            delayChildren: 0.5
          }}
          whileInView={'animate'}
          viewport={{ once: true, amount: 0.7 }}
          key={data.length}
        >
          {data.slice(0, 15).map((game, index) => (
            <MotionVStack
              width="13rem"
              height="100%"
              justifyContent="space-between"
              align="baseline"
              // animate={controls}
              variants={rightToLeftChildrenElementAnimation}
              // layoutId={`Popular-${game.id}`}
              key={game.id}
            >
              <GameImage
                href={`/games/${game.id}/${game.slug}`}
                data-testid={`popular-${index}`}
                src={game?.mediaUrls?.banner_mobile}
                width="13rem"
                height="375px"
              >
                <Text
                  className="blurry"
                  fontSize="1.5rem"
                  padding="1rem"
                  fontWeight="bold"
                  position="absolute"
                  borderRadius="1.8rem 0 1.8rem 0"
                  top="0"
                  left="0"
                >
                  {formatPlace(index)}
                </Text>
                <HStack
                  position="absolute"
                  bottom="0.1rem"
                  width="100%"
                  justifyContent="space-between"
                  paddingX="0.8rem"
                >
                  {game?.new ? <NewGame /> : <Box />}
                  <FavoriteButton fontSize="2xl" game={game} />
                </HStack>
              </GameImage>
              <GameTitle>{game.name}</GameTitle>
              <GameSubTitle>
                {dataStore?.providers?.[game.provider as string]?.name}
              </GameSubTitle>
              <HStack marginTop="auto" color="rgba(255,255,255,0.6)">
                <TextWithIcon icon={EyeIcon} text={game?.launchCount} />
                <TextWithIcon icon={VitalIcon} text={game?.volatility} />
              </HStack>
            </MotionVStack>
          ))}
        </MotionHStack>
      </WithFallbackComponent>
    </SectionDescription>
  );
};

export default Popular;
