import { languageMapping } from '../constant/languageMapping';

export enum CurrencyBase {
  'CENT' = 1,
  'EURO' = 100
}

interface NumberFormatOptions {
  localeMatcher?: 'lookup' | 'best fit';
  style?: 'decimal' | 'currency' | 'percent';
  currency?: string;
  currencyDisplay?: 'symbol' | 'code' | 'name';
  useGrouping?: boolean;
  minimumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  minimumSignificantDigits?: number;
  maximumSignificantDigits?: number;
}

export const humanCurrency = (
  amount: number,
  locale: string,
  currency: string = 'EUR',
  currencyBase: CurrencyBase = CurrencyBase.EURO,
  options: NumberFormatOptions = { style: 'currency' }
) => {
  const convertedAmount = amount / currencyBase;
  return new Intl.NumberFormat(languageMapping[locale], {
    ...options,
    currency
  }).format(convertedAmount);
};

export function getSeparator(
  locale: string,
  separatorType: 'group' | 'decimal' = 'decimal'
) {
  const numberWithGroupAndDecimalSeparator = 1000.1;
  return (
    Intl.NumberFormat(languageMapping[locale])
      .formatToParts(numberWithGroupAndDecimalSeparator)
      .find((part) => part.type === separatorType)?.value ?? '.'
  );
}
