import { useAppSettings } from '@/context/AppSettings';
import { useExtraProps } from '@/pages/_app';
import { getFullUrlByLicense } from '@/utils/multiDomains';
import { Box, Link, TabsProps, Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { ReactElement, RefObject } from 'react';
import Slider, { SliderProps } from '../Slider/Slider';
import { Category, Licence } from './types';
import { getTournamentsUrl } from '@/helpers/tournaments';
import { getCategoriesHeight } from '@/helpers/header';
import { HeaderWrapper } from '@/components/Header';
import NextLink from 'next/link';

type CategoriesProps = TabsProps & {
  data: {
    [key: string]: Category;
  };
  observeRef: RefObject<HTMLDivElement>;
  children?: ReactElement | null;
  activeSlug?: string;

  sliderProps?: Partial<SliderProps>;
};

const Categories = ({
  data,
  observeRef,
  children,
  activeSlug,
  sliderProps
}: CategoriesProps) => {
  const config = useAppSettings();
  const { lang, t } = useTranslation();
  const { license } = useExtraProps();
  const router = useRouter();
  // const anim = useRef<number>(0);
  const categories = Object.values(data)
    .filter(
      (category) =>
        category.licenses.includes(`${license}+` as Licence) && category.visible
    )
    ?.sort((a, b) => a?.order - b?.order);

  const categoryItems: CategoryItem[] = [
    {
      id: 'all',
      name: t('common:all'),
      slug: 'all',
      url: getFullUrlByLicense(license, lang),
      img: '/icons/all.svg'
    },
    ...categories.map((category) => ({
      ...category,
      url: `${getFullUrlByLicense(license, lang)}/category/${category.slug}`,
      img: category?.mediaUrls?.icon as unknown as string
    }))
  ];

  if (config.isTournamentEnabled) {
    categoryItems.push({
      id: 'TOURNAMENTS',
      name: t('catalog:tournaments'),
      slug: 'TOURNAMENTS',
      url: getTournamentsUrl({
        locale: lang,
        license
      }),
      img: '/icons/tournaments.svg'
    });
  }

  const currentSlug = activeSlug ?? router?.query?.slug ?? 'all';
  return categoryItems?.length ? (
    <HeaderWrapper
      data-testid={'categories-header'}
      height={`${getCategoriesHeight()}px`}
      containerProps={{ display: 'flex' }}
    >
      <Slider {...sliderProps} root={'nav'} gap="0">
        {categoryItems?.map((categoryItem) => (
          <CategoryItem
            key={categoryItem.id}
            {...categoryItem}
            currentSlug={currentSlug}
          />
        ))}
      </Slider>
    </HeaderWrapper>
  ) : null;
};

export default Categories;

interface CategoryItem {
  id: string;
  name: string;
  slug: string;
  url: string;
  img: string;
}

const CategoryItem = ({
  name,
  img,
  slug,
  id,
  url,
  currentSlug
}: CategoryItem & { currentSlug: string | string[] }) => {
  const isActiveSlug = (slug: string) => currentSlug === slug;
  const activeStyles = {
    color: 'buttonPrimary',
    textDecoration: 'none',
    '> div:first-of-type': {
      bg: 'buttonPrimary',
      transition: 'background-color 0.3s ease'
    }
  };

  return (
    <NextLink key={id} href={url} passHref>
      <Link
        data-testid={`category-${slug}`}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        px={4}
        gap={2}
        transition="color 0.3s ease"
        sx={{ ...(isActiveSlug(slug) ? activeStyles : {}) }}
        _hover={{ ...activeStyles }}
        _focus={{ ...activeStyles }}
      >
        <Box
          width="28px"
          height="28px"
          bg={isActiveSlug(slug) ? 'buttonPrimary' : '#FFF'}
          __css={{
            maskImage: `url(${img})`,
            maskRepeat: 'no-repeat',
            maskSize: '28px 28px'
          }}
        ></Box>

        <Text
          flex={'1 1 auto'}
          fontWeight="400"
          fontSize="1rem"
          lineHeight="6"
          pt="0.2rem"
          letterSpacing={'.05rem'}
          whiteSpace={'nowrap'}
        >
          {name}
        </Text>
      </Link>
    </NextLink>
  );
};
