import { chakra } from '@chakra-ui/react';
import React from 'react';
import { coinsAnimationStyles } from './style';
import { silver } from './base64';
import {
  JackpotLevelAmount,
  JackpotLevelDropsBefore,
  JackpotLevelInfoButton,
  JackpotLevelWinnerButton,
  lightsTitle,
  SVGSilverTitle
} from '@/components/Jackpots';

type SVGLevelProps = {
  onClickWinnerHistory: () => void;
  onClickInformation: () => void;
  amount: string;
  dropsBefore: string;
};

export const SVGSilverLevel = ({
  onClickWinnerHistory,
  onClickInformation,
  amount,
  dropsBefore
}: SVGLevelProps) => {
  const borderColor = '#BAD9EA';
  return (
    <chakra.svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={'100%'}
      viewBox="0 0 381 188"
      css={coinsAnimationStyles}
    >
      {/* BITMAPS + MASK + FILTER + GRADIENT */}
      <defs>
        <image
          id="silver-coins1"
          x="0"
          y="0"
          width={77 / 2}
          xlinkHref={silver.coins1}
          // xlinkHref={'/goldenPalace/jackpots/silver/levels/coins-1.webp'}
        />

        <image
          id="silver-coins2"
          x="0"
          y="0"
          width={83 / 2}
          xlinkHref={silver.coins2}
          // xlinkHref={'/goldenPalace/jackpots/silver/levels/coins-2.webp'}
        />

        <image
          id="silver-coins3"
          x="0"
          y="0"
          width={71 / 2}
          xlinkHref={silver.coins3}
          // xlinkHref={'/goldenPalace/jackpots/silver/levels/coins-3.webp'}
        />

        <mask id="mask">
          <rect x="0" y="12" width="381" height="176" rx="11" fill="white" />
        </mask>

        {/* TEXT EFFECT */}
        {SVGSilverTitle.textEffect}
      </defs>

      {/* BACKGROUND + COINS */}
      <g mask="url(#mask)">
        {/* BACKGROUND */}
        <image
          // href="/goldenPalace/jackpots/silver/levels/background.webp"
          href={silver.bg}
          width={'100%'}
          y={11}
        />

        {/* COINS */}

        <use
          data-name={'coin-1'}
          xlinkHref="#silver-coins1"
          className={'coin'}
          style={
            {
              '--coin-x': '35px',
              '--coin-y': '40px',
              '--coin-to-y': '30px',
              '--coin-duration': '3.2s',
              '--coin-transform': 'rotate(0deg)'
            } as React.CSSProperties
          }
        />

        <use
          data-name={'coin-2'}
          xlinkHref="#silver-coins1"
          className={'coin'}
          style={
            {
              '--coin-x': '300px',
              '--coin-y': '50px',
              '--coin-to-y': '65px',
              '--coin-duration': '3.2s',
              '--coin-transform': 'rotate(0deg)'
            } as React.CSSProperties
          }
        />

        <use
          data-name={'coin-3'}
          xlinkHref="#silver-coins2"
          className={'coin'}
          style={
            {
              '--coin-x': '10px',
              '--coin-y': '95px',
              '--coin-to-y': '70px',
              '--coin-duration': '4s',
              '--coin-transform': 'rotate(0deg)'
            } as React.CSSProperties
          }
        />

        <use
          data-name={'coin-4'}
          xlinkHref="#silver-coins2"
          className={'coin'}
          style={
            {
              '--coin-x': '395px',
              '--coin-y': '20px',
              '--coin-to-y': '30px',
              '--coin-duration': '3s',
              '--coin-transform': 'rotate(95deg)'
            } as React.CSSProperties
          }
        />

        <use
          data-name={'coin-5'}
          xlinkHref="#silver-coins3"
          className={'coin'}
          style={
            {
              '--coin-x': '80px',
              '--coin-y': '130px',
              '--coin-to-y': '115px',
              '--coin-duration': '3s',
              '--coin-transform': 'rotate(86deg)'
            } as React.CSSProperties
          }
        />

        <use
          data-name={'coin-6'}
          xlinkHref="#silver-coins3"
          className={'coin'}
          style={
            {
              '--coin-x': '330px',
              '--coin-y': '125px',
              '--coin-to-y': '100px',
              '--coin-duration': '5s',
              '--coin-transform': 'rotate(0deg)'
            } as React.CSSProperties
          }
        />
      </g>

      {/* BORDER */}
      <rect
        x="1"
        y="13"
        width="379"
        height="174"
        rx="10"
        stroke={borderColor}
        strokeWidth="1"
        fill={'transparent'}
      />

      {/* JACKPOT + PILL */}
      {SVGSilverTitle.textAndPill}

      {/* JACKPOT LIGHTS*/}
      {lightsTitle}

      {/* AMOUNT */}
      <JackpotLevelAmount amount={amount} />

      {/* DROPS BEFORE */}
      <JackpotLevelDropsBefore dropsBefore={dropsBefore} />

      {/*WINNER BUTTON*/}
      <JackpotLevelWinnerButton
        onclick={onClickWinnerHistory}
        borderColor={borderColor}
      />

      {/*INFO BUTTON*/}
      <JackpotLevelInfoButton
        onclick={onClickInformation}
        borderColor={borderColor}
      />
    </chakra.svg>
  );
};
