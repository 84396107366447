import axios, { AxiosError } from 'axios';
import getConfig from 'next/config';
import { parameters } from './http.types';

const { publicRuntimeConfig } = getConfig() || {};

// Aim to cancel a api call
const source = axios.CancelToken.source();
const apiUrlFromHost = () => {
  if (typeof window === 'undefined') return '';
  // from bo
  const currentHost = document?.location?.host;
  const currentProtocol = document?.location?.protocol;
  if (!currentHost || !currentProtocol) return '';

  const hostRemapped = currentHost.replace('www', 'api');
  return `${currentProtocol}//${hostRemapped}`;
};
const apiUrl = apiUrlFromHost() || publicRuntimeConfig?.NEXT_PUBLIC_API_URL;

// const persistedLangage =
//   typeof window !== 'undefined' && window?.localStorage?.getItem('locale')
//     ? window?.localStorage?.getItem('locale')
//     : 'en';

const param: parameters = {
  baseURL: apiUrl, //whichApi,
  withCredentials: true,
  cancelToken: source.token
};

export const HTTP = axios.create(param);

export type HttpError = AxiosError;
