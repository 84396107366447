import {
  Box,
  Button,
  ButtonProps,
  Spinner,
  Text,
  Tooltip
} from '@chakra-ui/react';
import { AccountIcon, CloseIcon, MoreIcon } from '@/theme/Icons';
import { truncate } from '@/helpers/string';
import { UserMenu } from '@/components';
import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { getSeparator } from '@/helpers/humanCurrency';

type ProfileAccountButtonProps = ButtonProps & {
  isLoading: boolean;
  isPlaying: boolean;
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
  handleClose?: () => void;
  username?: string;
  amount: string;
};
export const ProfileAccountButton = ({
  isPlaying,
  isLoading,
  isOpen,
  amount,
  username,
  onOpen,
  onClose,
  handleClose,
  ...props
}: ProfileAccountButtonProps) => {
  const { lang } = useTranslation();
  const decimalSeparator = getSeparator(lang, 'decimal');

  // for nl remove non-breaking space and euro
  amount = amount.replace(/€\xa0/, '');
  // remove euro
  amount = amount.replace(/€/, '');

  let splitAmount = truncate(amount).split(decimalSeparator);
  if (splitAmount.length !== 2) {
    splitAmount = [truncate(amount), ''];
  }
  const [amountUnit, amountDecimal] = splitAmount;

  return (
    <Button
      variant="outline"
      data-testid="profile-menu-button"
      borderRadius="1rem"
      paddingX="0.3rem"
      height="3.2rem"
      cursor="pointer"
      display={'flex'}
      justifyContent={'start'}
      alignSelf={handleClose ? 'stretch' : undefined}
      gap={2}
      onClick={handleClose ? handleClose : onOpen}
      {...props}
    >
      <AccountIcon
        data-testid="top-user-profile-img"
        width="2rem"
        height="2rem"
      />

      <Box
        maxWidth={!handleClose ? 'calc(100% - 32px - .44rem)' : undefined}
        overflow={'hidden'}
        mr={'auto'}
      >
        <Text
          fontWeight="400"
          fontSize=".875rem"
          lineHeight={'1.25rem'}
          data-testid="user-badge-username"
          align={'left'}
          mt={'1px'}
          mb={-1.5}
        >
          <Tooltip
            label={username}
            // isDisabled={(userData?.username as string).length <= 12}
          >
            {truncate(username ?? '')}
          </Tooltip>
        </Text>

        {isLoading ? (
          <Spinner size={'xs'} />
        ) : (
          <Tooltip label={amount}>
            <Text
              style={{ filter: isPlaying ? 'blur(.5rem)' : 'none' }}
              data-testid="top-balance-amount"
              textOverflow={'ellipsis'}
              overflow={'hidden'}
              whiteSpace={'nowrap'}
              maxWidth={'100%'}
              fontSize=".875rem"
              lineHeight={'1.25rem'}
            >
              {'€ '}
              {amountUnit}
              {amountDecimal ? (
                <Text as={'span'} fontSize={'.7rem'}>
                  {decimalSeparator}
                  {amountDecimal}
                </Text>
              ) : null}
            </Text>
          </Tooltip>
        )}
      </Box>
      {handleClose ? (
        <CloseIcon width="1rem" cursor="pointer" />
      ) : (
        <UserMenu
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          isPlaying={isPlaying}
        >
          <MoreIcon onClick={onOpen} width={'.44rem'} />
        </UserMenu>
      )}
    </Button>
  );
};
