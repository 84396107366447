import { Stack, StackProps } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

type FormStackProps = PropsWithChildren & StackProps;

const FormStack = ({ children, ...props }: FormStackProps) => {
  return (
    <Stack width="100%" spacing={['1rem']} direction={['row']} {...props}>
      {children}
    </Stack>
  );
};

export default FormStack;
