import { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { PortalProps } from './Portal.types';

const Portal: FC<PortalProps> = ({ children, container = '__next' }) => {
  // const currentElement = useRef(document.createElement('Fragment'));
  // currentElement.current.setAttribute('role', 'fragment');

  // useEffect((): any => {
  // const rootPortal: HTMLElement | null = document.getElementById(container);
  // const element = currentElement.current;

  // rootPortal?.appendChild(element);

  // return () => rootPortal?.removeChild(element);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const containerElement = document.getElementById(container);

  if (containerElement) {
    return createPortal(children, containerElement);
  }

  return null;
};

export default Portal;

export const PortalBanner: FC<PropsWithChildren> = ({ children }) => {
  return <Portal container={'portal-banner'}>{children}</Portal>;
};

export const PortalHeader: FC<PropsWithChildren> = ({ children }) => {
  return <Portal container={'portal-header'}>{children}</Portal>;
};

export const PortalBackground: FC<PropsWithChildren> = ({ children }) => {
  return <Portal container={'portal-background'}>{children}</Portal>;
};
