import { getPageTitle, SeoTagsConfig } from '@/helpers/seo';

export const SeoTags = ({ canonicalUrl, config = {} }: SeoTagsProps) => {
  if (config['og:url'] === '') {
    config['og:url'] = canonicalUrl;
  }

  return (
    <>
      {Object.entries(config)?.map(([key, value]) => {
        switch (key) {
          case 'title':
            if (!value) return null;

            return (
              <>
                <title key="pageTitle">
                  {getPageTitle(value, config?.['og:site_name'])}
                </title>

                <meta key={'itemPropName'} itemProp="name" content={value} />
              </>
            );
          case 'description':
            if (!value) return null;

            return (
              <>
                <meta key="description" name="description" content={value} />
                <meta
                  key="itemPropDescription"
                  itemProp="description"
                  content={value}
                />
              </>
            );
          case 'image':
            if (!value) return null;
            return (
              <meta key="itemPropImage" itemProp="image" content={value} />
            );

          default:
            const metaAttributes: {
              content: string;
              name?: string;
              property?: string;
            } = {
              [key.startsWith('og:') ? 'property' : 'name']: key,
              content: value
            };

            return value ? <meta key={key} {...metaAttributes} /> : null;
        }
      })}
    </>
  );
};

type SeoTagsProps = {
  canonicalUrl: string;
  config?: SeoTagsConfig;
};
