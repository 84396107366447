import { useAuth } from '@/context/Auth';
import { errorsFormBuilder } from '@/helpers/errorsFormBuilder';
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  UseModalProps,
  useToast
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, HTTP, Input } from '../';

type ResetPasswordModalType = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const ResetPasswordModal = ({ ...props }: UseModalProps) => {
  const { t } = useTranslation();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const toast = useToast();
  const { userData } = useAuth();
  const [errorFields, setErrorFields] = useState<string[]>([
    'currentPassword',
    'newPassword'
  ]);

  const form = useForm<ResetPasswordModalType>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all'
  });

  const onSubmit = async (formValues: ResetPasswordModalType) => {
    if (!userData?.id) return;

    setLoadingSubmit(true);
    try {
      await HTTP.post(`/users/${userData.id}/edit-password`, {
        currentPassword: formValues.currentPassword,
        newPassword: formValues.newPassword
      });
      toast({
        status: 'success',
        position: 'top',
        title: t('forgot-password:success'),
        description: t('forgot-password:successReset')
      });
      props.onClose();
      form.reset();
      setLoadingSubmit(false);
    } catch (error: any) {
      const violations = error?.data?.violations;
      violations?.length &&
        errorsFormBuilder({
          violations,
          fields: errorFields,
          useFormInstance: form
        });
      setLoadingSubmit(false);
    }
  };

  const newPassword = useRef({});
  newPassword.current = form.watch('newPassword', '');

  return (
    <Modal isCentered {...props}>
      <ModalOverlay />
      <ModalContent
        maxWidth={['90%', '90%', '40rem']}
        paddingX={['1rem', '1rem', '3rem']}
        paddingY="3rem"
        border="1px solid"
        borderColor="whiteAlpha.300"
        borderRadius="1.25rem"
      >
        <ModalCloseButton />
        <ModalHeader>
          <Text textStyle="h3" as="h3" textAlign="center">
            {t('forgot-password:changePassword')}
          </Text>
        </ModalHeader>
        <Form
          stackProps={{
            mt: '2rem',
            spacing: '2rem'
          }}
          onSubmit={onSubmit}
          {...form}
        >
          <Input
            id="currentPassword"
            type="password"
            forceTextType={false}
            fontSize="1.2rem"
            padding="1.5rem"
            label={t('forgot-password:password')}
            name="currentPassword"
            validation={{
              required: t('forgot-password:passwordRequired')
            }}
          />
          <Input
            id="newPassword"
            type="password"
            forceTextType={false}
            fontSize="1.2rem"
            padding="1.5rem"
            marginTop="3rem"
            label={t('forgot-password:enterNewPassword')}
            name="newPassword"
            validation={{
              required: t('forgot-password:passwordRequired'),
              minLength: {
                value: 8,
                message: t('forgot-password:passwordAtLeast8')
              }
            }}
          />
          <Input
            id="confirmPassword"
            type="password"
            forceTextType={false}
            fontSize="1.2rem"
            padding="1.5rem"
            label={t('forgot-password:confirmPassword')}
            name="confirmPassword"
            validation={{
              required: t('forgot-password:passwordRequired'),
              validate: (value) =>
                value === newPassword.current ||
                t('forgot-password:passwordDoNotMatch')
            }}
          />
          <Button
            data-testid="submit-password-change"
            type="submit"
            onClick={form.handleSubmit(onSubmit)}
            backgroundColor="buttonPrimary"
            isLoading={loadingSubmit}
          >
            {t('common:confirm')}
          </Button>
        </Form>
      </ModalContent>
    </Modal>
  );
};

export default ResetPasswordModal;
