import { useLoginModal } from '@/context/LoginModal';
import { Button, ButtonProps, HStack, StackProps } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import RegisterButton from './RegisterButton';
import useMediaQuery from '@/hooks/useMediaQuery';
import { toRem } from '@/helpers/toRem';

type SignInAndRegisterProps = StackProps & {
  isCustomHeader?: boolean;
  showRegisterButton?: boolean;
  showLoginButton?: boolean;
};

const SignInButton = ({ ...props }: ButtonProps) => {
  const { t } = useTranslation();
  const { onOpen } = useLoginModal();
  return (
    <Button
      data-testid="signIn-button"
      onClick={onOpen}
      alignSelf="flex-end"
      spacing="0"
      align="baseline"
      variant="outline"
      colorScheme="neutral"
      paddingY="1rem !important"
      minHeight={toRem(36)}
      h={toRem(36)}
      {...props}
    >
      {t('common:signIn')}
    </Button>
  );
};

export const SignInAndRegisterButtons = ({
  isCustomHeader = false,
  showRegisterButton = true,
  showLoginButton = true,
  ...props
}: SignInAndRegisterProps) => {
  const isMobile = useMediaQuery('(max-width: md)');
  return (
    <HStack className="hide" {...props}>
      {showLoginButton && (
        <SignInButton
          paddingX={
            isMobile && isCustomHeader ? '2.5rem !important' : '1rem !important'
          }
        />
      )}
      {showRegisterButton && (
        <RegisterButton
          paddingX={
            isMobile && isCustomHeader ? '2.5rem !important' : '1rem !important'
          }
        />
      )}
    </HStack>
  );
};

export default SignInButton;
