import { useAuth } from '@/context/Auth';
import { useFilters } from '@/context/Filters';
import { useExtraProps } from '@/pages/_app';
import {
  Box,
  HStack,
  Icon,
  Link,
  StackProps,
  Text,
  VStack
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { useRef } from 'react';
import { GameImage, ScrollWithoutScrollBar } from '.';
import { useCatalog } from '../../context/Catalog';
import { HeartIcon, EyeIcon, UserIcon } from '../../theme/Icons';
import NoGameFallback from '../Fallback/NoGameFallback';
import {
  MotionGrid,
  MotionHStack,
  rightToLeftChildrenElementAnimation
} from '../Motion';
import NewGame from '../Tag/NewGame';
import { GameSubTitle, GameTitle, TextWithIcon } from './Artefacts';
import SectionDescription, { SectionTitle } from './SectionDescription';
import { SuggestedGame } from './Suggested';
import { shuffle } from 'lodash';
import { GameCatalogItem } from '@/services/getCatalog';

type FavoritesProps = StackProps & {};

export const RandomFavorites = ({ ...props }: FavoritesProps) => {
  const { filteredGames } = useFilters();
  const { isLogged, favorites } = useAuth();
  const { t } = useTranslation();
  const { licensePath } = useExtraProps();
  // console.log('{license, licensePath}: ', { license, licensePath });

  let data: GameCatalogItem | undefined;
  if (isLogged) {
    const favGames = filteredGames.filter((game) =>
      favorites.includes('/games/' + game.id)
    );
    const shuffleFavGames = shuffle(favGames);
    data = shuffleFavGames.pop();
    // console.log({ data, favorites, favGames, filteredGames, shuffleFavGames });
  }

  if (!data) return null;
  return (
    <VStack align="flex-start" width="100%" spacing="2rem" {...props}>
      <HStack width="100%">
        <Icon as={UserIcon} width={30} height={30} />
        <SectionTitle>{t('common:favorites')}</SectionTitle>
      </HStack>
      <Link href={licensePath + '/favorites'} style={{ margin: '1rem 0' }}>
        {t('common:seeAllFavoritesGames')}
      </Link>
      <SuggestedGame
        game={data}
        fullWidth
        margin={0}
        customStyle={{ margin: '.5rem 0' }}
      />
    </VStack>
  );
};

const Favorites = ({ ...props }: FavoritesProps) => {
  const { filteredGames } = useFilters();
  const { dataStore } = useCatalog();
  const { isLogged, isFavorite } = useAuth();
  const { t } = useTranslation();
  const ref = useRef(null);
  const { licensePath } = useExtraProps();
  const data = isLogged
    ? filteredGames?.filter((game) => isFavorite(game.id))?.slice(0, 15)
    : [];

  const extraProps = data.length
    ? {
        gap: '1rem',
        gridTemplateColumns: [
          'repeat(auto-fit, 20rem)',
          'repeat(auto-fit, 20rem)',
          'repeat(auto-fit, 25rem)'
        ],
        gridTemplateRows: `repeat(${Math.min(3, data.length)}, 120px)`,
        gridAutoFlow: 'column'
      }
    : {};

  return (
    <SectionDescription
      icon={UserIcon}
      title={t('common:favorites')}
      id="favorites"
      seeAllRoute={isLogged ? licensePath + '/favorites' : undefined}
      customRef={ref}
      {...props}
    >
      {data.length ? (
        <MotionGrid
          css={ScrollWithoutScrollBar}
          {...extraProps}
          overflowY="hidden"
          ref={ref}
          paddingY="1rem"
          initial={'initial'}
          transition={{
            staggerChildren: 0.15,
            delayChildren: 0.5
          }}
          whileInView={'animate'}
          viewport={{ once: true, amount: 0.7 }}
          key={data.length}
        >
          {data.map((game, index) => (
            <MotionHStack
              key={game.id}
              // layout
              // layoutId={`Favorites-${game.id}`}
              variants={rightToLeftChildrenElementAnimation}
              display="flex"
              flexDirection="row"
              spacing={8}
              width={['20rem', '20rem', '25rem']}
            >
              <GameImage
                href={`/games/${game.id}/${game.slug}`}
                src={game?.mediaUrls?.banner_desktop}
                data-testid={`favorites-${index}`}
                stackProps={{
                  width: '7.5rem',
                  minWidth: '7.5rem',
                  height: '7.5rem'
                }}
              >
                {game?.new ? (
                  <NewGame
                    position="absolute"
                    bottom="0.5rem"
                    left="0.7rem"
                    fontSize="xx-small"
                  />
                ) : (
                  <Box />
                )}
              </GameImage>
              <VStack maxWidth="13rem" flex="1" align="baseline">
                <GameSubTitle>
                  {dataStore?.providers?.[game.provider as string]?.name}
                </GameSubTitle>
                <GameTitle>{game.name}</GameTitle>
                <HStack color="whiteAlpha.600">
                  <TextWithIcon text={game.launchCount} icon={EyeIcon} />
                  <TextWithIcon text={game.favoritesCount} icon={HeartIcon} />
                </HStack>
              </VStack>
            </MotionHStack>
          ))}
        </MotionGrid>
      ) : isLogged ? (
        <NoGameFallback
          title="catalog:noFavoritesGames"
          subTitle="catalog:modifyFiltersOrAddFavorites"
        />
      ) : (
        <Text as="p" textAlign="center" textStyle="p" fontWeight="bold">
          {t('catalog:signInFavoriteGames')}
        </Text>
      )}
    </SectionDescription>
  );
};

export default Favorites;
