import React, { FC, useEffect, useState } from 'react';
import { VStack, Text, Flex, Box } from '@chakra-ui/react';

const requestDuration = '3s'; // TODO : Replace with reel upload duration

interface ProgressLinePropTypes {
  label: string;
  backgroundColor: string;
  requestDuration: string;
  visualParts: any;
}

const ProgressLine: FC<ProgressLinePropTypes> = ({
  label,
  backgroundColor,
  requestDuration,
  visualParts = [
    {
      percentage: '0%',
      color: 'white'
    }
  ]
}) => {
  const [widths, setWidths] = useState(
    visualParts.map(() => {
      return 0;
    })
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      // Set a new array of percentage widths based on the props
      setWidths(
        visualParts.map((item: { percentage: any }) => {
          return item.percentage;
        })
      );
    });
  }, [visualParts]);

  return (
    <VStack>
      <Text>{label}</Text>
      <Flex
        //className="progressVisualFull"
        height="6px"
        margin="10px 0"
        minWidth="250px"
        borderRadius="3px"
        overflow="hidden"
        style={{ backgroundColor }}
      >
        {visualParts.map((item: { color: any }, index: number) => {
          return (
            <Box
              key={index}
              width={widths[index]}
              backgroundColor={item.color}
              transition={`width ${requestDuration}`}
              //className="progressVisualPart"
            />
          );
        })}
      </Flex>
    </VStack>
  );
};

export default ProgressLine;
