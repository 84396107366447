import { Box, Button, ButtonProps, IconProps, Text } from '@chakra-ui/react';
import { BellIconStroke } from '@/theme/Icons';
import React, { useContext } from 'react';
import { NotificationContext } from '@/context/Notification';

type NotificationButtonProps = ButtonProps & {
  bellSize?: string;
  label?: string;
  bulletPosition?: { top?: string; right?: string; left?: string };
  iconProps?: IconProps;
  onClick?: () => void;
};

export const NotificationButton = ({
  label,
  bellSize = '1.5rem',
  bulletPosition = { top: '0.5rem', right: '-0.5rem' },
  onClick,
  ...props
}: NotificationButtonProps) => {
  const notificationContext = useContext(NotificationContext);
  return (
    <Button
      data-testid="open-notification-overview-button"
      id={'btn-open-notification-overview'}
      variant={'unstyled'}
      aria-label="Bell button"
      borderRadius="1rem"
      cursor="pointer"
      textAlign="center"
      position="relative"
      width={'auto'}
      height={'auto'}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
        notificationContext.setNotificationOverviewOpened(
          !notificationContext.notificationOverviewOpened
        );
      }}
      padding={0}
      display={'flex'}
      boxSizing={'border-box'}
      minWidth={'36px'}
      {...props}
    >
      <Box position="relative">
        <BellIconStroke width={bellSize} height={bellSize} />
        {notificationContext?.unreadUserNotifications?.length > 0 && (
          <Box
            background="customRed"
            borderRadius="50%"
            className="notification-counter"
            display="inline-block"
            fontSize="0.625rem"
            width=".75rem"
            height=".75rem"
            lineHeight=".75rem"
            position="absolute"
            textAlign="center"
            style={{
              textIndent: '-0.1rem'
            }}
            pl={'2px'}
            {...bulletPosition}
          >
            {notificationContext.unreadUserNotifications.length}
          </Box>
        )}

        {label ? (
          <Text
            fontWeight="semibold"
            fontSize="0.8rem"
            cursor="pointer"
            marginTop=".5rem"
          >
            {label}
          </Text>
        ) : null}
      </Box>
    </Button>
  );
};
