import GTM from '@/helpers/googleTagManager';
import { DeepPartial, useDisclosure } from '@chakra-ui/react';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import dynamic from 'next/dynamic';
import { useExtraProps } from '@/pages/_app';
import useTranslation from 'next-translate/useTranslation';
import { getFallbackLanguage } from '@/helpers/lang';
import { BannerType } from '@/types/api/ge-strapi/banner';
import { useAppSettings } from '@/context/AppSettings';
import useMediaQuery from '@/hooks/useMediaQuery';
import { strapiImageUrl } from '@/helpers/url';

const LoginModal = dynamic(() =>
  import('@/components/index').then((mod) => mod.LoginModal)
);

type LoginModalContextType = {
  onOpen: () => void;
  onClose: () => void;
  isOpen: boolean;
  isModalClosed?: boolean;
  setIsModalClosed?: Dispatch<SetStateAction<boolean>>;
  backgroundUrl: string | undefined;
};

export const LoginModalContext = createContext<LoginModalContextType>({
  onClose: () => {},
  onOpen: () => {},
  isOpen: false,
  backgroundUrl: undefined
});

type FetchDataType = {
  bannerLang: string;
  license: string;
  imageData: DeepPartial<BannerType> | undefined;
};

export const LoginModalProvider = ({ children }: PropsWithChildren) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { license } = useExtraProps();
  const isMobile = useMediaQuery('(max-width: md)');
  const { lang } = useTranslation();
  const [isModalClosed, setIsModalClosed] = useState<boolean>(false);
  const [fetchData, setFetchData] = useState<FetchDataType | undefined>(
    undefined
  );
  const config = useAppSettings();
  const enableLoginBackgroundFetch: boolean =
    config?.customStyle?.enableLoginBackgroundFetch ?? false;

  useEffect(() => {
    if (!enableLoginBackgroundFetch || !license) return;

    const bannerLang = getFallbackLanguage(lang);
    const isAlreadyFetched =
      fetchData?.bannerLang === bannerLang && fetchData?.license === license;

    if (!isAlreadyFetched) {
      const loginBannerFetcher = async (
        bannerLang: string,
        license: string
      ) => {
        try {
          const data = await fetch(
            `/api/cms/banner?locale=${bannerLang}&licences=${license}&positions=login&random=true`
          );
          const result: DeepPartial<BannerType>[] = await data.json();
          setFetchData({ bannerLang, license, imageData: result?.[0] });
        } catch (err) {
          setFetchData({
            bannerLang,
            license,
            imageData: undefined
          });
          //console.error('loginBannerFetcher - error', err);
        }
      };
      void loginBannerFetcher(bannerLang, license);
    }
  }, [enableLoginBackgroundFetch, license, lang, fetchData]);

  const onCloseModal = () => {
    setIsModalClosed(true);
  };

  const backgroundUrl = useMemo(() => {
    if (!fetchData?.imageData) return undefined;

    return strapiImageUrl(
      isMobile
        ? fetchData.imageData?.attributes?.pictureMobile?.data?.attributes.url
        : fetchData.imageData?.attributes?.picture?.data?.attributes.url
    );
  }, [isMobile, fetchData]);

  return (
    <LoginModalContext.Provider
      value={{
        onClose,
        onOpen: () => {
          onOpen();
          GTM.formEvent('login_open');
        },
        isOpen,
        isModalClosed,
        setIsModalClosed,
        backgroundUrl
      }}
    >
      {children}
      <LoginModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        callback={(user) => {
          GTM.formEvent('login', undefined, 'login-modal-form', user);
        }}
        onCloseModal={onCloseModal}
      />
    </LoginModalContext.Provider>
  );
};

export const useLoginModal = () => useContext(LoginModalContext);
