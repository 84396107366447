import { useFilters } from '@/context/Filters';
import {
  Button,
  HStack,
  Menu,
  MenuButtonProps,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react';
import { MotionProps } from 'framer-motion';
import { useCatalog } from '../../context/Catalog';
import { ChevronIcon } from '../../theme/Icons';
import { MotionMenuButton } from '../Motion';
import { Category } from './types';

type CategoriesProps = MotionProps | MenuButtonProps;

const CategoriesDropdown = ({ ...props }: CategoriesProps) => {
  const { dataStore } = useCatalog();
  const { filterFields, setFilterFields, countPerCat } = useFilters();
  const countCategory = (category: Category) => {
    return category.id === 'all'
      ? countPerCat.total
      : countPerCat[`/categories/${category.id}`];
  };
  const categories = [
    { id: 'all', name: 'all' } as Category,
    ...Object.values(dataStore.categories || {})
  ];
  return (
    <Menu>
      <MotionMenuButton
        variant="ghost"
        as={Button}
        border="1px solid"
        borderColor="whiteAlpha.600"
        fontWeight="bold"
        rightIcon={<ChevronIcon width={30} height={30} />}
        minWidth="10rem"
        {...props}
      >
        <HStack>
          <Text width="99.9%" noOfLines={0}>
            {filterFields.category.name}
          </Text>
          <Text>({countCategory(filterFields.category)})</Text>
        </HStack>
      </MotionMenuButton>
      <MenuList
        borderColor="whiteAlpha.600"
        className="blurry"
        backgroundColor="backgroundPrimary"
      >
        {categories.map((category) => (
          <MenuItem
            key={category.id}
            onClick={() =>
              setFilterFields({
                ...filterFields,
                category
              })
            }
            fontWeight="bold"
          >
            <HStack>
              <Text width="99.9%" noOfLines={0}>
                {category.name}
              </Text>
              <Text>({countCategory(category)})</Text>
            </HStack>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
export default CategoriesDropdown;
