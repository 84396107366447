import {
  Button,
  HStack,
  Icon,
  IconButton,
  Stack,
  StackProps,
  Text,
  TextProps
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { PropsWithChildren, RefObject } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { ArrowLeft, ArrowRight } from '../../theme/Icons';
import {
  bottomUPChildrenElementAnimation,
  MotionHStack,
  MotionStack,
  MotionText
} from '../Motion';

type SectionDescriptionProps = PropsWithChildren &
  StackProps & {
    title?: string;
    seeAllRoute?: string;
    icon?: any;
    subTitle?: string;
    withControl?: boolean;
    customRef?: RefObject<HTMLElement>;
  };

type SectionTitleProps = PropsWithChildren &
  TextProps & {
    withControl?: boolean;
  };

export const SectionTitle = ({
  withControl = true,
  children,
  ...props
}: SectionTitleProps) => {
  return (
    <Text
      as="h2"
      textTransform="uppercase"
      fontWeight="bold"
      fontSize="xl"
      {...props}
    >
      {children}
    </Text>
  );
};

const SectionDescription = ({
  title,
  icon,
  seeAllRoute,
  subTitle,
  children,
  withControl = true,
  customRef,
  ...props
}: SectionDescriptionProps) => {
  const isMobile = useMediaQuery('(max-width: md)');
  const { t } = useTranslation();

  const onPrevClick = () => {
    if (!customRef?.current) return;
    const left = 0;
    customRef?.current?.scrollBy({
      left: left - 600,
      behavior: 'smooth'
    });
  };

  const onNextClick = () => {
    const left = 0;
    customRef?.current?.scrollBy({
      left: left + 600,
      behavior: 'smooth'
    });
  };

  return (
    <Stack
      flexDirection={isMobile || !subTitle ? 'column' : 'row'}
      alignItems="stretch"
      as="section"
      width="100%"
      id={title?.split(' ')?.join('')?.toLowerCase()}
      {...props}
    >
      <MotionStack
        flexDirection={isMobile || !subTitle ? 'row' : 'column'}
        justifyContent="space-between"
        height="auto"
        minWidth="15rem"
        width={isMobile || !subTitle ? '100%' : 'initial'}
        paddingRight="2rem !important"
        align="baseline"
        transition={{
          staggerChildren: 0.15,
          delayChildren: 0.5
        }}
        initial={'initial'}
        whileInView={'animate'}
        viewport={{ once: true, amount: 0.7 }}
      >
        {icon && title && (
          <MotionHStack
            variants={bottomUPChildrenElementAnimation}
            width="100%"
            mb="1rem"
            justifyContent="space-between"
            {...props}
          >
            <HStack>
              <Icon as={icon} width={30} height={30} />
              <SectionTitle>{title || ''}</SectionTitle>
            </HStack>
            {isMobile && seeAllRoute && (
              <Link href={seeAllRoute} passHref>
                <Button as="a" variant={'outline'}>
                  {t('catalog:seeAll')}
                </Button>
              </Link>
            )}
          </MotionHStack>
        )}
        {!isMobile && (
          <>
            <MotionText
              variants={bottomUPChildrenElementAnimation}
              as="h3"
              fontWeight="500"
              fontSize="2xl"
            >
              {subTitle}
            </MotionText>
            {withControl && (
              <MotionStack
                variants={bottomUPChildrenElementAnimation}
                flexDirection={isMobile || !subTitle ? 'row' : 'column'}
                spacing={isMobile || !subTitle ? 0 : '2rem'}
              >
                {/* <HStack>
                  <IconButton
                    onClick={onPrevClick}
                    variant="ghost"
                    aria-label="go left"
                    _focus={{
                      color: '#fff'
                    }}
                    icon={<ArrowLeft width={30} />}
                  />
                  <IconButton
                    onClick={onNextClick}
                    variant="ghost"
                    aria-label="go left"
                    _focus={{
                      color: '#fff'
                    }}
                    icon={<ArrowRight width={30} />}
                  />
                </HStack> */}
                {!isMobile && seeAllRoute && (
                  <Link href={seeAllRoute} passHref>
                    <Button as="a" variant="outline">
                      {t('catalog:seeAll')}
                    </Button>
                  </Link>
                )}
              </MotionStack>
            )}
          </>
        )}
      </MotionStack>

      {children}
    </Stack>
  );
};
export default SectionDescription;
