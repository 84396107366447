import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig() || {};

export const FALLBACK_LANGUAGE =
  publicRuntimeConfig?.currentAppConfig.fallbackLanguage;

export const getFallbackLanguage = (locale?: string | undefined) => {
  const initialLanguages = ['fr', 'en', 'nl'];
  return locale && initialLanguages.includes(locale)
    ? locale
    : FALLBACK_LANGUAGE;
};
