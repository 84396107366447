import { Grid } from '@chakra-ui/react';
import { FC } from 'react';
import { GameImage } from '../GamesCatalog';
interface FilterProviderProps {
  data: { [key in string]: Provider };
  onChange: (value: Provider) => void;
  selectedValue: any;
}

type ProviderCardProps = {
  provider: Provider;
  onChange: (value: Provider) => void;
  selectedValue: any;
};

const ProviderCard = ({
  provider,
  onChange,
  selectedValue
}: ProviderCardProps) => {
  return (
    <GameImage
      cursor="pointer"
      objectFit="fill"
      borderRadius="0"
      src={provider?.mediaUrls?.logo || 'undefined'}
      data-testid={provider?.name}
      stackProps={{
        overflow: 'hidden',
        borderRadius: '0.5rem',
        width: '7rem',
        height: '4.5rem',
        border:
          selectedValue?.id === provider.id
            ? '2px solid buttonPrimary'
            : '2px solid transparent'
      }}
      onClick={() => onChange(provider)}
    />
  );
};

export type Provider = {
  id: string;
  mediaUrls: {
    banner?: string | null;
    logo?: string | null;
  };
  name: string;
};

const FilterProvider: FC<FilterProviderProps> = ({
  data,
  onChange,
  selectedValue
}) => {
  return (
    <Grid
      gap="1rem"
      gridTemplateColumns="repeat(auto-fit, 7rem)"
      gridTemplateRows="repeat(auto-fit, 4.5rem)"
      placeItems="center"
    >
      {Object.keys(data)?.map((iri) => (
        <ProviderCard
          key={data[iri].id}
          provider={data[iri]}
          selectedValue={selectedValue}
          onChange={onChange}
        />
      ))}
    </Grid>
  );
};

export default FilterProvider;
