import { useFilters } from '@/context/Filters';
import { toRem } from '@/helpers/toRem';
import { Checkbox, HStack, Tag, Text } from '@chakra-ui/react';

type FilterCheckboxListProps = {
  data: any;
  onChange: (data: string[]) => void;
  selectedValue: string[];
};

const FilterCheckboxList = ({
  data,
  onChange,
  selectedValue = []
}: FilterCheckboxListProps) => {
  const { countByProvider } = useFilters();
  // sanity check: convert to string[]
  const membersArrayToString = (array: any[] | { '@id': string }) => {
    return Array.isArray(array)
      ? array.map((item) => String(item))
      : array['@id']
      ? [array['@id']]
      : [];
  };
  const handleSelection = (iri: string) => {
    const sanitizedValue = membersArrayToString(selectedValue);
    const isDuplicate = sanitizedValue.includes(iri);

    let selectedData;

    if (isDuplicate) {
      const selectedWithoutCurrent = membersArrayToString(selectedValue).filter(
        (selectedTheme: string) => selectedTheme !== iri
      );
      onChange(selectedWithoutCurrent);
      selectedData = selectedWithoutCurrent;
    } else {
      selectedData = [...sanitizedValue, iri];
      onChange(selectedData);
    }
  };

  return (
    <HStack
      as="ul"
      width="100%"
      wrap="wrap"
      spacing="0"
      alignItems="flex-start"
      flexDirection="column"
    >
      {Object.keys(data)?.map((key: string) => {
        return (
          <Checkbox
            key={data[key]['@id']}
            id={data[key]['@id']}
            onChange={() => handleSelection(key)}
            isChecked={membersArrayToString(selectedValue)?.includes(key)}
            justifyContent="flex-start"
            width="auto"
            colorScheme="brand"
            style={{ marginBottom: '1rem' }}
          >
            <Text
              as="label"
              htmlFor={data[key]['@id']}
              fontWeight="500"
              fontSize={toRem(18)}
              width={'auto'} // prevent a bug in chakra-ui because a collusion of event between label with a 100% width and document scroll
            >
              {data[key].name}
              {data[key]['@type'] === 'Provider' ? (
                <sup
                  style={{
                    marginLeft: '.5rem',
                    fontSize: toRem(11),
                    fontWeight: '400'
                  }}
                >
                  {countByProvider?.[data[key]['@id']]}
                </sup>
              ) : null}
            </Text>
          </Checkbox>
        );
      })}
    </HStack>
  );
};

export default FilterCheckboxList;
