import WithFeaturesDisable from '@/hoc/WithFeaturesDisable';
import { StackProps, Text, VStack } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';

const DynamicPlanetSatelliteIcon = dynamic(() =>
  import('@/theme/Icons').then((mod) => mod.PlanetSatelliteIcon)
);

const DynamicGoldenPlanetIcon = dynamic(() =>
  import('@/theme/Icons').then((mod) => mod.GoldenPlanetIcon)
);

type NoGameFallbackProps = StackProps & {
  title?: string;
  subTitle?: string;
};

const NoGameFallback = ({
  title = 'common:NoGames',
  subTitle = 'catalog:modifyFilters',
  ...props
}: NoGameFallbackProps) => {
  const { t } = useTranslation();
  return (
    <VStack
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      textAlign="center"
      spacing="1rem"
      zIndex="10"
      data-testid="no-game-fallback"
      {...props}
    >
      <>
        {WithFeaturesDisable(
          <DynamicGoldenPlanetIcon width={150} height={150} />,
          'NoGameGoldenPlanet'
        )}
        {WithFeaturesDisable(
          <DynamicPlanetSatelliteIcon width={150} height={150} />,
          'NoGameOriaPlanet'
        )}
      </>
      <Text fontWeight="bold" fontSize="1.8rem">
        {t(title)}
      </Text>
      <Text>{t(subTitle)}</Text>
    </VStack>
  );
};

export default NoGameFallback;
