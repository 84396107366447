import { useAppSettings } from '@/context/AppSettings';
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  UseModalProps
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

const ChangeEmailModal = ({ ...props }: UseModalProps) => {
  const { t } = useTranslation();
  const config = useAppSettings();

  return (
    <Modal isCentered {...props}>
      <ModalOverlay />
      <ModalContent
        maxWidth={['90%', '90%', '40rem']}
        paddingX={['1rem', '1rem', '3rem']}
        paddingY="3rem"
        border="1px solid"
        borderColor="whiteAlpha.300"
        borderRadius="1.25rem"
        alignItems="flex-start"
      >
        <ModalCloseButton />
        <Text>
          {t('account:changeEmailDescription')}{' '}
          <Button variant="link" as="a" href={`mailto:${config?.supportEmail}`}>
            {config?.supportEmail}
          </Button>
        </Text>
      </ModalContent>
    </Modal>
  );
};

export default ChangeEmailModal;
