import { LicenceType } from '@/utils/multiDomains';
import { useRouter } from 'next/router';
import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { GameCatalogItem, GameDataStore } from '@/services/getCatalog';

type CatalogProviderPropsType = PropsWithChildren & {
  games: GameCatalogItem[];
  dataStore: GameDataStore;
  licence?: LicenceType;
};

type CatalogProviderContextType = {
  games: GameCatalogItem[];
  dataStore: GameDataStore;
  goToGameInfo?: ({ id, slug }: any) => void;
  licence?: LicenceType | string;
  //gamesAvailableInTournaments?: Map<string, { id: string; slug: string }[]>;
};

export const CatalogContext = createContext<CatalogProviderContextType>({
  games: [],
  dataStore: {
    bonuses: {},
    categories: {},
    features: {},
    jackpots: {},
    providers: {},
    themes: {},
    sections: {},
    highlightedTournaments: {}
  },
  licence: ''
});

export const CatalogProvider: FC<CatalogProviderPropsType> = ({
  children,
  games,
  dataStore,
  licence
}) => {
  const router = useRouter();
  // const { isTournamentEnabled } = useAppSettings();

  const goToGameInfo = ({ id, slug }: any) => {
    router.push(`games/${id}/${slug}`);
  };

  return (
    <CatalogContext.Provider
      value={{
        games,
        dataStore,
        goToGameInfo,
        licence
        // gamesAvailableInTournaments: getGamesAvailableInTournaments({
        //   dataStore,
        //   enabled: isTournamentEnabled
        // })
      }}
    >
      {children}
    </CatalogContext.Provider>
  );
};
// export directly context with the useContext hooks
export const useCatalog = () => useContext(CatalogContext);
