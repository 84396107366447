import { useCallback, useEffect, useRef } from 'react';
import {
  Notification,
  useNotification,
  UserNotification
} from '@/context/Notification';
import { JackpotHitType, JackpotType } from '@/types/api/ge-jackpot/jackpot';
import { HTTP } from '@/components';
import { useJackpots } from '@/context/Jackpots';
import { debounce } from 'lodash';

export enum EventMessageType {
  GENERAL_NOTIFICATIONS = 'general_notifications',
  UPDATE_NOTIFICATION = 'update_notification',
  DELETE_NOTIFICATION = 'delete_notification',
  USER_NOTIFICATION = 'user_notification',
  CAMPAIGN_DELETED = 'campaign_deleted',
  JACKPOT_HIT = 'jackpot_hit',
  JACKPOT_UPDATE = 'jackpot_update'
}

type EventResponse =
  | {
      type:
        | EventMessageType.GENERAL_NOTIFICATIONS
        | EventMessageType.UPDATE_NOTIFICATION
        | EventMessageType.DELETE_NOTIFICATION;
      data: Notification;
    }
  | {
      type: EventMessageType.USER_NOTIFICATION;
      data: UserNotification;
    }
  | {
      type: EventMessageType.CAMPAIGN_DELETED;
      data: { id: string };
    }
  | {
      type: EventMessageType.JACKPOT_HIT;
      data: JackpotHitType;
    }
  | {
      type: EventMessageType.JACKPOT_UPDATE;
      data: JackpotType;
    };

const apiUrl = HTTP?.defaults?.baseURL || '';
const debug = false;

const SSE = () => {
  const notificationContext = useNotification();
  const jackpotsContext = useJackpots();
  const jackpotsRef = useRef<JackpotType[] | undefined>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedRefresh = useCallback(
    debounce(() => jackpotsContext.setJackpotUpdateTime(Date.now()), 5000),
    []
  );

  useEffect(() => {
    jackpotsRef.current = jackpotsContext.jackpots;
  }, [jackpotsContext.jackpots]);

  useEffect(() => {
    const sse = new EventSource(`${apiUrl}/events/stream`, {
      withCredentials: true
    });

    if (sse) {
      sse.onopen = (event) => {
        debug && console.log(`SSE connection opened`);
      };

      sse.onmessage = (event) => {
        const response: EventResponse = JSON.parse(event.data);
        debug && console.log('SSE - response : ->', response);

        switch (response.type) {
          case EventMessageType.GENERAL_NOTIFICATIONS:
          case EventMessageType.UPDATE_NOTIFICATION:
          case EventMessageType.DELETE_NOTIFICATION:
            notificationContext.handleGeneralNotificationMessage(response.data);
            break;
          case EventMessageType.USER_NOTIFICATION:
            notificationContext.addUserNotification(response.data);
            break;
          case EventMessageType.CAMPAIGN_DELETED:
            notificationContext.removeCampaignNotifications(response.data);
            break;
          case EventMessageType.JACKPOT_UPDATE:
            if (jackpotsContext.enabled) {
              // replace existing jackpot or add new one
              const jackpotList = [...(jackpotsRef.current || [])];
              const where = jackpotList.findIndex(
                ({ publicId }) => publicId === response.data.publicId
              );
              if (where === -1) {
                jackpotList.push(response.data);
                debouncedRefresh();
              } else {
                jackpotList[where] = response.data;
              }
              jackpotsContext.setJackpots(jackpotList);
            }
            break;
          case EventMessageType.JACKPOT_HIT:
            if (jackpotsContext.enabled) {
              jackpotsContext.addJackpotHit(response.data);
            }
            break;
          default:
            break;
        }
      };
      sse.onerror = (err) => {
        debug && console.error(err);
      };
    }

    return () => {
      debug && console.log('Closing SSE connection');
      sse?.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default SSE;
