import {
  CloseIcon,
  FullScreenIcon,
  SmallScreenIcon,
  UserIcon
} from '@/theme/Icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Grid,
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import React, { useMemo, useRef, useState } from 'react';
import { ComingSoon } from '..';
import { InGameNavBar } from '../Navbar/InGameNavBar';
import { GameCatalogItem } from '@/services/getCatalog';

const MotionVStack = motion(VStack);

type GameIframeProps = {
  url?: string;
  games?: GameCatalogItem;
  onClose: () => void;
  isOpen: boolean;
  srcContent?: string;
};

const GameIframe = ({
  url,
  onClose,
  isOpen,
  games,
  srcContent
}: GameIframeProps) => {
  const { t } = useTranslation();
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isMobileMemo = useMemo(
    () => window?.matchMedia?.('(max-width: 62rem)').matches,
    []
  );

  const [percent, setPercent] = useState(80);

  const getDynamicButtonPosition = (margin: string) => {
    const defaultPosition = 'top_right';
    const positions = {
      top_right: {
        top: margin,
        right: margin
      },
      top_left: {
        top: margin,
        left: margin
      },
      top_center: {
        top: margin
      },
      bottom_center: {
        bottom: margin
      },
      bottom_left: {
        bottom: margin,
        left: margin
      },
      bottom_right: {
        bottom: margin,
        right: margin
      }
    };
    return positions[
      games?.settings?.controlButtonGroupPosition || defaultPosition
    ];
  };
  const {
    isOpen: alertIsOpen,
    onOpen: alertOnOpen,
    onClose: alertOnClose
  } = useDisclosure();

  const {
    isOpen: drawerIsOpen,
    onOpen: drawerOnOpen,
    onClose: drawerOnClose
  } = useDisclosure();

  const cancelRef = useRef(null);

  const confirmQuitGame = () => {
    onClose();
    alertOnClose;
  };

  const handleSetFullScreen = () => setFullScreen(!fullScreen);
  const memoizedIframe = React.useMemo(
    () => (
      <Box
        as="iframe"
        width="100%"
        height="100%"
        src={srcContent ? '' : url}
        srcDoc={srcContent ? srcContent : undefined}
        allowFullScreen={true}
      ></Box>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url]
  );

  const openMobileDrawer = () => {
    if (percent === 0) {
      setPercent(80);
    } else {
      setPercent(0);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={alertOnOpen}
      onOverlayClick={alertOnOpen}
      onEsc={alertOnOpen}
      trapFocus={false}
      size="full"
    >
      <ModalOverlay />
      <ModalContent
        position="relative"
        margin="0"
        height="100%"
        width="100%"
        as={VStack}
        spacing="0"
        borderRadius={[0, 'initial']}
      >
        {isMobileMemo ? (
          <>
            {memoizedIframe}
            <IconButton
              zIndex="9999"
              position="absolute"
              borderRadius="lg"
              {...getDynamicButtonPosition('10px')}
              aria-label="close icon"
              backgroundColor="customGrey.900"
              onClick={alertOnOpen}
              size="sm"
              icon={<CloseIcon width={3} height={3} />}
            />

            <MotionVStack
              // display="flex"
              display="none"
              position="fixed"
              bottom="0"
              align="baseline"
              transform={`translateY(${percent}%)`}
              backgroundColor="backgroundPrimaryDarker"
              paddingY="0.7rem"
              width="100vw"
            >
              <Box
                zIndex="9999"
                backgroundColor="white"
                width="4.2rem"
                height="0.4rem"
                borderRadius="2rem"
                alignSelf="center"
                onClick={openMobileDrawer}
              />
              <Box
                zIndex="9999"
                paddingY="2rem"
                paddingLeft="1rem"
                paddingRight="0"
                width="100%"
              >
                <IconButton
                  borderRadius="lg"
                  aria-label="open favorites icon"
                  backgroundColor="customGrey.900"
                  size="lg"
                  minWidth="4rem"
                  onClick={drawerIsOpen ? drawerOnClose : drawerOnOpen}
                  icon={<UserIcon width={10} height={10} />}
                />
              </Box>
            </MotionVStack>
          </>
        ) : (
          <>
            <InGameNavBar marginBottom="0" />

            <Grid
              width="100%"
              height="100%"
              position="relative"
              placeItems="center"
            >
              {fullScreen && (
                <Box
                  position="absolute"
                  {...getDynamicButtonPosition('20px')}
                  zIndex={9999}
                >
                  <IconButton
                    borderRadius="lg"
                    aria-label="small screen icon"
                    backgroundColor="customGrey.900"
                    onClick={() => setFullScreen(!fullScreen)}
                    icon={<SmallScreenIcon width={4} height={4} />}
                    marginRight="10px"
                  />
                  <IconButton
                    borderRadius="lg"
                    aria-label="close icon"
                    backgroundColor="customGrey.900"
                    onClick={alertOnOpen}
                    icon={<CloseIcon width={4} height={4} />}
                  />
                </Box>
              )}
              <Box
                width={fullScreen ? '100%' : '75%'}
                height={fullScreen ? '100%' : '75%'}
                position="relative"
              >
                {memoizedIframe}
                {!fullScreen && (
                  <>
                    <IconButton
                      zIndex="9999"
                      position="absolute"
                      borderRadius="lg"
                      top="10px"
                      right="-50px"
                      aria-label="close icon"
                      backgroundColor="customGrey.900"
                      onClick={alertOnOpen}
                      icon={<CloseIcon width={4} height={4} />}
                    />
                    <IconButton
                      zIndex="9999"
                      position="absolute"
                      borderRadius="lg"
                      top="60px"
                      right="-50px"
                      aria-label="full screen icon"
                      backgroundColor="customGrey.900"
                      onClick={handleSetFullScreen}
                      icon={<FullScreenIcon width={4} height={4} />}
                    />
                    {/* <IconButton
                      zIndex="9999"
                      position="absolute"
                      borderRadius="lg"
                      bottom="-60px"
                      left="0"
                      size="lg"
                      minWidth="4rem"
                      aria-label="open favorites icon"
                      backgroundColor="customGrey.900"
                      onClick={drawerOnOpen}
                      icon={<UserIcon width={10} height={10} />}
                    /> */}
                  </>
                )}
              </Box>
            </Grid>
          </>
        )}
      </ModalContent>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={alertOnClose}
        isOpen={alertIsOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>{t('detail:closeGame')}</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{t('detail:sureCloseGame')}</AlertDialogBody>
          <AlertDialogFooter>
            <Button variant="outline" ref={cancelRef} onClick={alertOnClose}>
              {t('common:no')}
            </Button>
            <Button onClick={confirmQuitGame} colorScheme="red" ml={3}>
              {t('common:yes')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <Drawer
        onOverlayClick={drawerOnClose}
        onEsc={drawerOnClose}
        isOpen={drawerIsOpen}
        placement="bottom"
        onClose={drawerOnClose}
        size="4xl"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody padding="2rem" paddingLeft={['1rem', '1rem', '9rem']}>
            <ComingSoon />
            {/* <Suggested icon={UserIcon} title="Favorites" games={games} /> */}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Modal>
  );
};

export default GameIframe;
