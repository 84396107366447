import InputError from '@/components/Form/InputError';
import { useWallet } from '@/context/Wallet';
import { CurrencyBase, useFormatCurrency } from '@/hooks/useFormat';
import { MoneyIcon } from '@/theme/Icons';
import GTM from '@/helpers/googleTagManager';
import { debounce } from 'lodash';
import {
  Box,
  Button,
  Divider,
  Grid,
  HStack,
  Text,
  VStack,
  Input,
  FormControl,
  FormHelperText,
  NumberInput,
  NumberInputField
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useEffect, useMemo } from 'react';
import { PayloadFastDeposit } from '.';
import { useAuth } from '@/context/Auth';
export type FaceDepositStep1 = {
  form: PayloadFastDeposit;
  setForm: React.Dispatch<React.SetStateAction<PayloadFastDeposit>>;
  handleRequest: () => void;
  isLoading: boolean;
  error?: string;
  onCbBlur: () => void;
};

const FastDepositStep1 = ({
  form,
  setForm,
  handleRequest,
  isLoading,
  error,
  onCbBlur
}: FaceDepositStep1) => {
  const { userData } = useAuth();
  const { balance } = useWallet();
  const { t, lang } = useTranslation();
  const BoxMotion = useMemo(() => motion(Box), []);
  const debouncedChooseAmountOfDeposit = useMemo(
    () =>
      debounce((amount: number) => {
        GTM.ecommerceEvent(
          'add_to_cart',
          amount * CurrencyBase.EURO,
          { method: 'FASTDEPOSIT', provider: 'HIPAY' },
          userData,
          balance,
          !userData?.alreadyDeposited
        );
      }, 300),
    [userData, balance]
  );

  const predefinedAmounts = [
    {
      value: 10,
      label: useFormatCurrency({
        amount: 10,
        maximumFractionDigits: 2,
        currencyBase: CurrencyBase.CENT
      })
    },
    {
      value: 20,
      label: useFormatCurrency({
        amount: 20,
        maximumFractionDigits: 2,
        currencyBase: CurrencyBase.CENT
      })
    },
    {
      value: 50,
      label: useFormatCurrency({
        amount: 50,
        maximumFractionDigits: 2,
        currencyBase: CurrencyBase.CENT
      })
    }
  ];
  const isErrorStyle = {
    border: '1px solid red'
  };
  const getCookie = (name: string) => {
    const value = `; ${window.document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return (parts.pop() as string).split(';').shift();
  };

  // cookie isn't reactive, so put it to useEffect to trigger a state changes
  useEffect(() => {
    setForm((form) => ({
      ...form,
      language: getCookie('NEXT_LOCALE') || lang
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <BoxMotion
      initial={{ opacity: 0, x: 2000 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{
        x: -2000,
        duration: 0.7,
        opacity: 0
      }}
      transition={{
        ease: 'linear',
        duration: 0.4
      }}
      data-testid="FastDepositStep1"
    >
      <VStack marginBottom={'1rem'} marginTop="1rem">
        <MoneyIcon width={30} height={30} />
        <Text fontWeight={'bold'}>{t('wallet:fastDepositAmountTitle')}</Text>
        <Text fontSize={'.7rem'} textAlign="center">
          {t('wallet:fastDepositAmountChoice')}
        </Text>
        <Grid width="100%" gridTemplateColumns="repeat(3, 1fr)" gridGap="1rem">
          {predefinedAmounts.map((amount) => {
            return (
              <Button
                key={amount.label}
                paddingLeft="2rem"
                paddingRight="2rem"
                fontSize={['.9rem']}
                border="1px solid"
                borderColor="whiteAlpha.300"
                borderRadius=".5rem"
                color={form.amount === amount.value ? 'white' : '#1F2544'}
                backgroundColor={
                  form.amount === amount.value ? 'buttonPrimary' : 'white'
                }
                _hover={{
                  backgroundColor: 'buttonPrimary',
                  color: 'white'
                }}
                onClick={() => {
                  setForm((form) => ({
                    ...form,
                    amount: amount.value
                  }));
                  debouncedChooseAmountOfDeposit(amount.value);
                }}
              >
                {amount.label}
              </Button>
            );
          })}
        </Grid>

        <FormControl paddingTop={'1rem'} paddingBottom={'1rem'}>
          <NumberInput precision={2} step={0.2} value={form.amount}>
            <NumberInputField
              placeholder={t('wallet:fastDepositEnterAmount')}
              _placeholder={{ fontSize: '1rem' }}
              name="amount"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = e.target;
                setForm((form) => ({
                  ...form,
                  amount: value
                }));
                debouncedChooseAmountOfDeposit(+value);
              }}
              value={form.amount}
              onBlur={onCbBlur}
              max={balance?.depositLimit}
              style={!!error ? isErrorStyle : {}}
            />
          </NumberInput>
          {!!error ? (
            <FormHelperText whiteSpace="pre-line" color={'red'}>
              {error as string}
            </FormHelperText>
          ) : null}
        </FormControl>
      </VStack>
      <HStack justifyContent={'center'}>
        <Button
          paddingLeft="2rem"
          marginBottom={'2rem'}
          onClick={handleRequest}
          backgroundColor="buttonPrimary"
          disabled={
            !form.amount || isLoading || parseInt(form.amount + '') <= 0
          }
          alignSelf="center"
          marginRight="0"
          size="md"
          isDisabled={isLoading}
        >
          {t('wallet:fastDepositGenerateQrCode')}
        </Button>
      </HStack>

      <Divider />
      <Link href={'/me/wallet/cash-in'} passHref>
        <a
          style={{
            fontSize: '.8rem',
            paddingTop: '1rem',
            display: 'block',
            textAlign: 'center'
          }}
        >
          {t('wallet:fastDepositOtherPayment')}
        </a>
      </Link>
    </BoxMotion>
  );
};

export default FastDepositStep1;
