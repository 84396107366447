import { useAppSettings } from '@/context/AppSettings';
import { useAuth } from '@/context/Auth';
import { useNotification } from '@/context/Notification';
import { useWallet } from '@/context/Wallet';
import GTM from '@/helpers/googleTagManager';
import { useCurrentHost } from '@/hooks/useCurrentHost';
import useMediaQuery from '@/hooks/useMediaQuery';
import { CloseIcon } from '@/theme/Icons';
import { getFullUrlByDomain } from '@/utils/multiDomains';
import {
  Button,
  ComponentWithAs,
  HStack,
  IconButton,
  IconProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, ReactElement, useEffect, useState } from 'react';
import { UserMenu } from '../';
import ProfileHeader from '../Header/ProfileHeader';
import { SettingsProvider, useSettings } from '../UserSettings/SettingsContext';
import { PaymentMethod } from '../UserSettings/Wallet/CashIn/CashInForm';
import ProfileSideNav from './Profile_Sidenav/ProfileSidenav';

export type SectionsProps = {
  component: ReactElement;
  label: string;
  slug: string;
};

type ProfilePageProps = {
  sections: SectionsProps[];
  title: string;
  slug: string;
  icon: ComponentWithAs<'svg', IconProps>;
  tabIndex?: number;
  paymentMethods?: PaymentMethod[];
  gaminHallCashoutMax?: number;
};

const CustomTab = ({ section }: { section: SectionsProps }) => {
  const { tabsInfos } = useSettings();
  return (
    <Tab key={section?.label} as="li" data-testid={'tabs-' + section?.slug}>
      {section?.label}
      {tabsInfos?.[section?.slug]}
    </Tab>
  );
};

const ProfilePage: FC<ProfilePageProps> = ({
  sections,
  title,
  slug,
  icon,
  tabIndex,
  paymentMethods,
  gaminHallCashoutMax
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { notifToShow } = useNotification();
  const [newTabIndex, setNewTabIndex] = useState(tabIndex);

  const isMobile = useMediaQuery('(max-width: md)');

  const router = useRouter();
  const { t, lang: locale } = useTranslation();

  const { balance: userWalletBalance } = useWallet();
  const { userData } = useAuth();
  const config = useAppSettings();
  const currentHost = useCurrentHost();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const findIndex = sections.findIndex((section) =>
        url.includes(section.slug)
      );
      if (findIndex >= 0) {
        setNewTabIndex(findIndex);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newIndex = newTabIndex !== -1 ? newTabIndex : tabIndex;

  useEffect(() => {
    const currentTab = sections[newIndex || 0]?.slug || sections[0].slug;
    GTM.pageView({
      page: {
        name: `profile/${slug}/${currentTab}`,
        language: router.locale || 'fr'
      },
      user: userData,
      wallet: userWalletBalance
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newIndex]);

  // currentHost === config.domains.MAIN
  //     ? `https://${config.domains.MAIN}${config.hallsUrl}`
  //     : getFullUrlByDomain(locale, currentHost);
  const backToGamesUrl = getFullUrlByDomain(locale, currentHost) || '';

  return (
    <SettingsProvider
      paymentMethods={paymentMethods}
      gaminHallCashoutMax={gaminHallCashoutMax}
    >
      <HStack
        width="100%"
        height="100%"
        minH="100vh"
        overflowY="hidden"
        align="baseline"
      >
        {!isMobile && currentHost && <ProfileSideNav />}
        <Link href={backToGamesUrl || ''} passHref>
          {!isMobile ? (
            <Button
              data-testid="back-to-games-button"
              as="a"
              position="absolute"
              top={
                notifToShow.length > 0 ? `${notifToShow.length * 70}px` : '3rem'
              }
              right="3rem"
              variant="link"
              color="white !important"
              fontWeight="bold"
              cursor="pointer"
              zIndex="overlay"
              rightIcon={
                <CloseIcon
                  backgroundColor="buttonPrimary"
                  color="white"
                  padding="4px"
                  borderRadius="4px"
                  width="1.3rem"
                  height="1.3rem"
                />
              }
            >
              {t('common:backToGames')}
            </Button>
          ) : (
            <IconButton
              data-testid="back-to-games-mobile-button"
              as="a"
              aria-label={t('common:backToGames')}
              position="absolute"
              top={
                notifToShow.length > 0 ? `${notifToShow.length * 70}px` : '1rem'
              }
              right="1rem"
              variant="link"
              zIndex="overlay"
              icon={
                <CloseIcon
                  backgroundColor="buttonPrimary"
                  color="white"
                  padding="4px"
                  borderRadius="4px"
                  width="1.5rem"
                  height="1.5rem"
                />
              }
            ></IconButton>
          )}
        </Link>
        <UserMenu
          data-testid="ProfilePage"
          placement="left"
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        >
          <VStack
            // align=['fk']
            align={['flex-start', 'baseline']}
            width="100%"
            height="100%"
            padding={['0 1rem 2rem 1rem', '0 1rem 2rem 1rem', '2rem 3rem']}
            margin="0 !important"
            overflowX="hidden"
            background={`url(/${config.appName}/backgrounds/profile.webp) bottom right no-repeat`}
          >
            <ProfileHeader title={title} icon={icon} onClick={onOpen} />
            <Tabs
              width="100%"
              minHeight="100vh"
              overflow="hidden"
              maxWidth="75rem"
              isLazy
              onChange={(index) =>
                router.push(`/me/${slug.toLowerCase()}/${sections[index].slug}`)
              }
              index={newIndex}
            >
              <TabList
                className="no-scrollbar"
                as="ul"
                listStyleType="none"
                marginBottom={['2rem', '2rem', '5rem']}
              >
                {sections.map((section) => (
                  <CustomTab
                    key={section.slug}
                    section={section}
                    data-testid={section?.slug}
                  />
                ))}
              </TabList>
              <TabPanels
                width="100%"
                height="100%"
                // paddingRight={['2rem', '0']}
              >
                {sections.map((section) => (
                  <TabPanel
                    key={section.label}
                    width="100%"
                    height="100%"
                    padding="0"
                  >
                    {section.component}
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </VStack>
        </UserMenu>
      </HStack>
    </SettingsProvider>
  );
};

export default ProfilePage;
