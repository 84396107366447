import BorderedBox from '@/components/Box';
import { useStepper } from '@/components/Stepper';
import { toEuro } from '@/helpers/number';
import { useFormatCurrency } from '@/hooks/useFormat';
import { ChevronIcon, EuroIcon, InformationIcon, PenIcon } from '@/theme/Icons';
import {
  Box,
  Button,
  Divider,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  NumberInput,
  NumberInputField,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import {
  ChangeEvent,
  createRef,
  EventHandler,
  FC,
  LegacyRef,
  useEffect,
  useState
} from 'react';
import { useSettings } from '../../../SettingsContext';
import GHSelector from '../../GamingHallCashOut/GHSelector';
import BankAccountSelector from '../BankAccount/BankAccountSelector';
import { modeTypes } from '../BankAccount/BankAccountSelector.types';
import BankAccountActionModal from '../BankAccount/BankAccountActionModal';
import { useAuth } from '@/context/Auth';
import { HTTP } from '@/components/Http';
import { useWallet } from '@/context/Wallet';
import { useCashOutConfig } from '@/context/CashoutConfig';

const SelectAmount: FC<SelectAmountProps> = ({
  introKey = 'wallet:retrieveYourMoney',
  selectAmountKey = 'wallet:selectAmount',
  isGamingHall = false
}) => {
  const {
    balance,
    minCashOut,
    maxGHCashOut,
    sliderValue,
    setSliderValue,
    selectedGamingHall,
    setSelectedGamingHall,
    selectedBankAccount,
    defaultBankAccount
  } = useSettings();

  const { userData, userInfos } = useAuth();
  const { getBalance } = useWallet();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const toast = useToast();
  const router = useRouter();
  const { t } = useTranslation();
  const minAmount = useFormatCurrency({ amount: minCashOut });
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [modalMode, setModalMode] = useState<modeTypes>('add');
  const [isError, setIsError] = useState<string>('');

  const { next } = useStepper();

  const formatMinCashOut = useFormatCurrency({ amount: minCashOut });
  const maxLimit = isGamingHall
    ? Math.min(maxGHCashOut, balance?.amount || 0)
    : balance?.amount || 0;
  const formatMaxLimit = useFormatCurrency({
    amount: maxLimit
  });
  const numberAmount = parseFloat(sliderValue.toString()) * 100;
  const remaining = useFormatCurrency({
    amount: Math.max(0, balance?.amount || 0)
  });

  const { setMethodType } = useCashOutConfig();
  const checkValue = () => {
    if (numberAmount > maxLimit) {
      toast({
        title: `${t('wallet:maximumCashOutExceeded')} : ${formatMaxLimit}`,
        status: 'warning',
        position: 'top',
        duration: 4000,
        isClosable: true
      });
      setSliderValue?.(maxLimit / 100);
      setIsError(`${t('wallet:maximumCashOutExceeded')} : ${formatMaxLimit}`);
    } else if (numberAmount < minCashOut) {
      toast({
        title: `${t('wallet:minimumCashOutRequired', {
          amount: minAmount
        })}`,
        status: 'warning',
        position: 'top',
        duration: 4000,
        isClosable: true
      });
      setSliderValue?.(minCashOut / 100);
      setIsError(
        `${t('wallet:minimumCashOutRequired', {
          amount: minAmount
        })}`
      );
    } else {
      setIsError('');
    }
  };

  const handleChangeSlider = (newValue: number) => {
    setSliderValue?.(newValue);
  };

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    let newAmount = event.target.value;
    if (!newAmount) setSliderValue?.(0);
    else if (!new RegExp('^[0-9]+[.,]?(\\d{1,2})?$').test(newAmount)) return;
    else {
      newAmount = newAmount.toString().replace(',', '.').replace(/^0+/, '');
      setSliderValue?.(Number.isNaN(parseFloat(newAmount)) ? 0 : newAmount);
    }
  };

  const inputNumberRef: LegacyRef<HTMLInputElement> | undefined = createRef();

  const requestPayload = () => ({
    IBAN: selectedBankAccount?.['iban'] || defaultBankAccount?.iban,
    BIC: selectedBankAccount?.['bic'],
    firstName: userInfos?.person?.firstName,
    lastName: userInfos?.person?.lastName,
    username: userInfos?.person?.username,
    address: {
      street: userInfos?.person?.address?.street,
      secondLine: userInfos?.person?.address?.secondLine || '',
      number: userInfos?.person?.address?.number,
      zipCode: userInfos?.person?.address?.zipCode,
      city: userInfos?.person?.address?.city,
      country: userInfos?.person?.address?.country
    },
    amount: Math.round(parseFloat(sliderValue.toString()) * 100),
    type: 'bank_transfer',
    origin: 'website',
    currency: balance?.currency,
    status: 'requested',
    playerId: userData?.id
  });

  const onSubmit = async () => {
    if (isGamingHall) {
      next();
      return;
    }
    const requestCashOut = requestPayload();
    try {
      setIsSubmitting(true);
      await HTTP.post('/wallet/bank_transfers', requestCashOut);
      setIsSubmitting(false);
      toast({
        title: t('common:success'),
        description: t('wallet:cashOutRegistered'),
        status: 'success',
        position: 'top',
        duration: 4000,
        isClosable: true
      });
      getBalance?.();
      setMethodType('bank_transfer');
      next();
    } catch (error: any) {
      setIsSubmitting(false);
      let description = error?.data?.detail;
      const isAddressNumberEmpty = error?.data?.violations?.find(
        (violation: any) => violation.propertyPath === 'address.number'
      );
      if (isAddressNumberEmpty) {
        description = t('wallet:addressNumberEmpty');
      }
      toast({
        title: t('common:error'),
        description,
        status: 'error',
        position: 'top',
        duration: 4000,
        isClosable: true
      });
    }
  };

  const styleBorderBox = () =>
    !isGamingHall
      ? { border: 'none', padding: 0, borderRadius: 0, background: 'none' }
      : {};

  useEffect(() => {
    setSliderValue?.(minCashOut / 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VStack align="baseline" width="100%">
      {/* {introKey && (
        <Text as="h2" textStyle="h2" marginBottom="3rem">
          <Trans
            i18nKey={introKey}
            values={{ amount: minAmount }}
            // eslint-disable-next-line react/jsx-key
            components={[<strong />]}
          />
        </Text>
      )} */}
      {(balance?.amount || 0) < minCashOut ? (
        <>
          <Text fontWeight="bold" fontSize="1.5rem">
            {t('wallet:yourBalanceIsBellow', { amount: minAmount })}
          </Text>
          <Button
            as="a"
            variant="link"
            data-testid="you-should-provide-wallet-button"
            onClick={() =>
              router.push({
                search: 'tab=cash-in'
              })
            }
          >
            {t('wallet:youShouldProvideWallet')}
          </Button>
        </>
      ) : (
        <>
          {/* <Text fontSize="1.7rem" alignSelf="flex-start" fontWeight="bold">
            {t(selectAmountKey)}
          </Text> */}
          {!isGamingHall ? (
            <HStack width="100%" my=".5rem" onClick={(e) => e.preventDefault()}>
              <BankAccountSelector
                openModal={onOpen}
                modalMode={setModalMode}
              />
            </HStack>
          ) : null}

          <Text
            fontSize="1rem"
            alignSelf="flex-start"
            fontWeight="400"
            mt=".5rem"
          >
            {t('wallet:cashoutAvailableBalanceBankTransfer')} {remaining}
          </Text>
          <BorderedBox
            spacing="1rem"
            margin=".5rem 0 2rem 0 !important"
            {...styleBorderBox()}
          >
            <HStack
              alignSelf={isGamingHall ? 'normal' : 'flex-end'}
              flexDirection={['column', 'row']}
              width={'100%'}
            >
              {isGamingHall && (
                <div style={{ flex: 1 }}>
                  <GHSelector
                    onSelect={(selected) => setSelectedGamingHall?.(selected)}
                    selectedId={selectedGamingHall?.id}
                  />
                </div>
              )}
              <InputGroup w={isGamingHall ? 'auto' : 'full'}>
                <InputLeftElement
                  onClick={() => inputNumberRef.current?.focus()}
                  height="100%"
                >
                  {isGamingHall ? <PenIcon height={5} width={5} /> : null}
                </InputLeftElement>
                <Input
                  data-testid="selected-amount-input"
                  borderColor={'figma.neutral.300'}
                  // _hover={{
                  //   borderColor: 'rgba(255,255,255,0.5)'
                  // }}
                  inputMode={'decimal'}
                  value={sliderValue.toString().replace('.', ',')}
                  onChange={handleChangeInput}
                  onBlur={checkValue}
                  textAlign={isGamingHall ? 'end' : 'start'}
                  fontSize="1.5rem"
                  fontWeight="bold"
                  borderRadius=".5rem"
                  padding="1rem"
                  paddingRight={isGamingHall ? '3rem' : '1rem'}
                  sx={{ textIndent: isGamingHall ? '0' : '0rem' }}
                  ref={inputNumberRef}
                  isInvalid={!!isError}
                />
                <InputRightElement
                  height="100%"
                  width="1.5rem"
                  fontSize="1.5rem"
                  right="1rem"
                >
                  {!!isError ? <InformationIcon /> : <EuroIcon />}
                </InputRightElement>
              </InputGroup>
            </HStack>
            {!!isError ? <Text color="#FC8181">{isError}</Text> : null}

            {isGamingHall ? (
              <Slider
                aria-label="Select Amount"
                min={toEuro(minCashOut)}
                max={toEuro(isGamingHall ? maxGHCashOut : maxLimit)}
                value={parseFloat(sliderValue.toString())}
                onChange={handleChangeSlider}
                onChangeEnd={checkValue}
                focusThumbOnChange={false}
              >
                <SliderTrack
                  height="0.7rem"
                  borderRadius="1rem"
                  bg="whiteAlpha.600"
                >
                  <SliderFilledTrack bg="buttonPrimary" />
                </SliderTrack>
                <SliderThumb
                  backgroundColor="buttonPrimary"
                  boxSize="1.75rem"
                  boxShadow="0px 2px 10px 1px rgba(0, 0, 0, 0.5)"
                >
                  <Box
                    backgroundColor="white"
                    boxSize={2}
                    borderRadius="full"
                  />
                </SliderThumb>
              </Slider>
            ) : null}

            <HStack width="100%" justifyContent="space-between">
              <Text fontSize="1rem">
                <b>Min.</b> {formatMinCashOut}
              </Text>
              {/* <Text fontSize="1rem">
                <b>{t('wallet:remaining')}</b> {remaining}
              </Text> */}
            </HStack>
          </BorderedBox>
          {/* <Divider
            display={['none', 'none', 'block']}
            color="whiteAlpha.400"
            marginY="1rem !important"
          /> */}
          <Button
            data-testid="send-cash-out"
            alignSelf={'flex-end'}
            backgroundColor="buttonPrimary"
            size="md"
            onClick={onSubmit}
            isDisabled={
              numberAmount < minCashOut ||
              numberAmount > maxLimit ||
              (isGamingHall && !selectedGamingHall) ||
              isSubmitting
            }
            // rightIcon={<ChevronIcon w={8} h={10} transform="rotate(-90deg)" />}
            marginTop="2rem !important"
            padding={'0 1rem'}
          >
            {t('wallet:withdrawal')}
          </Button>
        </>
      )}
      {!isGamingHall ? (
        <BankAccountActionModal
          isOpen={isOpen}
          onClose={onClose}
          mode={modalMode}
        />
      ) : null}
    </VStack>
  );
};
export default SelectAmount;

export interface SelectAmountProps {
  introKey?: string;
  selectAmountKey?: string;
  isGamingHall?: boolean;
}
