import { useAuth } from '@/context/Auth';
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Grid,
  HStack,
  SlideDirection,
  VStack
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { PropsWithChildren, useMemo, useState } from 'react';
import { ProfileLogOut, profileNavLinks, ProfileSidenavLink } from '../';
import CancelRequestedCashOut from '../CancelRequestedCashOut';
import { RandomFavorites } from '../GamesCatalog/Favorites';
import LastSession from '../LastSession/LastSession';
import SupportButtonModal from '../Modal/SupportButtonModal';
import { CatalogTinyInfo } from '../Profile/Profile_TinyInfo/CatalogTinyInfo';

type UserMenuProps = PropsWithChildren & {
  placement?: SlideDirection;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  isPlaying?: boolean;
};

const UserMenu = ({
  placement = 'right',
  children,
  onClose,
  onOpen,
  isOpen,
  isPlaying,
  ...props
}: UserMenuProps) => {
  const { t } = useTranslation();
  const { userData } = useAuth();
  const [trapFocus, setTrapFocus] = useState<boolean>(true);
  const memoFavorites = useMemo(
    () => (
      <>
        <RandomFavorites marginTop="2rem" />
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <>
      {children}
      <Drawer
        onOverlayClick={onClose}
        onEsc={onClose}
        isOpen={isOpen}
        placement={placement}
        onClose={onClose}
        trapFocus={trapFocus}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            as={VStack}
            padding={['2rem 2rem 1rem', '2rem']}
            spacing={4}
            alignItems={'stretch'}
          >
            <CatalogTinyInfo
              handleClose={onClose}
              inMenu
              isPlaying={isPlaying}
            />
          </DrawerHeader>

          <DrawerBody padding="0rem 2rem 2rem">
            <Grid
              as="ul"
              backgroundColor="rgba(1, 9, 37, 0.2)"
              border="1px solid rgba(142, 148, 179, 0.2)"
              borderRadius="2rem"
              gap="2rem"
              gridTemplateRows="repeat(2, 1fr)"
              gridAutoFlow="column"
              padding="2rem"
            >
              {profileNavLinks?.map((el: any, i: number) => (
                <li key={el.url + i} onClick={onClose}>
                  <ProfileSidenavLink
                    data-testid={`profile-menu-${el.label}`}
                    icon={el.icon}
                    alt={t(`common:${el.label}`)}
                    height={el.height}
                    width={el.width}
                    href={el.url}
                  />
                </li>
              ))}
              <SupportButtonModal
                variant="unstyled"
                fontWeight="normal"
                height="auto"
                iconProps={{ width: '2rem', height: '2rem' }}
                onOpen={(status) => setTrapFocus(status)}
              />
            </Grid>
            <CancelRequestedCashOut />
            {/* <RandomFavorites marginTop="5rem" /> */}
            {memoFavorites}
          </DrawerBody>
          <DrawerFooter
            as={HStack}
            alignItems="center"
            justifyContent="center"
            padding="2rem"
            spacing="auto"
          >
            <ProfileLogOut />
            <LastSession date={userData?.lastLogin || ''} />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default UserMenu;
