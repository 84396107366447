import useTranslation from 'next-translate/useTranslation';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react';
import {
  getBackgroundUrl,
  ModalHitProps,
  SVGModalHit
} from '@/components/Jackpots';

export const ModalHitMobile = ({
  onCloseModal,
  isOpen,
  level,
  message,
  amount
}: ModalHitProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      onOverlayClick={onCloseModal}
      size={'full'}
    >
      <ModalOverlay />
      <ModalContent
        p={'0!'}
        bg={'black!'}
        maxW={'none'}
        m={'0!'}
        border={'none'}
      >
        <ModalBody
          position={'relative'}
          p={'0 0 100vw'}
          overflow={'hidden'}
          display={'block'}
        >
          <Box pt={'1rem'} mx={'-5rem'}>
            <SVGModalHit level={level} amount={amount} />
          </Box>

          {/*MESSAGE*/}
          <Box
            textAlign={'center'}
            alignSelf={'start'}
            my={'2rem'}
            mx={'auto'}
            maxWidth={'400px'}
            sx={{
              h2: {
                fontSize: '2rem',
                fontWeight: '700',
                lineHeight: 1.1
              },
              p: {
                fontWeight: '400',
                m: 0
              }
            }}
            dangerouslySetInnerHTML={{
              __html: message ?? ''
            }}
          />

          {/*Treasure Image*/}

          <Box
            position={'absolute'}
            bottom={0}
            w={'100%'}
            style={{ aspectRatio: '1' }}
            bgImage={`url(${getBackgroundUrl(level, true)})`}
            bgRepeat="no-repeat"
            bgSize={'cover'}
            bgPosition={'top'}
            zIndex={0}
          />

          {/*CTA*/}
          <Button
            position={'fixed'}
            w={'calc(100% - 4rem)'}
            inset={'auto 2rem 2.5rem'}
            zIndex={1}
            background="buttonPrimary"
            display={'flex'}
            mx={'auto'}
            onClick={onCloseModal}
            height={'3.25rem'} // 52
          >
            {t('jackpot:close')}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
