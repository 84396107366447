// @ts-nocheck
import DataTable, {
  AmountCell,
  StatusCell,
  Statuses,
  TypeCell
} from '@/components/Table/DataTable';
import { useFormatDate } from '@/hooks/useFormat';
import { TableProps, useDisclosure } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, { useMemo, useRef, useState } from 'react';
import Detail from './Detail';

type HistoryProps = TableProps & {};

export type Transaction = {
  id: string;
  '@id': string;
  '@type': 'GamingHallPayout' | 'UserPayout';
  amount: number;
  balanceAfter: number;
  balanceBefore: number;
  createdAt: string;
  currency: string;
  status: Statuses;
  type: string;
  updatedAt: string;
  userId: string;
  requestedGamingHallId?: string;
  code?: string;
};

const History = ({ ...props }: HistoryProps) => {
  const [selectedRow, setSelectedRow] = useState<Transaction | null>(null);
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(
    () => [
      {
        Header: t('wallet:type'),
        id: 'type',
        accessor: 'type',
        Cell: ({ value }: { value: string }) => <TypeCell value={value} />
      },
      {
        Header: t('wallet:status'),
        id: 'status',
        accessor: 'status',
        Cell: ({ value }: { value: Statuses }) => {
          return <StatusCell value={value} />;
        }
      },
      {
        Header: t('common:date'),
        id: 'date',
        accessor: 'createdAt',
        Cell: ({ value }: { value: string }) => {
          const [date, time] = useFormatDate(value).split(' ');
          return (
            <>
              {date}
              <br />
              {time}
            </>
          );
        }
      },
      {
        Header: t('wallet:amount'),
        id: 'amount',
        accessor: 'amount',

        Cell: ({ value }: { value: number }) => (
          <AmountCell textAlign={['end', 'start']} value={value} />
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const childRef = useRef();

  const clickOnRow = (row: any) => {
    setSelectedRow(row);
    onOpen();
  };

  return (
    <>
      <DataTable
        columns={columns}
        ref={childRef}
        fetchUrl="/payout_requests"
        selectRow={clickOnRow}
        data-testid="history-page"
        hideSortArrowWhenNoSortOnMobile
        {...props}
      />
      <Detail
        isOpen={isOpen}
        onClose={onClose}
        row={selectedRow}
        fetchData={childRef.current?.fetchData}
      />
    </>
  );
};

export default History;
