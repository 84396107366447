import { useFormatCurrency } from '@/hooks/useFormat';
import { Progress, ResponsiveValue, VStack } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { Text } from '@chakra-ui/react';

type RemainingProgressBarProps = {
  remaining: number;
  max: number;
  size?: ResponsiveValue<(string & {}) | 'sm' | 'md' | 'lg' | 'xs'> | undefined;
};

const RemainingProgressBar = ({
  remaining,
  max,
  size = 'lg'
}: RemainingProgressBarProps) => {
  const { t } = useTranslation();
  const progressPercentage = (remaining / max) * 100;
  return (
    <VStack width="100%" align="flex-start" spacing="1rem">
      <Text fontSize="2rem" fontWeight="bold" as="h1">
        <b>{t('wallet:remaining')}</b>{' '}
        {useFormatCurrency({ amount: remaining })}
      </Text>

      <Progress
        width="100%"
        colorScheme={'red'}
        borderRadius="1rem"
        size={size}
        background="rgba(255, 255, 255, 0.5)"
        value={progressPercentage}
      />

      <Text alignSelf="flex-end">
        <b>Max</b> {useFormatCurrency({ amount: max })}
      </Text>
    </VStack>
  );
};

export default RemainingProgressBar;
