export const removeTags = (text: string): string =>
  text.replace(/(<([^>]+)>)/gi, ' ');

export const upFirst = (str: string) => str[0].toUpperCase() + str.slice(1);

export const truncate = (text: string, maxLength: number = 12) => {
  if (text.length <= maxLength) {
    return text; // No need to truncate
  } else {
    return text.substring(0, maxLength) + '…';
  }
};
