import { isObject, forEach } from 'lodash';
/* 
    Helper to convert properties of object to dot key

    nb: it seems that forEach of Lodash is fatest than the native one.
    see result : https://www.measurethat.net/Benchmarks/ShowResult/238014
*/

export const dotizeObject = (object: {}) => {
  const result: { [key in string]: unknown } = {};

  function flatten(obj: {}, prefix: string = '') {
    forEach(obj, (value, key) => {
      if (isObject(value)) {
        flatten(value, `${prefix}${key}.`);
      } else {
        result[`${prefix}${key}`] = value;
      }
    });
  }

  flatten(object);

  return result;
};
