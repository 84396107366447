import { HTTP } from '@/components/Http';
import { NextRouter } from 'next/router';
import { getFallbackLanguage } from '@/helpers/lang';
import { configDomains } from '@/utils/multiDomains';

const { availableLanguages } = require('../../i18n');

export const strapiImageUrl = (path?: string) => {
  if (path === undefined) return undefined;

  return path && path.startsWith('http')
    ? path
    : HTTP.defaults.baseURL + '/cms' + path;
};

export const isFullUrl = (url: string) =>
  url.startsWith('https://') || url.startsWith('http://');

export const isPath = (url: string) => url.startsWith('/');

export const isHash = (url: string) => url.startsWith('#');

export const isStartingByLocale = (url: string, locales: string[] = []) =>
  locales.some((l: string) => url?.startsWith(`/${l}/`));

export const hasLocale = (url: string, locales: string[] = []) =>
  locales.some((l: string) => url?.includes(`/${l}/`));

export const isInDomains = (url: string, domains: string[]) =>
  domains.some((d) => url?.startsWith(`https://${d}`));

export const isValidUrl = (url: string) => {
  return url && (isHash(url) || isPath(url) || isFullUrl(url));
};

export type GetRouterInfoFromUrlParams = {
  url: string;
  locale: string;
  locales?: string[];
  domains?: string[];
  withFallbackLanguage?: boolean;
};
export const getRouterInfoFromUrl = ({
  url,
  locale,
  locales,
  domains,
  withFallbackLanguage
}: GetRouterInfoFromUrlParams) => {
  locales = locales ?? availableLanguages ?? [];
  domains = domains ?? Object.values(configDomains)?.filter(Boolean);

  if (!isPath(url) && !isFullUrl(url) && !isHash(url)) {
    return { url: null, options: {} };
  }

  let options: {
    locale?: string | false;
  } = {};

  const fallBackLocale = getFallbackLanguage(locale);

  if (isPath(url)) {
    const newLocale = withFallbackLanguage
      ? fallBackLocale
      : locales?.find((l) => url!.startsWith(`/${l}`)) ?? locale;

    if (isStartingByLocale(url, locales)) {
      // remove language from path
      url = `/${url.split('/').slice(2).join('/')}`;
    }

    // change local if different
    if (locale !== newLocale) {
      options = { locale: newLocale };
    }
  }

  if (isInDomains(url, domains)) {
    if (hasLocale(url, locales)) {
      const urlParts = url.split('/');
      // update local when withFallbackLanguage
      urlParts[3] = withFallbackLanguage
        ? getFallbackLanguage(urlParts[3])
        : urlParts[3];
      url = urlParts.join('/');
    } else {
      const urlParts = url.split('/');
      // insert locale in url
      urlParts.splice(3, 0, withFallbackLanguage ? fallBackLocale : locale);
      url = urlParts.join('/');
    }
  }

  return { url, options };
};

export type RouteToUrlParams = {
  router: NextRouter;
  url?: string | null;
  withFallbackLanguage?: boolean;
  pushOptions?: {
    locale?: string;
    shallow?: boolean;
    scroll?: boolean;
    unstable_skipClientCache?: boolean;
  };
};

export async function routeToUrl({
  router,
  url,
  withFallbackLanguage = false,
  pushOptions = {}
}: RouteToUrlParams) {
  url = url?.trim() ?? '';
  if (!url) return;

  const { locale = 'en', locales } = router;
  const params = {
    url,
    locale,
    locales,
    withFallbackLanguage
  };
  const { url: fixedUrl, options: localeOptions } =
    getRouterInfoFromUrl(params);
  const options = {
    ...pushOptions,
    ...localeOptions
  };

  try {
    // console.log('url -> routeToUrl', {
    //   fixedUrl,
    //   options: JSON.stringify(options)
    // });

    if (fixedUrl) {
      await router.push(fixedUrl, fixedUrl, options);
    }
  } catch (error) {
    console.error('routeToUrl : ERROR', {
      error,
      url,
      options
    });
  }
}
