import dynamic from 'next/dynamic';
import getConfig from 'next/config';
import { ComponentType } from 'react';

export function getBrandComponent(componentName: string): ComponentType<any> {
  const { publicRuntimeConfig } = getConfig();
  const { appName } = publicRuntimeConfig?.currentAppConfig;
  if (appName) {
    const capitalizedBrandName =
      appName.charAt(0).toUpperCase() + appName.slice(1);
    return dynamic(
      () => import(`../components/${capitalizedBrandName}/${componentName}`)
    );
  }

  throw new Error('Could not get config app name');
}
