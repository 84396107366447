import { NextRouter } from 'next/router';
import { freeUrl, onlineUrl } from '../constant/freeAccessUrl';

export const isFreeAccess = (router: NextRouter) => {
  // return freeUrl.includes(`/${router.pathname.split('/')[1]}`);
  return freeUrl.includes(router.pathname);
};

export const isOnlineAccess = (router: NextRouter) => {
  return onlineUrl.includes(router.pathname);
};
