/* eslint-disable react-hooks/exhaustive-deps */
import BorderedBox from '@/components/Box';
import { Form } from '@/components/Form/Form';
import Input from '@/components/Form/Input';
import { FormStack, HTTP, InputSelect } from '@/components/index';
import ChangeEmailModal from '@/components/Modal/ChangeEmailModal';
import ResetPasswordModal from '@/components/Modal/ResetPasswordModal';
import { dotizeObject } from '@/helpers/dotizeObject';
import { errorsFormBuilder } from '@/helpers/errorsFormBuilder';
import { AccountIcon, EditIcon } from '@/theme/Icons';
import {
  Button,
  Checkbox,
  HStack,
  Spinner,
  Text,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react';
import setLanguage from 'next-translate/setLanguage';
import useTranslation from 'next-translate/useTranslation';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { UserContext, UserInfos } from '@/context/Auth';
import BankAccountActionModal from '../../Wallet/CashOut/BankAccount/BankAccountActionModal';
import BankAccountSelector from '../../Wallet/CashOut/BankAccount/BankAccountSelector';
import { modeTypes } from '../../Wallet/CashOut/BankAccount/BankAccountSelector.types';
import { useAppSettings } from '@/context/AppSettings';

type PrefixePhones = {
  iso: string;
  prefix: number;
  country: string;
};
const MyProfile = () => {
  const {
    userData,
    userInfos,
    setUserInfos,
    episMajorError,
    episMinorError,
    episValidationError,
    me
  } = useContext(UserContext);
  const [loadingUser, setLoadingUser] = useState(false);
  const toast = useToast();
  const { t, lang } = useTranslation();
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [phonePrefixes, setPhonePrefixes] = useState<PrefixePhones[]>([]);
  const config = useAppSettings();
  const availableLanguagesObject: Record<string, string> =
    config.availableLanguagesObject;

  const isMinorError: boolean = userData?.identity?.error === 'MINOR';
  const canUpdateProtectData: boolean = Boolean(
    userData?.permission?.editProtectedData
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: resetPasswordIsOpen,
    onOpen: resetPasswordOnOpen,
    onClose: resetPasswordOnClose
  } = useDisclosure();
  const [modalMode, setModalMode] = useState<modeTypes>('add');
  const {
    isOpen: isChangeEmailOpen,
    onClose: onChangeEmailClose,
    onOpen: onChangeEmailOpen
  } = useDisclosure();

  // handle Major, Minor and default behaviors for EPIS
  const isUpdatable = (field: string = '') => {
    if (canUpdateProtectData) {
      if (episMajorError || episValidationError) {
        // it's Major, all fields are editable
        return false;
      }
    } else {
      // default, protected data ara not editable
      return true;
    }
  };

  const form = useForm<UserInfos>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: userInfos
  });

  const { getValues } = form;

  const getPhoneInfos = async () => {
    try {
      const { data } = await HTTP.get('/user/phone_prefixes');
      setPhonePrefixes(
        data?.sort((a: PrefixePhones, b: PrefixePhones) =>
          a.country.localeCompare(b.country)
        )
      );
    } catch (error) {}
  };

  useEffect(() => {
    form.reset(userInfos);
    setErrorFields(Object.keys(dotizeObject(userInfos || {})));
  }, [userInfos]);

  useEffect(() => {
    getPhoneInfos();
  }, [lang]);

  const onSubmit = async (formValues: UserInfos) => {
    try {
      const response = await HTTP.post('user/profile', formValues);
      const language = response?.data?.person?.preferredLanguage;
      // setUserInfos(response?.data as UserInfos);
      setUserInfos?.(response.data as UserInfos);
      setLanguage(language);
      document.cookie = `NEXT_LOCALE=${language}; max-age=31536000; path=/`;
      // If language ha been changed, next-translate doens't see this change and output the last language. No fix so far.
      toast({
        title: t('common:success'),
        description: t('common:yourChangesSubmitted'),
        status: 'success',
        duration: 4000,
        isClosable: true,
        position: 'top'
      });
      me?.();
    } catch (error: any) {
      const violations = error?.data?.violations;
      violations?.length &&
        errorsFormBuilder({
          violations,
          fields: errorFields,
          useFormInstance: form
        });
    }
  };

  if (loadingUser || !userInfos) {
    return <Spinner size="xl" />;
  } else {
    return (
      <>
        <BorderedBox
          display="flex"
          flexDirection={['column', 'column', 'row']}
          justifyContent="space-between"
          marginBottom="2.5rem"
        >
          <HStack alignSelf="flex-start">
            <div style={{ position: 'relative' }}>
              <AccountIcon width="3.7rem" height="3.7rem" />
            </div>
            <VStack alignItems="flex-start" paddingLeft="10px">
              <Text
                fontWeight="bold"
                fontSize="1.125rem"
                maxWidth="15rem"
                noOfLines={1}
              >
                {userInfos.person.username}
              </Text>
              <Text
                fontWeight="medium"
                fontSize={['.687rem', '.687rem', '1rem']}
                color="customGrey.900"
                lineHeight={1.5}
                style={{ margin: 0 }}
              >
                {userInfos.person.email}
              </Text>
            </VStack>
          </HStack>
          <VStack
            align={['flex-start', 'flex-start', 'flex-end']}
            alignSelf={['flex-start', 'flex-start', 'flex-end']}
            marginTop={['1rem', '1rem', '0rem']}
          >
            <Button
              data-testid="change-email-address"
              onClick={onChangeEmailOpen}
              variant="link"
            >
              <Text>{t('account:changeEmailAddress')}</Text>
              <EditIcon
                order={[0, 0, 1]}
                marginLeft={['0rem', '0rem', '0.5rem']}
                marginRight={['0.5rem', '0.5rem', '0rem']}
              />
            </Button>
            <Button
              data-testid="reinitialise-password"
              variant="link"
              onClick={resetPasswordOnOpen}
            >
              <Text>{t('account:resetPassword')}</Text>
              <EditIcon
                order={[0, 0, 1]}
                marginLeft={['0rem', '0rem', '0.5rem']}
                marginRight={['0.5rem', '0.5rem', '0rem']}
              />
            </Button>
          </VStack>
        </BorderedBox>
        <Form
          stackProps={{
            width: '100%',
            spacing: '2rem'
          }}
          onSubmit={form.handleSubmit(onSubmit)}
          {...form}
        >
          <FormStack>
            <Input
              type="text"
              name="person.firstName"
              data-testid="profile-firstname-input"
              label={t('form:firstName')}
              isReadOnly={isUpdatable('firstName')}
              // isDisabled={isUpdatable('person.firstName')}
            />
            <Input
              type="text"
              label={t('form:lastName')}
              data-testid="profile-lastname-input"
              name="person.lastName"
              isReadOnly={isUpdatable('lastName')}
              // isDisabled={isUpdatable('person.lastName')}
              validation={{
                required: 'Missing last name'
              }}
            />
          </FormStack>
          <FormStack direction="column">
            {userInfos.person.nationalRegistrationNumber ? (
              <Input
                type="text"
                label={t('form:nationalIDNumber')}
                data-testid="profile-nrn-input"
                // isReadOnly={isUpdatable()}
                name="person.nationalRegistrationNumber"
                isReadOnly={isUpdatable()}
                flex="1"
                // isDisabled={isUpdatable()}
              />
            ) : null}
            {userInfos.person.passportNumber ? (
              <Input
                type="text"
                label={t('form:passportNumber')}
                data-testid="profile-passport-input"
                isReadOnly={true}
                name="person.passportNumber"
                flex="1"
                // isDisabled={true}
              />
            ) : null}
            <Input
              type="text"
              label={t('form:country')}
              data-testid="profile-country-input"
              name="person.address.country"
              isReadOnly={true}
              isRequired
            />
          </FormStack>
          <FormStack>
            <Input
              type="date"
              label={t('form:dateOfBirth')}
              name="person.birthdate"
              data-testid="profile-birthdate-input"
              colorScheme="white"
              isReadOnly={isUpdatable()}
              validation={{
                required: 'Missing birth date'
              }}
            />
            <Input
              type="text"
              data-testid="profile-placeofbirth-input"
              label={t('form:placeOfBirth')}
              name="person.birthplace"
            />
          </FormStack>
          <FormStack>
            <Input
              type="text"
              name="person.address.zipCode"
              data-testid="profile-zipcode-input"
              label={t('form:zipCode')}
              width={['inherit', '40%', '30%']}
              validation={{
                required: 'Missing Zip code',
                maxLength: {
                  value: userInfos.person.address.country === 'BE' ? 4 : 10,
                  message: t('register:zipCodeTooLongError', {
                    max: userInfos.person.address.country === 'BE' ? 4 : 10
                  })
                }
              }}
            />
            <Input
              type="text"
              name="person.address.city"
              data-testid="profile-city-input"
              label={t('form:city')}
            />
          </FormStack>
          <FormStack>
            <Input
              type="text"
              name="person.address.street"
              data-testid="profile-street-input"
              label={t('form:streetAddress')}
              validation={{
                required: 'Missing street'
              }}
            />
            <Input
              type="text"
              name="person.address.number"
              data-testid="profile-street-number-input"
              width={['40%', '40%', '30%']}
              label={t('form:streetNumber')}
              validation={{
                required: 'Missing street number'
              }}
            />
          </FormStack>
          <Input
            type="text"
            name="person.address.secondLine"
            data-testid="profile-secondLine"
            label={t('form:secondLine')}
          />
          <FormStack>
            <InputSelect
              name="person.mobile.country"
              data-testid="profile-country-select-input"
              label={t('form:prefix')}
              width={['40%', '40%', '30%']}
              value={getValues('person.mobile.country')}
            >
              <option disabled value=""></option>
              {phonePrefixes.map((phone) => (
                <option key={phone.iso} value={phone.iso}>
                  {phone.country}
                </option>
              ))}
            </InputSelect>
            <Input
              type="tel"
              label={t('form:phoneNumber')}
              data-testid="profile-phonenumber-input"
              name="person.mobile.numbers"
            />
          </FormStack>
          <FormStack>
            <InputSelect
              label={t('form:preferredLanguage')}
              data-testid="profile-preferred-language-select-input"
              name="person.preferredLanguage"
              isRequired
            >
              {Object.entries(availableLanguagesObject).map(([key, value]) => (
                <option key={key} value={key}>
                  {value as string}
                </option>
              ))}
            </InputSelect>
            <Input
              type="text"
              name="person.occupation"
              data-testid="profile-occupation-input"
              label={t('form:occupation')}
            />
          </FormStack>
          {/* <Input type="text" name="person.IBAN" label="Bank account" /> */}
          <BankAccountSelector openModal={onOpen} modalMode={setModalMode} />
          <BankAccountActionModal
            isOpen={isOpen}
            onClose={onClose}
            mode={modalMode}
          />
          <Controller
            control={form?.control}
            name="person.optInCommercialMessages"
            defaultValue={false}
            rules={{ required: false }}
            render={({ field: { onChange, ref, value, name } }) => (
              <Checkbox
                id={name}
                colorScheme="red"
                onChange={onChange}
                isChecked={value}
                ref={ref}
                marginY="1rem"
                justifyContent="flex-start"
                width="100%"
              >
                <Text as="label" htmlFor={name}>
                  {t('form:newsletter')}
                  {form?.formState?.errors?.person?.optInCommercialMessages && (
                    <Text as="span" color="buttonPrimary" marginLeft="1rem">
                      {t('form:fieldRequired')}
                    </Text>
                  )}
                </Text>
              </Checkbox>
            )}
          />
          <Button
            alignSelf={['center', 'center', 'flex-end']}
            type="submit"
            size="lg"
            backgroundColor="buttonPrimary"
            isLoading={form.formState.isSubmitting}
          >
            {t('account:saveMyProfile')}
          </Button>
        </Form>
        <ResetPasswordModal
          isOpen={resetPasswordIsOpen}
          onClose={resetPasswordOnClose}
        />
        <ChangeEmailModal
          isOpen={isChangeEmailOpen}
          onClose={onChangeEmailClose}
        />
      </>
    );
  }
};

export default MyProfile;
