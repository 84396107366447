import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

export interface UseIntersectionObserverType {
  options?: IntersectionObserverInit;
  handler: (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) => void;
}

const UseIntersectionObserver = ({
  options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1
  },
  handler
}: UseIntersectionObserverType) => {
  const observer = useRef<IntersectionObserver>(null);
  const elRef = useRef<any[]>([]);
  const addNode = useCallback((node: any) => elRef.current.push(node), []);
  const getObserver = (ref: MutableRefObject<IntersectionObserver | null>) => {
    let observer = ref.current;
    if (observer !== null) {
      return observer;
    }
    let newObserver = new IntersectionObserver(handler, options);
    ref.current = newObserver;
    return newObserver;
  };

  useEffect(() => {
    if (observer?.current) observer?.current?.disconnect();

    const newObserver = getObserver(observer);
    for (const node of elRef?.current) {
      if (node) newObserver?.observe(node);
    }

    return () => newObserver?.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { addNode, elRef };
};

export default UseIntersectionObserver;
