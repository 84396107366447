import { useState } from 'react';
import useMediaQuery from '@/hooks/useMediaQuery';

import { useJackpots } from '@/context/Jackpots';
import {
  JackpotLevel,
  JackpotLevelType,
  JackpotSlider
} from '@/components/Jackpots';
import { Swiper } from 'swiper/types';
import { Box, BoxProps } from '@chakra-ui/react';

export const JackpotsList = (props: BoxProps) => {
  const { jackpots } = useJackpots();
  const isMobile = useMediaQuery('(max-width: sm)');
  const [openedIndex, setOpenedIndex] = useState<number | null>(null);

  const onClickOpen = (index: number) => () => {
    setOpenedIndex(openedIndex === index ? null : index);
  };

  const renderLevel = (jackpotLevel: JackpotLevelType, index: number) => (
    <JackpotLevel
      key={jackpotLevel.ref + '-' + index}
      jackpotLevel={jackpotLevel}
      onClick={onClickOpen(index)}
      isOpen={openedIndex === index}
      px={[4, 8, 0]}
      w={'100%'}
    />
  );

  const sortedJackPot =
    jackpots?.[0]?.levels?.sort((l1: JackpotLevelType, l2: JackpotLevelType) =>
      l1.ref.localeCompare(l2.ref)
    ) ?? [];

  return sortedJackPot?.length ? (
    isMobile ? (
      <JackpotSlider
        onSlideChange={(swiper: Swiper) => {
          // close the slide when other slide
          if (openedIndex !== swiper.activeIndex) {
            setOpenedIndex(null);
          }
        }}
        {...props}
      >
        {sortedJackPot?.map(renderLevel)}
      </JackpotSlider>
    ) : (
      <Box
        display={'flex'}
        alignItems={'stretch'}
        w={'full'}
        color={'black'}
        gap={6}
        px={[0, 0, 8, 4, 4]}
        {...props}
      >
        {sortedJackPot?.map(renderLevel)}
      </Box>
    )
  ) : null;
};
