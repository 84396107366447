import {
  JackpotLevelType,
  SVGBronzeLevelMini,
  SVGGoldLevelMini,
  SVGSilverLevelMini,
  useJackpotLinkInfo
} from '@/components/Jackpots';
import { useNumberAnimation } from '@/hooks/useNumberAnimation';
import { formatCurrency } from '@/hooks/useFormat';
import { Box, BoxProps } from '@chakra-ui/react';
import Link from 'next/link';

type JackpotLevelMiniProps = BoxProps & {
  jackpotLevel: JackpotLevelType;
  isMobile: boolean;
};

export const JackpotLevelMini = ({
  jackpotLevel,
  isMobile,
  ...props
}: JackpotLevelMiniProps) => {
  const linkInfoLang = useJackpotLinkInfo();

  // Animate amount
  const amount = useNumberAnimation({
    value: jackpotLevel?.amount,
    animationValueDuration: 10000,
    animationValueStepDuration: 100
  });

  const amountFormatted = formatCurrency({
    amount: amount,
    currency: 'EUR'
  });

  const renderSVG = (jackpotLevel: JackpotLevelType, amount: string) => {
    switch (jackpotLevel.ref) {
      case '1':
        return <SVGGoldLevelMini isMobile={isMobile} amount={amount} />;
      case '2':
        return <SVGSilverLevelMini isMobile={isMobile} amount={amount} />;
      default:
      case '3':
        return <SVGBronzeLevelMini isMobile={isMobile} amount={amount} />;
    }
  };

  const content = (
    <Box as={linkInfoLang ? 'a' : undefined} {...props}>
      {renderSVG(jackpotLevel, amountFormatted)}
    </Box>
  );

  return linkInfoLang ? (
    <Link href={linkInfoLang} passHref>
      {content}
    </Link>
  ) : (
    content
  );
};
