import { HTTP } from '@/components/Http';
import { useAuth } from '@/context/Auth';
import { useWallet, WalletContext } from '@/context/Wallet';
import GTM from '@/helpers/googleTagManager';
import { Box, Portal, Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { ElementRef, useContext, useEffect, useRef, useState } from 'react';
import FastDepositResponse from './FastDepositResponse';
import FastDepositStep1 from './FastDepositStep1';
import FastDepositStep2 from './FastDepositStep2';
import {
  getCategoriesHeight,
  getHeaderHeight,
  getJackpotsHeight,
  hasCategoriesInHeader,
  hasJackpotsInHeader
} from '@/helpers/header';
import { useRouter } from 'next/router';
import useMediaQuery from '@/hooks/useMediaQuery';
import { useBodyClick } from '@/hooks/useBodyClick';

export type FastDepositModalProps = {};
export type ApiNewFastDepositResponseBody = {
  status: 'OK' | 'ERROR';
  qrcode: string; // base64
  isFirst: boolean;
  expiresAt: string;
  externalTransRef: string;
};
export type PayloadFastDeposit = {
  amount: string | number;
  language: string;
};
export type UserBalanceType = {
  amount: number;
  currency: string;
  depositLimit?: number;
  available?: number;
  used?: number;
  promptDeposit?: boolean;
  lastDepositAmount?: number;
};
export type PaymentStatusResponseBody = {
  status: 'SUCCESS' | 'ERROR' | 'PENDING';
  redirectUrl?: string;
  amount?: number;
  transactionId?: string;
  balance?: UserBalanceType;
  isFirst?: boolean;
};
const initPaymentData: Partial<PaymentStatusResponseBody> = {
  status: 'PENDING',
  redirectUrl: '',
  amount: 0,
  transactionId: '',
  isFirst: false
};
const initData: ApiNewFastDepositResponseBody = {
  status: 'OK',
  qrcode: '',
  isFirst: false,
  expiresAt: '',
  externalTransRef: ''
};

const FastDeposit = ({ ...props }: FastDepositModalProps) => {
  const walletContext = useContext(WalletContext);
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [data, setData] = useState<ApiNewFastDepositResponseBody>(initData);
  const [providerResponse, setProviderResponse] =
    useState<PaymentStatusResponseBody>(
      initPaymentData as PaymentStatusResponseBody
    );
  const [timeCompleted, setTimeCompleted] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(Date.now());
  const intervalRef = useRef<number | null>(null);
  const timeoutProviderResponse = useRef<number | null>(null);
  const startDate = useRef<number | null>(0);
  const expire = new Date(data.expiresAt).getTime();
  const { getBalance } = useWallet();
  const { t, lang } = useTranslation();
  const [form, setForm] = useState<PayloadFastDeposit>({
    amount: '',
    language: lang
  });
  const [error, setError] = useState<string>('');
  const { userData } = useAuth();
  const { balance } = useWallet();
  const endpoint: { [key in string]: string } = {
    fastDeposit: '/wallet/fastDeposit',
    success: '/wallet/success'
  };

  const handleQrCode = async () => {
    try {
      setIsLoading(true);
      const qr = await HTTP.post(endpoint.fastDeposit, {
        ...form,
        amount: Math.round(+form.amount * 100) // round number to avoid Floating point variables
      });
      setIsLoading(false);
      setData(qr.data);
      setStep(2);
      GTM.ecommerceEvent(
        'begin_checkout',
        Math.round(+form.amount * 100),
        { method: 'FASTDEPOSIT', provider: 'HIPAY' },
        userData,
        balance,
        !userData?.alreadyDeposited
      );
    } catch (error: any) {
      Text;
      setIsLoading(false);
      setError(error?.data.error);
    }
  };

  const handleProviderResponse = async () => {
    try {
      const response = await HTTP.post(endpoint.success, {
        provider: 'HIPAY',
        transactionId: data.externalTransRef
      });
      setProviderResponse(response.data);
    } catch (error) {
      setProviderResponse(initPaymentData as PaymentStatusResponseBody); // in order to retry in case of http error
      // setIsSuccess(false);
    }
  };

  const getRemainingTime = ({
    currentTime,
    startDate,
    data,
    expire
  }: {
    currentTime: number;
    startDate: any;
    data: ApiNewFastDepositResponseBody;
    expire: number;
  }) => {
    if (currentTime && startDate.current && data.expiresAt) {
      return (
        100 *
        (1 - (currentTime - startDate.current) / (expire - startDate.current))
      );
    }
  };

  // timer
  useEffect(() => {
    intervalRef.current = window.setInterval(
      () => setCurrentTime((current) => (current || Date.now()) + 1000),
      1000
    );
    return () => window.clearInterval(intervalRef.current || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.expiresAt) {
      startDate.current = Date.now();
      const getServerTime = async () => {
        const time = await fetch('/api/time/getTime');
        const response = await time.json();
        setCurrentTime(response.currentTime);
      };
      getServerTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if ((currentTime || 0) > +expire) setTimeCompleted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime]);

  useEffect(() => {
    if (providerResponse.status === 'SUCCESS') {
      setIsSuccess(true);
      setStep(3);
      getBalance?.();
      const paymentMethod = {
        method: 'FASTDEPOSIT',
        provider: 'HIPAY'
      };
      GTM.purchaseDepositEvent(
        paymentMethod,
        userData,
        balance,
        providerResponse?.amount,
        providerResponse?.transactionId
      );
    } else if (providerResponse.status === 'ERROR') {
      setIsSuccess(false);
      setStep(3);
      getBalance?.();
    } else {
      // pending
      if (data.externalTransRef) {
        timeoutProviderResponse.current = window.setTimeout(
          handleProviderResponse,
          3000
        );
        return () => window.clearTimeout(timeoutProviderResponse.current || 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, providerResponse]);

  useEffect(() => {
    setError('');
  }, [lang]);

  const reset = () => {
    setStep(1);
    setTimeCompleted(false);
    setData(initData);
    setProviderResponse(initPaymentData as PaymentStatusResponseBody);
    setError('');
  };

  const viewCurrentStep = {
    1: (
      <FastDepositStep1
        form={form}
        handleRequest={handleQrCode}
        isLoading={isLoading}
        setForm={setForm}
        error={error}
        onCbBlur={() => setError('')}
      />
    ),
    2: (
      <FastDepositStep2
        callback={reset}
        currentTime={currentTime as number}
        data={data}
        expire={expire}
        form={form}
        getRemainingTime={
          getRemainingTime({
            currentTime: Date.now(),
            startDate,
            data,
            expire
          }) as number
        }
        timeCompleted={timeCompleted}
      />
    ),
    3: (
      <FastDepositResponse
        isSuccess={isSuccess}
        callback={() => {
          reset();
        }}
      />
    )
  };

  const isScreenMobile = useMediaQuery('(max-width: 37.5rem)');
  const { pathname } = useRouter();
  const hasJackpots = hasJackpotsInHeader({ pathname, enabled: true });
  const hasCategories = hasCategoriesInHeader({ pathname });
  const headerHeight = getHeaderHeight(!isScreenMobile);
  const categoriesHeight = hasCategories ? getCategoriesHeight() : 0;
  const jackpotsHeight = hasJackpots ? getJackpotsHeight(!isScreenMobile) : 0;
  const gap = 8;

  const top = `calc(${headerHeight}px + ${categoriesHeight}px + ${jackpotsHeight}px + var(--top-header, 0px) + ${gap}px)`;
  const ref = useRef<ElementRef<'div'>>(null);

  // add event listener to close FastDeposit
  // when clicking outside of the FastDeposit and the wallet button
  useBodyClick({
    condition: walletContext.isFastDepositOpen,
    onBodyClick: () => walletContext.setIsFastDepositOpen(false),
    btnSelector: '#btn-open-fast-deposit',
    refOverlay: ref
  });

  return (
    <Portal {...props}>
      <Box
        ref={ref}
        position="fixed"
        display={walletContext.isFastDepositOpen ? 'block' : 'none'}
        top={['4rem', `${top}`]}
        transition={'top ease .3s'}
        right={[
          '3rem',
          '3rem',
          '3rem',
          'calc((100vw - min(100%,1280px))/2 + 1rem)'
        ]}
        maxWidth={['17rem']}
        paddingX={['1rem']}
        paddingBottom="1rem"
        paddingTop="1rem"
        border="1px solid"
        borderColor="whiteAlpha.300"
        borderRadius="1.25rem"
        m={0}
        zIndex="99999999"
        backgroundColor="backgroundPrimary.900"
      >
        <Box as="section" overflow="hidden" id="modalFastDeposit">
          {viewCurrentStep[step]}
        </Box>
      </Box>
    </Portal>
  );
};
export default FastDeposit;
