import {
  Box,
  HStack,
  Input,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  VStack
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { FC, useState, useEffect } from 'react';
import { BetLimit } from '../GamesCatalog/types';
interface FilterBetLimitProps {
  data?: BetLimitDataType;
  onChange: (betLimit: BetLimit) => void; // todo
  selectedValue: BetLimit;
}

type BetLimitDataType = {
  min: RangeType;
  max: RangeType;
};

type RangeType = {
  min: number;
  max: number;
  step?: number;
  translationKey: string;
};

const FilterBetLimit: FC<FilterBetLimitProps> = ({
  onChange,
  selectedValue
}) => {
  const { t } = useTranslation();
  const [limit, setLimit] = useState<any>({
    ...selectedValue
  });

  useEffect(() => {
    setLimit({ ...selectedValue });
  }, [selectedValue]);

  const usedData: any = Object.assign({
    // todo: refactor this to an object array
    min: {
      min: 0,
      max: 10,
      step: 0.1,
      translationKey: t('common:betMinimum')
    },
    max: {
      min: 10,
      max: 10000,
      step: 50,
      translationKey: t('common:betMaximum')
    }
  });
  return (
    <VStack align="baseline" width="100%" spacing="2rem">
      {Object.keys(usedData).map((m: string, k: number) => {
        const mData: RangeType = usedData[m];
        return (
          <VStack key={k} align="baseline" width="100%" spacing="1rem">
            <HStack width="100%" justifyContent="space-between">
              <Text
                fontSize="1rem"
                fontWeight="bold"
                textTransform="uppercase"
                color="customGrey.900"
              >
                {mData.translationKey}
              </Text>
              <HStack>
                <Input
                  border="none"
                  padding="0"
                  width="auto"
                  maxWidth="3rem"
                  fontSize="1rem"
                  fontWeight="bold"
                  color="customGrey.900"
                  type="number"
                  value={limit[m]}
                  min={mData.min}
                  max={mData.max}
                  onBlur={() => {
                    onChange({
                      ...selectedValue,
                      ...limit
                    });
                  }}
                  // onChange={(e) => {
                  //   setLimit({
                  //     ...selectedValue,
                  //     [m]: parseFloat(e.target.value)
                  //   });
                  // }}
                />
                <Text fontSize="1rem" fontWeight="bold" color="customGrey.900">
                  €
                </Text>
              </HStack>
            </HStack>
            <Slider
              key={k}
              defaultValue={limit[m]}
              min={mData.min}
              max={mData.max}
              step={mData.step}
              value={Number(limit[m])}
              onChange={(e) => setLimit({ ...limit, [m]: e })}
              width="calc(100% - 1.75rem)"
              onChangeEnd={() =>
                onChange({
                  ...selectedValue,
                  ...limit
                })
              }
            >
              <SliderTrack
                height="0.7rem"
                borderRadius="1rem"
                bg="whiteAlpha.600"
              >
                <Box position="relative" right={10} />
                <SliderFilledTrack bg="buttonPrimary" />
              </SliderTrack>
              <SliderThumb
                backgroundColor="buttonPrimary"
                boxSize="1.75rem"
                boxShadow="0px 2px 10px 1px rgba(0, 0, 0, 0.5)"
              >
                <Box backgroundColor="white" boxSize={2} borderRadius="full" />
              </SliderThumb>
            </Slider>
          </VStack>
        );
      })}
    </VStack>
  );
};

export default FilterBetLimit;
