import React from 'react';
import { Tag, TagProps } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';

const NewGame = ({ ...props }: TagProps) => {
  const { t } = useTranslation();

  return (
    <Tag
      backgroundColor="buttonSecondary"
      color="buttonTextSecondary"
      variant="solid"
      fontWeight="bold"
      borderRadius="9px"
      textTransform="uppercase"
      {...props}
    >
      {t('common:new')}
    </Tag>
  );
};

export default NewGame;
