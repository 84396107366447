import { CheckedIcon, WarningIcon } from '@/theme/Icons';
import { Text, Box, Divider, Button, HStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
export type FastDepositResponseTypes = {
  isSuccess: boolean;
  callback: () => void;
};
const FastDepositResponse = ({
  isSuccess,
  callback
}: FastDepositResponseTypes) => {
  const { t } = useTranslation();
  const BoxMotion = useMemo(() => motion(Box), []);

  const Success = () => {
    return (
      <>
        <HStack style={{ marginBottom: '1rem' }}>
          <CheckedIcon
            border="2px solid green"
            borderRadius="full"
            padding="3px"
            marginRight="1rem"
            width={30}
            height={30}
          />
          <Text>{t('wallet:fastDepositSuccess')}</Text>
        </HStack>

        <Divider />
        <Button
          backgroundColor="buttonPrimary"
          colorScheme="blackAlpha"
          onClick={callback}
          marginTop="1rem"
        >
          {t('wallet:fastDepositNewCashin')}
        </Button>
      </>
    );
  };
  const Error = () => {
    return (
      <>
        <WarningIcon color={'buttonPrimary'} width={30} height={30} />
        <Text style={{ paddingBottom: '1rem' }}>
          {t('wallet:fastDepositErrorMsg')}
        </Text>
        <Divider />
        <Button
          backgroundColor="buttonPrimary"
          colorScheme="blackAlpha"
          onClick={callback}
          marginTop="1rem"
        >
          <Text>{t('wallet:fastDepositRetry')}</Text>
        </Button>
      </>
    );
  };
  return (
    <BoxMotion
      initial={{ opacity: 0, x: 2000 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{
        x: -2000,
        duration: 0.7,
        opacity: 0
      }}
      transition={{
        ease: 'linear',
        duration: 0.4
      }}
      data-testid="FastDepositResponse"
    >
      {isSuccess ? <Success /> : <Error />}
    </BoxMotion>
  );
};
export default FastDepositResponse;
