import { getRef } from '@/helpers/jackpot';
import {
  SVGModalHitBronze,
  SVGModalHitGold,
  SVGModalHitSilver
} from '@/components/Jackpots';

type SVGModalHitProps = {
  level: ReturnType<typeof getRef>;
  amount: string;
};

export const SVGModalHit = ({ level, amount }: SVGModalHitProps) => {
  switch (level) {
    case '1':
      return <SVGModalHitGold amount={amount} />;
    case '2':
      return <SVGModalHitSilver amount={amount} />;
    case '3':
      return <SVGModalHitBronze amount={amount} />;
  }
};
