import { SupportIcon } from '@/theme/Icons';
import {
  Button,
  ButtonProps,
  Icon,
  IconProps,
  Stack,
  StackProps,
  Text,
  TextProps
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';

type SupportButtonModalProps = StackProps &
  ButtonProps & {
    iconProps?: IconProps;
    textProps?: TextProps;
    onOpen?: (status: boolean) => void;
    withoutLabel?: boolean;
  };

const SupportButtonModal = ({
  iconProps,
  textProps,
  onOpen,
  withoutLabel = false,
  ...props
}: SupportButtonModalProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack
        alignItems="center"
        justifyContent="center"
        as={Button}
        onClick={() => {
          window.FreshworksWidget?.('open');
          onOpen?.(false);
        }}
        data-testid="support-button"
        {...props}
      >
        <Icon
          as={SupportIcon}
          color="white"
          marginRight="0.5rem"
          {...iconProps}
        />
        {!withoutLabel ? (
          <Text
            whiteSpace="nowrap"
            fontSize="1rem"
            margin="0 !important"
            {...textProps}
          >
            {t('common:serviceSupport')}
          </Text>
        ) : null}
      </Stack>
    </>
  );
};

export default SupportButtonModal;
