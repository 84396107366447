import { useEffect } from 'react';

const UseIframeEvent = ({ callback }: { callback: () => void }) => {
  useEffect(() => {
    const handleMessage = (e: MessageEvent<any>) => {
      if (e.data.source === 'local') {
        const { event } = e.data;
        if (event === 'close') {
          callback();
        }
      }
      if (e.data === 'closeGame') {
        // for airdice
        callback();
      }

      if (e.data.event === 'GameExited') {
        // for True Odds
        callback();
      }

      if (e.data.type === 'rp.g2w.RedirectToLobby') {
        // for RubyPlay
        callback();
      }
    };
    window.addEventListener('message', handleMessage, false);
    return () => window.removeEventListener('message', handleMessage, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
export default UseIframeEvent;
