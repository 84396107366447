/* eslint-disable react-hooks/exhaustive-deps */
import GTM from '@/helpers/googleTagManager';
import { toEuro } from '@/helpers/number';
import { useFormatCurrency, CurrencyBase } from '@/hooks/useFormat';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  HStack,
  Spinner,
  Text,
  useToast
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { HTTP, WalletCashInForm, WalletCashInSummary } from '../../..';
import { useAuth } from '../../../../context/Auth';
import { useWallet } from '../../../../context/Wallet';
import { PaymentMethod } from './CashInForm';

const CashIn = () => {
  const [maxDeposit, setMaxDeposit] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [summaryProps, setSummaryProps] = useState<any>();
  const [walletError, setWalletError] = useState<JSX.Element | null>(null);
  const { setAside, balance, getBalance, minimumCashInRequired } = useWallet();
  const { userData, isLoginFetching } = useAuth();
  const router = useRouter();

  const { t, lang } = useTranslation('common');
  const toast = useToast();

  useEffect(() => {
    getBalance?.();
  }, []);

  useEffect(() => {
    if (Object.keys(balance || {}).length) {
      getWalletData();
    }
    // clean-up function for removing 3rd column (on tab change)
    return () => setAside(null);
  }, [balance]);

  const depositLimit = useFormatCurrency({
    amount: toEuro(balance?.depositLimit || 0),
    maximumFractionDigits: 0,
    currencyBase: CurrencyBase.CENT
  });
  const minimum = useFormatCurrency({
    amount: toEuro(minimumCashInRequired),
    maximumFractionDigits: 0,
    currencyBase: CurrencyBase.CENT
  });

  const getWalletData = () => {
    // temp fake data
    try {
      setMaxDeposit(balance?.available || 0);
      if (parseFloat(balance?.available + '') < minimumCashInRequired) {
        setWalletError(
          <div style={{ padding: '0.5em 2em 0.5em 2em' }}>
            <Text as="h3" textStyle="h3">
              {t('wallet:maximumDepositReached')}
            </Text>
            <Text>
              {t('wallet:comeBackInFewDays', {
                depositLimit,
                minimum
              })}
            </Text>
          </div>
        );
      }
    } catch (e: any) {
      setWalletError(e?.message || t('wallet:serviceNotAvailable'));
    }
    setIsLoading(false);
  };

  const doSubmitRequest = async () => {
    setSummaryProps({ ...summaryProps, isLoading: true });

    try {
      const { amount, provider, method } = summaryProps;
      console.log('{ amount, provider, method }: ', {
        amount,
        provider,
        method
      });
      const { data } = await HTTP.post('/wallet/deposit', {
        amount: parseFloat(amount) * 100, // need eurocents
        // todo: add following to ge-payment (order)
        currency: 'EUR',
        provider,
        method,
        language: lang
      });

      if (data.status === 'OK' && data.redirectUrl) {
        setAside(null);
        setSummaryProps({});
        setWalletError(
          <div style={{ padding: '0.5em 2em 0.5em 2em' }}>
            <h2>{t('wallet:redirectingPayment')}...</h2>
            <p>
              {t('wallet:automaticallyRedirected')}{' '}
              <a href={data.redirectUrl}>{t('common:clickHere')}</a>
            </p>
          </div>
        );

        // temp solution until we implement this feature in backend
        // idea > is first deposit if
        //  - balance = 0(hard to reach except at start, before any deposit)
        //  - deposit limit is equal to unsolicited amount

        // FIX SOLUTION DEPLOYED: USE alreadyDeposited property of me()
        const isFirstTimeDeposit = (): boolean | undefined => {
          return !userData?.alreadyDeposited;
        };

        const eventCallback = () => {
          router.push(data.redirectUrl);
        };

        GTM.ecommerceEvent(
          'begin_checkout',
          amount * CurrencyBase.EURO,
          { method, provider } as PaymentMethod,
          userData,
          balance,
          isFirstTimeDeposit(),
          undefined,
          eventCallback
        );

        router.push(data.redirectUrl);
      } else {
        setWalletError(<h3>{t('wallet:errorWithDeposit')}</h3>);
      }
      setTimeout(() => setWalletError(null), 3000);
    } catch (error: any) {
      const errorMessage = error.data?.error || t('wallet:errorCashIn');
      setWalletError(errorTextList.backendError(errorMessage));

      setSummaryProps({ ...summaryProps, isLoading: false });
    }
  };

  const errorTextList = {
    legalLimit: (
      <div style={{ padding: '0.5em 2em 0.5em 2em' }}>
        <h3>{t('wallet:youAreAbove')}</h3>
        <p>
          {t('wallet:weNeedYour')}
          {t('wallet:validationAround')}
        </p>
        <Link href="/me/settings/depositLimit">
          <a>{t('wallet:goToSettings')}</a>
        </Link>
      </div>
    ),
    capLimit: (
      <div style={{ padding: '0.5em 2em 0.5em 2em' }}>
        <h3>{t('wallet:youAreAbove')}</h3>
        <p>{t('wallet:youCanDirectly')}</p>
        <Link href="/me/settings/depositLimit">
          <a>{t('wallet:goToSettings')}</a>
        </Link>
      </div>
    ),
    backendError: (errorMessage: string) => (
      <Alert
        status="error"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <AlertIcon />
        <AlertTitle>{t('wallet:error')}!</AlertTitle>
        <AlertDescription paddingY={3}>{errorMessage}</AlertDescription>
        <Button
          mt={'1'}
          onClick={() => getBalance?.().then(() => setWalletError(null))}
        >
          {t('common:next')}
        </Button>
      </Alert>
    )
  };
  // For Aside
  const updateAside = (provider?: string, method?: string, amount?: number) => {
    if (!balance) return;
    const selectedAmount = amount || 0;
    const minimunAmount = 5;
    if (selectedAmount > balance.depositLimit - balance.used) {
      // Error: above weekly legal limit
      setAside(errorTextList.legalLimit);
    } else if (selectedAmount > balance.available) {
      // Error: above weekly-cap
      setAside(errorTextList.capLimit);
    } else if (selectedAmount < minimunAmount) {
      // Error: min 5
      setAside(t('wallet:minimumAmountIs', { amount: minimunAmount }));
    } else setSummaryProps({ provider, method, amount, isLoading: false });
  };

  useEffect(() => {
    summaryProps &&
      balance &&
      setAside(
        <WalletCashInSummary
          {...summaryProps}
          maxDeposit={maxDeposit || 0}
          onSubmit={doSubmitRequest}
        />
      );
  }, [summaryProps, maxDeposit, balance]);

  if (isLoading || isLoginFetching) {
    return (
      <HStack>
        <Text textStyle="h3" as="h3">
          {t('wallet:loadingWallet')}
        </Text>
        <Spinner />
      </HStack>
    );
  }

  if (!userData?.permission?.deposit) {
    return (
      <Text textStyle="h2" as="h2">
        {t('wallet:youDontHave')}
      </Text>
    );
  }

  return (
    <Box data-testid="WalletCashIn" height="100%">
      {!walletError && <WalletCashInForm onSelect={updateAside} />}
      {walletError}
    </Box>
  );
};

export default CashIn;
