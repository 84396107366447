/* eslint-disable @next/next/no-img-element */
import {
  Box,
  HStack,
  Input,
  Stack,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, { DragEvent, FC, useRef, useState } from 'react';
import { humanReadableBytes } from '../../helpers/humanReadableBytes';
import { TrashIcon } from '../../theme/Icons';
import { TextWithIcon } from '../GamesCatalog';
import { DropZonePropsType } from './DropZone.types';

const DropZone: FC<DropZonePropsType> = ({
  title,
  acceptedFiles,
  maxFileSize,
  error = null,
  onSetFile,
  onClean,
  children
}) => {
  const { t } = useTranslation();
  const [filePreviewUrl, setFilePreviewUrl] = useState<string | null>(null);
  const toast = useToast();

  const FileErrorToast = ({ title }: { title: string }) =>
    toast({
      status: 'error',
      title,
      position: 'top-right'
    });

  let fileInput = useRef<HTMLInputElement>(null);
  let boxInput = useRef<HTMLInputElement>(null);
  let newFile: File;
  let reader = new FileReader();
  const displayAcceptedFilesTypes = acceptedFiles
    .map((el: string) => el.split('/')[1].toUpperCase())
    .join(', ');

  const handleChange = (e: any) => {
    e.preventDefault();
    onClean?.();
    if (e.hasOwnProperty('dataTransfer')) {
      newFile = e!.dataTransfer!.files[0];
    } else {
      newFile = e.target.files[0];
    }

    reader.onabort = () =>
      FileErrorToast({ title: t('account:filesReadingAborted') });
    reader.onerror = () =>
      FileErrorToast({ title: t('account:filesReadingFailed') });
    reader.onloadend = () => {
      if (!acceptedFiles.includes(newFile?.type))
        return FileErrorToast({
          title: `${t('account:acceptedFiles')} : ` + displayAcceptedFilesTypes
        });
      if (newFile?.size > maxFileSize)
        return FileErrorToast({
          title:
            `${t('account:maximumSize')} : ` +
            humanReadableBytes(String(maxFileSize))
        });

      const file = reader.result;
      newFile && onSetFile(newFile);
      newFile && file && setFilePreviewUrl(file?.toString());
    };
    newFile && reader.readAsDataURL(newFile);
  };

  const handleClick = (e: any) => {
    e.stopPropagation();
    onSetFile(null);
    fileInput.current!.click();
  };

  const handleRootClick = (e: any) => {
    e.stopPropagation();
    fileInput.current!.click();
  };

  const handleRemove = () => {
    onClean?.();
    setFilePreviewUrl(null);
    onSetFile(null);
  };

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.dataTransfer.effectAllowed = 'none';
    e.dataTransfer.dropEffect = 'none';
    e.stopPropagation();
    handleChange(e);
    boxInput.current!.style.backgroundColor = 'rgba(142, 148, 179, 0.1)';
  };

  return (
    <VStack align="baseline" width="100%">
      <Text fontWeight="bold" fontSize="0.8rem">
        {title}
      </Text>
      {!filePreviewUrl || error ? (
        <>
          <Box
            id={`drop-${title}`}
            data-testid={`drop-${title}`}
            width="100%"
            ref={boxInput}
            position="relative"
            border="1px dashed rgba(255, 255, 255, 0.2)"
            borderRadius="20px"
            background="rgba(142, 148, 179, 0.1)"
            onClick={handleRootClick}
          >
            <Input
              cursor="pointer"
              paddingTop="56.25% !important"
              width="100%"
              type="file"
              accept={acceptedFiles.join(',')}
              onChange={handleChange}
              ref={fileInput}
              position="relative"
              opacity="0"
              onDragEnter={(e) => {
                boxInput.current!.style.backgroundColor = 'buttonPrimary';
              }}
              onDragLeave={() =>
                (boxInput.current!.style.backgroundColor =
                  'rgba(142, 148, 179, 0.1)')
              }
              onDrop={handleDrag}
            />
            <HStack
              cursor="pointer"
              position="absolute"
              top="50%"
              width="100%"
              transform="translateY(-50%)"
              onClick={handleClick}
              justifyContent="center"
            >
              <Text color="customGrey.900">{t('account:dropYourFileOr')}</Text>
              <Text fontSize={['sm', 'md']} decoration="underline">
                {t('account:browse')}
              </Text>
            </HStack>
          </Box>
          {error && <p style={{ color: 'red' }}>{error}</p>}
        </>
      ) : (
        <Stack
          padding={['1em', '2em 3.5em']}
          border="1px solid rgba(142, 148, 179, 0.2)"
          borderRadius="20px"
          width="100%"
        >
          {filePreviewUrl && !error && (
            <img src={filePreviewUrl} alt={'preview'} width="100%" />
          )}
          {
            <TextWithIcon
              text={t('common:delete')}
              icon={TrashIcon}
              iconProps={{ width: '15px', height: '15px' }}
              textProps={{
                color: '#fff',
                fontSize: '0.9em',
                fontWeight: 'semibold'
              }}
              style={{ opacity: '0.4' }}
              _hover={{ opacity: '1' }}
              cursor="pointer"
              onClick={handleRemove}
            />
          }
          {children}
        </Stack>
      )}
    </VStack>
  );
};
export default DropZone;
