import { useWallet } from '@/context/Wallet';
import { CurrencyBase, useFormatCurrency } from '@/hooks/useFormat';
import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, { FC } from 'react';
import { ArrowBendIcon, MoneyIcon } from '../../../../theme/Icons';

export interface WalletCashInSummaryProps {
  provider?: string;
  method?: string;
  amount?: number;
  capValue?: number;
  isLoading?: boolean;
  onSubmit: () => void;
  maxDeposit: number;
}

const WalletCashInSummary: FC<WalletCashInSummaryProps> = ({
  provider,
  method,
  amount,
  maxDeposit,
  capValue,
  isLoading,
  onSubmit
}) => {
  const { t } = useTranslation();
  const amountToCent = useFormatCurrency({
    amount: amount ?? 0,
    currencyBase: CurrencyBase.CENT
  });
  const { minimumCashInRequired } = useWallet();
  const minCashIn = useFormatCurrency({ amount: minimumCashInRequired });

  return (
    <VStack
      spacing="3rem"
      textAlign="center"
      data-testid="WalletCashInSummary"
      maxWidth="25rem"
      margin="0 auto"
    >
      {provider && method && amount ? (
        <React.Fragment>
          <Text fontSize="1.5rem" data-testid={'cashin-summary'}>
            {t('wallet:youWillAddToWallet', {
              amount: amountToCent,
              method
            })}
          </Text>

          <HStack
            as="span"
            border="1px solid rgba(142, 148, 179, 0.2)"
            className="blurry"
            borderRadius="2.5rem"
            padding="1rem 1.5rem"
            spacing="1rem"
          >
            <Text fontWeight="bold" fontSize="2rem">
              +{amountToCent}
            </Text>
            <ArrowBendIcon width={30} height={30} />
            <MoneyIcon
              width={30}
              height={30}
              color="buttonPrimary"
              marginLeft="15px"
            />
          </HStack>
          <Text color="customGrey.900" fontSize="0.8rem">
            {t('wallet:youWillBeRedirected')} {t('wallet:oncePaymentConfirmed')}
          </Text>

          <Button
            data-testid="proceed-cash-in"
            onClick={onSubmit}
            backgroundColor="buttonPrimary"
            disabled={isLoading}
            size="lg"
            fontSize="1.2rem"
            width="fit-content"
          >
            {isLoading ? t('common:loadingGeneric') : t('wallet:proceed')}
          </Button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <p>
            {t('wallet:dropUpToSpecifiedAmountPerWeek', {
              dropUpAmount: capValue,
              amount: minCashIn
            })}
          </p>

          <h6 style={{ flex: 1 }}>
            {t('wallet:allowableDepositAmount', {
              amount: Math.max(maxDeposit, 0)
            })}
          </h6>
        </React.Fragment>
      )}
    </VStack>
  );
};

export default WalletCashInSummary;
