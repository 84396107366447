import { HStack, StackProps, VStack } from '@chakra-ui/react';
import { MotionProps } from 'framer-motion';
import { PropsWithChildren } from 'react';
import { MotionVStack } from '../Motion';
import { GameSubTitle, GameTitle } from './Artefacts';
import GameImage from './GameImage';
import { HTTP } from '../Http';
import { BannerType } from '../../types/api/ge-strapi/banner';

type BannerImageAndTitleProps = PropsWithChildren &
  MotionProps &
  StackProps & {
    banner: BannerType;
    fullWidth?: boolean;
  };

const BannerTitleAndImage = ({
  banner,
  fullWidth = false,
  children,
  ...props
}: BannerImageAndTitleProps) => {
  const width = fullWidth ? 'auto' : '288';
  const height = fullWidth ? 'auto' : '208';

  const bannerUrl: string =
    banner.attributes.pictureMobile.data?.attributes?.url || '';
  if (!bannerUrl) return null;

  const pictureSrc = bannerUrl.startsWith('http')
    ? bannerUrl
    : HTTP.defaults.baseURL + '/cms' + bannerUrl;

  return (
    <MotionVStack
      align="baseline"
      key={banner.id}
      width={width + 'px'}
      {...props}
    >
      <GameImage
        href={banner.attributes.redirectUrl}
        data-testid={banner.attributes.title}
        src={pictureSrc}
        width={width}
        height={height}
        style={{ height: '100%' }}
        boxProps={{ position: 'static' }}
      >
        <HStack
          position="absolute"
          bottom={fullWidth ? '0.5rem' : '0'}
          width="100%"
          justifyContent="space-between"
          paddingX="0.8rem"
        ></HStack>
      </GameImage>
      <VStack
        fontSize="sm"
        justifyContent="start"
        alignItems="start"
        width="100%"
        color="rgba(255,255,255,0.6)"
        style={{ marginTop: '1rem' }}
      >
        <GameTitle>{banner?.attributes?.title}</GameTitle>
        <GameSubTitle
          dangerouslySetInnerHTML={{ __html: banner.attributes.description }}
        ></GameSubTitle>
      </VStack>
      {children}
    </MotionVStack>
  );
};

export default BannerTitleAndImage;
