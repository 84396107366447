import React, { PropsWithChildren } from 'react';
import { UserContext } from '../context/Auth';

const HandleAuthRedirection = ({ children }: PropsWithChildren) => {
  const { isLogged } = React.useContext(UserContext);

  return isLogged && children;
};

export default HandleAuthRedirection;
