import {
  JackpotDataMessageType,
  JackpotLevelRefType,
  JackpotLevelType
} from '@/types/api/ge-jackpot/jackpot';
import { formatCurrency } from '@/hooks/useFormat';
import { getHMSFormat } from '@/helpers/time';

export const getRef = (jackpotLevelRef: string) => {
  return ['1', '2', '3'].includes(jackpotLevelRef)
    ? (jackpotLevelRef as '1' | '2' | '3')
    : '3';
};

export const getWinMessage = (
  winMessage: JackpotDataMessageType[],
  languageMessage: string
) => {
  const messageFound =
    winMessage?.find((m) => m.language?.toLowerCase() === languageMessage)
      ?.data || '';

  const fallbackMessage =
    languageMessage !== 'en' && !messageFound
      ? winMessage?.find((m) => m.language?.toLowerCase() === 'en')?.data
      : '';

  return (messageFound || fallbackMessage) ?? '';
};

type GetDropsBeforeI18nKeyAndValue = {
  jackpotLevel: JackpotLevelType;
  timeRemaining: number;
};
export const getDropsBeforeI18nKeyAndValue = ({
  jackpotLevel,
  timeRemaining
}: GetDropsBeforeI18nKeyAndValue) => {
  const ref = getRef(jackpotLevel.ref);
  let value: string;
  let key: string = `jackpot:dropsBeforeRef${ref}`;

  switch (jackpotLevel.type) {
    case 'total_bet':
      value = formatCurrency({
        amount: +jackpotLevel?.maxValue,
        currency: 'EUR',
        maximumFractionDigits: 2
      });

      return {
        key,
        value
      };
    case 'draw_time':
      // Convert seconds to hours, minutes, and seconds
      if (timeRemaining > 0) {
        value = getHMSFormat(timeRemaining);
        return {
          key,
          value
        };
      } else {
        return {
          key: `jackpot:dropsFinishedRef${ref}`,
          value: ''
        };
      }
  }
};

export const getJackpotColor = (
  level: JackpotLevelRefType,
  type = 'border'
) => {
  const ref = getRef(level);
  switch (ref) {
    case '1':
      return `jackpot.gold.${type}`;
    case '2':
      return `jackpot.silver.${type}`;
    case '3':
      return `jackpot.bronze.${type}`;
  }
};
