import { useCallback, useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
export type CountDownSimpleTypes = {
  expiryTs: number;
  currentTimeTs: number;
};
const CountDownSimple = ({ expiryTs, currentTimeTs }: CountDownSimpleTypes) => {
  const { t } = useTranslation();
  const [dateShape, setDateShape] = useState<{
    minutes: string | number;
    seconds: string | number;
  }>({
    minutes: 0,
    seconds: 0
  });
  const setNewTimeCallback = useCallback(() => {
    if (expiryTs && currentTimeTs) {
      const distanceToDate = expiryTs - currentTimeTs;
      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

      let minutes: number | string = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
      );
      let seconds: number | string = Math.floor(
        (distanceToDate % (1000 * 60)) / 1000
      );

      if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`;
      }
      if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`;
      }

      setDateShape({ minutes, seconds });
    }
  }, [currentTimeTs, expiryTs]);

  useEffect(() => {
    setNewTimeCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimeTs, expiryTs]);

  return (
    <Text
      fontSize={'0.7rem'}
      textAlign="center"
      marginTop={'1rem'}
      data-testid="CountDownSimple"
    >
      {t('wallet:fastDepositQrCodeExpire', {
        min: dateShape.minutes,
        sec: dateShape.seconds
      })}
      {/* Le QR code expire dans {dateShape.minutes} min {dateShape.seconds}s */}
    </Text>
  );
};
export default CountDownSimple;
