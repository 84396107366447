import { FC } from 'react';
import { getFallbackLanguage } from '@/helpers/lang';
import { getBrandComponent } from '@/helpers/getBrandComponent';
import { LegalComponentProps } from '@/helpers/legals';

export const LegalComponent: FC<LegalComponentProps> = (props) => {
  const { lang, license, path } = props;

  const componentLang = getFallbackLanguage(lang)?.toUpperCase();
  const componentPath = `${path}${license}License${componentLang}`;

  try {
    const Component = getBrandComponent(componentPath);
    return <Component />;
  } catch (e) {
    return null;
  }
};
