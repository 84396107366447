import { SignInAndRegisterButtons } from '@/components/Button/SignInButton';
import { useFormatCurrency } from '@/hooks/useFormat';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  ButtonProps,
  HStack,
  StackProps,
  useDisclosure
} from '@chakra-ui/react';
import React, { FC, useContext, useEffect } from 'react';
import { WalletButton } from '../../';
import { useAuth } from '@/context/Auth';
import { useWallet, WalletContext } from '@/context/Wallet';
import useTranslation from 'next-translate/useTranslation';
import { NotificationContext } from '@/context/Notification';
import { getLicenseByPath } from '@/utils/multiDomains';
import { useRouter } from 'next/router';
import { ProfileAccountButton } from '@/components/Button/ProfileAccountButton';
import { NotificationButton } from '@/components/Button/NotificationButton';
import { SmarticoButton } from '@/components/Smartico/SmarticoButton';
import { UserMenuButtons } from '@/components/UserMenu/UserMenuButtons';

type CatalogTinyInfo = StackProps &
  ButtonProps & {
    handleClose?: () => void;
    isPlaying?: boolean;
    inMenu?: boolean;
    showLoginButton?: boolean;
    showRegisterButton?: boolean;
    showAllUsersActions?: boolean;
  };

export const CatalogTinyInfo: FC<CatalogTinyInfo> = ({
  handleClose,
  isPlaying,
  inMenu = false,
  showLoginButton = true,
  showRegisterButton = true,
  showAllUsersActions = true,
  ...props
}) => {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationContext);
  const walletContext = useContext(WalletContext);
  const { isLogged, userData, isLoginFetching } = useAuth();
  const { balance, isRetrieved, isGettingBalance } = useWallet();
  const isScreenMobile = useMediaQuery('(max-width: 37.5rem)');

  const { isOpen, onOpen, onClose } = useDisclosure();

  const amount = useFormatCurrency({ amount: balance?.amount });
  const router = useRouter();

  // disable Smartico button on F+
  const showSmarticoButton = getLicenseByPath(router.asPath) !== 'F';

  useEffect(() => {
    if (isLogged) {
      notificationContext.loadUserNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  // useEffect(() => {
  //   if (isOpen) {
  //     notificationContext.setNotificationOverviewOpened(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isOpen]);

  if (!isLogged && !isLoginFetching) {
    return (
      <SignInAndRegisterButtons
        showRegisterButton={showRegisterButton}
        showLoginButton={showLoginButton}
        {...props}
      />
    );
  }

  const onClickNotification = () => {
    if (inMenu && handleClose) {
      handleClose();
    }
    walletContext.setIsFastDepositOpen(false);
  };

  const onClickWallet = () => {
    if (inMenu && handleClose) {
      handleClose();
    }
    notificationContext.setNotificationOverviewOpened(false);
  };

  const flexLeftRight = '0 0 120px';

  if (isScreenMobile && showAllUsersActions && !inMenu) {
    return (
      <HStack
        data-testid="catalog-tiny-info"
        width="100%"
        spacing="0"
        justifyContent={'space-between'}
      >
        <HStack
          flex={flexLeftRight}
          spacing={4}
          justifyContent={'space-between'}
        >
          <NotificationButton
            onClick={onClickNotification}
            borderRadius={0}
            bellSize={'2rem'}
            iconProps={{}}
            bulletPosition={{
              top: '.1rem',
              right: '1.5rem'
            }}
            label={t('notifications:buttonMobileTitle')}
          />

          {showSmarticoButton && (
            <SmarticoButton
              variant="unstyled"
              lineHeight="1.2rem"
              width="2rem"
              minWidth={'none'}
              borderRadius={0}
              ml={'0!'}
              fontSize={'0.8rem'}
              iconProps={{ width: '2rem', height: '2rem', mt: [0] }}
            />
          )}
        </HStack>

        <WalletButton
          onClick={onClickWallet}
          top="-1.2rem"
          target={isPlaying ? '_blank' : undefined}
          flex={'0 0 3.3rem'}
          iconProps={{ width: '2rem', height: '2rem', ml: 1, mb: '1px' }}
        />

        <ProfileAccountButton
          username={userData?.username}
          amount={amount}
          onOpen={onOpen}
          onClose={onClose}
          isOpen={isOpen}
          isLoading={Boolean(!isRetrieved || isGettingBalance)}
          isPlaying={Boolean(isPlaying)}
          flex={flexLeftRight}
          variant="unstyled"
          ml={'0!'}
        />
      </HStack>
    );
  }

  return (
    <>
      {showAllUsersActions ? (
        <HStack
          flexDirection={['row', 'row-reverse']}
          flexWrap={'wrap'}
          spacing={0}
          gap={4}
          justifyContent={['center', 'center', 'center', 'flex-start']}
          {...props}
        >
          <ProfileAccountButton
            username={userData?.username}
            amount={amount}
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
            handleClose={handleClose}
            isLoading={Boolean(!isRetrieved || isGettingBalance)}
            isPlaying={Boolean(isPlaying)}
            flex={['1 1 100%', '0 0 auto']}
            height={'36px'}
            paddingX={'1rem !important'}
          />

          <UserMenuButtons
            handleClose={handleClose}
            onOpen={onOpen}
            onClose={onClose}
            onClickNotification={onClickNotification}
            onClickWallet={onClickWallet}
            showSmarticoButton={showSmarticoButton}
            inMenu={inMenu}
            isPlaying={Boolean(isPlaying)}
            isOpen={isOpen}
            height={'36px'}
          />
        </HStack>
      ) : null}
    </>
  );
};
