import { useAuth } from '@/context/Auth';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React, { FC, useEffect, useState } from 'react';
import Trans from 'next-translate/Trans';
import TermOfUseModalWithCta from '@/components/Modal/TermsOfUseModalWithCta';
import { HTTP } from '@/components';
import { APIUserType } from '@/context/Auth.types';
import PrivacyPolicyModalWithCta from '@/components/Modal/PrivacyPolicyModalWithCta';
const TermsAndConditionsModal = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [needConsent, setNeedConsent] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { userData, setUserData } = useAuth();

  const [consentGiven, setConsentGiven] = useState(false);

  useEffect(() => {
    if (userData) {
      if (!userData.optInTermsAndConditions) {
        setModalVisible(true);
      }
      setNeedConsent(!userData.optInTermsAndConditions);
    }
  }, [userData]);

  const onModalClose = async () => {
    if (needConsent && consentGiven) {
      try {
        setIsLoading(true);
        const { data } = await HTTP.post<APIUserType>(
          `user/${userData?.id}/consent-terms-and-conditions`,
          {}
        );

        if (!data.optInTermsAndConditions) {
          throw new Error("Terms haven't been updated");
        }

        setUserData(data);
        setModalVisible(false);
      } catch {
        toast({
          status: 'error',
          position: 'top-right',
          title: t('common:somethingWrongHappenned')
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div data-testid="terms-and-conditions-modal">
      <Modal
        closeOnOverlayClick={false}
        isOpen={modalVisible}
        onClose={onModalClose}
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader data-testid="terms-and-conditions-modal-header">
            {t('common:hello')}, {userData?.username ?? 'Dear player'}
          </ModalHeader>
          <ModalBody data-testid="terms-and-conditions-modal-body">
            <Box mb={'1rem'} data-testid="updated-terms-body">
              <Trans
                i18nKey={'common:termsHaveBeenUpdated'}
                components={{
                  termsOfUse: (
                    <TermOfUseModalWithCta
                      data-testid="see-terms-of-use"
                      fontWeight={'bold'}
                      color={'brand.1000'}
                      ctaType={'link'}
                    />
                  ),
                  privacyPolicy: (
                    <PrivacyPolicyModalWithCta
                      data-testid="see-privacy-policy"
                      fontWeight={'bold'}
                      color={'brand.1000'}
                      ctaType={'link'}
                    />
                  )
                }}
              />
            </Box>
            <Checkbox
              data-testid="accept-terms"
              mb={'1rem'}
              colorScheme="brand"
              isChecked={consentGiven}
              onChange={() => setConsentGiven((prevState) => !prevState)}
            >
              <b>{t('common:acceptTerms')}</b>
            </Checkbox>
            <Divider mb={'1rem'} />
            <b>{t('common:rememberResponsible')}</b>
            <br />
            {t('common:safeTravel')}
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button
              data-testid="terms-and-conditions-modal-close"
              backgroundColor="buttonPrimary"
              colorScheme="blackAlpha"
              onClick={onModalClose}
              isDisabled={isLoading || (needConsent && !consentGiven)}
              isLoading={isLoading}
            >
              {t('common:gotIt')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default TermsAndConditionsModal;
