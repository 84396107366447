import { useAppSettings } from '@/context/AppSettings';
import { useExtraProps } from '@/pages/_app';
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  YoutubeIcon
} from '@/theme/Icons';
import { HStack, IconProps, Link } from '@chakra-ui/react';

export type SocialLinksIconsKey = 'fb' | 'is' | 'yt' | 'tw';
export type SocialLinksMappingsType = Record<
  SocialLinksIconsKey,
  (props: IconProps) => React.ReactNode
>;
export type SocialLinksBodyType = {
  icon: keyof SocialLinksMappingsType;
  url: string;
};
export type SocialLinksType = {
  A: SocialLinksBodyType[];
  B: SocialLinksBodyType[];
  F: SocialLinksBodyType[];
  MAIN: SocialLinksBodyType[];
};

const iconsSocialMediaMapping: SocialLinksMappingsType = {
  fb: (props) => <FacebookIcon {...props} />,
  is: (props) => <InstagramIcon {...props} />,
  yt: (props) => <YoutubeIcon {...props} />,
  tw: (props) => <TwitterIcon {...props} />
};

const SocialLinks = () => {
  const config = useAppSettings();
  const links: SocialLinksType = config?.features?.socialMediaLinks;
  const { license } = useExtraProps();
  const currentSocialLinks = links[license];
  return (
    <HStack gap={'.5rem'} marginTop={'20px'}>
      {currentSocialLinks?.map((link) => (
        <Link key={link.url} href={link.url} target="_blank">
          {iconsSocialMediaMapping[link.icon]({
            width: '24px',
            height: '24px'
          })}
        </Link>
      ))}
    </HStack>
  );
};

export default SocialLinks;
