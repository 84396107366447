import { useAppSettings } from '@/context/AppSettings';
import { ButtonProps, IconButton, IconProps } from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC, useContext } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import { MoneyPlusIcon } from '@/theme/Icons';
import FastDeposit from '../UserSettings/Wallet/FastDeposit';
import { WalletContext } from '@/context/Wallet';

export type WalletButtonProps = ButtonProps & {
  target?: React.HTMLAttributeAnchorTarget;
  closeMenu?: () => void;
  iconProps?: IconProps;
  onClick?: () => void;
};

export const WalletButton: FC<WalletButtonProps> = ({
  target = '_self',
  closeMenu,
  iconProps = { width: '1.5rem', height: '1.5rem' },
  onClick,
  ...props
}) => {
  const walletContext = useContext(WalletContext);
  const isMobile = useMediaQuery('(max-width: md)');
  const config = useAppSettings();

  const features = config.features as unknown as {
    components: any;
    paymentProviders: any;
  };
  const isHipayFastDepositEnable =
    features?.paymentProviders?.HIPAY_FAST_DEPOSIT;

  const { pathname, push } = useRouter();
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const isMe = pathname.includes('/me');

  const WithLink = ({
    children,
    isHref
  }: {
    children: JSX.Element;
    isHref: boolean;
  }) => {
    if (isHref || !isHipayFastDepositEnable) {
      return (
        <Link href={'/me/wallet/cash-in'} passHref>
          {children}
        </Link>
      );
    }
    return children;
  };

  return (
    <>
      <WithLink isHref={isMobile}>
        <IconButton
          id={'btn-open-fast-deposit'}
          as="a"
          aria-label="Wallet button"
          data-testid="quick-wallet-button"
          icon={<MoneyPlusIcon {...iconProps} />}
          backgroundColor="buttonPrimary"
          borderRadius="1rem"
          size="3xl"
          width="3.3rem"
          height="3.3rem"
          target={target}
          cursor={'pointer'}
          onClick={(e) => {
            e.stopPropagation();
            onClick?.();
            if (!isMe) {
              walletContext.setIsFastDepositOpen(
                !walletContext.isFastDepositOpen
              );
            } else {
              if (closeMenu) closeMenu();
              push('/me/wallet/cash-in');
            }
          }}
          {...props}
        />
      </WithLink>
      {!isMobile && isHipayFastDepositEnable ? <FastDeposit /> : null}
    </>
  );
};
