import { css } from '@emotion/react';

export const coinsAnimationStyles = css`
  --coin-animation-transform: rotate(0deg);
  --coin-animation-type: ease-in-out infinite alternate;
  --coin-animation-duration: 2s;
  --coin-animation-duration-delay: 0.1s;

  @keyframes coinAnimation {
    0% {
      transform: translate(var(--coin-x, 0), var(--coin-y, 0))
        var(--coin-transform);
    }
    100% {
      transform: translate(var(--coin-x, 0), var(--coin-to-y, 100))
        var(--coin-transform);
    }
  }

  .coin {
    animation: coinAnimation var(--coin-animation-type) var(--coin-duration);
  }
`;
