import BorderedBox from '@/components/Box';
import { HTTP } from '@/components/Http';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { DepositLimit, useWallet } from '@/context/Wallet';
import { humanCurrency } from '@/helpers/humanCurrency';
import { humanDate } from '@/helpers/humanDate';
import {
  CurrencyBase,
  useFormatCurrency,
  useFormatDate
} from '@/hooks/useFormat';
import useTimer from '@/hooks/useTimer';
import { WatchIcon } from '@/theme/Icons';
import {
  Box,
  Button,
  Divider,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import { ChangeEvent, useEffect, useState } from 'react';
import RemainingProgressBar from '../../Wallet/QuickView/RemainingProgressBar';
import GTM from '@/helpers/googleTagManager';
import { useAuth } from '@/context/Auth';

const SettingsWallet = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t, lang } = useTranslation();
  const {
    isOpen: cancelModalisOpen,
    onOpen: cancelModalonOpen,
    onClose: cancelModalonClose
  } = useDisclosure();

  const {
    depositLimit,
    setDepositLimit,
    loadingDepositLimit,
    deadline,
    getDepositLimit,
    balance: userWalletBalance
  } = useWallet();
  const { userData } = useAuth();

  const [currentLimitAmount, setCurrentLimitAmount] = useState(
    (depositLimit?.amount as number) / CurrencyBase.EURO
  );

  const minimumAmount = useFormatCurrency({
    amount: 500
  });

  const defaultLimit = useFormatCurrency({
    amount: 20000
  });

  const presetAmounts = [
    {
      amount: 500,
      label: humanCurrency(500, lang, 'EUR', CurrencyBase.CENT, {
        style: 'currency',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    },
    {
      amount: 1000,
      label: humanCurrency(1000, lang, 'EUR', CurrencyBase.CENT, {
        style: 'currency',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    },
    {
      amount: 2000,
      label: humanCurrency(2000, lang, 'EUR', CurrencyBase.CENT, {
        style: 'currency',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
    }
  ];

  const { days, hours, seconds, minutes } = useTimer(deadline);
  const timeout = days === 0 && hours === 0 && seconds === 0 && minutes === 0;

  const [loadingLimitDemand, setLoadingLimitDemand] = useState(false);

  const toast = useToast();

  const unchangedAmount =
    depositLimit?.amount === currentLimitAmount * CurrencyBase.EURO;

  const pending = depositLimit?.pending && !timeout;

  const pendingAmount = useFormatCurrency({
    amount: depositLimit?.pending?.amount
  });

  const formattedDate = humanDate(deadline?.toString() as string, lang);

  useEffect(() => {
    setCurrentLimitAmount((depositLimit?.amount as number) / CurrencyBase.EURO);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositLimit, pending]);

  const changeLimit = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentLimitAmount(+e?.target?.value);
  };

  const demandLimit = async () => {
    setLoadingLimitDemand(true);
    const amount = currentLimitAmount * CurrencyBase.EURO;
    try {
      GTM.walletEvent(
        'deposit_limit_demand',
        undefined,
        userData,
        userWalletBalance
      );
      const response = await HTTP.post('/user/deposit_limit', {
        amount
      });
      setDepositLimit?.(response.data as DepositLimit);
      setLoadingLimitDemand(false);

      toast({
        status: 'success',
        position: 'top-right',
        title: t('wallet:newLimitSuccess')
      });
    } catch {
      setLoadingLimitDemand(false);
    }
  };

  const resetAmount = () => {
    setCurrentLimitAmount((depositLimit?.amount as number) / CurrencyBase.EURO);
  };

  const cancelDepositLimit = async () => {
    try {
      await HTTP.delete('/user/deposit_limit');
      await getDepositLimit?.();
      cancelModalonClose();
      toast({
        status: 'success',
        position: 'top-right',
        title: t('wallet:cancelLimitSuccess')
      });
    } catch {
      toast({
        status: 'error',
        position: 'top-right',
        title: t('wallet:cancelLimitError')
      });
      cancelModalonClose();
    }
  };

  if (loadingDepositLimit || !depositLimit) {
    return <Spinner />;
  }

  return (
    <VStack
      data-testid="SettingsWallet"
      // maxWidth="45rem"
      align="flex-start"
      spacing="2.5rem"
      marginTop="4rem"
    >
      {pending ? (
        <BorderedBox spacing="2rem">
          <HStack data-testid="deposit-limit-pending">
            <WatchIcon w="1.5rem" h="1.5rem" />
            <Text>
              {t('wallet:pendingRequestWillBeActivated', {
                limit: pendingAmount,
                date: formattedDate
              })}
            </Text>
          </HStack>
          <HStack align="baseline" spacing="1rem">
            <Text
              fontWeight="bold"
              fontSize="3xl"
            >{`${days}d ${hours}h ${minutes}m ${seconds}s`}</Text>
            <Text
              textTransform="lowercase"
              fontSize="0.8rem"
              color="whiteAlpha.600"
            >
              {t('wallet:beforeActivation')}
            </Text>
          </HStack>
          <Divider width="100%" />
          <Button
            variant="link"
            textDecoration="underline"
            textDecorationColor="textDecorationColor"
            onClick={cancelModalonOpen}
          >
            {t('wallet:cancelMyRequest')}
          </Button>
        </BorderedBox>
      ) : (
        <VStack
          width="100%"
          backgroundColor={[
            'transparent',
            'transparent',
            'backgroundPrimaryDarker'
          ]}
          padding={['0', '0', '2rem 3rem']}
          borderRadius="2xl"
          spacing="3rem"
        >
          <Stack
            width="100%"
            flexDirection={['column', 'column', 'row']}
            justifyContent="space-between"
            spacing={['2rem', '2rem', '0']}
            alignSelf={['center', 'center', 'flex-start']}
            minWidth={['240px', '240px', 'auto']}
          >
            <VStack align="flex-start">
              <Text
                width="100%"
                textAlign={['center', 'center', 'start']}
                textStyle="h3"
                flex="1"
              >
                {t('wallet:changeYourWeeklyDepositLimit')}
              </Text>
              <Text display={['none', 'none', 'block']} color="whiteAlpha.600">
                {t('wallet:currentLimit')}
              </Text>
            </VStack>
            <VStack
              flex="1"
              spacing="0"
              marginY={['3rem !important', '3rem !important', '0 !important']}
            >
              <HStack
                width="100%"
                alignItems="baseline"
                justifyContent={['center', 'center', 'flex-end']}
                spacing="0"
              >
                <Input
                  autoFocus
                  data-testid="deposit-limit-input"
                  padding="0"
                  minWidth="170px"
                  maxWidth={[
                    '-webkit-min-content',
                    '-webkit-min-content',
                    '-webkit-fit-content'
                  ]}
                  fontSize="3rem"
                  textAlign={['center', 'center', 'right']}
                  border="none"
                  backgroundColor="transparent"
                  value={currentLimitAmount}
                  type="number"
                  min={5}
                  _focusVisible={{
                    border: 'none'
                  }}
                  onBlur={() => {
                    if (currentLimitAmount < 5) {
                      toast({
                        status: 'error',
                        title: t('settings:minimumLimit', {
                          amount: minimumAmount
                        }),
                        position: 'top',
                        duration: 2000,
                        onCloseComplete: () => setCurrentLimitAmount?.(5)
                      });
                    }
                  }}
                  onChange={changeLimit}
                  onFocus={(e) => {
                    setTimeout(() => changeLimit(e), 100);
                  }}
                  onWheel={(e) => {
                    e.currentTarget.blur();
                    e.stopPropagation();
                  }}
                />
                <Text
                  // width="auto !important"
                  fontWeight="bold"
                  fontSize="1.8rem"
                  // left="105%"
                  // top="20%"
                >
                  {'€'}
                </Text>
              </HStack>
              <Button
                variant="link"
                textDecoration="underline"
                textDecorationColor="textDecorationColor"
                display={['block', 'block', 'none']}
                onClick={resetAmount}
                opacity={unchangedAmount ? 0 : 1}
              >
                {t('common:reset')}
              </Button>
            </VStack>
          </Stack>
          <HStack
            width="100%"
            flexDirection={['column', 'column', 'row']}
            spacing="0.5rem"
            textAlign="center"
            fontSize="0.8rem"
            color="whiteAlpha.600"
            marginTop="5rem !important"
          >
            <Text>
              {t('wallet:defaultLimitIs', {
                limit: defaultLimit
              })}
            </Text>
            <Text>
              {t('wallet:exceedRequestsProcess', {
                limit: defaultLimit
              })}
            </Text>
          </HStack>
          <HStack
            width="100%"
            spacing="0.8rem"
            justifyContent={['center', 'center', 'flex-start']}
            paddingX={['2rem', '2rem', 0]}
          >
            {presetAmounts.map((preset) => (
              <Button
                flex="6rem 0 0"
                variant={'solid'}
                borderRadius="xl"
                data-testid={`preset-amount-${preset.amount}`}
                key={preset.amount}
                height="3.5rem"
                // backgroundColor="rgba(43,43,43,0.8)"
                // color="#F9E5C2"
                // border="1px solid rgba(205, 166, 96, 0.4)"
                onClick={() => setCurrentLimitAmount?.(preset.amount)}
                className="depositBtn"
                disabled={!!pending}
              >
                {preset.label}
              </Button>
            ))}
          </HStack>
        </VStack>
      )}
      <VStack width="100%" spacing="2rem">
        <HStack
          order={[1, 1, 3]}
          width="100%"
          justifyContent={['center', 'center', 'space-between']}
        >
          {unchangedAmount ? (
            <Box />
          ) : (
            <Button
              variant="link"
              textDecoration="underline"
              textDecorationColor="textDecorationColor"
              display={['none', 'none', 'block']}
              onClick={resetAmount}
            >
              {t('common:reset')}
            </Button>
          )}
          <Button
            data-testid="activate-limit-button"
            width="100%"
            alignSelf={['center', 'center', 'flex-end']}
            maxWidth="18rem"
            height="3.5rem"
            isLoading={loadingLimitDemand}
            onClick={demandLimit}
            backgroundColor="buttonPrimary"
            disabled={unchangedAmount}
          >
            {t('wallet:setNewLimit')}
          </Button>
        </HStack>
        <Divider
          display={['none', 'none', 'block']}
          width="100%"
          order="2"
          marginY="3rem !important"
        />
        <Button
          variant="link"
          order={[3, 3, 1]}
          textDecoration="underline"
          whiteSpace="normal"
          textDecorationColor="textDecorationColor"
          alignSelf={['center', 'center', 'flex-start']}
          onClick={onOpen}
        >
          {t('wallet:howMuchCanIDeposit')}
        </Button>
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody padding="2rem">
            <RemainingProgressBar
              remaining={depositLimit?.remaining || 0}
              max={depositLimit?.amount || 20000}
              size="sm"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <ConfirmModal isOpen={cancelModalisOpen} onClose={cancelModalonClose}>
        <ModalHeader>{t('wallet:cancelMyRequest')}</ModalHeader>
        <ModalBody>
          <Button
            data-testid="confirm-cancel-request"
            backgroundColor="buttonPrimary"
            marginTop="1rem"
            onClick={cancelDepositLimit}
            // isLoading={loadingCancel}
          >
            {t('common:confirm')}
          </Button>
        </ModalBody>
      </ConfirmModal>
    </VStack>
  );
};

export default SettingsWallet;
