// this component is currently not used
import { useFilters } from '@/context/Filters';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { StackProps } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { GameImage, ScrollWithoutScrollBar } from '.';
import { ComingSoonPill } from '..';
import useMediaQuery from '../../hooks/useMediaQuery';
import { TrophyIcon } from '../../theme/Icons';
import { SectionTitleWithIcon } from './Artefacts';

type BiggestWinsProps = StackProps & {};

const BiggestWins = ({ ...props }: BiggestWinsProps) => {
  const baseHeight = 17;
  const mobileScales = [1, 1.3, 1.5];
  const desktopScales = [1.3, 1, 1.5];
  const sizes = [54, 64, 54];
  const { t } = useTranslation();

  const { filteredGames } = useFilters();
  const data = filteredGames
    ?.sort((a: any, b: any) => a.sections['forYou'] - b.sections['forYou'])
    ?.slice(0, 3);

  const isMobile = useMediaQuery('(max-width: md)');

  return (
    <VStack
      as="section"
      width="100%"
      maxWidth="1920px"
      margin="0 auto"
      align={['baseline', 'baseline', 'center']}
      id="biggestwins"
      {...props}
    >
      <ComingSoonPill data-inviewport="slide-up">
        <SectionTitleWithIcon icon={TrophyIcon}>
          {t('catalog:biggestWins')}
        </SectionTitleWithIcon>
      </ComingSoonPill>
      <HStack
        align="flex-end"
        justifyContent={['flex-start', 'flex-start', 'flex-start', 'center']}
        css={ScrollWithoutScrollBar}
        spacing="1.8rem"
      >
        {data.map((game, index) => (
          <VStack
            borderRadius="1.8rem"
            borderWidth="1px"
            padding="1rem"
            justifyContent="space-around"
            align="center"
            borderColor="customGrey.900"
            width="26rem"
            height={
              isMobile
                ? `${baseHeight / mobileScales[index]}rem`
                : `${baseHeight / desktopScales[index]}rem`
            }
            key={game['@id']}
            backgroundColor="backgroundPrimaryDarker"
            data-inviewport="scale-up"
          >
            <Text fontWeight="bold" fontSize={`${sizes[index]}px`}>
              {new Intl.NumberFormat('fr-BE', {
                style: 'currency',
                currency: 'EUR'
              }).format(140260.17)}
            </Text>
            <HStack width="100%" justifyContent="space-between">
              <HStack>
                <GameImage
                  src={game?.mediaUrls?.banner_desktop}
                  data-testid={`biggest-wins-${index}`}
                  borderRadius="full"
                  width="3.8rem"
                  height="3.8rem"
                />
                <VStack align="baseline">
                  <Text fontWeight="600" fontSize="md">
                    Kaerobee97
                  </Text>
                  <Text fontWeight="500" fontSize="sm">
                    {t('catalog:atHotPot')}
                  </Text>
                  <Text fontSize="xs" color="whiteAlpha.400">
                    14 min. ago
                  </Text>
                </VStack>
              </HStack>
              <GameImage
                src={game?.mediaUrls?.square}
                data-testid={`biggest-wins-square-${game?.name}`}
                borderRadius="0.8rem"
                width="3.8rem"
                height="3.8rem"
              />
            </HStack>
          </VStack>
        ))}
      </HStack>
    </VStack>
  );
};

export default BiggestWins;
