import {
  ComponentWithAs,
  Icon,
  IconProps,
  Text,
  VStack
} from '@chakra-ui/react';
import Link from 'next/link';
import React, { FC } from 'react';

type ProfileSidenavLinkPropsType = {
  icon: ComponentWithAs<'svg', IconProps>;
  alt: any;
  height: string;
  width: string;
  href: string;
};

const ProfileSidenavLink: FC<ProfileSidenavLinkPropsType> = ({
  icon,
  alt,
  href
}) => {
  return (
    <Link href={href} passHref>
      <VStack as="a" data-testid={href}>
        <Icon as={icon} width="2rem" height="2rem" />
        <Text fontSize="1rem">{alt}</Text>
      </VStack>
    </Link>
  );
};

export default ProfileSidenavLink;
