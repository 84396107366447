import useTranslation from 'next-translate/useTranslation';

const defaultOptions: Intl.DateTimeFormatOptions = {
  // weekday: 'short',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
};

export const numericDateFormatWithoutTime: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
};

const languageMapping: { [key: string]: string } = {
  fr: 'fr-BE',
  nl: 'nl-BE',
  en: 'en-UK'
};

type Keys = keyof typeof languageMapping;
type Values = typeof languageMapping[Keys];

export const useFormatDate = (
  date: string,
  options: Intl.DateTimeFormatOptions = defaultOptions
): string => {
  const { lang } = useTranslation();
  const locale: Values = languageMapping[lang];

  if (!date) return '';

  const convertedDate = new Date(date);
  return new Intl.DateTimeFormat(locale, options).format(convertedDate);
};

export const formatDate = (
  date: string,
  options: Intl.DateTimeFormatOptions = defaultOptions
): string => {
  const locale: Values = navigator.languages[0];

  if (!date) return '';

  const convertedDate = new Date(date);
  return new Intl.DateTimeFormat(locale, options).format(convertedDate);
};

export enum CurrencyBase {
  'CENT' = 1,
  'EURO' = 100
}
type useFormatCurrencyProps = {
  amount?: number;
  currency?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  currencyBase?: CurrencyBase;
};

export const useFormatCurrency = ({
  amount,
  currency = 'EUR',
  maximumFractionDigits = 2,
  currencyBase = CurrencyBase.EURO
}: useFormatCurrencyProps): string => {
  const { lang } = useTranslation();
  const locale: Values = languageMapping[lang];

  if (typeof amount === 'undefined') return '';

  const convertedAmount = amount / currencyBase;
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: maximumFractionDigits,
    maximumFractionDigits
  }).format(convertedAmount);
};

export const formatCurrency = ({
  amount,
  currency = 'EUR',
  maximumFractionDigits = 2,
  currencyBase = CurrencyBase.EURO
}: useFormatCurrencyProps): string => {
  const locale: Values = navigator.languages[0];

  if (typeof amount === 'undefined') return '';

  const convertedAmount = amount / currencyBase;
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: maximumFractionDigits,
    maximumFractionDigits
  }).format(convertedAmount);
};
