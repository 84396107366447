// This HOC can be used to render a component depending on the configuration in {root}/config/{projectName}
// in the features property.
// By default, all components are visible, here we can disable by name.
// Need to provide the component name as 2nd parameter because dev and production build are different and production minify the name of all component.
// so the name of this parameter MUST match the name of the configuration file (oria.js or goldenPalace.js at this time) in features.components.{componentName}

//   Example:
//   features: {
//     components: {
//      Logo: { ==> Name of component
//        disable: false
//       }
//     }
//   }
import { useAppSettings } from '@/context/AppSettings';
import { ComponentType } from 'react';

const WithFeaturesDisable = (
  Component: JSX.Element | ComponentType<{}> | null,
  componentName: string
) => {
  const appSettings = useAppSettings();

  const features: unknown = appSettings.features;
  const currentComponent = (features as { [key in string]: any })?.components[
    componentName
  ];

  const isComponentDisabled: boolean = currentComponent?.disable;

  return isComponentDisabled ? null : Component;
};

export const WithFeatureInlineDisable = (inline: any, featureName: string) => {
  const appSettings = useAppSettings();

  const features: unknown = appSettings.features;
  const currentComponent = (features as { [key in string]: any })?.components[
    featureName
  ];

  const isComponentDisabled: boolean = !!currentComponent?.disable;

  return isComponentDisabled ? null : inline;
};

export default WithFeaturesDisable;
