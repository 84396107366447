import {
  Box,
  Button,
  Grid,
  HStack,
  IconButton,
  MenuButton,
  Stack,
  VStack,
  Link,
  Text,
  TagProps
} from '@chakra-ui/react';
import { motion, Variant, Variants } from 'framer-motion';
import { forwardRef } from 'react';
import NewGame from '../Tag/NewGame';

export const MotionHStack = motion(HStack);
export const MotionVStack = motion(VStack);
export const MotionStack = motion(Stack);
export const MotionGrid = motion(Grid);
export const MotionBox = motion(Box);
export const MotionButton = motion(Button);
export const MotionIconButton = motion(IconButton);
export const MotionMenuButton = motion(MenuButton);
export const MotionLink = motion(Link);
export const MotionText = motion(Text);
export const MotionNewGame = ({
  variants
}: {
  variants: {
    [key: string]: Variant;
  };
}) => (
  <motion.span>
    <NewGame {...variants} />
  </motion.span>
);
export const stagger = {
  animate: {
    transition: {
      staggerChildren: 0.1
    }
  }
};

export const bottomUPChildrenElementAnimation: Variants = {
  initial: {
    y: '200px',
    opacity: 0
  },
  animate: {
    y: '0',
    opacity: 1
  }
};

export const rightToLeftChildrenElementAnimation: Variants = {
  initial: {
    x: '300px',
    opacity: 0
  },
  animate: {
    x: '0',
    opacity: 1
  }
};
export const leftToRightChildrenElementAnimation: Variants = {
  initial: {
    x: '-100px',
    opacity: 0
  },
  animate: {
    x: '0',
    opacity: 1
  }
};
