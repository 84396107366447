import { Form } from '@/components/Form/Form';
import Input from '@/components/Form/Input';
import { TextWithIcon } from '@/components/GamesCatalog';
import { useAuth } from '@/context/Auth';
import { CreditCardIcon } from '@/theme/Icons';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast
} from '@chakra-ui/react';
import { AxiosRequestConfig } from 'axios';
import useTranslation from 'next-translate/useTranslation';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HTTP } from '../../../../Http';
import { useSettings } from '../../../SettingsContext';
import { BankAccountActionModalPropsTypes } from './BankAccountActionModal.types';

const BankAccountActionModal: FC<BankAccountActionModalPropsTypes> = ({
  isOpen,
  onClose,
  mode
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { userData } = useAuth();
  const {
    bankAccounts,
    selectedBankAccount,
    getBankAccounts,
    getDefaultBankAccount,
    resetSelectedBankAccount
  } = useSettings();
  const [ibanError, setIbanError] = useState<string>();

  const [iban, setIban] = useState<string>(selectedBankAccount?.iban);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const form = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all'
  });

  const modes: any = {
    add: {
      method: 'post',
      endpoint: '/user/bank_accounts',
      modalText: {
        title: t('form:addNewBankAccount'),
        button: t('form:add')
      }
    },
    edit: {
      method: 'put',
      endpoint: `user/bank_accounts/${selectedBankAccount?.id}`,
      modalText: {
        title: t('form:editBankAccount'),
        button: t('form:edit')
      }
    },
    delete: {
      method: 'delete',
      endpoint: `user/bank_accounts/${selectedBankAccount?.id}`,
      modalText: {
        title: t('form:deleteBankAccount'),
        button: t('form:delete')
      }
    }
  };

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!userData?.id) return;
    setIbanError('');

    const payload = () => {
      if (mode !== 'delete')
        return {
          user: `/users/${userData.id}`,
          iban: iban,
          bic: ''
        };
    };

    const toastMessage: any = {
      add: {
        onSuccess: {
          title: t('form:newBankAccountCreated'),
          description: t('form:ibanAdd', { iban })
        }
      },
      edit: {
        onSuccess: {
          title: t('form:bankAccountEdited')
        }
      },
      delete: {
        onSuccess: {
          title: t('form:bankAccountDeleted')
        }
      }
    };

    const accountAlreadyExist = bankAccounts?.filter(
      (el: { iban: string }) => el.iban === iban
    ).length;

    if (mode !== 'delete' && accountAlreadyExist) {
      toast({
        title: t('common:warning'),
        description: t('form:ibanAlreadyExist', {
          iban
        }),
        status: 'warning',
        position: 'top',
        duration: 4000,
        isClosable: true
      });
    } else {
      const params: AxiosRequestConfig = {
        method: modes[mode]?.method,
        data: payload(),
        url: modes[mode]?.endpoint
      };

      try {
        setIbanError('');
        setIsLoading(true);
        const response = await HTTP.request(params);
        if ([200, 201, 204].includes(response.status)) {
          setIsLoading(false);
          resetSelectedBankAccount?.();
          toast({
            title: toastMessage[mode]?.onSuccess.title,
            description: toastMessage[mode]?.onSuccess.description,
            status: 'success',
            position: 'top',
            duration: 4000,
            isClosable: true
          });
          await getBankAccounts?.();
          await getDefaultBankAccount?.();
          onClose();
        }
      } catch (error: any) {
        setIsLoading(false);
        setIbanError(error?.data.detail);
        // toast({
        //   title: t('common:error'),
        //   description: error?.data.detail,
        //   status: 'error',
        //   position: 'top',
        //   duration: 4000,
        //   isClosable: true
        // });
      }
    }
  };

  const handleIban = (event: ChangeEvent<HTMLInputElement>) => {
    setIban(event.target.value);
    if (ibanError) setIbanError('');
  };

  // clean input
  useEffect(() => {
    if (mode === 'add' && isOpen) {
      resetSelectedBankAccount?.();
    }
    setIban(selectedBankAccount?.iban);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, selectedBankAccount]);

  return (
    <Modal
      isCentered
      data-testid="bank-account-modal"
      motionPreset="slideInBottom"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        maxWidth={['90%', '90%', '25rem']}
        paddingX={['1rem', '1rem', '1rem']}
        paddingTop={['1rem', '1rem', '2rem']}
        paddingBottom="1rem"
        border="1px solid"
        borderColor="whiteAlpha.300"
        borderRadius="1.25rem"
      >
        <ModalHeader>
          <TextWithIcon
            data-testid="title"
            text={modes[mode]?.modalText.title}
            icon={CreditCardIcon}
            iconProps={{ width: '25px', height: '25px' }}
            textProps={{
              color: '#fff',
              fontSize: '1em'
            }}
          />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Form {...form}>
            <>
              {mode !== 'delete' && (
                <>
                  <Input
                    name="add-bank-account"
                    data-testid="add-bank-account"
                    type="text"
                    fontSize="1rem"
                    padding="1rem"
                    marginTop="1rem"
                    id="iban"
                    value={iban}
                    onChange={handleIban}
                    autoFocus
                    isRequired
                    isInvalid={!ibanError}
                  />

                  {iban && ibanError && (
                    <Text color={'coral'}>{ibanError}</Text>
                  )}
                </>
              )}

              <Button
                data-testid="add-bank-account-button"
                alignSelf="flex-end"
                isLoading={isLoading}
                backgroundColor="buttonPrimary"
                size="md"
                type="submit"
                onClick={onSubmit}
                fontSize="1em"
                fontWeight="bold"
                margin="1.5em 0 1em 0 !important"
                disabled={!iban}
              >
                {modes[mode]?.modalText.button}
              </Button>
            </>
          </Form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default BankAccountActionModal;
