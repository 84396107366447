import { JackpotLevelType } from '@/types/api/ge-jackpot/jackpot';
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import { LightCloseIcon } from '@/theme/Icons';
import React from 'react';
import { formatCurrency } from '@/hooks/useFormat';
import useTranslation from 'next-translate/useTranslation';
import { getJackpotColor } from '@/helpers/jackpot';

type WinnersBoxProps = {
  jackpotLevel: JackpotLevelType;
  onClose: () => void;
};
export const JackpotLevelWinners = ({
  jackpotLevel,
  onClose
}: WinnersBoxProps) => {
  const { t } = useTranslation();

  const color = getJackpotColor(jackpotLevel.ref);

  return (
    <Box
      width={'100%'}
      border={'1px solid'}
      borderColor={color}
      borderRadius={'9.5px'}
      bg={'#141414'}
      padding={'1.25rem'}
      color={'white'}
    >
      <TableContainer>
        <Table variant="jackpot">
          <Thead borderColor={color}>
            <Tr>
              <Th fontSize={'0.75rem'} color={color} padding={'0'}>
                {t('jackpot:player')}
              </Th>
              <Th fontSize={'0.75rem'} color={color} padding={'0'}>
                {t('jackpot:prize')}
              </Th>
              <Th fontSize={'0.75rem'} color={color} padding={'0'}>
                {t('jackpot:date')}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {jackpotLevel?.hits?.map((winner) => (
              <Tr key={`jackpot-${jackpotLevel.publicId}-${winner?.userId}`}>
                <Td>{winner.username}</Td>
                <Td>
                  {formatCurrency({
                    amount: winner.amount,
                    currency: winner.currency
                  })}
                </Td>
                <Td>
                  {new Date(winner.createdAt).toLocaleString(undefined, {
                    dateStyle: 'short',
                    timeStyle: 'short'
                  })}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Box
        bg={'buttonPrimary'}
        display={'block'}
        borderRadius={'100%'}
        width={'1.5rem'}
        height={'1.5rem'}
        textAlign={'center'}
        cursor={'pointer'}
        margin={'1rem auto 0 auto'}
        onClick={onClose}
      >
        <LightCloseIcon />
      </Box>
    </Box>
  );
};
