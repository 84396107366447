export function daysLeftFromNow(date: number, now?: number) {
  now ||= Date.now();

  // The number of milliseconds in one day
  const ONE_DAY = 1000 * 60 * 60 * 24;

  // Calculate the difference in milliseconds
  const differenceMs = date - now;

  // Convert back to days and return
  return differenceMs / ONE_DAY;
}

export function hoursFromNow(date: number, now?: number) {
  now ||= Date.now();

  // The number of milliseconds in one day
  const ONE_HOUR = 1000 * 60 * 60;

  // Calculate the difference in milliseconds
  const differenceMs = date - now;

  // Convert back to hours and return
  return differenceMs / ONE_HOUR;
}

export function minutesFromNow(date: number, now?: number) {
  now ||= Date.now();
  // The number of milliseconds in one day
  const ONE_MINUTE = 1000 * 60;

  // Calculate the difference in milliseconds
  const differenceMs = date - now;

  // Convert back to minutes and return
  return differenceMs / ONE_MINUTE;
}

export const frenchFormattedDate = (date: Date) => {
  const yyyy = date.getFullYear();
  let mm: number | string = date.getMonth() + 1; // Months start at 0!
  let dd: number | string = date.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  return dd + '/' + mm + '/' + yyyy;
};
